import serviceUtil from ".././../../index";
// const config = {
//     headers: {
//       "content-type": "multipart/form-data",
//     },
//   };
const PackageBookingListUpComing = (email , id) => {
  return serviceUtil
    .get(`myBooking/packageBookingList?email=${email}&id=${id}`)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const getPackageBookingById = async (id) => {
  return await serviceUtil
    .get(`myBooking/packageBookingView/${id}`)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

export { PackageBookingListUpComing, getPackageBookingById };
