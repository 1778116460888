/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faFilter,
  //  faLocationDot
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import BottomFooter from "../../newUserLayout/userFooter/BottomFooter";
import UserSideBarModel from "./../../newUserLayout/userSidebarModel/index";
import PackageCard from "./../../../../components/atoms/userAtoms/packageCard/index";
import { Col, Row, Grid } from "antd";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import UserSideBar from "../../newUserLayout/userSideBar";
import // Packagefilter,
//  Packagefilter
"../../../../services/utils/userUtils/userPackageUtils/index";
import { packageSettingPackageCategoryGetAll } from "../../../../services/utils/admin/manageMaster/packageSettings/PackageCategoryService";
import { packageSettingPackageTypeGetAll } from "../../../../services/utils/admin/manageMaster/packageSettings/PackageTypeService";
// import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchPackageData } from "../../../../reduxData/sliceData/packageReducer";
import { filterSearchfun } from "../../../../services/utils/userUtils/dynamicFun";
// import OtherFunctionsObject from "../../../../services/utils/userUtils/dynamicFun/functionlist";
import { UserLoader } from "../../../../components/atoms/userLoader";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import PackageSeo from "../../../../constants/seo/packageSeo";

const objectStructure = [
  ["packageName"],
  ["state", "stateName"],
  ["place", "destinationName"],
];

const { useBreakpoint } = Grid;
const priceRangeObj = {
  "Up to 10000": { min: 0, max: 10000 },
  "10000-20000": { min: 10000, max: 20000 },
  "20000-30000": { min: 20000, max: 30000 },
  "30000-40000": { min: 30000, max: 40000 },
  "40000-50000": { min: 40000, max: 50000 },
  "50000-60000": { min: 50000, max: 60000 },
  "60000+": { min: 60000, max: 1000000 },
};

const INTERNATIONAL_STR_VALUE = "International";
const DOMESTIC_STR_VALUE = "Domestic";

function PackageOtherPage() {
  const nav = useNavigate();
  const [modelOpen, setmodelOpen] = useState(false);
  const [getData, setGetData] = useState([]);
  const [searchData, setsearchData] = useState("");
  const [filterData, setfilterData] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [packageTypeData, setpackageTypeData] = useState([]);
  const [selectType, setselectType] = useState([]);
  const [selectedCategory, setselectedCategory] = useState([]);
  const [priceRangeValue, setPriceRangeValue] = useState({});
  // const [starRatingValue, setstarRatingValue] = useState([]);
  const [isHighlighteddDomestic, setHighlightedDomestic] = useState(true);
  const [isHighlighteddInternational, setHighlightedInternational] =
    useState(false);
  const loading = useSelector((state) => state.packageAllData.loading);
  // const error = useSelector((state) => state.hotelAllData.loading);

  // ________________________________________________________________

  const dispatch = useDispatch();

  const getPackageDetilas = async () => {
    const data = await dispatch(fetchPackageData());
    setGetData(data.payload.response);
    setfilterData(data.payload.response);
    setPackageList(data.payload.response);
  };

  // _________________________________________________________________
  const { lg } = useBreakpoint();

  const searchOnChange = ({ target: { value: sval } }) => {
    setsearchData(sval);
    const filldata = filterSearchfun(sval, getData, objectStructure);
    setfilterData(filldata);
    setPackageList(filldata);
  };

  // _________________________________________________________________________________

  //package Category get All

  const getPackageRoomCategoryfun = async () => {
    const { data } = await packageSettingPackageCategoryGetAll();
    setcategoryData(data.response);
  };

  // _____________________________________________________________________________________

  //package room Type get all
  const getPackageTypeAll = async () => {
    const { data } = await packageSettingPackageTypeGetAll();
    setpackageTypeData(data.response);
  };

  const packageTypefun = (e) => {
    if (e.target.checked) {
      setselectType((prevState) => {
        return [...prevState, e.target.value];
      });
    } else if (!e.target.checked) {
      setselectType((prevState) => {
        let tempData = [...prevState];
        let newData = tempData.filter((data) => data !== e.target.value);
        return [...newData];
      });
    }
  };
  const priceRangeFun = (checkedValues) => {
    checkedValues &&
      setPriceRangeValue(() => {
        let priceRange = checkedValues?.map((key) => priceRangeObj[key]);
        return [...priceRange];
      });
  };

  const starHandleChange = (e, i) => {
    !e && console.log(e, i);
  };

  const categoryfun = (e) => {
    if (e.target.checked) {
      setselectedCategory((prevState) => {
        return [...prevState, e.target.value];
      });
    } else if (!e.target.checked) {
      setselectedCategory((prevState) => {
        let tempData = [...prevState];
        let newData = tempData.filter((data) => data !== e.target.value);
        return [...newData];
      });
    }
  };

  const handleDomesticButtonClick = () => {
    nav("/domestic-package");
    setHighlightedDomestic((prevstate) => !prevstate);
    setHighlightedInternational(false);
  };
  const handleInternationalButtonClick = () => {
    nav("/international-package");
    setHighlightedInternational((prevstate) => !prevstate);
    setHighlightedDomestic(false);
  };

  // _____________________________________________________________
  
  useEffect(() => {
    filterPackageList();
  }, [
    isHighlighteddInternational,
    isHighlighteddDomestic,
    priceRangeValue,
    selectedCategory,
    selectType,
  ]);

  const filterPackageList = () => {
    let filterPackageData = packageList;

    if (isHighlighteddInternational) {
      filterPackageData = filterPackageData.filter(
        (data) => data?.area === INTERNATIONAL_STR_VALUE
      );
    }

    if (isHighlighteddDomestic) {
      filterPackageData = filterPackageData.filter(
        (data) => data?.area === DOMESTIC_STR_VALUE
      );
    }

    //Price Range Filter
    if (priceRangeValue.length) {
      filterPackageData = filterPackageData.filter((item) => {
        const basicRate =
          item?.promotionId !== null
            ? parseInt(
                item?.packageBasicRate * (1 - item?.promotionId?.value / 100)
              )
            : item?.discountRate;
        const isInRange = priceRangeValue.every(
          (range) => basicRate >= range.min && basicRate <= range.max
        );
        return isInRange;
      });
    }

    // Category Filter
    if (selectedCategory.length) {
      filterPackageData = filterPackageData.filter((item) =>
        item?.packageCategory?.some((cat) =>
          selectedCategory.includes(cat?.packageCategory)
        )
      );
    }

    // Type filter
    if (selectType.length) {
      filterPackageData = filterPackageData.filter((item) =>
        selectType.includes(item?.packageType?.packageType)
      );
    }
    setfilterData(filterPackageData);
  };

  const getApifilter = async () => {
    setmodelOpen(false);
  };
  // _______________________________________________________________________

  useEffect(() => {
    getPackageDetilas();
    getPackageRoomCategoryfun();
    getPackageTypeAll();
  }, []);

  const sideBar = (
    <UserSideBar
      title="Search"
      searchValue={searchData}
      searchOnChange={searchOnChange}
      star={{
        starName: "Rating",
        starData: [1, 2, 3, 4, 5],
        handleChange: starHandleChange,
      }}
      priceRangeFun={priceRangeFun}
      priceRange={{
        priceRangeName: "Price Range",
        // priceRangeValue: pricelebel,
        priceRangeData: [
          "Up to 10000",
          "10000-20000",
          "20000-30000",
          "30000-40000",
          "40000-50000",
          "50000-60000",
          "60000+",
        ],
      }}
      categoryfun={categoryfun}
      category={{
        title: "Package Category",
        categoryName: "packageCategory",
        categoryData: categoryData,
      }}
      type={{
        title: "Package Type",
        typeName: "packageType",
        typeData: packageTypeData,
      }}
      typefun={packageTypefun}
      filterFunction={getApifilter}
      // filterFunction={filterFun}
    />
  );

  return (
    <>
      {loading ? <UserLoader /> : ""}
      <Row justify={"center"}>
        {/* <Col> */}
        {lg ? (
          <Col
            xs={22}
            sm={22}
            md={22}
            lg={5}
            xl={5}
            // style={{ marginLeft: `${lg ? '8%' : '0%'}` }}
          >
            {sideBar}
          </Col>
        ) : (
          ""
        )}

        {/* </Col> */}
        <Col xs={24} sm={24} md={24} lg={17} xl={17} >
          <Row className="pt-4" style={{ paddingLeft: "5px" }}>
            {/* <Space size={"large"}>
              <Button
                size="large"
                className={`${
                  isHighlighteddDomestic ? "activeDiv" : "deactiveDiv"
                }`}
                onClick={handleDomesticButtonClick}
              >
                Domestic
              </Button>
              <Button
                size="large"
                className={`${
                  isHighlighteddInternational ? "activeDiv" : "deactiveDiv"
                }`}
                onClick={handleInternationalButtonClick}
              >
                International
              </Button>
            </Space> */}
            <div className="ps-4 pb-1">
              <Stack
                alignItems={"flex-start"}
                justifyContent={"center"}
                direction="row"
                spacing={1}
              >
                <Chip
                  label="Domestic"
                  color={isHighlighteddDomestic ? "primary" : "default"}
                  component={"button"}
                  onClick={handleDomesticButtonClick}
                />
                <Chip
                  label="International"
                  color={isHighlighteddInternational ? "primary" : "default"}
                  component={"button"}
                  onClick={handleInternationalButtonClick}
                />
              </Stack>
            </div>
          </Row>
          <Row justify={"center"}>
            <Helmet>
              <title>{PackageSeo[0].title}</title>
              <meta name="description" content={PackageSeo[0].description} />
            </Helmet>
            <Col span={24}>
              {filterData &&
                filterData.map((item, idx) => {
                  return <PackageCard key={idx} data={item} />;
                })}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* _____________________footer_______________________ */}
      <BottomFooter>
        {/* <div>
          <FontAwesomeIcon
            className="footericon"
            color="#000"
            style={{ fontSize: '1rem' }}
            icon={faLocationDot}
            onClick={() => {
              setmodelOpen(modelOpen ? false : true)
            }}
          />
          <p>Location</p>
        </div> */}

        <div onClick={() => setmodelOpen((o) => !o)}>
          <FontAwesomeIcon
            className="footericon"
            color="#000"
            style={{ fontSize: "1rem" }}
            icon={faFilter}
          />
          <p>Filters</p>
        </div>
      </BottomFooter>
      <UserSideBarModel
        isModalOpen={modelOpen}
        onCancel={() => {
          setmodelOpen(false);
        }}
      >
        {sideBar}
      </UserSideBarModel>
      {/* ____________________________________________ */}
    </>
  );
}

export default PackageOtherPage;
