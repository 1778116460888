const AGENT_REGISTRATION_HEADERS = [
 {
  dataIndex: "col1",
  title: "S.N",
  nummeric: false,
  disablePadding: true,
  label: "S.N",
  width: 40,
 },
 {
  dataIndex: "col2",
  title: "Agent Name",
  nummeric: false,
  disablePadding: true,
  width: 120,
 },
 {
  dataIndex: "col3",
  title: "Company Code",
  nummeric: false,
  disablePadding: true,
  filter: "select",
  width: 80,
 },
 {
  dataIndex: "col4",
  title: "Agent URL",
  nummeric: false,
  disablePadding: true,
  label: "Category",
  filter: "select",
  width: 80,
 },
//  {
//   dataIndex: "col5",
//   title: "Assigned To",
//   nummeric: false,
//   disablePadding: true,
//   label: "Category",
//   filter: "select",
//   width: 80,
//  },
 {
  dataIndex: "col6",
  title: "Actions",
  nummeric: false,
  disablePadding: true,
  label: "Category",
  filter: "select",
  width: 80,
 },
];

export default AGENT_REGISTRATION_HEADERS;
