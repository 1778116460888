/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  faAddressCard,
  faBan,
  faClose,
  faFileShield,
  faFileSignature,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import MoblieLoginModel from "../../mobileLoginModel/MoblieLoginModel";
import "./index.css";
import { removeUserDetails } from "../../../../reduxData/sliceData/userDetailsReducer";
import {
  faRightFromBracket,
  faBriefcase,
  faCircleUser,
} from "@fortawesome/free-solid-svg-icons";

function UserDrawer({ showDrower, setshowDrower }) {

  const [userDetails, setuserDetails] = useState();

  const selector = useSelector((s) => s.userDetails.user);
  const dispatch = useDispatch();
  const [model, setmodel] = useState(false);
  useEffect(() => {
    setshowDrower(false);
  }, [model]);
  useEffect(() => {
    setuserDetails(selector);
  }, []);
  
  const logoutFun = () => {
    dispatch(removeUserDetails());

    setTimeout(function () {
      window.location.href = "/";
    }, 100);
  };

  return (
    <>
      <Drawer
        placement="right"
        width={250}
        className="right-menu"
        bodyStyle={{ padding: "0px", margin: "0px", zIndex: "0" }}
        closeIcon={
          <FontAwesomeIcon
            color="#fff"
            style={{ fontSize: "2rem", marginLeft: "180px" }}
            icon={faClose}
          />
        }
        onClose={() => setshowDrower(false)}
        open={showDrower}
      >
        <p className="text-center h5"> Menu</p>
        <hr />
        {userDetails?.name ? (
          <>
            <p className="main-sidebar-box " onClick={() => setmodel(true)}>
              {" "}
              <FontAwesomeIcon icon={faRightToBracket} /> &nbsp; Welcome{" "}
              {userDetails?.name}
            </p>{" "}
          </>
        ) : (
          <>
            <p className="main-sidebar-box " onClick={() => setmodel(true)}>
              {" "}
              <FontAwesomeIcon icon={faRightToBracket} /> &nbsp; Login
            </p>{" "}
          </>
        )}
        <hr />
        {userDetails?.name && (
          <>
            <Link to="/bookings" className="text-light menu-text">
              <p className="main-sidebar-box  ">
                <FontAwesomeIcon icon={faBriefcase} /> &nbsp; My Booking
              </p>{" "}
            </Link>
            <hr />
            <Link to="/user-profile" className="text-light menu-text">
              <p className="main-sidebar-box  ">
                <FontAwesomeIcon icon={faCircleUser} /> &nbsp; My Profile
              </p>{" "}
            </Link>
            <hr />
          </>
        )}
        <Link to="/privacyandpolicylink" className="text-light menu-text">
          <p className="main-sidebar-box  ">
            <FontAwesomeIcon icon={faFileShield} /> &nbsp; Privacy Policy
          </p>{" "}
        </Link>
        <hr />
        <Link to="/termsandconditionslink" className="text-light menu-text">
          <p className="main-sidebar-box ">
            <FontAwesomeIcon icon={faFileSignature} /> &nbsp; Terms and
            Conditions
          </p>{" "}
        </Link>
        <hr />
        <Link to="/cancellationpolicylink" className="text-light menu-text">
          <p className="main-sidebar-box  ">
            <FontAwesomeIcon icon={faBan} /> &nbsp; Cancellation Policy{" "}
          </p>{" "}
        </Link>
        <hr />
        <Link to="/aboutUsLink" className="text-light">
          <p className="main-sidebar-box  ">
            <FontAwesomeIcon icon={faAddressCard} /> &nbsp; About Us{" "}
          </p>{" "}
        </Link>
        <hr />
        {userDetails?.name && (
          <>
            <p className=" main-sidebar-box " onClick={logoutFun}>
              <FontAwesomeIcon icon={faRightFromBracket} /> &nbsp; Logout{" "}
            </p>{" "}
            <hr />
          </>
        )}
      </Drawer>
      {!userDetails?.name ? (
        <MoblieLoginModel
          isModalOpen={model}
          onCancel={() => setmodel(false)}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default UserDrawer;
