const BASIC_SETTINGS_DESIGNATION = [
  {
    dataIndex: "col1",
    title: "S.N",
    nummeric: false,
    disablePadding: true,
    label: "SL.No",
    // sorter: {
    //   compare: (a, b) => a.math - b.math,
    //   multiple: 2,
    // },
    width: 45,
  },
  {
    dataIndex: "col2",
    title: "Designation",
    nummeric: false,
    disablePadding: true,
    // sorter: {
    //   compare: (a, b) => a.math - b.math,
    //   multiple: 2,
    // },
    width: 100,
  },
  {
    dataIndex: "col3",
    title: "Actions",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 30,
  },
];

export default BASIC_SETTINGS_DESIGNATION;
