import { Col, Row, Tag } from "antd";
import React from "react";
// import offerImage from "../../../../assets/offerImage.png";
import {
  // StarFilled,
  TeamOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndianRupeeSign,
  faLocationDot,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import BtnComp from "../../buttonComponent/BtnComp";
// import _ from "lodash";
import { Paper } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import {
  selectHotel,
  addFilteredData,
} from "../../../../reduxData/sliceData/hotelReducer";
import { useDispatch } from "react-redux";
import soldout from "../../../../assets/soldout.png";

export const SuggestionCard = ({ data }) => {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const navigateToPage = (hotelName, destinationName) => {

    document.getElementById("hotelNameHead")?.scrollIntoView({ block: "end" });
    dispatch(selectHotel(data));
    dispatch(addFilteredData([data]));

    setTimeout(() => {
      const nameHotel = hotelName?.includes(" ") ? hotelName?.split(" ").join("-") : hotelName;
      nav(`/hotel/${nameHotel}-hotels-in-${destinationName}`, { state: { data } });
    }, 1000);

  };

  return (
    <Paper className="similarHotels" style={{ margin: "2%" }}>
      <Col style={{ background: "#fff", padding: "10px" }}>
        <Row style={{ height: "150px" }}>
          <img
            // src={offerImage}
            src={data?.profilePic[0]}
            alt=""
            style={{
              width: "-webkit-fill-available",
              height: "-webkit-fill-available",
            }}
          ></img>
        </Row>
        <div>
          <Row style={{ marginTop: "10px" }}>
            {/* <h5>Hotel Name</h5> */}
            <h5> {data?.basicDetails?.hotelName}</h5>
          </Row>

          <Row>
            <Tag
              color="#52b520"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "1px 6px",
              }}
            >
              <span style={{ fontSize: "15px" }}>
                {data?.hotelReviewId?.length > 0
                  ? (
                    data?.hotelReviewId?.reduce(
                      (sum, review) => sum + review?.rating,
                      0
                    ) / data?.hotelReviewId?.length
                  ).toFixed(1)
                  : 0}{" "}
              </span>
              &nbsp;
              <FontAwesomeIcon icon={faStar} style={{ fontSize: "11px" }} />
            </Tag>
          </Row>

          <Row style={{ marginTop: "5px", marginBottom: "5px" }}>
            <div style={{ color: "#1675df" }}>
              <FontAwesomeIcon
                icon={faLocationDot}
                style={{ marginRight: "6px" }}
              />
              {data?.hotelLocation?.place?.destinationName}
            </div>
          </Row>

          <Row className="py-1">
            {data?.basicDetails?.coupleFriendlyHotel && (
              <Tag icon={<TeamOutlined />} style={{ fontWeight: 600 }}>
                Couple Friendly
              </Tag>
            )}
            {data?.basicDetails?.sanitizedStay && (
              <Tag
                icon={<SafetyCertificateOutlined />}
                style={{ fontWeight: 600 }}
              >
                Gos Stay Assured
              </Tag>
            )}
          </Row>

        </div>


        {data?.stopSale && (
          <Col
            span={8}
            className="mt-4"
            style={{ color: "Red", fontSize: "1.6rem" }}
          >
            <img
              className="sold-out-img"
              src={soldout}
              alt="Sold Out"
            />
          </Col>
        )}

        {!data?.stopSale && (
          <>
            <Row>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>
                  <span style={{ color: "#000", fontSize: "18px" }}>
                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                  </span>{" "}
                  <span
                    style={{ color: "#000", fontSize: "18px", fontWeight: "900" }}
                  >
                    {parseInt(data?.mainroomPriceWithDiscount) || ""}
                  </span>
                </span>
                <span
                  style={{
                    color: "#919191",
                    fontSize: "18px",
                    textDecoration: "line-through",
                    textDecorationColor: "red",
                    textDecorationThickness: "1px",
                  }}
                >
                  <span>
                    {/* <FontAwesomeIcon icon={faIndianRupeeSign} /> */}₹{" "}
                  </span>{" "}
                  <span>
                    {parseInt(data?.mainroomPriceWithoutDiscount) || ""}
                    {/* <del style={{}}></del> */}
                  </span>
                </span>
              </div>
            </Row>

            <Row>
              <BtnComp
                btnClickFun={() => navigateToPage(data?.basicDetails?.hotelName, data?.hotelLocation?.place?.destinationName)}
                btnName="Book Now"
                color={"#fff"}
                bg_color={"#1675df"}
                width={"-webkit-fill-available"}
                style={{ borderRadius: "10px" }}
              />
            </Row>
          </>
        )}
      </Col>
    </Paper>
  );
};
