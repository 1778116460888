import React from "react";
import "./payfail.scss";
import { faRedo, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Failure = () => {
  return (
    <div className="pay-container">
      <div className="icon">
        <i className="fas fa-times-circle"></i>
      </div>
      <h1>Payment Failed</h1>
      <p>We are sorry, but your payment could not be processed at this time. Please try again or contact customer support for assistance.</p>
      <div className="actions">
        <a href="/hotel/checkout" className="retry-btn">
          <FontAwesomeIcon className="fa-redo" icon={faRedo} />
          <span className="ms-1">Retry Payment</span>
        </a>

        <a href="/" className="home-btn">
          <FontAwesomeIcon className="fa-home" icon={faHome} />
          <span className="ms-1">Home</span>
        </a>
      </div>
    </div>
  );
};

export default Failure;
