import React from "react";

const CheckBoxComponent = ({
  label,
  onChange,
  labelClassName = "form-check-label form-label w-100",
  inputClassName = "form-check-input styleCheckboxInput",
  divClassName = "form-check col-sm-1",
  inputName = "option1",
  inputValue = "something",
  checked,
  inputId = "check1",
  labelStyle,
  disabled
}) => {
  return (
    <div className={divClassName} style={{ width: "100%" }}>
      <input
        type="checkbox"
        className={inputClassName}
        id={inputId}
        name={inputName}
        value={inputValue}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        


      />
      <label className={labelClassName} style={labelStyle}>
        {label}
      </label>
    </div>
  );
};

export default CheckBoxComponent;
