import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { Paper, Typography, Card, CardContent } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { GetHouseboatBookingDataById } from "../../../../services/utils/admin/myBooking/houseBoatBookingLIst";

const { TabPane } = Tabs;

const HouseboatBookingDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const nav = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getHouseboatBookingById = async () => {
      try {
        const {
          data: { response },
        } = await GetHouseboatBookingDataById(id);
        setData(response[0]);
      } catch (err) {
        console.log(err);
      }
    };
    getHouseboatBookingById();
  }, []);
  return (
    <>
      {isMobile && (
        <div className="mobile2ndHead justify-content-start">
          <IconButton onClick={() => nav("/bookings")}>
            <ArrowBackIcon sx={{ color: "white" }} />
          </IconButton>
          <p>&nbsp;&nbsp;HouseBoat Booking Details</p>
        </div>
      )}
      <Paper
        elevation={0}
        square
        sx={{
          p: 2,
          mt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card style={{ height: "50%", width: "50%" }}>
          {/* <CardMedia
            component="img"
            alt="houseboat"
            image={data?.houseboatID?.hbImage[0]}
            sx={{width: '100%' , height: '100%' , objectFit: 'contain'}}
          /> */}
          <img
            src={data?.houseboatID?.hbImage[0]}
            alt="houseboat"
            style={{
              width: "100%",
              height: "300px",
              border: "1px solid black",
            }}
          />
          <CardContent>
            <Typography variant="h5" component="div">
              {data?.houseboatID?.houseBoatName}
            </Typography>
          </CardContent>
        </Card>
      </Paper>

      <Tabs
        defaultActiveKey="overview"
        size="large"
        animated
        tabBarGutter={40}
        tabBarStyle={{ paddingLeft: "20px" }}
      >
        <TabPane tab="Overview" key="overview">
          <Grid container spacing={3}>
            {/* First Column */}
            <Grid item xs={12} md={4} spacing={2}>
              <Grid container direction="column">
                <Typography
                  className="text-capitalize"
                  sx={{ pl: 2 }}
                  variant="h5"
                  gutterBottom
                >
                  {data?.houseboatID?.houseBoatName}
                </Typography>
                <Typography
                  className="text-capitalize"
                  sx={{ pl: 2, color: "grey" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  {data?.houseboatID?.description}
                </Typography>
              </Grid>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} md={4} sx={{ pt: 1, pl: 4 }}>
              <Grid container direction="row">
                <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                  Guest:
                </Typography>
                <Typography
                  variant="h6"
                  className="text-capitalize"
                  gutterBottom
                >
                  {data?.firstName}&nbsp;{data?.lastName}
                </Typography>
              </Grid>
              <Grid container direction="row">
                {/* <Typography variant="h6" sx={{ pr: 2, pl: 2 }}>
                  Booking Date:
                  <p>{moment(data?.bookingDate).format("DD/MM/YYYY")}</p>
                </Typography> */}
                <Typography variant="h6" sx={{ px: 3, pl: 2 }}>
                  Travel Date:
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {moment(data?.billingDetails?.travelDate).format(
                    "DD/MM/YYYY"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </TabPane>
      </Tabs>
    </>
  );
};

export default HouseboatBookingDetail;
