const todayDate = new Date().toJSON().slice(0, 10);

const userDetailsState = {
  token: "",
  name: "",
  email: "",
  phone: "",
};

const home = {
  footerActiveTab: 0,
};

const hotelRegistrationMainState = {
  hotelName: "",
  currency: "",
  hotelCategory: "",
  hotelType: "",
  markupType: "",
  status: "",
  coupleFriendlyHotel: false,
  sanitizedStay: true,
  region: "",
  country: "",
  state: "",
  place: "",
  location: "",
  zipCode: "",
  latitude: "",
  longitude: "",
  hotelAddress: "",
  gsttax: "",
  hotelDescription: "",
  EMIDeatils: "",
  contactDetails: [
    {
      settingsContactType: "",
      settingsPerson: "",
      settingsMobile: "",
      settingsEmail: "",
    },
  ],
  nearByAttractionDetails: [
    {
      settingsPlace: "",
      kilometers: "",
    },
  ],
  childComplimentaryAge: {
    min: "",
    max: "",
  },
  childChargeableAge: {
    minAge: "",
    maxAge: "",
  },
  hotelAmenities: [
    { Wifi: false },
    { Restaurant: false },
    { Power: false },
    { AC: false },
    { Laundry: false },
    { SwimingPool: false },
    { Housekeeping: false },
    { Minibar: false },
    { Petfriendly: false },
    { Sauna: false },
    { Balcony: false },
    { Steamroom: false },
    { Liftservice: false },
    { Fan: false },
    { Bed: false },
    { RoomBed: false },
    { Bar: false },
    { Escalator: false },
    { SwimmingPool: false },
    { FullTimeRoomService: false },
    { HairDryer: false },
    { Towel: false },
  ],

  weekDays: [
    { monday: false },
    { tuesday: false },
    { wednesday: false },
    { thursday: false },
    { friday: false },
  ],
  weekEndDays: [{ saturday: false }, { sunday: false }],

  bankDetails: [
    {
      bankName: "",
      accountNumber: "",
      bankAddress: "",
      ifscCode: "",
      faxNumber: "",
      swiftCode: "",
      telephone: "",
      contactPerson: "",
    },
  ],
  room: {
    roomCategory: "",
    roomData: [],
  },
  termsAndConditions: [
    {
      termsAndConditions: "",
    },
  ],
  // isDeleted: "",
};

// export { houseBoatRegistrationState, hotelRegistrationMainState }

// {
//   roomCategory: '',
//   roomType:[],
// },

const houseBoatRegistrationState = {
  houseBoatName: "",
  contactPerson: "",
  contactEmail: "",
  contactMobile: "",
  description: "",
  category: "",
  region: "",
  boatType: "",
  status: "",
  country: "",
  state: "",
  place: "",
  emiStartsfrom: "",
  weekDays: [
    { sunday: true },
    { monday: true },
    { tuesday: true },
    { wednesday: true },
    { thursday: true },
    { friday: true },
    { saturday: true },
  ],
  weekEndDays: [
    { sunday: false },
    { monday: false },
    { tuesday: false },
    { wednesday: false },
    { thursday: false },
    { friday: false },
    { saturday: false },
  ],
  room: {
    roomCategory: "",
    roomData: [],
  },
  childComplimentaryAge: {
    min: "",
    max: "",
  },
  childChargeableAge: {
    minAge: "",
    maxAge: "",
  },
  inclusion: [],
  exclusion: [],
  termsAndConditions: [],
  houseBoatHighlights: "100m",
};
const hotelOccupancyState = {
  marketType: "",
  validityList: [{ fromDate: "", toDate: "" }],
  crb: [
    {
      occupancyType: "",
      totalAdult: "",
      totalChild: "",
      extraAdult: "",
      extraChild: "",
    },
  ],
  esr: [
    {
      occupancyType: "",
      totalAdult: "",
      totalChild: "",
      extraAdult: "",
      extraChild: "",
    },
  ],
  deluxeAc: [
    {
      occupancyType: "",
      totalAdult: "",
      totalChild: "",
      extraAdult: "",
      extraChild: "",
    },
  ],
  crpv: [
    {
      occupancyType: "",
      totalAdult: "",
      totalChild: "",
      extraAdult: "",
      extraChild: "",
    },
  ],
};

const stopSaleState = {
  marketType: "",
  roomCategory: "",
  validityList: [{ fromDate: "", toDate: "" }],
};
const compulsoryState = {
  supplementCode: "",
  tagLine: "",
  market: "",
  validityList: [{ fromDate: "", toDate: "" }],
  dataCrbSingleRate: "",
  dataCrbSingleAdultRate: "",
  dataCrbSingleChildRate: "",

  dataCrbDoubleRate: "",
  dataCrbDoubleAdultRate: "",
  dataCrbDoubleChildRate: "",

  dataCrbTripleRate: "",
  dataCrbTripleAdultRate: "",
  dataCrbTripleChildRate: "",

  dataCrbQuadRate: "",
  dataCrbQuadAdultRate: "",
  dataCrbQuadChildRate: "",

  dataCrbApartmentRate: "",
  dataCrbApartmentAdultRate: "",
  dataCrbApartmentChildRate: "",

  dataEsrSingleRate: "",
  dataEsrSingleAdultRate: "",
  dataEsrSingleChildRate: "",

  dataEsrDoubleRate: "",
  dataEsrDoubleAdultRate: "",
  dataEsrDoubleChildRate: "",

  dataEsrTripleRate: "",
  dataEsrTripleAdultRate: "",
  dataEsrTripleChildRate: "",

  dataEsrQuadRate: "",
  dataEsrQuadAdultRate: "",
  dataEsrQuadChildRate: "",

  dataDeluxeAcSingleRate: "",
  dataDeluxeAcSingleAdultRate: "",
  dataDeluxeAcSingleChildRate: "",

  dataDeluxeAcDoubleRate: "",
  dataDeluxeAcDoubleAdultRate: "",
  dataDeluxeAcDoubleChildRate: "",

  dataDeluxeAcTripleRate: "",
  dataDeluxeAcTripleAdultRate: "",
  dataDeluxeAcTripleChildRate: "",

  dataDeluxeAcQuadRate: "",
  dataDeluxeAcQuadAdultRate: "",
  dataDeluxeAcQuadChildRate: "",

  dataCrpvSingleRate: "",
  dataCrpvSingleAdultRate: "",
  dataCrpvSingleChildRate: "",

  dataCrpvDoubleRate: "",
  dataCrpvDoubleAdultRate: "",
  dataCrpvDoubleChildRate: "",

  dataCrpvTripleRate: "",
  dataCrpvTripleAdultRate: "",
  dataCrpvTripleChildRate: "",

  dataCrpvQuadRate: "",
  dataCrpvQuadAdultRate: "",
  dataCrpvQuadChildRate: "",
};

const savePolicyState = {
  validityList: [{ fromDate: "", toDate: "", deletebtn: false }],
  cancellationFee: [
    { CancellationFee: "", percent: "", priorHour: "", deletebtn: false },
  ],
  AmendmentFee: [
    { amendmentFee: "", percent: "", priorHour: "", deletebtn: false },
  ],
  childPolicys: [{ childPolicy: "", deletebtn: false }],
  additionalPolicies: {
    allSeasons1: {
      noShow: "",
      typeOf: "%",
    },
    allSeasons2: {
      EarlyDeparture: "",
      typeOf: "%",
    },
    allSeasons3: {
      NonRefundable: "",
      typeOf: "%",
    },
  },
  policyCode: "",
  marketType: "",
  remarks: "",
  status: "notLive",
};
const userShikaraBookNowModelState = {
  shikaraID: "",
  goingTo: "",
  checkIn: todayDate,
  // guest: {
  //   adults: '1',
  //   children: '0',
  // },
};

const hotelObjStructure = [
  ["basicDetails", "hotelName"],
  ["hotelLocation", "hotelAddress"],
  ["hotelLocation", "location"],
  // ['place', 'destinationName'],
];

const userhotelBookingDetails = {
  firstName: "",
  lastName: "",
  whatsAppNo: "",
  email: "",
  gstnNumber: "",
  componyName: "",
  // companyAddress: "",
  businessEmailId: "",
  // companyPhoneNo: "",
};

const companyProfileState = {
  companyName: "",
  authorizedPerson: "",
  website: "",
  mainOffice: "",
  yearStandUp: "",
  labours: "",
  branches: "",
  PO: "",
  address: "",
  emailId: "",
  mobileNumber: "",
  telNumber: "",
  faxNumber: "",
  companyPolicy: [],
};

const paymentDetailsState = {
  merchant_id: "251860",
  order_id: Date.now(),
  currency: "INR",
  amount: "0.00",
  redirect_url: `${process.env.REACT_APP_BASE_URL}orders/payments/ccavResponse`,
  // redirect_url: `${window.location.origin}/hotel/booking/confirm/hgh`,
  cancel_url: `${window.location.origin}/hotel/booking/fail`,
  language: "EN",
  billing_name: "",
  billing_address: "",
  billing_city: "",
  billing_state: "",
  billing_zip: "",
  billing_tel: "",
  billing_country: "India",
  billing_email: "",
  delivery_name: "",
  delivery_address: "",
  delivery_city: "",
  delivery_state: "",
  delivery_zip: "",
  delivery_country: "India",
  delivery_tel: "",
  merchant_param1: "",
  merchant_param2: " ",
  merchant_param3: "",
  merchant_param4: "",
  merchant_param5: "",
  promo_code: "",
  customer_identifier: "65656565",
};

export {
  houseBoatRegistrationState,
  hotelRegistrationMainState,
  hotelOccupancyState,
  stopSaleState,
  compulsoryState,
  savePolicyState,
  userShikaraBookNowModelState,
  todayDate,
  hotelObjStructure,
  userhotelBookingDetails,
  paymentDetailsState,
  userDetailsState,
  home,
  companyProfileState,
};
