import { createSlice } from "@reduxjs/toolkit";
import moment from "moment/moment";

const currentDate = moment().startOf("day").format("DD/MM/YYYY");
const nextDate = moment().add(1, "day").endOf("day").format("DD/MM/YYYY");

export const searchCardData = createSlice({
  name: "searchCardData",
  initialState: {
    placeData: {},
    searchCardData: {
      destinationName: "",
      checkIn: currentDate,
      checkOut: nextDate,
      guestDetails: {
        rooms: 1,
        childAge: [],
        adults: 2,
        children: 0,
      },
    },
    roomBillingDetails: {},
    searchToggle: 0,
  },
  reducers: {
    setPlaceData: (state, action) => {
      state.placeData = action.payload;
    },
    setSearchCardData: (state, action) => {
      state.searchCardData = action.payload;
    },
    setSelectedRoomRate: (state, action) => {
      state.roomBillingDetails = action.payload;
    },
    setSearchToggle: (state, action) => {
      state.searchToggle = action.payload;
    },
    setSearchCardFocus: (state, action) => {
      state.searchCardFocus = action.payload;
    }
  },
});

export const {
  setPlaceData,
  setSearchCardData,
  setSelectedRoomRate,
  setSearchToggle,
  setSearchCardFocus
} = searchCardData.actions;
export default searchCardData.reducer;
