const AGENT_SETTINGS_HEADERS = [
  {
    dataIndex: "col1",
    title: "S.N",
    nummeric: false,
    disablePadding: true,
    label: "SL.No",
    width: 40,
  },
  {
    dataIndex: "col2",
    title: "Agent Category",
    nummeric: false,
    disablePadding: true,

    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 120,
  },
  {
    dataIndex: "col3",
    title: "Actions",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 80,
  },
];

export default AGENT_SETTINGS_HEADERS;
