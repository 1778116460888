import { Button, Col, Rate, Row, Skeleton } from "antd";
import React, { useState, useEffect } from "react";
import { Grid } from "antd";
import tik from "../../../../assets/tik.png";
import activity from "../../../../assets/activity.png";
import food from "../../../../assets/food.png";
import sight from "../../../../assets/vision.png";
import visa from "../../../../assets/passport.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faCar,
  faCoffee,
  faHotel,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectPackage } from "../../../../reduxData/sliceData/packageReducer";
const { useBreakpoint } = Grid;
import { packageTerms } from "../../../../services/utils/userUtils/userPackageUtils/index";

const iconObject = {
  Hotel: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <FontAwesomeIcon
        icon={faBed}
        style={{ fontSize: "2rem", color: "#5893f6" }}
        className="px-4"
      />
      <br />
      Hotel
    </Col>
  ),
  Cab: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <FontAwesomeIcon
        icon={faCar}
        style={{ fontSize: "2rem", color: "#5893f6" }}
        className="px-4"
      />
      <br />
      Travel
    </Col>
  ),
  Activity: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <img src={activity} alt="" className="px-4" />
      <br />
      Activity
    </Col>
  ),
  Food: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <img src={food} alt="" className="px-4" />
      <br />
      Food
    </Col>
  ),
  Sight: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <img src={sight} alt="" className="px-4" />
      <br />
      Sight
    </Col>
  ),
  Visa: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <img src={visa} alt="" className="px-4" />
      <br />
      Visa
    </Col>
  ),
};

function PackageCard({ data }) {
  const {
    emi,
    discountRate,
    packageName,
    inclusion,
    include,
    noOfNights,
    state,
    // reviewId,
    packageBasicRate,
    packageImage,
    promotionId,
  } = data;
  // debugger;
  const [highlights, setHighlights] = useState([]);

  const { sm } = useBreakpoint();
  const nav = useNavigate();
  const dispatch = useDispatch();
  // debugger;

  const getHighlightsPackages = async () => {
    const packageHighlights = await packageTerms();
    // debugger;
    if (packageHighlights?.data?.response) {
      packageHighlights.data.response.forEach((item) => {
        switch (item.descriptionType) {
          case "Package Highlights":
            setHighlights((prevState) => [...prevState, item.description]);
            break;

          default:
            // Handle any other descriptionType values if needed
            break;
        }
      });
    }
  };
  useEffect(() => {
    getHighlightsPackages();
  }, []);

  return (
    <Row justify={"center"}>
      <Col span={24} style={{ padding: "2%" }}>
        <Row
          elevation={24}
          style={{
            padding: "2%",
            paddingTop: "0%",
            boxShadow: "0px 4px 10px #0000000d",
            borderBottom: "1px solid #eee",
          }}
        >
          <Col sm={24} xs={24} md={24} lg={10} order={1}>
            <div className="just-center imgdiv">
              {packageImage ? (
                <img
                  src={packageImage && packageImage}
                  alt=""
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                <Skeleton.Image
                  active={true}
                  className="packageSkeletonimg"
                />
              )}
              {/* <Skeleton.Image active={true} className="scaltenstyle"/> */}
            </div>
          </Col>
          <Col
            lg={10}
            sm={16}
            md={16}
            xs={24}
            order={2}
            style={{ borderRight: "1px solid #eee" }}
          >
            <Row style={{ height: "100%", padding: "1%" }}>
              <Col
                lg={12}
                sm={24}
                md={12}
                xs={24}
                style={{
                  borderRight: "1px solid #eee",
                  borderBottom: "1px solid #eee",
                }}
              >
                {/* <p className="h6 p-2">Phuket Krabi Tour Packages</p> */}
                <p className="h6 p-2">{packageName}</p>
                <p
                  className="m-2"
                  style={{ color: "#ff6b00", fontSize: "0.8rem" }}
                >
                  {/* Places to visit: 1D Phuket , 3D Karbi, 1D Phi Phi Island */}
                  Places to visit: {noOfNights + 1}D {state?.stateName}
                </p>
                <Button
                  className="rounded-0 m-2"
                  style={{ backgroundColor: "#5893f6", color: "#fff" }}
                >
                  {noOfNights + 1} Days {noOfNights} Nights
                </Button>
              </Col>
              <Col lg={12} sm={24} md={12} xs={24}>
                <p style={{ fontWeight: "700" }} className="p-2">
                  {" "}
                  Package Highlights
                </p>
                {highlights.map((v, i) => {
                  return (
                    <ul key={i} style={{ listStyleImage: `url(${tik})` }}>
                      <li>{v}</li>
                    </ul>
                  );
                })}
              </Col>
              <Col
                lg={12}
                sm={24}
                md={12}
                xs={24}
                style={{
                  borderTop: "1px solid #eee",
                  borderRight: "1px solid #eee",
                }}
              >
                <p style={{ fontWeight: "700" }} className="p-2 mb-0">
                  Hotels included in package
                </p>
                {data?.rateId?.occupancy[0]?.selectHotel[0]?.basicDetails
                  ?.hotelName && (
                    <ul style={{ listStyle: "none", padding: 0 }} className="p-2" >
                      {data?.rateId?.occupancy[0]?.selectHotel.map((val, id) => {
                        return (
                          <li key={id} className="mt-1">
                            <FontAwesomeIcon icon={faHotel} className="me-1" />
                            {val?.basicDetails?.hotelName ? val?.basicDetails?.hotelName : ""}
                          </li>
                        );
                      })}
                    </ul>
                  )}
              </Col>
              <Col
                lg={12}
                sm={24}
                md={12}
                xs={24}
                style={{
                  height: "120px",
                  overflowY: "scroll",
                  borderTop: "1px solid #eee",
                }}
              >
                <p style={{ fontWeight: "700" }} className="p-2">
                  Includes
                </p>
                {inclusion.map((val, ide) => {
                  return (
                    <ul key={ide} style={{ listStyleImage: `url(${tik})` }}>
                      <li>{val.details.value}</li>
                    </ul>
                  );
                })}
              </Col>
            </Row>
          </Col>

          <Col
            lg={4}
            sm={8}
            md={8}
            xs={24}
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            order={sm ? 3 : 4}
          >
            <Row className="py-2">
              <Rate disabled allowHalf value={4.5} />
            </Row>
            <p className="h-3" style={{ color: "#cc0000" }}>
              &#8377;              
              {packageBasicRate}
            </p>
            {discountRate && (
              <span
                className="packageSummery-price pt-2 pr-1"
                style={{
                  textDecoration: "line-through",
                  textDecorationColor: "red",
                  textDecorationThickness: "2px",
                }}
              >
                <s>
                  &#8377;
                  {promotionId?.promotionType.percentage
                    ? parseInt(
                      parseInt(packageBasicRate)
                        ? parseInt(packageBasicRate) /
                        (1 - promotionId?.value / 100)
                        : ""
                    )
                    : parseInt(packageBasicRate) + promotionId?.value}
                </s>
              </span>
            )}
            <span>
              <p style={{ color: "#ff0000", display: "initial" }}>EMI</p>{" "}
              <p style={{ fontSize: "0.9rem", display: "initial" }}>
                starting from ₹{emi}
              </p>
            </span>
            <br />

            <Button
              style={{
                margin: "4%",
                color: "#fff",
                backgroundColor: "#3d75df",
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(selectPackage(data));
                setTimeout(function () {
                  nav("/package/secondPage");
                }, 100);
              }}
            >
              Book Now
            </Button>
            <Button
              style={{
                margin: "4%",
                color: "#fff",
                backgroundColor: "#ff4d63",
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(selectPackage(data));
                setTimeout(function () {
                  nav("/package/secondPage");
                }, 100);
              }}
            >
              View Details
            </Button>
            {include ? (
              <p className="mt-2" style={{ fontWeight: "700" }}>
                {" "}
                <FontAwesomeIcon icon={faCoffee} /> Breakfast
              </p>
            ) : (
              ""
            )}
          </Col>
          <Col span={24} order={sm ? 4 : 3}>
            <p style={{ fontWeight: "700" }} className="p-2">
              Inclusions
            </p>
            <Row>
              {include.map((v, i) => {
                return <span key={i}>{iconObject[v]}</span>;
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PackageCard;
