/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-autofocus */
import {
  faArrowLeft,
  faIndianRupeeSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sfPro from "../../../../assets/FontsFree-Net-SFProDisplay-Regular.ttf";

// import headlogo from "../../../../assets/headLogo.png";
import {
  Col,
  Row,
  Grid,
  Collapse,
  Radio,
  Tag,
  Button,
  Modal,
  Form,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import NewInputComp from "../../../../components/atoms/input/NewInputComp";
import BottomFooter from "../../newUserLayout/userFooter/BottomFooter";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  paymentDetailsState,
  userhotelBookingDetails,
} from "../../../../constants/states";
import {
  bookingFormBusinessSchema,
  bookingFormSchema,
} from "./bookingFormSchema";
import { InputOTP } from "antd-input-otp";
import {
  otpSentService,
  otpVerifyService,
  userDetailsForHotelService,
  // otpVerifyService
} from "../../../../services/utils/userUtils/hotelutils/otpverification";
import HotelBookingProceedModel from "../../../../components/molecules/modalComponent/HotelBookingProceedModel";
import { NotificationBox } from "../../../../components/atoms/notificationBox/NotificationBox";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import HotelBookNowCards from "./../../../../components/atoms/card/hotelBookNowCards/index";
import OfferModal from "../../../../components/molecules/modalComponent/OfferModal/OfferModal";
import { setSelectedRoomRate } from "../../../../reduxData/sliceData/searchCardReducer";
import PayUPaymentPage from "../../../../components/payu/PayUPaymentPage";
import axios from "axios";
const { useBreakpoint } = Grid;
const { Panel } = Collapse;

export const HotelBookNowSecondPage = () => {
  const param = useParams();
  const [sucessModel, setSucessModel] = useState(false);
  const { xl, sm } = useBreakpoint();
  const nav = useNavigate();
  const [showForm, setShowForm] = useState("personal");
  const [offerCode, setofferCode] = useState("GOSFLASHDEAL01");
  const [otpModel, setOtpModel] = useState(false);
  const [otpValue, setotpValue] = useState("");
  const [saveOtp, setsaveOtp] = useState("");
  const [otpVerifed, setOtpVerifed] = useState(false);
  const [proceedToPaymentOption, setproceedToPaymentOption] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionId, settransactionId] = useState(null);
  const [hash, setHash] = useState(null);
  const [toggle, setToggle] = useState(1);
  const [formData, setFormData] = useState({});
  const [bookingDetail, setBookingDetail] = useState({ roomCategory: "" });
  const [proceedPaymentFailedModel, setproceedPaymentFailedModel] = useState(false);
  const [paymentFailMessage, setPaymentFailMessage] = useState("");
  const [contextHolder] = NotificationBox();
  const [applyedDiscountValue, setApplyedDiscountValue] = useState(0);
  const [disablwOptBtn, setdisablwOptBtn] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setuserDetails] = useState();
  const [showOtpInvalidMessage, setShowOtpInvalidMessage] = useState(false);

  const roomState = useSelector((state) => state.hotelAllData.selectedRoom);
  const hotelImages = useSelector(
    (state) => state.hotelAllData.selectedData.profilePic
  );
  const hotelDetails = useSelector(
    (state) => state?.hotelAllData?.selectedData
  );
  const gst = useSelector(
    (state) => state?.hotelAllData?.filterData[0]?.hotelLocation?.gsttax
  );

  const hotelbasicDetails = useSelector((state) => state?.hotelAllData);
  const billingDetails = useSelector((state) => state?.searchCardData);
  const roomDetails = useSelector((state) => state?.hotelAllData?.selectedRoom);

  const selectedRoomData = useSelector(
    (state) => state?.searchCardData?.roomBillingDetails
  );

  const selector = useSelector((s) => s.userDetails.user);

  const [payableBill, setpayableBill] = useState();
  const [adminPrice, setAdminPrice] = useState();
  const [customerPayableBill, setCustomerPayableBill] = useState();
  const [finalRoomPrice, setFinalRoomPrice] = useState(0);
  const [discountCopon, setDiscountCoupon] = useState(0);
  const [finalGst, setfinalGst] = useState(0);
  const [customerFinalGst, setCustomerFinalGst] = useState(0);
  const [finalDiscount, setFinalDiscount] = useState(0);
  const [findRoomRate, setFindRoomRate] = useState(0);

  const dispatch = useDispatch();
  const handleRadioChange = (e) => {
    setSelectedPromoCode(e.target.value);
    setIsModalVisible(true);
  };
  useEffect(() => {
    setuserDetails(selector);
  }, []);

  function handleDataFromChild(data) {
    setDiscountCoupon(parseInt(data));
  }

  useEffect(() => {
    const container = document.getElementById("bookNowHere");
    if (container) {
      container.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);
  useEffect(() => {

    let roomPrice =
      selectedRoomData?.roomRateWithOutDiscount -
      applyedDiscountValue +
      selectedRoomData.gstAmount;

    setAdminPrice(selectedRoomData?.roomRate);

    // setpayableBill(Math.ceil(selectedRoomData?.actualRoomRate1));
    setFinalRoomPrice(selectedRoomData?.roomRate1);
    setpayableBill(
      parseInt(
        (discountCopon != 0
          ? selectedRoomData?.roomRate1 - applyedDiscountValue - discountCopon
          : selectedRoomData?.roomRate1 - applyedDiscountValue) +
        (((discountCopon != 0
          ? selectedRoomData?.roomRate1 - applyedDiscountValue - discountCopon
          : selectedRoomData?.roomRate1 - applyedDiscountValue) *
          gst) /
          100 +
          ((discountCopon != 0
            ? selectedRoomData?.roomRate1 -
            applyedDiscountValue -
            selectedRoomData?.roomRate * selectedRoomData?.numberOfDays -
            discountCopon
            : selectedRoomData?.roomRate1 -
            applyedDiscountValue -
            selectedRoomData?.roomRate * selectedRoomData?.numberOfDays) *
            18) /
          100)
      )
    );

    setCustomerPayableBill(
      parseInt(
        (discountCopon != 0
          ? selectedRoomData?.roomRate1 - applyedDiscountValue - discountCopon
          : selectedRoomData?.roomRate1 - applyedDiscountValue) +
        ((discountCopon != 0
          ? selectedRoomData?.roomRate1 - applyedDiscountValue - discountCopon
          : selectedRoomData?.roomRate1 - applyedDiscountValue) *
          gst) /
        100
      )
    );
    setfinalGst(
      parseInt(
        ((discountCopon != 0
          ? selectedRoomData?.roomRate1 - applyedDiscountValue - discountCopon
          : selectedRoomData?.roomRate1 - applyedDiscountValue) *
          gst) /
        100 +
        ((discountCopon != 0
          ? selectedRoomData?.roomRate1 -
          applyedDiscountValue -
          selectedRoomData?.roomRate * selectedRoomData?.numberOfDays -
          discountCopon
          : selectedRoomData?.roomRate1 -
          applyedDiscountValue -
          selectedRoomData?.roomRate * selectedRoomData?.numberOfDays) *
          18) /
        100
      )
    );

    setCustomerFinalGst(
      parseInt(
        ((discountCopon != 0
          ? selectedRoomData?.roomRate1 - applyedDiscountValue - discountCopon
          : selectedRoomData?.roomRate1 - applyedDiscountValue) *
          gst) /
        100
      )
    );
    setFinalDiscount(
      parseInt(
        discountCopon != 0
          ? applyedDiscountValue + discountCopon
          : applyedDiscountValue
      )
    );
    let percentageDecimal =
      hotelDetails?.basicDetails?.markupType?.markupValue / 100;

    // Calculate x using the dynamic percentage
    // let x = (payableBill - finalGst) / (1 + percentageDecimal);
    // setFindRoomRate(x);
    setFindRoomRate(payableBill - finalGst);
    dispatch(
      setSelectedRoomRate({
        ...selectedRoomData,
        payableAmount: Math.ceil(roomPrice),
      })
    );
  }, [
    applyedDiscountValue,
    roomState,
    discountCopon,
    findRoomRate,
    payableBill,
    finalGst,
  ]);
  const offerMessage = "Congratulation! Coupon applied bravo! you saved";

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  // const [mainValue, setMainValue] = useState()
  const ref = useRef(null);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  let offerPercentage = {
    offer1: 0,
    offer2: 0,
    offer3: 0,
  };
  roomState?.gosOffers?.forEach(
    (o, i) =>
    (offerPercentage[`offer${i + 1}`] =
      Math.floor(gosOfferCalculate(o[`value${i + 1}`])) || 0)
  );

  const { offer1, offer2, offer3 } = offerPercentage;

  function gosOfferCalculate(r) {
    if (r) {
      return (selectedRoomData?.roomRate1 * r) / 100;
    } else {
      return 0;
    }
  }

  // let hotelImages = data.filter((val) => {
  //   return val?._id === roomState?._id;
  // });
  const applyDiscount = () => {
    if (offerCode == "GOSFLASHDEAL01") {
      setApplyedDiscountValue(offer1);
    } else if (offerCode == "GOSDEALOFTHEHOUR02") {
      setApplyedDiscountValue(offer2);
    } else if (offerCode == "GOSDAILYDEAL03") {
      setApplyedDiscountValue(offer3);
    }
  };
  useEffect(() => {
    applyDiscount();
  }, [offerCode, offerPercentage]);

  function generateTransactionID() {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionId = `${merchantPrefix}${timestamp}${randomNum}`;
    return settransactionId(transactionId);
  }
  const getHash = (amount, id, roomCategory, firstName, email, phone, productInfo) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}orders/payments/payu`, {
        amount,
        id,
        roomCategory,
        firstName,
        email,
        phone,
        transactionId: transactionId,
        productInfo
      })
      .then((res) => {
        setHash(res.data.hash);
        settransactionId(res.data.transactionId);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    generateTransactionID();
  }, []);

  const triggerVerifyOTP = () => {
    let title = "Please verify OTP";

    setErrors({ ...errors, whatsAppNo: title });
    showSwalPopUp("warning", title);
  };

  const showSwalPopUp = (icon, title,) => {
    Swal.fire({
      icon: icon,
      title: title,
      showConfirmButton: false,
      timer: 3000,
      customClass: {
        popup:
          window.innerWidth <= 600 ? "custom-popup-class-checkout" : "",
        title:
          window.innerWidth <= 600 ? "custom-title-class-checkout" : "",
      },
    });
  }

  const {
    values,
    // setValues,
    errors,
    touched,
    setErrors,
    setTouched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: userhotelBookingDetails,
    validationSchema:
      showForm == "personal" ? bookingFormSchema : bookingFormBusinessSchema,
    onSubmit: async (value) => {
      try {
        if (otpVerifed) {
          sessionStorage.setItem("userDetails", JSON.stringify(value));
          const res = await userDetailsForHotelService({
            ...value,
            whatsAppNo: `+91${value.whatsAppNo}`,
          });

          if (res) {
            setproceedToPaymentOption(false);
            showSwalPopUp("success", "Click to Proceed Payment.");
          } else {
            //alert("something went wrong");
          }
        } else {
          triggerVerifyOTP();
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const otpBtn = async () => {
    if (String(values.whatsAppNo).length === 10) {
      const res = await otpSentService({ phone: "+91" + values.whatsAppNo });
      setsaveOtp(res.data);
      if (res.data) {
        setErrors({ ...errors, whatsAppNo: "" });
      }
      setShowOtpInvalidMessage(false);
      setOtpModel(true);
    } else {
      setErrors({ ...errors, whatsAppNo: "Enter a valid mobile no." });

      setTouched({ whatsAppNo: true });
    }
  };

  const verifyFunction = async () => {
    const res = await otpVerifyService({
      phone: `+91${values.whatsAppNo}`,
      otp: Number(otpValue.join("")),
    });
    if (res) {
      showSwalPopUp("success", "Mobile Number Verified.");
      setOtpVerifed(true);
      setdisablwOptBtn(true);
    }
  };
  const usedIds = new Set();
  function generateRandomId() {
    const min = 100000; // Minimum 6-digit number
    const max = 999999; // Maximum 6-digit number

    let randomId;
    do {
      randomId = Math.floor(Math.random() * (max - min + 1)) + min;
    } while (usedIds.has(randomId));

    usedIds.add(randomId);
    return randomId;
  }

  function separateLeadingZeros(number) {
    const matches = number.match(/^(\d*?)(0*)(\d+)$/);
    if (matches) {
      const leadingZeros = matches[2];
      const remainingDigits = matches[3];
      return [leadingZeros, remainingDigits];
    } else {
      return [0, number];
    }
  }
  const createPaymentOrder = async (validCoupanCode, validCoupanCodeValue) => {
    try {
      if (!proceedToPaymentOption) {
        //Booking API
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = yyyy + "-" + mm + "-" + dd;

        const [d, m, y] = billingDetails.searchCardData.checkIn.split("/");
        // const checkInDate = new Date(`${y}-${m}-${d}`);
        const checkInDate = y + "-" + m + "-" + d;

        const [ddd, mmm, yyy] =
          billingDetails.searchCardData.checkOut.split("/");
        // const checkOutDate = new Date(`${yyy}-${mmm}-${ddd}`);
        const checkOutDate = yyy + "-" + mmm + "-" + ddd;

        // customerData.amount = customerPayableBill;

        const randomNumber = generateRandomId();

        const lastDocument = await axios.get(
          `${process.env.REACT_APP_BASE_URL}user/hotelBooking/getLastHotelGuestDetails`
        );

        let invoiceNo;
        if (!lastDocument.data.response.invoiceNumber) {
          invoiceNo = "GOS0000";
        } else {
          invoiceNo = separateLeadingZeros(
            lastDocument.data.response.invoiceNumber.substring(3)
          );

          if (invoiceNo[0] != "") {
            let lastDigit = parseInt(invoiceNo[1]) + 1;
            invoiceNo = "GOS" + invoiceNo[0] + lastDigit;
          } else {
            invoiceNo = "GOS" + invoiceNo[1];
          }
        }

        try {
          let user = sessionStorage.getItem("userDetails");
          let userDetails = JSON.parse(user);

          const postData = {
            // Your data goes here
            hotelId: hotelbasicDetails?.selectedData?._id,
            user_id: users?.id,
            booking_id: "GOS" + randomNumber,
            order_id: "1234",
            firstName: userDetails?.firstName,
            lastName: userDetails?.lastName,
            email: userDetails?.email,
            whatsAppNumber: userDetails?.whatsAppNo,
            checkIn: checkInDate,
            checkOut: checkOutDate,
            bookingDate: today,
            roomCategory: roomDetails?.roomCategoryId?.roomCategory,
            roomType: roomDetails?.roomTypeId?.tagLine,
            occupancyType: roomDetails?.OccupancyTypeId?.occupancyType,
            appliedCoupon: validCoupanCode,
            appliedCouponValue: validCoupanCodeValue,
            guest: {
              rooms: billingDetails?.searchCardData?.guestDetails?.rooms,
              adults: billingDetails?.searchCardData?.guestDetails?.adults,
              child: billingDetails?.searchCardData?.guestDetails?.children,
              childAge: billingDetails?.searchCardData?.guestDetails?.childAge,
            },
            roomRateWithDiscount:
              billingDetails?.roomBillingDetails?.roomRateWithDiscount,
            invoiceNumber: invoiceNo,
            roomPrice: Math.floor(finalRoomPrice),
            discount: Math.floor(finalDiscount),
            payableAmount: Math.floor(payableBill),
            gst: Math.floor(finalGst),
            customerPayableAmount: Math.floor(customerPayableBill),
            adminRoomPrice: Math.floor(adminPrice),
            customerGst: Math.floor(customerFinalGst),
            subTotal: Math.floor(payableBill - finalGst),
            propertyTax: Math.floor(((payableBill - finalGst) * gst) / 100),
            commission: Math.floor(
              discountCopon
                ? (findRoomRate *
                  hotelDetails?.basicDetails?.markupType?.markupValue) /
                100 -
                discountCopon
                : (findRoomRate *
                  hotelDetails?.basicDetails?.markupType?.markupValue) /
                100
            ),
            status: "draft",
            gstnNumber: values.gstnNumber,
            componyName: values.componyName,
            businessEmailId: values.businessEmailId,
            // Add more key-value pairs as needed
          };

          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}user/hotelBooking/createHotelGuestDetails`,
            postData
          );
          const bookingInfo = response?.data?.response;

          //setRmCategory(roomDetails?.roomCategoryId?.roomCategory);
          //setHid(response?.data?.response?._id);
          setBookingDetail(bookingInfo);

          setFormData(postData);

          try {
            // debugger;
            await getHash(
              customerPayableBill,
              bookingInfo._id,
              bookingInfo?.roomCategory,
              bookingInfo?.firstName,
              bookingInfo?.email,
              bookingInfo?.whatsAppNo,
              bookingInfo?.booking_id
            );
            setToggle(2);
            // debugger;
          } catch (error) {
            console.error("Error getting hash:", error);
          } finally {
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error inserting booking details:", error);
        }
      } else if (!values.firstName || !values.lastName || !values.whatsAppNo || !values.email) {
        setPaymentFailMessage("Scroll down to enter user details");
        setproceedPaymentFailedModel(true);
      } else if (!otpVerifed) {
        triggerVerifyOTP();
      } else {
        setPaymentFailMessage("Scroll down to submit");
        setproceedPaymentFailedModel(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    try {
      if (otpValue.length == 6) {
        if (saveOtp.OTP == otpValue.join("")) {
          verifyFunction();
          setShowOtpInvalidMessage(false);
          setOtpModel(false);
          setotpValue("");
        } else {
          setShowOtpInvalidMessage(true);
        }
      }
    } catch (err) {
      console.log(err);
      alert("OTP not verified.");
      setOtpModel(false);
      setotpValue("");
    }
  }, [otpValue]);

  useEffect(() => {
    setIsModalVisible(offerCode ? true : false);
    const successRgx = /^success/;
    const failedRgx = /^failed/;
    if (param["*"]) {
      if (param["*"] == "success%7D") {
        setSucessModel(true);
      }
      if (successRgx.test(param["*"])) {
        setSucessModel(true);
      } else if (failedRgx.test(param["*"])) {
        alert("Failed");

        // setSucessModel(true)
      }
    }
    return () => scrollToTop();
  }, []);
  const [dataFromChild, setDataFromChild] = useState("");

  // Callback function to receive data from child
  const handleDataFromChilds = (data) => {
    setDataFromChild(data);
  };

  return (
    <>
      {toggle == 1 && (
        <div style={{ background: "#ffffff" }} ref={ref} id="bookNowHere">
          {contextHolder}
          <Row justify={"center"}>
            <Col
              xs={22}
              sm={22}
              md={22}
              lg={22}
              xl={20}
              xxl={20}
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                color: "#0850a5",
                margin: "20px 0px",
              }}
              onClick={() => {
                nav(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp; Modify Your Booking
            </Col>

            <Col
              xs={22}
              sm={22}
              md={22}
              lg={22}
              xl={12}
              xxl={12}
              order={xl ? 1 : 2}
            >
              {applyedDiscountValue > 0 && (
                <Row style={{ marginBottom: "10px" }}>
                  <Col span={24}>
                    <div className="savedonBooking">
                      <p>
                        🎉 Yah! you just saved ₹ {applyedDiscountValue} on this
                        booking !
                      </p>
                    </div>
                  </Col>
                </Row>
              )}

              <Collapse
                collapsible="icon"
                bordered={false}
                style={{
                  border: "1px solid #ccc",
                  marginTop: "4%",
                  padding: "6%",
                  marginBottom: "2%",
                }}
              >
                <Radio.Group
                  onChange={(e) => {
                    setShowForm(e.target.value);
                  }}
                  value={showForm}
                  defaultActiveKey="personal"
                  className="customerDetailsTab mb-4 d-flex"
                  style={{
                    marginBottom: 8,
                    width: "100%",
                  }}
                >
                  <Radio.Button value="personal" style={{ width: "50%" }}>
                    <span style={{ cursor: "pointer" }}> Personal</span>
                  </Radio.Button>
                  <Radio.Button value="business" style={{ width: "50%" }}>
                    <span style={{ cursor: "pointer" }}>Business</span>
                  </Radio.Button>
                </Radio.Group>
                {/* <Panel showArrow={false}> */}
                <h3>Customer Details</h3>
                <p className="h-4"> Room 1 : Primary Guest Details</p>
                <Form.Item style={{ border: "none" }}>
                  <NewInputComp
                    star="none"
                    name="firstName"
                    value={values.firstName}
                    label={<p className="h-4">Name</p>}
                    placeholder={"First Name"}
                    handleChange={handleChange}
                    style={{
                      width: "100%",
                      backgroundColor: "#f8f8f8",
                      height: "50px",
                    }}
                    errors={errors}
                    touched={touched}
                  />
                </Form.Item>
                <Form.Item style={{ border: "none" }}>
                  <NewInputComp
                    star="none"
                    label={""}
                    name="lastName"
                    value={values.lastName}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    placeholder={"Last Name"}
                    style={{
                      width: "100%",
                      backgroundColor: "#f8f8f8",
                      height: "50px",
                    }}
                  />
                </Form.Item>

                <Form.Item style={{ border: "none" }}>
                  <NewInputComp
                    star="none"
                    disabled={otpVerifed}
                    label={<p className="h-4">Phone</p>}
                    placeholder={"987654321"}
                    name={"whatsAppNo"}
                    value={values.whatsAppNo}
                    errors={errors}
                    type={"number"}
                    touched={touched}
                    handleChange={handleChange}
                    prefix="+91"
                    style={{
                      width: "100%",
                      backgroundColor: "#f8f8f8",
                      height: "50px",
                    }}
                    suffix={
                      <div style={{ float: "right", cursor: "pointer" }}>
                        <Button
                          ghost={true}
                          className="promocodetext "
                          onClick={otpBtn}
                          disabled={disablwOptBtn}
                        >
                          <span style={{ cursor: "pointer" }}>
                            Verify with OTP
                          </span>
                        </Button>
                      </div>
                    }
                  />
                  {/* <div style={{ float: "right", marginTop: "-45px" }}> */}
                </Form.Item>
                {showForm === "personal" && (
                  <>
                    <p className="h-4 mx-2">
                      {" "}
                      Verify to get booking details on
                    </p>
                    <Form.Item style={{ border: "none" }}>
                      <NewInputComp
                        star="none"
                        label={<p className="h-4">E-mail Address</p>}
                        placeholder={"Enter E-mail"}
                        name="email"
                        value={values.email}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        style={{
                          width: "100%",
                          backgroundColor: "#f8f8f8",
                          height: "50px",
                        }}
                      />
                    </Form.Item>
                    <p className="h-4 mx-2">
                      {" "}
                      We&apos;ll send your booking details on your email
                    </p>
                  </>
                )}
                {/* ________________________________________________ */}
                {/* <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '4%',
              }}
            ></div> */}
                {/* <Radio.Group
              onChange={(e) => {
                setShowForm(e.target.value)
              }}
              value={showForm}
              defaultActiveKey="personal"
              className="customerDetailsTab"
              style={{
                marginBottom: 8,
                width: '100%',
              }}
            >
              <Radio.Button value="personal" style={{ width: '48.5%' }}>
                Personal
              </Radio.Button>
              <Radio.Button value="business" style={{ width: '48.5%' }}>
                Business
              </Radio.Button>
            </Radio.Group> */}
                {/* ________________________________________________ */}
                <div style={{ paddingBottom: "50px" }}>
                  {showForm === "business" && (
                    <>
                      <Form.Item style={{ border: "none" }}>
                        <NewInputComp
                          star="none"
                          label={<p className="h-4">GSTN&#40;Option&#41;</p>}
                          placeholder={"Enter GSTN"}
                          name="gstnNumber"
                          value={values.gstnNumber}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          style={{
                            width: "100%",
                            backgroundColor: "#f8f8f8",
                            height: "50px",
                          }}
                        />
                      </Form.Item>
                      <Form.Item style={{ border: "none" }}>
                        <NewInputComp
                          star="none"
                          label={<p className="h-4">Company Name</p>}
                          placeholder={"Enter Company Name"}
                          name="componyName"
                          value={values.componyName}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          style={{
                            width: "100%",
                            backgroundColor: "#f8f8f8",
                            height: "50px",
                          }}
                        />
                      </Form.Item>
                      {/* <Form.Item style={{ border: "none" }}>
                    <NewInputComp
                      star="none"
                      label={<p className="h-4">Company Address</p>}
                      placeholder={"Enter Company Address"}
                      name="companyAddress"
                      value={values.companyAddress}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      style={{
                        width: "100%",
                        backgroundColor: "#f8f8f8",
                        height: "50px",
                      }}
                    />
                  </Form.Item> */}
                      <Form.Item style={{ border: "none" }}>
                        <NewInputComp
                          star="none"
                          label={<p className="h-4">Business E-mail ID</p>}
                          placeholder={"Enter Business E-mail ID"}
                          name="businessEmailId"
                          handleChange={handleChange}
                          value={values.businessEmailId}
                          errors={errors}
                          touched={touched}
                          style={{
                            width: "100%",
                            backgroundColor: "#f8f8f8",
                            height: "50px",
                          }}
                        />
                      </Form.Item>
                      {/* <Form.Item style={{ border: "none" }}>
                    <NewInputComp
                      star="none"
                      label={<p className="h-4">Company Phone No.</p>}
                      placeholder={"Enter Company Phone No."}
                      type={"number"}
                      name="companyPhoneNo"
                      value={values.companyPhoneNo}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      style={{
                        width: "100%",
                        backgroundColor: "#f8f8f8",
                        height: "50px",
                      }}
                    />
                  </Form.Item> */}
                    </>
                  )}
                  <Form.Item style={{ border: "none" }}>
                    <Button
                      type="submit"
                      htmlType="submit"
                      onClick={handleSubmit}
                      className="customerDetailbtn w-100 mt-4"
                    >
                      <span style={{ cursor: "pointer" }}>Submit</span>
                    </Button>
                  </Form.Item>
                </div>

                {/* </Panel> */}
              </Collapse>
            </Col>
            {/* --------------------------------Second Column ----------------------------------------------------*/}

            <Col
              xs={22}
              sm={22}
              md={22}
              lg={22}
              xl={8}
              xxl={8}
              order={xl ? 2 : 1}
            >
              <Row justify={"center"} gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
                  <HotelBookNowCards
                    hotelImages={hotelImages.slice(0, 8)}
                    onDataFromChild={handleDataFromChilds}
                    gst={Number(gst)}
                    proceedToPaymentOption={proceedToPaymentOption}
                    applyedDiscountValue={applyedDiscountValue}
                    roomState={roomState}
                    selectedRoomData={selectedRoomData}
                    proceedBtnFun={(validCoupanCode, validCoupanCodeValue) => {
                      createPaymentOrder(validCoupanCode, validCoupanCodeValue);
                    }}
                    hide={sm}
                    onData={handleDataFromChild}
                  />
                </Col>
                {offer1 != 0 && (
                  <Col xs={24} sm={24} md={24} lg={20} xl={20} xxl={20}>
                    <div>
                      <Collapse
                        expandIconPosition="end"
                        bordered={false}
                        defaultActiveKey={["1"]}
                        // onChange={""}
                        style={{
                          border: "1px solid #ccc",
                          padding: "1%",
                          marginBotton: "2%",
                        }}
                      >
                        <Panel
                          header={
                            <div style={{ textAlign: "left" }}>
                              <h4>Gos Offers</h4>
                              <p style={{ color: "#ccc" }}>
                                Promo-code / Gos offers / Bank offers
                              </p>
                            </div>
                          }
                          key="1"
                        >
                          <div
                            style={{
                              border: "1px  solid #1675DF",
                              // padding: '2%',
                              borderRadius: "10px",
                            }}
                          >
                            <>
                              <Radio.Group
                                onChange={(e) => setofferCode(e.target.value)}
                                value={offerCode}
                              >
                                <Row>
                                  <Col span={24}>
                                    <Row
                                      style={{
                                        borderBottom: "1px  solid #1675DF",
                                        padding: "2%",
                                        backgroundColor: `${offerCode === "GOSFLASHDEAL01"
                                          ? "#D0E2F7"
                                          : "#fafafa"
                                          }`,
                                        borderRadius: "10px 10px 0px 0px",
                                      }}
                                    >
                                      <Col span={18}>
                                        <Radio
                                          value={"GOSFLASHDEAL01"}
                                          name="promocode"
                                          onChange={handleRadioChange}
                                        >
                                          <div>
                                            <Tag color="green">
                                              GOSFLASHDEAL01
                                            </Tag>
                                          </div>
                                        </Radio>
                                        <div style={{ marginLeft: "8%" }}>
                                          <p>
                                            {selectedPromoCode ===
                                              "GOSFLASHDEAL01" ||
                                              offerCode === "GOSFLASHDEAL01"
                                              ? `${offerMessage} ₹${offer1}`
                                              : `Use GOSFLASHDEAL01 coupon and get ₹${offer1}`}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col span={6}>
                                        <h4> &nbsp; ₹{offer1} </h4>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={24}>
                                    {" "}
                                    <Row
                                      style={{
                                        borderBottom: "1px  solid #1675DF",
                                        backgroundColor: `${offerCode === "GOSDEALOFTHEHOUR02"
                                          ? "#D0E2F7"
                                          : "#fafafa"
                                          }`,
                                        padding: "2%",
                                      }}
                                    >
                                      <Col span={18}>
                                        <Radio
                                          value={"GOSDEALOFTHEHOUR02"}
                                          name="promocode"
                                          onChange={handleRadioChange}
                                        >
                                          <div>
                                            <Tag color="green">
                                              GOSDEALOFTHEHOUR02
                                            </Tag>
                                          </div>
                                        </Radio>
                                        <div style={{ marginLeft: "8%" }}>
                                          <p>
                                            {selectedPromoCode ===
                                              "GOSDEALOFTHEHOUR02" ||
                                              offerCode === "GOSDEALOFTHEHOUR02"
                                              ? `${offerMessage} ₹${offer2}`
                                              : `Use GOSDEALOFTHEHOUR02 coupon and get ₹${offer2}`}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col span={6}>
                                        <h4> &nbsp; ₹{offer2}</h4>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={24}>
                                    {" "}
                                    <Row
                                      style={{
                                        padding: "2%",
                                        borderRadius: "0px 0px 10px 10px",
                                        backgroundColor: `${offerCode === "GOSDAILYDEAL03"
                                          ? "#D0E2F7"
                                          : "#fafafa"
                                          }`,
                                      }}
                                    >
                                      <Col span={18}>
                                        <Radio
                                          value={"GOSDAILYDEAL03"}
                                          name="promocode"
                                          onChange={handleRadioChange}
                                        >
                                          <div>
                                            <Tag color="green">
                                              GOSDAILYDEAL03
                                            </Tag>
                                          </div>
                                        </Radio>
                                        <div style={{ marginLeft: "8%" }}>
                                          <p>
                                            {selectedPromoCode ===
                                              "GOSDAILYDEAL03" ||
                                              offerCode === "GOSDAILYDEAL03"
                                              ? `${offerMessage} ₹${offer3}`
                                              : `Use GOSDAILYDEAL03 coupon and get ₹${offer3}`}
                                          </p>
                                        </div>
                                      </Col>
                                      <Col span={6}>
                                        <h4> &nbsp; ₹{offer3}</h4>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Radio.Group>
                            </>
                          </div>

                          <Row style={{ width: "100%", marginTop: "4%" }}>
                            <Col span={24}>
                              <h5>Have a promo code</h5>

                              <NewInputComp
                                star="none"
                                label={""}
                                value={offerCode}
                                placeholder={"Enter promo code"}
                                style={{
                                  width: "100%",
                                  backgroundColor: "#f8f8f8",
                                  height: "50px",
                                }}
                              />
                              <div
                                style={{
                                  float: "right",
                                  marginTop: "-50px",
                                  marginRight: "10px",
                                }}
                              >
                                <Button
                                  ghost={true}
                                  className="promocodetext mt-2 me-2"
                                >
                                  Apply
                                </Button>
                              </div>
                              <button className="promocodetext mt-3 pt-2">
                                View all promo codes
                              </button>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <div>
            <BottomFooter>
              <Row style={{ padding: "2% 6%", width: "100%" }}>
                <Col
                  span={16}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <span
                    className="h1"
                    style={{
                      fontWeight: "700",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom: "0px",
                    }}
                  >
                    {/* {`₹ ${roomState?.roomRate + selectedRoomData?.gstAmount}`} */}
                    {/* {`₹ ${selectedRoomData.payableAmount}`} */}
                    {/* {`₹ 
                  ${(
                    selectedRoomData?.roomRate1 -
                    applyedDiscountValue +
                    selectedRoomData?.serviceChargeAndGST1
                  ).toFixed(0)}
                `} */}
                    {/* &nbsp; */}
                    {`₹ ${dataFromChild}`}
                    &nbsp;
                    <p>
                      <s
                        style={{
                          color: "#888",
                          textDecoration: "line-through",
                          textDecorationColor: "red",
                          textDecorationThickness: "2px",
                          fontSize: "22px",
                          fontFamily: "SF Pro, Arial, sans-serif",
                          // fontFamily: { sfPro },
                          src: `${sfPro}`,
                        }}
                      >
                        {/* <FontAwesomeIcon
                      className="fa-rupee-sign"
                      icon={faIndianRupeeSign}
                    /> */}

                        {`  ₹
                    ${Math.ceil(
                          (
                            selectedRoomData?.roomRate1 + applyedDiscountValue
                          ).toFixed(0)
                        )}
                      `}
                      </s>
                    </p>
                  </span>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    className="savedonBookingbtn"
                    onClick={() => {
                      createPaymentOrder();
                    }}
                  >
                    PROCEED <p style={{ fontSize: "0.65rem" }}>to payment</p>
                  </Button>
                </Col>
              </Row>
              {
                <Modal
                  footer={null}
                  onCancel={() => {
                    setOtpModel((o) => !o);
                    setotpValue("");
                  }}
                  open={otpModel}
                >
                  <Row justify={"center"}>
                    <Col>
                      <h2
                        style={{
                          textAlign: "left",
                          marginBottom: "10%",
                          color: "#1675df",
                        }}
                      >
                        Verify OTP
                      </h2>
                      <InputOTP
                        autoFocus={true}
                        inputType="numeric"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        length={6}
                        value={otpValue}
                        onChange={setotpValue}
                        onValidate={() => alert("Entered last number")}
                      />
                      <div
                        className="pt-3"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {showOtpInvalidMessage ? (
                          <p
                            className="text-capitalize text-danger"
                            style={{ fontSize: 14, paddingTop: "1px" }}
                          >
                            Invalid&nbsp;OTP
                          </p>
                        ) : (
                          <div></div>
                        )}
                        <Button
                          className="text-uppercase text-danger"
                          type="text"
                          style={{
                            fontSize: 14,
                            paddingTop: "1px",
                            border: "none",
                            outline: "none",
                            cursor: "pointer",
                          }}
                          onClick={otpBtn}
                        >
                          Resend&nbsp;OTP
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Modal>
              }

              {/* failed model */}
              <HotelBookingProceedModel
                open={sucessModel}
                setOpen={setSucessModel}
                modelViewType={"paymentSuccess"}
              />
              <HotelBookingProceedModel
                open={proceedPaymentFailedModel}
                setOpen={setproceedPaymentFailedModel}
                modelViewType={"isNotFormFilled"}
                message={paymentFailMessage}
              />
              {applyedDiscountValue > 0 && (
                <OfferModal
                  open={isModalVisible}
                  handleOk={handleModalOk}
                  handleCancel={handleModalCancel}
                  offerCode={offerCode}
                  discountValue={applyedDiscountValue}
                />
              )}
            </BottomFooter>
          </div>
        </div>
      )}
      {toggle === 2 && (
        <PayUPaymentPage
          setToggle={setToggle}
          form={formData}
          hash={hash}
          amount={customerPayableBill}
          transactionId={transactionId}
          bookingDetail={bookingDetail}
        />
      )}
    </>
  );
};
