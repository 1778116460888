import axios from "axios";

// Testing URL
// const baseURL = "http://10.10.20.18:4005";
// const baseURL = "http://10.10.20.14:2800/";
// const baseURL = "http://10.10.20.23:2800/";

// Development URL

// Development URL
// rack server
// const baseURL = "http://10.10.30.250:2800/";
// const baseURL = "http://10.10.50.195:2800/";
// const baseURL = "http://10.10.51.187:2800/";
// const baseURL = "http://10.10.20.141:2800/";
// const baseURL = "http://10.10.51.219:2800/";
// const baseURL = "http://10.10.20.241:2800/";
// const baseURL = "http://10.10.51.2:2800/";
// const baseURL = "http://192.168.154.237:2800/";
// const baseURL = "http://192.168.1.176:2800/";
//dev server
// const baseURL = "http://10.10.30.20:2800/";

// const baseURL = "http://10.10.51.219:2800/";

// Production URL
// const baseURL = "https://flinko.com:8443";
// const baseURL = "http://10.10.51.140:2800/";
// const baseURL = "http://65.0.224.132/";
// const baseURL = "https://api.godsownstay.com/";
const baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
});
axiosInstance.interceptors.request.use((config) => {
  config.headers = {
    "content-type": "application/json",
    // "content-type":
    //   "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    // "content-type": "multipart/form-data",
    ...config.headers,
  };
  return config;
});
export { axiosInstance, baseURL };
