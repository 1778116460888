import {
  faArrowLeft,
  faCalendar,
  // faChild,
  // faUsers,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Paper } from "@material-ui/core";
import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import BtnComp from "../../../../components/atoms/buttonComponent/BtnComp";
import NewInputComp from "../../../../components/atoms/input/NewInputComp";
import InputDate from "../../../../components/atoms/inputDate";
import SelectComponent from "../../../../components/atoms/SelectComponent/SelectComponent";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { CreatePackageBookingGuestDetails } from "../../../../services/utils/userUtils/userPackageUtils";
import UserSucessModel from "../../../../components/molecules/modalComponent/UserSucessModel";
import { packageSchemaForBookNow } from "../../userFormSchema/UserFormSchema";
import { packageBookingEmail } from "../../../../services/utils/userUtils/userPackageUtils/index";
import moment from "moment";
const d = new Date().toJSON().slice(0, 10);
const option = [
  {
    label: "Select",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
];

export const ViewPackageDetails = () => {
  const selector = useSelector((s) => s.userDetails.user);
  const [users, setuserDetails] = useState();
  const { state: data } = useLocation();

  //debugger;
  const [successModel, setSuccessModel] = useState(false);
  useEffect(() => {
    setuserDetails(selector);
  }, []);
  const nav = useNavigate();
  const [couponCode, setCouponCode] = useState();
  // const discountRate =
  //   data.packageBasicRate && data?.promotionId?.promotionType?.percentage
  //     ? parseInt(
  //         data?.packageBasicRate -
  //           parseInt(
  //             data.packageBasicRate * (1 - data?.promotionId?.value / 100)
  //           )
  //       )
  //     : data?.promotionId?.value;
  // const [defaultValue, setDefaultValue] = useState(
  //   data.packageBasicRate && data.packageBasicRate - discountRate
  // );

  const [defaultValue, setDefaultValue] = useState(
    (data?.promotionId?.promotionType?.percentage
      ? parseInt(
        parseInt(data?.packageBasicRate)
          ? parseInt(data?.packageBasicRate) /
          (1 - data?.promotionId?.value / 100)
          : ""
      )
      : parseInt(data?.packageBasicRate) + data?.promotionId?.value) -
    (data?.promotionId?.promotionType?.percentage
      ? parseInt(
        parseInt(data?.packageBasicRate)
          ? parseInt(data?.packageBasicRate) /
          (1 - data?.promotionId?.value / 100) -
          parseInt(data?.packageBasicRate)
          : ""
      )
      : data?.promotionId?.value)
  );

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setValues,
    handleReset,
  } = useFormik({
    initialValues: {
      packageID: "",
      user_id: "",
      prefix: "",
      firstName: "",
      lastName: "",
      whatsappNumber: "",
      emailAddress: "",
      billingDetails: {
        arrival: "",
        departure: "",
        room: "",
        // guest: "",
        subtotal: "",
        discount: "",
        payable: "",
        couponCode: "",
        totalCost: "",
      },
      travelDate: d,
      // adult: 1,
      // child: 0,
      // payableAmount: "",
    },
    validationSchema: packageSchemaForBookNow,
    onSubmit: () => {
      setSuccessModel(true);
    },
  });
  const modelHandleOk = async () => {
    const { data } = await CreatePackageBookingGuestDetails(values);
    // debugger;
    const emailData = await packageBookingEmail(data);
    if (emailData) {
      nav("/holiday-kerala/kerala-travel-packages/Domestic.html");
      setSuccessModel(false);
      handleReset();
    }
  };
  const handleCancel = () => {
    setSuccessModel(false);
  };

  const handleDateChange = (e, textDate) => {
    const dateArry = textDate.split("-");

    var formattedDate = undefined;

    if (dateArry.length == 3) {
      formattedDate = dateArry[2] + "-" + dateArry[1] + "-" + dateArry[0];
    }

    setValues({
      ...values,
      travelDate: formattedDate,
    });
  };

  useEffect(() => {
    // const { contractId } = data;
    data &&
      setValues({
        ...values,
        packageID: data._id,
        user_id: users?.id,
        billingDetails: {
          // travelDate: guest.ckeckIn,
          // totalTime: contractId && contractId?.timeHrs,
          // guest:
          //   guest &&
          //   `${guest.guest.adults} adults ${guest.guest.children} children `,
          subtotal: data?.promotionId?.promotionType?.percentage
            ? parseInt(
              parseInt(data?.packageBasicRate)
                ? parseInt(data?.packageBasicRate) /
                (1 - data?.promotionId?.value / 100)
                : ""
            )
            : parseInt(data?.packageBasicRate) + data?.promotionId?.value,
          discount: data?.promotionId?.promotionType?.percentage
            ? parseInt(
              parseInt(data?.packageBasicRate)
                ? parseInt(data?.packageBasicRate) /
                (1 - data?.promotionId?.value / 100) -
                parseInt(data?.packageBasicRate)
                : ""
            )
            : data?.promotionId?.value,
          couponCode: couponCode,
          totalPayable: defaultValue,
        },
        // packageID: data._id,
        // payableAmount: defaultValue,
      });
  }, [couponCode, defaultValue, users]);

  const couponApply = () => {
    let numbers = couponCode?.match(/\d+/g)?.join("");

    if (numbers) {
      setDefaultValue(defaultValue - (defaultValue * parseInt(numbers)) / 100);
      message.success(`${couponCode} Applied`);
    }
    handleCouponCode({ target: { name: "couponCode", value: "" } });
  };

  const handleCouponCode = (e) => {
    setCouponCode(e.target.value);
  };

  return (
    <div style={{ background: "#ffffff", height: "100vh" }}>
      <Row justify={"center"} gutter={[30, 10]} className="m-2">

        <Col xs={22} sm={22} md={22} lg={22} xl={24} xxl={24}
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            color: "#0850a5",
            margin: "20px 0px",
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          &nbsp; View package details
        </Col>

        <Col xs={22} sm={22} md={12} lg={12} xl={12} xxl={12} className="mb-2">
          <Row
            style={{
              border: "1px solid #ddd",
              padding: "15px 15px 30px 15px",
            }}
          >
            <Col span={24}>
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                Enter Your Details
              </span>
            </Col>

            <Row gutter={[20, 20]} className="pkg-details">
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <SelectComponent
                  label={<h6 style={{ color: "#000" }}>Prefix</h6>}
                  options={option}
                  className={"inputField"}
                  star={"none"}
                  name={"prefix"}
                  errors={errors}
                  touched={touched}
                  value={values.prefix}
                  handleChange={(v) => setValues({ ...values, prefix: v })}
                />
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <NewInputComp
                  label={<h6 style={{ color: "#000" }}>First Name</h6>}
                  placeholder={"First Name"}
                  className={"inputField"}
                  name={"firstName"}
                  value={values.firstName}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <NewInputComp
                  label={<h6 style={{ color: "#000" }}>Last Name</h6>}
                  placeholder={"Last Name"}
                  className={"inputField"}
                  name={"lastName"}
                  value={values.lastName}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <NewInputComp
                  label={<h6 style={{ color: "#000" }}>Mobile number</h6>}
                  placeholder={"Phone Number"}
                  className={"inputField"}
                  name={"whatsappNumber"}
                  value={values.whatsappNumber}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <NewInputComp
                  label={<h6 style={{ color: "#000" }}>E-mail Address</h6>}
                  placeholder={"E-mail Address"}
                  className={"inputField"}
                  name={"emailAddress"}
                  value={values.emailAddress}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>

              <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
                <InputDate
                  label={<h6 style={{ color: "#000" }}>Travel Date</h6>}
                  className={"inputField cursor-pointer webkitWidth"}
                  suffixIcon={<FontAwesomeIcon icon={faCalendar} />}
                  //format={"YYYY-MM-DD"}
                  format={"DD-MM-YYYY"}
                  errors={errors}
                  touched={touched}
                  value={dayjs(values.travelDate)}
                  handleChange={handleDateChange}
                />
              </Col>

              <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                <NewInputComp
                  label={<h6 style={{ color: "#000" }}>Adult</h6>}
                  className={"inputField"}
                  name={"adult"}
                  value={values.adult}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                />
              </Col>

              <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                <NewInputComp
                  label={<h6 style={{ color: "#000" }}>Child</h6>}
                  className={"inputField"}
                  name={"child"}
                  value={values.child}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  defaultValue={0}
                />
              </Col>

            </Row>
          </Row>
        </Col>

        <Col xs={22} sm={22} md={12} lg={12} xl={12} xxl={12} >
          <Row style={{ border: "1px solid #ddd", padding: "0% 3%" }}>
            <Row
              style={{
                width: "100%",
                marginBottom: "2%",
                paddingTop: "5px",
              }}
            >
              <h3>Billing Details</h3>
            </Row>

            <Row
              justify={"center"}
              style={{
                width: "100%",
                marginBottom: "2%",
                background: "#f5f5f5",
                border: "1px solid #eee",
                padding: "10px 15px",
              }}
            >
              <h6>
                Billing Summary 1 ({data.packageName && data.packageName})
              </h6>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              {/* <Row > */}
              <Col>
                Travel Date <br />
                {/* Total time */}
              </Col>

              <Col>
                {moment(values.travelDate).format("ll")} <br />
              </Col>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <Col>Guests</Col>
              {/* <Col>1 Adults , 0 Children</Col> */}
              <Col>{`${values.adult} Adults , ${values.child} children`}</Col>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <Col>
                <h6>Subtotal (INR) :</h6>
              </Col>
              <Col>
                {" "}
                <h6 style={{ marginRight: "5px" }}>
                  {data?.promotionId?.promotionType.percentage
                    ? parseInt(
                      parseInt(data?.packageBasicRate)
                        ? parseInt(data?.packageBasicRate) /
                        (1 - data?.promotionId?.value / 100)
                        : ""
                    )
                    : parseInt(data?.packageBasicRate) +
                    data?.promotionId?.value}
                  {/* {data.packageBasicRate} */}
                </h6>
              </Col>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <Col>
                <h6>Discount (INR):</h6>
              </Col>
              <Col>
                {" "}
                <h6 style={{ marginRight: "5px" }}>
                  {data?.promotionId?.promotionType?.percentage
                    ? parseInt(
                      parseInt(data?.packageBasicRate)
                        ? parseInt(data?.packageBasicRate) /
                        (1 - data?.promotionId?.value / 100) -
                        parseInt(data?.packageBasicRate)
                        : ""
                    )
                    : data?.promotionId?.value}
                  {/* {data.packageBasicRate - data.discountRate} */}
                  {/* {discountRate} */}
                </h6>
              </Col>
            </Row>

            <Row
              style={{
                background: "#f5f5f5",
                marginBottom: "3%",
                padding: "8px 0px",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  marginBottom: "2%",
                  borderBottom: "1px solid #ddd",
                  alignItems: "baseline",
                }}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ padding: "8px 15px" }}
                >
                  <h5>Coupon Code</h5>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ padding: "8px 15px" }}
                >
                  <NewInputComp
                    label={""}
                    name="couponCode"
                    value={couponCode}
                    star={"none"}
                    className={"inputField"}
                    handleChange={handleCouponCode}
                  />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ padding: "8px 15px" }}
                >
                  <BtnComp
                    btnName={"Apply"}
                    width="100%"
                    height="100%"
                    bg_color="#ff4d63"
                    color="#ffffff"
                    fontSize="17px"
                    btnClickFun={couponApply}
                  />
                </Col>
              </Row>

              <Row
                justify={"space-between"}
                style={{
                  width: "100%",
                  marginBottom: "2%",
                  borderBottom: "1px solid #ddd",
                  padding: "8px 0px",
                }}
              >
                <Col>
                  <h6>Total Payable (including tax) :</h6>
                </Col>
                <Col>
                  <h6 style={{ marginRight: "5px" }}>{defaultValue}</h6>
                </Col>
              </Row>
            </Row>

            <Row style={{ width: "100%", marginBottom: "2%" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <BtnComp
                  btnName={"Submit Query"}
                  width="100%"
                  height="100%"
                  bg_color="#3d75df"
                  color="#ffffff"
                  fontSize="17px"
                  btnClickFun={handleSubmit}
                />
              </Col>
            </Row>

            <Row
              justify={"center"}
              style={{ width: "100%", alignItems: "baseline" }}
            >
              <FontAwesomeIcon
                icon={faLock}
                style={{ color: "#3d75df", marginRight: "1%" }}
              />
              <h5 style={{ color: "#3d75df" }}>Safe And Secure</h5>
            </Row>

            <UserSucessModel
              isModalOpen={successModel}
              handleOk={modelHandleOk}
              handleCancel={handleCancel}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};
