import { useFormik } from "formik";
import React, { useState } from "react";
import { NotificationBox } from "../../atoms/notificationBox/NotificationBox";
import ModalComponent from "../../molecules/modalComponent/ModelComponent";
import { Col, Row } from "antd";
import NewInputComp from "../../atoms/input/NewInputComp";
import { resetAdminPassword } from "../../../services/utils/loginForm";
import { ResetPasswordAdminScheema } from "./ResetPasswordSchema";
import { useNavigate } from "react-router-dom";
// import SelectComponent from "../../atoms/SelectComponent/SelectComponent";

const ResetPasswordForm = () => {
  const [reModalOpen, setReModal] = useState(true);
  const [contextHolder, openNotificationSuccess, openNotificationError] =
    NotificationBox();
  const history = useNavigate();

  const {
    values,
    errors,
    touched,
    handleSubmit,
    // setValues,
    handleChange,
    handleReset,
  } = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: ResetPasswordAdminScheema,
    onSubmit: (values, action) => {
      action.resetForm();
      addDataFunction();
      setReModal(false);
      history("/login");
    },
  });

  const addDataFunction = async () => {
    let token = localStorage.getItem("resetToken");
    // debugger;
    const res = await resetAdminPassword(values, token);
    try {
      res.data.error
        ? openNotificationError(res.data.message)
        : openNotificationSuccess(res.data.message);
    } catch (error) {
      res ? openNotificationError("Something Went Wrong..!") : "";
    }
  };
  const handleClose = () => {
    setReModal(false);
  };

  return (
    <>
      {contextHolder}
      <ModalComponent
        isModalOpen={reModalOpen}
        modalTitle="Reset Login Credentials"
        showFooter={false}
        showClearBtn={false}
        showSaveBtn={false}
        cancelBtnfun={handleClose}
        createBtnFun={handleSubmit}
        resetBtnfun={handleReset}
        createBtnName="Submit"
      >
        <Row className="p-4" gutter={20}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <NewInputComp
              value={values.password ? values.password : ""}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              label="Password"
              name="password"
            />
          </Col>
        </Row>
      </ModalComponent>
    </>
  );
};

export default ResetPasswordForm;
