import { Button, 
  // Col, 
  // Row 
} from "antd";
import React, { Component, useEffect, useState } from "react";
import errorImage from '../../../assets/404.png'

function Follback({ error }) {
  const reloadfun = () => {
    location.reload();
  };

  const styleDesktopImage = {
    width: '60%',
    height: '70%',
    objectFit: 'contain'
  }

  const styleMobileImage = {
    paddingTop: '25px',
    width: '150%',
    height: '100%',
    objectFit: 'contain'
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsDesktop(window.innerWidth > 1024);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div id="error-boxx container"
      style={{
        display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          height: isDesktop ? '750px' : '100%',
          gap: isMobile ? '10px' : 0
      }}
      >
        {/* <div className="face2">
          <div className="eye"></div>
          <div className="eye righteye"></div>
          <div className="mouth sad"></div>
        </div>
        <div className="shadoww movee"></div> */}
        <img
          src={errorImage}
          alt="Page not found"
          style={isDesktop && styleDesktopImage || isMobile && styleMobileImage}
        />
        {/* <div className="messagee"> */}
          <h1 className="alert text-center">Error!</h1>
          <p>oh no, something went wrong. </p>
        {/* </div> */}
        {/* <div className="messagee"> */}
          <h1 className="alert text-center">{error}</h1>
        {/* </div> */}
        <Button className="button-boxx" onClick={reloadfun}>
            Try again
          </Button>
      </div>
      {/* <Row
        justify={"center"}
        style={{ position: "absolute", top: "70%", right: "45%" }}
      >
        <Col>
          <Button className="button-boxx" onClick={reloadfun}>
            {" "}
            Try again
          </Button>
        </Col>
      </Row> */}
    </div>
  );
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <Follback />;
    }

    return this.props.children;
  }
}

// const Follback =()=>{
//     return (
//         <div id="error-box">
//     <div className="dot"></div>
//     <div className="dot two"></div>
//     <div className="face2">
//       <div className="eye"></div>
//       <div className="eye right"></div>
//       <div className="mouth sad"></div>
//     </div>
//     <div className="shadow move"></div>
//     <div className="message"><h1 class="alert">Error!</h1><p>oh no, something went wrong.</div>
//     <button className="button-box"><h1 class="red">try again</h1></button>
//   </div>
//     )
// }

export { ErrorBoundary, Follback };
