const HOUSEBOAT_ROOM_AVAILABILITY_HEADERS = [
  {
    dataIndex: "col1",
    title: "S.N",
    nummeric: false,
    disablePadding: true,
    label: "S.N",
    width: 40,
  },

  {
    dataIndex: "col2",
    title: "Validity-From",
    nummeric: false,
    disablePadding: true,
    width: 80,
  },

  {
    dataIndex: "col3",
    title: "Validity-To",
    nummeric: false,
    disablePadding: true,
    filter: "select",
    width: 80,
  },

  {
    dataIndex: "col4",
    title: "Room Category",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 120,
  },

  {
    dataIndex: "col5",
    title: "Total Rooms",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 80,
  },

  {
    dataIndex: "col6",
    title: "Actions",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 80,
  },
];

export default HOUSEBOAT_ROOM_AVAILABILITY_HEADERS;
