import React from "react";
import { Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Carousel } from "antd";
// import { faBed, faCar} from '@fortawesome/free-solid-svg-icons';
// import activity from '../../../../assets/activity.png'
// import food from '../../../../assets/food.png';
// import sight from '../../../../assets/vision.png';
// import visa from '../../../../assets/passport.png';
import tik from "../../../../assets/tik.png";
import qt from "../../../../assets/qt.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import "./package.css";

function PackageNearByPage() {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <>
      <Card>
        <Row gutter={[16, 16]}>
          <Col md={8}>
            <img
              className="imgcard"
              src="https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg"
              alt=""
            />
          </Col>
          <Col md={8} className="mb-5">
            <span className="title">
              GOD’S OWN STAY Pearl 2 & 3 Bedrooms Full Day Cruise
            </span>
            <p className="sub-title1">INDIA-Kerala-Alleppey</p>
            <p className="sub-title2">Boat Jetty Punnamada Alleppey</p>
            <Card className="mt-3 carousel-card">
              <Carousel afterChange={onChange}>
                <div>
                  <Row>
                    <div className="card-content">
                      <div>
                        <img className="" src={qt} alt="" />
                      </div>
                      <span
                        className="px-2 mt-4"
                        style={{ borderLeft: "1px solid lightgray" }}
                      ></span>
                      <div className="mt-4 card-description">
                        <p>
                          Certainly worth the visit. Very unique experience,
                          took two different tours and enjoyed both. Very
                          relaxing. The boats are reasonably clean, and offer
                          lot of options.
                        </p>
                        <p className="text-start">
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />

                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                        </p>
                        <div className="my-3" style={{ display: "flex" }}>
                          <div>
                            <img
                              className="testimoni-pic"
                              src="https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg"
                              alt=""
                            />
                          </div>
                          <div className="testimoni-name">
                            <span>Yamini Menon</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
                <div>
                  <Row>
                    <div className="card-content">
                      <div>
                        <img className="" src={qt} alt="" />
                      </div>
                      <span
                        className="px-2 mt-4"
                        style={{ borderLeft: "1px solid lightgray" }}
                      ></span>
                      <div className="mt-4 card-description">
                        <p>
                          Certainly worth the visit. Very unique experience,
                          took two different tours and enjoyed both. Very
                          relaxing. The boats are reasonably clean, and offer
                          lot of options.
                        </p>
                        <p className="text-start">
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />

                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                        </p>
                        <div className="my-3" style={{ display: "flex" }}>
                          <div>
                            <img
                              className="testimoni-pic"
                              src="https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg"
                              alt=""
                            />
                          </div>
                          <div className="testimoni-name">
                            <span>Yamini Menon</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
                <div>
                  <Row>
                    <div className="card-content">
                      <div>
                        <img className="" src={qt} alt="" />
                      </div>
                      <span
                        className="px-2 mt-4"
                        style={{ borderLeft: "1px solid lightgray" }}
                      ></span>
                      <div className="mt-4 card-description">
                        <p>
                          Certainly worth the visit. Very unique experience,
                          took two different tours and enjoyed both. Very
                          relaxing. The boats are reasonably clean, and offer
                          lot of options.
                        </p>
                        <p className="text-start">
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />

                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                        </p>
                        <div className="my-3" style={{ display: "flex" }}>
                          <div>
                            <img
                              className="testimoni-pic"
                              src="https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg"
                              alt=""
                            />
                          </div>
                          <div className="testimoni-name">
                            <span>Yamini Menon</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
                <div>
                  <Row>
                    <div className="card-content">
                      <div>
                        <img className="" src={qt} alt="" />
                      </div>
                      <span
                        className="px-2 mt-4"
                        style={{ borderLeft: "1px solid lightgray" }}
                      ></span>
                      <div className="mt-4 card-description">
                        <p>
                          Certainly worth the visit. Very unique experience,
                          took two different tours and enjoyed both. Very
                          relaxing. The boats are reasonably clean, and offer
                          lot of options.
                        </p>
                        <p className="text-start">
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />

                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                        </p>
                        <div className="my-3" style={{ display: "flex" }}>
                          <div>
                            <img
                              className="testimoni-pic"
                              src="https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg"
                              alt=""
                            />
                          </div>
                          <div className="testimoni-name">
                            <span>Yamini Menon</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
                <div>
                  <Row>
                    <div className="card-content">
                      <div>
                        <img className="" src={qt} alt="" />
                      </div>
                      <span
                        className="px-2 mt-4"
                        style={{ borderLeft: "1px solid lightgray" }}
                      ></span>
                      <div className="mt-4 card-description">
                        <p>
                          Certainly worth the visit. Very unique experience,
                          took two different tours and enjoyed both. Very
                          relaxing. The boats are reasonably clean, and offer
                          lot of options.
                        </p>
                        <p className="text-start">
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />

                          <FontAwesomeIcon
                            icon={faStar}
                            color="#FFA500"
                            style={{ marginRight: "5px" }}
                          />
                        </p>
                        <div className="my-3" style={{ display: "flex" }}>
                          <div>
                            <img
                              className="testimoni-pic"
                              src="https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg"
                              alt=""
                            />
                          </div>
                          <div className="testimoni-name">
                            <span>Yamini Menon</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </Carousel>
            </Card>
          </Col>
          <Col md={8}>
            <span className="houseboat-title">Houseboat Highlights</span>
            <Row>
              <Col md={2} sm={3}>
                <img className="tik" src={tik} alt="" />
              </Col>
              <Col md={22} sm={21}>
                <span className="houseboat-content">24*7 Gos care Support</span>
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={3}>
                <img className="tik" src={tik} alt="" />
              </Col>
              <Col md={22} sm={21}>
                <span className="houseboat-content">
                  Couple friendly Houseboat
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={3}>
                <img className="tik" src={tik} alt="" />
              </Col>
              <Col md={22} sm={21}>
                <span className="houseboat-content">
                  Complimentary Breakfast + Welcome Drink + Lunch + Tea &
                  Snacks+ Dinner
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={3}>
                <img className="tik" src={tik} alt="" />
              </Col>
              <Col md={22} sm={21}>
                <span className="houseboat-content">
                  We don’t have any hidden charges
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={3}>
                <img className="tik" src={tik} alt="" />
              </Col>
              <Col md={22} sm={21}>
                <span className="houseboat-content">
                  We don’t have any hidden charges
                </span>
              </Col>
            </Row>
            <Row style={{ paddingTop: "20px" }}>
              <Col md={24}>
                <span className="bottom-text">
                  <span className="emi-text">EMI</span>&nbsp;Starting from{" "}
                  <span className="number-text">₹1499</span>
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="profile" title="Gallery">
                {/* <ImageGallery items={images} autoPlay={true} /> */}
              </Tab>
              <Tab eventKey="home" title="Room Details">
                <Card className="tableCard">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ width: "25%" }}>
                          Room Category / Trip Type
                        </th>
                        <th style={{ width: "25%" }}>Share Type</th>
                        <th style={{ width: "25%" }}>Price</th>
                        <th style={{ width: "25%" }}>Book</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="table-content">
                          Premium 2 Bed Room / Full Day
                        </td>
                        <td className="table-content">
                          Room With BreakFast +Welcome Drink + Lunch + Tea &
                          Snacks + Dinner
                        </td>
                        <td className="price-td">
                          {" "}
                          <strong className="prive-value">₹7890</strong>&nbsp;
                          <span className="offer-price">26300</span>{" "}
                          <span className="off-value">70% off &nbsp;</span>
                          <span className="extrabed-text">
                            Extra Bed (Child : 600,Adult : 700)
                          </span>
                        </td>
                        <td>
                          <button className="contact-btn">Contact Us</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="table-content">
                          Premium 3 Bed Room / Full Day
                        </td>
                        <td className="table-content">
                          Room With BreakFast +Welcome Drink + Lunch + Tea &
                          Snacks + Dinner
                        </td>
                        <td className="price-td">
                          {" "}
                          <strong className="prive-value">₹7890</strong>&nbsp;
                          <span className="offer-price">26300</span>{" "}
                          <span className="off-value">70% off &nbsp;</span>
                          <span className="extrabed-text">
                            Extra Bed (Child : 600,Adult : 700)
                          </span>
                        </td>
                        <td>
                          <button className="contact-btn">Contact Us</button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default PackageNearByPage;
