import React, { useEffect } from "react";

import { useFormik } from "formik";
import { Col, Row } from "antd";
import NewInputComp from "../../../atoms/input/NewInputComp";
import ModalComponent from "../ModelComponent";
import { listpropertySchema } from "./listPropertySchema";
import { listPropertySendFunction } from "../../../../services/utils/userUtils/hotelutils";
// import { listpropertySchema } from './listPropertySchema'
function ListPropertyModel({
  open,
  setListPropertyModelOpen,
  openNotificationError,
  openNotificationSuccess,
}) {
  const { values, errors, touched, handleSubmit, handleChange, setErrors } =
    useFormik({
      initialValues: {
        userName: "",
        hotelName: "",
        hotelAddress: "",
        roomCategory: "",
        email: "",
        phoneNumber: "",
      },
      validationSchema: listpropertySchema,
      onSubmit: async (value, helpers) => {
        try {
          const res = await listPropertySendFunction(value);
          if (res) {
            openNotificationSuccess("Details sent to Admin Successfully..");
          }
        } catch (err) {
          openNotificationError("Something went wrong..");
        }
        helpers.resetForm();
        setListPropertyModelOpen(false);
      },
    });
  useEffect(() => {
    setErrors({});
  }, [open]);

  return (
    <ModalComponent
      isModalOpen={open}
      showFooter={false}
      modalTitle="List your Property"
      closable={false}
      modalWidth={600}
      cancelBtnfun={() => setListPropertyModelOpen((o) => !o)}
      createBtnFun={handleSubmit}
    >
      <div
        style={{
          padding: "2%",
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col
            span={9}
            sm={9}
            lg={6}
            className="just-end me-2"
            style={{ whiteSpace: "nowrap" }}
          >
            <p style={{ fontWeight: "500" }}>Contact Person :</p>
          </Col>
          <Col span={14} sm={14} lg={16}>
            <NewInputComp
              value={values.userName}
              errors={errors}
              touched={touched}
              name="userName"
              label={""}
              placeholder="Contact Person"
              star="none"
              handleChange={handleChange}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col
            span={9}
            sm={9}
            lg={6}
            className="just-end me-2"
            style={{ whiteSpace: "nowrap" }}
          >
            <p style={{ fontWeight: "500" }}>Hotel Name :</p>
          </Col>
          <Col span={14} sm={14} lg={16}>
            <NewInputComp
              value={values.hotelName}
              errors={errors}
              touched={touched}
              name="hotelName"
              label={""}
              placeholder="Enter Hotel Name"
              star="none"
              handleChange={handleChange}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col
            span={9}
            sm={9}
            lg={6}
            className="just-end me-2"
            style={{ whiteSpace: "nowrap" }}
          >
            <p style={{ fontWeight: "500" }}>Address :</p>
          </Col>
          <Col span={14} sm={14} lg={16}>
            <NewInputComp
              value={values.hotelAddress}
              errors={errors}
              touched={touched}
              name="hotelAddress"
              label={""}
              placeholder="Address"
              star="none"
              handleChange={handleChange}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col
            span={9}
            sm={9}
            lg={6}
            className="just-end me-2"
            style={{ whiteSpace: "nowrap" }}
          >
            <p style={{ fontWeight: "500" }}>Room Type :</p>
          </Col>
          <Col span={14} sm={14} lg={16}>
            <NewInputComp
              value={values.roomCategory}
              errors={errors}
              touched={touched}
              name="roomCategory"
              label={""}
              placeholder="Room Type"
              star="none"
              handleChange={handleChange}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col
            span={9}
            sm={9}
            lg={6}
            className="just-end me-2"
            style={{ whiteSpace: "nowrap" }}
          >
            <p style={{ fontWeight: "500" }}>Email :</p>
          </Col>
          <Col span={14} sm={14} lg={16}>
            <NewInputComp
              value={values.email}
              errors={errors}
              touched={touched}
              name="email"
              label={""}
              placeholder="Email"
              star="none"
              handleChange={handleChange}
            />
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col
            span={9}
            sm={9}
            lg={6}
            className="just-end me-2"
            style={{ whiteSpace: "nowrap" }}
          >
            <p style={{ fontWeight: "500" }}>Phone Number :</p>
          </Col>
          <Col span={14} sm={14} lg={16}>
            <NewInputComp
              value={values.phoneNumber}
              errors={errors}
              touched={touched}
              name="phoneNumber"
              label={""}
              placeholder="Enter Phone Number"
              star="none"
              handleChange={handleChange}
            />
          </Col>
        </Row>
      </div>
    </ModalComponent>
  );
}

export default ListPropertyModel;
