export const RegexValidation = {
  Fields: /^[a-zA-Z0-9]+(?:[.]?(?:[ ]?[a-zA-Z0-9]+))*(?:[.]?)*$/,
  Fieldsmsg: "Symbols & multiple spaces are not allowed.",

  passwordReg: /^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$/,
  passwordRegMsg: "Enter Valid Password ",

  OnlyString: /^[a-zA-Z]+\s*(?:[.]?(?:\s*[a-zA-Z]+\s*))*(?:[.]?)*$/,
  OnlyStringMsg: "Only characters are allowed.",

  onlyAlphaNumeric: /^[A-Z0-9]+$/,
  onlyAlphaNumericMsg: "Only capital letters or numbers are allowed",

  onlyAlphaNumerics:
    /^[a-zA-Z0-9]+\s*(?:[.]?(?:\s*[a-zA-Z0-9]+\s*))*(?:[.]?)*\s*$/,
  onlyAlphaNumericMsgs: "Only capital letters or numbers are allowed",

  onlyNumbers: /^[0-9]+$/,
  onlyNumbersMsg: "Only numbers are allowed",

  mobile: /^(0|91)?[6-9][0-9]{9}\s*$/,
  mobileMsg: "Please enter valid mobile no.",
  // eslint-disable-next-line no-useless-escape
  // onlyEmail: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  onlyEmail: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/,
  onlyEmailMsg: "Please enter valid email address.",

  floatNumbers: /^[+-]?([0-9]*[.])?[0-9]+$/,
  floatNumbersMsg: "Only numbers or floating numbers are allowed.",

  LogitudeLatitude: /^[-]?((1[0-7][0-9])|([0-9]?[0-9]))\.(\d+)|(180(\.0+)?)$/,
  LongitudeMsg: "Longitude must be a number between -180 and 180.",
  LatitudeMsg: "Latitude must be a number between -90 and 90.",

  Address:
    /^(?!.*([,/.#'-\s])\1)[0-9a-zA-Z,-.#'/][0-9a-zA-Z\s,-.#'/]*[0-9a-zA-Z,-.'#/]$/,
  AddressMsg: "Enter Correct Address Format",

  StringAndSpecialCharacters: /^[ A-Za-z_@.,/#&$%*!"'()+-]*$/,
  StringAndSpecialCharactersMsg:
    "Only characters and special characters are allowed",

  GSTN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  GSTNMsg: "Please enter a valid GSTN.",
};
