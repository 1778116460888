/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { ReactComponent as Hotelimgc } from "../../../../assets/hoteliconC.svg";
import { ReactComponent as PackageC } from "../../../../assets/hotelimg2.svg";
import { ReactComponent as HouseboatC } from "../../../../assets/hotelimg1.svg";
import { ReactComponent as Boatico } from "../../../../assets/boat3.svg";
// import wave from "../../../../assets/w12.png";
// import wstrip from "../../../../assets/wstrip.png";
import { Row, Col, Grid, Flex } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import "../../../homePage/homePageHeader/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginModel from "../../../../components/molecules/modalComponent/LoginModel";
import HeaderForAll from "../../../homePage/homePageHeader/HeaderForAll";
import { useNavigate } from "react-router";
import DropDownComp from "../../../../components/atoms/dropdownComponent/DropDownComp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faBriefcase,
  faCircleUser,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { removeUserDetails } from "../../../../reduxData/sliceData/userDetailsReducer";
import { fetchHotelData } from "../../../../reduxData/sliceData/hotelReducer";
import { setSearchCardData } from "../../../../reduxData/sliceData/searchCardReducer";

import moment from "moment/moment";
import { getProfileImage } from "../../../../services/utils/userUtils/hotelutils/otpverification";

const currentDate = moment().startOf("day").format("DD/MM/YYYY");
const nextDate = moment().add(1, "day").endOf("day").format("DD/MM/YYYY");

// import SearchCard from '../../../../components/atoms/card/userCards/SearchCard'

const { useBreakpoint } = Grid;

function MainHeader() {
  const { md, lg, xl } = useBreakpoint();
  const nav = useNavigate();
  const [logoutDropdown, setlogoutDropdown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [routePage, setroutePage] = useState("hotel");
  const [loginOpen, setLoginOpen] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [userDetails, setuserDetails] = useState();
  // const [searchboxShow, setsearchboxShow] = useState(false)
  const selector = useSelector((s) => s.userDetails.user);
  const [userProfileData, setUserProfileData] = useState();
  const dispatch = useDispatch();

  const LoginClickFun = () => {
    setisModalOpen(isModalOpen ? false : true);
  };
  const closeBtnFun = () => {
    setisModalOpen(isModalOpen ? false : true);
  };
  const logoutFun = () => {
    dispatch(removeUserDetails());
    setTimeout(function() {
      nav("/");
  }, 100);
  };

  const homepageNavigate = () => {
    dispatch(
      setSearchCardData({
        destinationName: "",
        checkIn: currentDate,
        checkOut: nextDate,
        guestDetails: {
          rooms: 1,
          childAge: [],
          adults: 2,
          children: 0,
        },
      })
    );
    nav("/");
    dispatch(fetchHotelData());
  };

  useEffect(() => {
    setuserDetails(selector);
  }, []);

  useEffect(() => {
    const getUserProfileImage = () => {
      getProfileImage(selector.id).then((res) => {
        setUserProfileData(res.data.response);
      });
    };
    if (selector?.id) {
      getUserProfileImage();
    }
  }, [selector]);

  // useEffect(() => {
  //   lg || md ? setsearchboxShow(true) :setsearchboxShow(false)
  // }, [lg,md])

  return (
    <>
      <HeaderForAll />
      {xl && (
        <div className="Hotel_second_header">
          <div>
            <img
              src={
                md || lg
                  ? `${process.env.REACT_APP_S3_BUCKET_URL}/images/background/w12.png`
                  : `${process.env.REACT_APP_S3_BUCKET_URL}/images/background/wstrip.png`
              }
              alt=""
              className="waveimg"
            />
          </div>
          <div className="tabContent" style={{ marginTop: "-43%" }}>
            <Row style={{ width: "100%" }} className=" just-center ">
              <Col span={24}>
                <Row
                  justify={`${lg || md ? "center" : "space-around"}`}
                  gutter={`${lg || md ? 40 : 10}`}
                  className="m-2 tabscontent"
                >
                  <Col>
                    <div
                      className="topiconCard-other"
                      onClick={homepageNavigate}
                    >
                      <Hotelimgc
                        className="headerlogostyle1"
                        style={{ cursor: "pointer" }}
                      />
                      <p className="iconTexthead" style={{ cursor: "pointer" }}>
                        Hotel
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="topiconCard-other"
                      onClick={() =>
                        nav(
                          "/holiday-kerala/kerala-travel-packages/Domestic.html"
                        )
                      }
                    >
                      <PackageC
                        className="headerlogostyle1"
                        style={{ cursor: "pointer" }}
                      />
                      <p className="iconTexthead" style={{ cursor: "pointer" }}>
                        Package
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="topiconCard-other"
                      onClick={() =>
                        nav("/houseboat/houseboat-in-alleppey.html")
                      }
                    >
                      <HouseboatC
                        className="headerlogostyle1"
                        style={{ cursor: "pointer" }}
                      />
                      <p className="iconTexthead" style={{ cursor: "pointer" }}>
                        Houseboat
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="topiconCard-other"
                      onClick={() => nav("/shikara/alleppey-shikara-boat.html")}
                    >
                      <Boatico
                        className="headerlogostyle1"
                        style={{ cursor: "pointer" }}
                      />
                      <p className="iconTexthead" style={{ cursor: "pointer" }}>
                        Shikara
                      </p>
                    </div>
                  </Col>
                  <Row
                    className="login-content"
                    justify={"end"}
                    style={{ marginRight: "-15%" }}
                  >
                    <Col span={24} className="just-center loginmobile">
                      {/* __________________________________________________________________________ */}

                      {userDetails?.name ? (
                        <div
                          style={{ color: "white", width: "100%" }}
                          onClick={() => setlogoutDropdown(!logoutDropdown)}
                        >
                          <div className="logoutdropdown">
                            Welcome {userProfileData?.firstName} &nbsp;
                            {/* <span style={{paddingTop:"-10px"}}> */}
                            <DropDownComp
                              placement={"bottomRight"}
                              arrow={true}
                              icon={true}
                              open={logoutDropdown}
                              overlayStyle={{
                                marginTop: "50px",
                                marginLeft: "45px",
                              }}
                              DropOptions={[
                                <>
                                  <Flex gap={5} vertical>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => nav("/user-profile")}
                                    >
                                      <FontAwesomeIcon
                                        style={{ color: "#164888" }}
                                        icon={faCircleUser}
                                      />{" "}
                                      &nbsp;My Profile
                                    </span>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => nav("/bookings")}
                                    >
                                      <FontAwesomeIcon
                                        style={{ color: "#164888" }}
                                        icon={faBriefcase}
                                      />{" "}
                                      &nbsp;My Bookings
                                    </span>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => nav("/feedbacklink")}
                                    >
                                      <FontAwesomeIcon
                                        style={{ color: "#164888" }}
                                        icon={faHeadset}
                                      />{" "}
                                      &nbsp;Contact Us
                                    </span>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={logoutFun}
                                    >
                                      <FontAwesomeIcon
                                        style={{ color: "#164888" }}
                                        icon={faRightFromBracket}
                                      />{" "}
                                      &nbsp;Logout
                                    </span>
                                  </Flex>
                                </>,
                              ]}
                            />
                            {/* </span> */}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="loginHide "
                          style={{ marginLeft: "150px", marginTop: "0px" }}
                        >
                          <div className="loginheadbtn" onClick={LoginClickFun}>
                            <div>
                              <Typography style={{ cursor: "pointer" }}>
                                Login/SignUp{" "}
                                <CaretDownOutlined
                                  style={{ paddingTop: "-10px" }}
                                />
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
            {!userDetails?.name ? (
              <LoginModel
                isModalOpen={isModalOpen}
                setisModalOpen={setisModalOpen}
                closeBtnFun={closeBtnFun}
                setLoginOpen={setLoginOpen}
                loginOpen={loginOpen}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default MainHeader;
