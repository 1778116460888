const HOTAL_SETTINGS_SEASON_TYPE = [
  {
    dataIndex: "col1",
    title: "S.N",
    nummeric: false,
    disablePadding: true,
    label: "S.N",
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 40,
  },
  {
    dataIndex: "col2",
    title: "Season Type",
    nummeric: false,
    disablePadding: true,

    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 120,
  },
  {
    dataIndex: "col5",
    title: "Actions",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 80,
  },
];

export default HOTAL_SETTINGS_SEASON_TYPE;
