import serviceUtil from ".././../../index";
const config = {
 headers: {
  Accept: "application/json",
  "Content-Type": "application/json",
 },
 body: JSON.stringify(),
};

const basicSettingsDesignationGetAll = () => {
 return serviceUtil
  .get("master/basicSettings/getDesignation")
  .then((res) => {
   const data = res && res.data;
   return { data };
  })
  .catch((err) => {
   const errRes = err && err.response.data;
   return { errRes };
  });
};

const basicSettingDestinationCreate = (payload) => {
 return serviceUtil
  .post(`master/basicSettings/createDesignation`, payload, config)
  .then((res) => {
   return res;
  })
  .catch((err) => {
   const errRes = err && err.response.data;
   return { errRes };
  });
};

const basicSettingsDesignationUpdate = (designationId, payload) => {
 return serviceUtil
  .put(`master/basicSettings/updateDesignation/${designationId}`, payload, config)
  .then((res) => {
   const data = res && res.data;
   return { data };
  })
  .catch((err) => {
   const errRes = err && err.response.data;
   return { errRes };
  });
};

const basicSettingsDesignationDelete = (userId) => {
 return serviceUtil
  .deleteById(`master/basicSettings/deleteDesignation/${userId}`)
  .then((res) => {
   const { data, message, err } = res && res.data;
   return { data, message, err };
  })
  .catch((err) => ({ err }));
};

export { basicSettingsDesignationGetAll, basicSettingDestinationCreate, basicSettingsDesignationDelete, basicSettingsDesignationUpdate };
