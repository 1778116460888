import _ from "lodash";

//get value from a nested object by passing the keys in the array

function valueFun(obj, arr) {
    let value = obj;
    arr.forEach(o=>{
        if (value[o]) {
            value= value[o]
        }
    })
    return value;
  }


  // object to serach based on the input object and the nested key in array

const filterSearchfun = (input, data,searchArr) => {
  if (typeof input === 'string'){
    let tokens = input
      .toLowerCase()
      .split(' ')
      .filter((token) => {
        token.trim() !== ''
      })
    const regStr = tokens.length ? tokens.join('|') : input
    const searchReg = new RegExp(regStr, 'gim')

    const filterlist = data?.filter((obj) => {
      
      const searchString = _.reduce(searchArr,(sum,o)=>sum + valueFun(obj,o),"")

      let objstring = searchString.toString().toLowerCase().trim() + ' '
      return objstring.match(searchReg)
    })
   
    return filterlist
  }
    
  }

  // sort nested array of object data by passing the 1 and -1  (object,sortValue 1 or -1 , nested keyArray which need to compare)

  const sortNestedData = (obj,sortValue,keyArr) =>
  obj.sort((a, b) => {
    if (sortValue === 1) {
      return valueFun(a,keyArr) - valueFun(b,keyArr)
    } else {
      return valueFun(b,keyArr) - valueFun(a,keyArr)
    }
  })

  // filter based on two array of object by targeting particular key passing in the arrya which is present in both

  const filterKeyArr=(obj1,obj2,keyArr1,keyArr2)=>{
   return _.filter(obj1, (o1) =>
      _.some(
        obj2,
        (o2) =>
        valueFun(o1,keyArr1) ===valueFun(o2,keyArr2)
      ),
    )
  }

  const checkboxFilter=({ target: { checked } },val,setValue,keyArr1,keyArr2)=>{
        setValue((pre) =>
          checked == true
            ? [...pre, val]
            : pre.filter((prev) =>valueFun(prev,keyArr1) !== valueFun(val,keyArr2)),
        )
  }


  export {filterSearchfun,valueFun,sortNestedData,filterKeyArr,checkboxFilter};