const INVOICE_TABLE_HEADERS = [
    {
      dataIndex: "col1",
      title: "S.N",
      nummeric: false,
      disablePadding: true,
      label: "S.N",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 30,
    },
    {
      dataIndex: "col2",
      title: "Customer name",
      nummeric: false,
      disablePadding: true,
  
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 120,
    },
    {
      dataIndex: "col3",
      title: "Agent name",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 50,
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
      dataIndex: "col4",
      title: "Booking code",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 50,
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    {
        dataIndex: "col5",
        title: "Rate",
        nummeric: false,
        disablePadding: true,
        label: "Category",
        filter: "select",
        width: 30,
        sorter: {
          compare: (a, b) => a.math - b.math,
          multiple: 2,
        },
      },
    {
      dataIndex: "col6",
      title: "Actions",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 30,
    },
  ];
  
  export default INVOICE_TABLE_HEADERS;
  