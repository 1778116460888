const BASIC_SETTINGS_MARKUP_TYPE = [
    {
      dataIndex: "col1",
      title: "S.N",
      nummeric: false,
      disablePadding: true,
      label: "S.N",
      // sorter: {
      //   compare: (a, b) => a.math - b.math,
      //   multiple: 2,
      // },
      width: 20,
    },
    {
      dataIndex: "col2",
      title: "Markup Type",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 80,
      // sorter: {
      //   compare: (a, b) => a.math - b.math,
      //   multiple: 2,
      // },
    },
    {
      dataIndex: "col3",
      title: "Markup Name",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 80,
      // sorter: {
      //   compare: (a, b) => a.math - b.math,
      //   multiple: 2,
      // },
    },
    {
      dataIndex: "col4",
      title: "Markup Value",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 80,
      // sorter: {
      //   compare: (a, b) => a.math - b.math,
      //   multiple: 2,
      // },
    },
    {
      dataIndex: "col5",
      title: "Actions",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 20,
    },
  ];
  
  export default BASIC_SETTINGS_MARKUP_TYPE;
  