import { Button, Col, Modal, Row } from "antd";
import React from "react";

function UserSucessModel({ isModalOpen, handleOk, handleCancel }) {
  return (
    <Modal
      footer={null}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <p className="my-4 fs-5">
        Your Enquiry Request Send to Godsownstay.com and Executive will contact
        you soon,Thank you.
      </p>

      <Row className="div-index ">
        <Col span={24}>
          <Button
            className="user-model-closebtn border-0"
            onClick={handleCancel}
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="user-model-okbtn border-0"
            onClick={handleOk}
            style={{ backgroundColor: "#3d75df" }}
          >
            OK
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default UserSucessModel;
