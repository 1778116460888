/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import {
  faAdd,
  faMapMarkerAlt,
  faUser,
  faBuildingUn,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Dropdown, Grid, Row, Space, Tag } from "antd";
import React, {
  useEffect,
  forwardRef,
  // useRef,
  useState,
} from "react";
import "./index.css";
import BtnComp from "./../../buttonComponent/BtnComp";
import { DatePicker } from "antd";
import {
  CalendarOutlined,
  CaretDownOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

// import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

import { useDispatch, useSelector } from "react-redux";
// import { searchApi } from "../../../../reduxData/sliceData/hotelReducer";
import Slider from "react-slick";
import {
  advanceSearchFilterApi,
  hotelAdvanceSearch,
} from "../../../../services/utils/userUtils/hotelutils";
import { setSearchCardData } from "../../../../reduxData/sliceData/searchCardReducer";
import {
  addFilteredData,
  searchApi,
  selectHotel,
} from "../../../../reduxData/sliceData/hotelReducer";
import { UserLoader } from "../../userLoader";
import { useLocation } from "react-router-dom";
const { useBreakpoint } = Grid;

const locationIcon = (
  <FontAwesomeIcon
    style={{ marginRight: "5px" }}
    color={"#1675DF"}
    icon={faMapMarkerAlt}
  />
);
const buildingIcon = (
  <FontAwesomeIcon
    style={{ marginRight: "5px" }}
    color={"#1675DF"}
    icon={faBuildingUn}
  />
);

const num = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const num2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
const { RangePicker } = DatePicker;

function HeaderSearchCard(props, ref) {
  const currentDate = moment().startOf("day").format("DD/MM/YYYY");
  const nextDate = moment().add(1, "day").endOf("day").format("DD/MM/YYYY");
  // const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [dropDownOn, setdropDownOn] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOptionsArray, setDropdownOptionsArray] = useState([]);
  const [loading, setloading] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const searchCardData = useSelector(
    (state) => state?.searchCardData?.searchCardData
  );
  const selectedHotelData = useSelector(
    (state) => state?.hotelAllData?.selectedData
  );


  const [maxRoom, setmaxRoom] = useState(0);
  const [maxAdult, setmaxAdult] = useState(0);
  const [maxChild, setmaxChild] = useState(0);

  const { xs, lg, md } = useBreakpoint();
  const dispatch = useDispatch();
  const [searchBoxData, setsearchBoxData] = useState(searchCardData);

  const [searchboxShow, setsearchboxShow] = useState(false);
  useEffect(() => {
    lg || md ? setsearchboxShow(true) : setsearchboxShow(false);
  }, [lg, md]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderStetting = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    color: "red",
  };

  const mainSearchBtn = () => {
    if (!searchBoxData?.checkIn && !searchBoxData?.checkOut) {
      setsearchBoxData((prevData) => ({
        ...prevData,
        checkIn: currentDate,
        checkOut: nextDate,
      }));
    }
    
    try {
      const payload = {
        destinationName: selectedHotelData.basicDetails?.hotelName,
        checkIn: searchBoxData?.checkIn,
        checkOut: searchBoxData?.checkOut,
        guestDetails: {
          rooms: searchBoxData?.guestDetails.rooms,
          adults: searchBoxData?.guestDetails.adults,
          children: searchBoxData?.guestDetails.children,
          childAge: searchBoxData?.guestDetails.childAge,
        },
      };

      console.log("payload", payload);

      dispatch(setSearchCardData(payload));

      setloading(true);
      advanceSearchFilterApi(payload)
        .then((res) => {
          dispatch(setSearchCardData(searchBoxData));
          dispatch(selectHotel(res.data.data[0]));
          dispatch(addFilteredData(res.data.data));
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };
  const guestAdd = (field) => {
    setsearchBoxData((o) => ({
      ...o,
      guestDetails: {
        ...o.guestDetails,
        [field]: Number(o.guestDetails[field]) + 1,
      },
    }));
  };
  const guestSub = (field) => {
    let i = 1;
    if (field != "children") {
      i = 1;
    } else {
      i = 0;
    }
    if (searchBoxData?.guestDetails[field] > i) {
      setsearchBoxData((o) => ({
        ...o,
        guestDetails: {
          ...o.guestDetails,
          [field]: Number(o.guestDetails[field]) - 1,
        },
      }));
    }
  };

  const childAdd = () => {
    setsearchBoxData((o) => ({
      ...o,
      guestDetails: {
        ...o.guestDetails,
        childAge: [...o.guestDetails.childAge, 0],
        children: o.guestDetails.childAge.length + 1,
      },
    }));
  };

  const childSub = () => {
    setsearchBoxData((o) => ({
      ...o,
      guestDetails: {
        ...o.guestDetails,
        childAge: [
          ...o.guestDetails.childAge.slice(1, o.guestDetails.childAge.length),
        ],
        children: Math.max(0, o.guestDetails.childAge.length - 1),
      },
    }));
  };

  async function guestApply() {
    setDropdownOpen(false);
    const {
      destinationName,
      checkIn,
      checkOut,
      guestDetails: { adults, childAge, children, rooms },
    } = searchBoxData;
    try {
      const payload = {
        destinationName: destinationName,
        checkIn: checkIn ? checkIn : currentDate,
        checkOut: checkOut ? checkOut : nextDate,
        guestDetails: {
          rooms: rooms,
          adults: adults ? adults : 2,
          children: children ? children : 0,
          childAge: childAge,
        },
      };
    } catch (err) {
      console.log(err);
    }
  }

  const hotelSearchDropdown = async (e) => {
    try {
      if (e.length >= 3) {
        setloading(true);
        const res = await hotelAdvanceSearch(e);
        res?.data && setDropdownOptionsArray(res?.data);
        setloading(false);
      }
    } catch (err) {
      setloading(false);

      console.log(err);
    }
  };
  useEffect(() => {
    hotelSearchDropdown(searchValue);
  }, [searchValue]);

  useEffect(() => {

    let totalRoom = 0;
    let totalAdult = 0;
    let totalChild = 0;

    selectedHotelData?.hotelAvailabilityId?.forEach((ele) => {
      totalRoom = Math.max(totalRoom, ele.noOfRooms);
    });

    selectedHotelData?.occupancyId?.occupencyDetails?.forEach((ele) => {
      totalAdult = Math.max(totalAdult, ele.totalAdult + ele.extraAdult);
      totalChild = Math.max(totalChild, ele.totalChild + ele.extraChild);
    });

    setmaxRoom(totalRoom);
    setmaxAdult(totalAdult * totalRoom);
    setmaxChild(totalChild * totalRoom);

  }, [selectedHotelData]);

  const items = [
    {
      key: "1",
      label: (
        <Row gutter={10}>
          <Col
            lg={12}
            md={24}
            sm={24}
            sx={24}
            style={{ borderRight: "1px solid #ccc" }}
          >
            <Row className="mt-3">
              <p className={`h-4 ${xs ? "ps-2" : ""}`}>
                <b>Rooms</b>
              </p>
              <Row
                style={{ width: "100%", paddingTop: "2%" }}
                justify="space-around"
              >
                <Col span={4}>
                  <div
                    className="childAgeboxbtn"
                    onClick={() => guestSub("rooms")}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                </Col>
                <Col span={8} className="childBoxtxt">
                  {searchBoxData?.guestDetails?.rooms
                    ?.toString()
                    .padStart(2, "0")}
                </Col>
                <Col span={4}>
                  {" "}
                  <div
                    className={`childAgeboxbtn${searchBoxData?.guestDetails?.rooms >= maxRoom ? ' add-disabled' : ''}`}
                    onClick={() => guestAdd("rooms")}
                  >
                    <FontAwesomeIcon icon={faAdd} />
                  </div>
                </Col>
              </Row>
            </Row>
            <Row>
              <p className={`h-4 ${xs ? "ps-2" : ""}`}>
                <b>Adults</b>
              </p>
              <Row
                style={{ width: "100%", paddingTop: "2%" }}
                justify="space-around"
              >
                <Col span={4}>
                  <div
                    className="childAgeboxbtn"
                    onClick={() => guestSub("adults")}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                </Col>
                <Col span={8} className="childBoxtxt">
                  {searchBoxData?.guestDetails?.adults
                    ?.toString()
                    .padStart(2, "0")}
                </Col>
                <Col span={4}>
                  {" "}
                  <div
                    className={`childAgeboxbtn${searchBoxData?.guestDetails?.adults >= maxAdult ? ' add-disabled' : ''}`}
                    onClick={() => guestAdd("adults")}
                  >
                    <FontAwesomeIcon icon={faAdd} />
                  </div>
                </Col>
              </Row>
            </Row>
            <Row>
              <p className={`h-4 ${xs ? "ps-2" : ""}`}>
                <b>Children (1 - 12yrs)</b>
              </p>
              <Row
                style={{ width: "100%", paddingTop: "2%" }}
                justify="space-around"
              >
                <Col span={4}>
                  <div className="childAgeboxbtn" onClick={() => childSub()}>
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                </Col>
                <Col span={8} className="childBoxtxt">
                  {searchBoxData?.guestDetails?.children
                    ?.toString()
                    .padStart(2, "0")}
                </Col>
                <Col span={4}>
                  <div
                    className={`childAgeboxbtn${searchBoxData?.guestDetails?.children >= maxChild ? ' add-disabled' : ''}`}
                    onClick={() => childAdd()}>
                    <FontAwesomeIcon icon={faAdd} />
                  </div>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col lg={12} md={24} sm={24} sx={24} style={{ padding: "2%" }}>
            <p className={`h-4 ${xs ? "ps-2" : ""}`}>
              <b>Select Child Age </b>
            </p>
            <p
              className={`${xs ? "ps-2" : ""}`}
              style={{ color: "#ccc", fontSize: "0.9rem" }}
            >
              Set exact child age to find the best deals
            </p>
            <p className={`h-4 ${xs ? "ps-2" : ""}`}>
              <b>Child Age</b>
            </p>

            <Row
              gutter={[0, 5]}
              style={{
                padding: "2%",
                paddingLeft: "8%",
                // height: "150px",
                overflow: "auto",
              }}
            >
              {searchBoxData?.guestDetails?.childAge.map((_, cIndex) => {
                return (
                  <Col span={22} key={cIndex} style={{ width: "60px" }}>
                    <Slider className="slideAge" {...sliderStetting}>
                      {num.map((v, i) => {
                        return (
                          <Col span={6} key={i}>
                            <div
                              key={i}
                              className={`${searchBoxData?.guestDetails?.childAge[
                                cIndex
                              ] === v
                                ? "childAge childAgeHover"
                                : "childAge"
                                }`}
                              onClick={() => {
                                setsearchBoxData((o) => {
                                  let childAgeArray = [
                                    ...o.guestDetails.childAge,
                                  ];
                                  childAgeArray[cIndex] = v;

                                  return {
                                    ...o,
                                    guestDetails: {
                                      ...o.guestDetails,
                                      childAge: childAgeArray,
                                    },
                                  };
                                });
                              }}
                            >
                              {v}
                            </div>
                          </Col>
                        );
                      })}
                    </Slider>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Button className="applybtn" onClick={guestApply}>
            Apply
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <>
      {loading ? <UserLoader /> : ""}
      <div className="search-card-container" ref={ref}>
        <div className="search-card-MainDiv">
          <Row>
            <Col lg={21} md={21} sm={24} xs={24}>
              <Row
                justify="center"
                style={{
                  width: "100%",
                  display: `${pathname.includes("bookNow") ? "none" : ""}`,
                }}
              >
                <Col lg={18} md={18} sm={24} xs={24}>
                  <div className="searchMainDivdesDetailsPage">
                    <Row justify={"space-between"}>
                      <Col
                        style={{
                          color: "#1675DF",
                          marginTop: "2px",
                          fontWeight: "700",
                        }}
                      >
                        {searchBoxData?.destinationName}
                        <br />
                        <p
                          style={{
                            color: "#808080",
                            fontWeight: "400",
                            fontSize: "0.6rem",
                          }}
                        >
                          {currentDate} - {nextDate},{" "}
                          {searchBoxData?.guestDetails.rooms} Room,{" "}
                          {searchBoxData?.guestDetails.adults} Adult,{" "}
                          {searchBoxData?.guestDetails.children} Child
                        </p>
                      </Col>
                      <Col
                        className="just-center"
                        style={{
                          display: `${isMobile && pathname.includes("bookNow")
                            ? "none"
                            : ""
                            }`,
                        }}
                      >
                        <Tag
                          className="just-center"
                          onClick={() => {
                            setsearchboxShow((o) => !o);
                          }}
                          color="#108ee9"
                        >
                          Edit <DownOutlined style={{ paddingLeft: "2px" }} />
                        </Tag>
                      </Col>
                    </Row>
                  </div>
                  {/* {searchboxShow && (
                  <div className="hotelSearch">
                    <SearchCard />
                  </div>
                )} */}
                </Col>
              </Row>
              {(searchboxShow || props.showSearch) && (
                <Row className="search-widget">
                  <Col span={10} xs={24} lg={7} md={10}>
                    <div style={{ width: "100%" }}>
                      <b>Where would you like to go?</b>
                      <p>{selectedHotelData.basicDetails?.hotelName}</p>
                    </div>
                  </Col>
                  <Col
                    className="large-device1"
                    span={10}
                    xs={24}
                    lg={10}
                    md={10}
                  >
                    <Row>
                      <Col span={12}>
                        <b>Check-In</b>
                      </Col>
                      <Col span={12}>
                        <b> Check-Out</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div
                          className="dateoutline1"
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          {/* <FontAwesomeIcon
                        color={"#1675DF"}
                        style={{ padding: "10px 10px 10px 10px" }}
                        icon={faCalendar}
                      /> */}

                          <RangePicker
                            prefixIcon={<CalendarOutlined />}
                            format="DD/MM/YYYY"
                            placeholder={[
                              searchCardData.checkIn,
                              searchCardData.checkOut,
                            ]}
                            disabledDate={disabledDate}
                            onChange={(_, s) => {
                              let searchData = {
                                ...searchBoxData,
                                checkIn: s[0],
                                checkOut: s[1],
                              };
                              setsearchBoxData(searchData);
                            }}
                            suffixIcon=""
                            className="serchInputcard"
                            style={{ width: "98%", marginRight: "15px" }}
                            dateRender={(current) => {
                              return (
                                <div
                                  className="ant-picker-cell-inner serchInputcard"
                                  style={{ color: "#212529" }}
                                >
                                  {current.date()}
                                </div>
                              );
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="large-device1"
                    span={4}
                    style={{ cursor: "pointer" }}
                    xs={24}
                    lg={4}
                    md={4}
                  >
                    <Row>
                      <Col lg={24} md={24} sm={12} xs={12}>
                        <b>Guest</b>
                      </Col>
                      <Col
                        lg={24}
                        md={24}
                        sm={24}
                        xs={24}
                        style={{ cursor: "pointer" }}
                      >
                        <Dropdown
                          style={{ cursor: "pointer", width: "100%" }}
                          trigger={["click"]}
                          placement="bottomRight"
                          overlayClassName="GuestDropdown"
                          open={dropdownOpen}
                          onClick={() =>
                            setDropdownOpen((prevState) => !prevState)
                          }
                          menu={{
                            items,
                          }}
                        >
                          <Space>
                            <p
                              style={{
                                color: "#ccc",
                                whiteSpace: "noWrap",
                                cursor: "pointer",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faUser}
                                color={"#1675DF"}
                              />
                              {searchBoxData?.guestDetails?.rooms ? (
                                <>
                                  {` Rooms : ${searchBoxData?.guestDetails.rooms
                                    .toString()
                                    .padStart(2, "0")} Guest: ${(
                                      searchBoxData?.guestDetails?.adults +
                                      searchBoxData?.guestDetails?.children
                                    )
                                      .toString()
                                      .padStart(2, "0")}`}
                                </>
                              ) : (
                                <>&nbsp; Guest details &nbsp;</>
                              )}
                              &nbsp;
                              <CaretDownOutlined style={{ color: "#ccc" }} />
                            </p>
                          </Space>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={3}
                    md={3}
                    sm={24}
                    xs={24}
                    className="container-searchbtn"
                  >
                    <BtnComp
                      btnClickFun={mainSearchBtn}
                      btnName="Search"
                      className="searchbtn"
                    />{" "}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default forwardRef(HeaderSearchCard);
