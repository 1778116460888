import serviceUtil from ".././../../index";

const HotelBookingList = (email, id) => {
  return serviceUtil
    .get(`myBooking/hotelBookingList?email=${email}&id=${id}`)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err?.response?.data;
      return { errRes };
    });
};

const getUpcomingBookingList = () => {
  return serviceUtil
    .get("myBooking/upcomminghotelBookingList")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const getCurrentMonthUpcomingBookingList = () => {
  return serviceUtil
    .get("myBooking/currentmonthupcomminghotelBookingList")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const getCompletedBookingList = () => {
  return serviceUtil
    .get("myBooking/completedhotelBookingList")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const getCurrentMonthCompletedBookingList = () => {
  return serviceUtil
    .get("myBooking/currentMonthCompletedhotelBookingList")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const getCancelledBookingList = () => {
  return serviceUtil
    .get("myBooking/cancelledhotelBookingList")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const getCurrentMonthCancelledBookingList = () => {
  return serviceUtil
    .get("myBooking/currentMonthcancelledhotelBookingList")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const GetHotelBookingById = async (id) => {
  return await serviceUtil
    .get(`myBooking/hotelBookingView/${id}`)
    .then((response) => {
      const data = response && response.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

export {
  HotelBookingList,
  getUpcomingBookingList,
  getCurrentMonthUpcomingBookingList,
  getCompletedBookingList,
  getCurrentMonthCompletedBookingList,
  getCancelledBookingList,
  getCurrentMonthCancelledBookingList,
  GetHotelBookingById,
};
