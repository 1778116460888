import serviceUtil from "../../../..";
const config = {
  headers: {
   Accept: "application/json",
   "Content-Type": "application/json",
  },
  body: JSON.stringify(),
 };

const houseBoatContractListGetById = (id) => {
    return serviceUtil
     .get(`registration/houseboatSettings/getAllHouseboatContractByHouseboatId/${id}`)
     .then((res) => {
      const data = res && res.data;
      return { data };
     })
     .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
     });
   };

   const houseBoatSaveContractRateCreate = (payload) => {
    return (
      serviceUtil
        .post(`registration/houseboatSettings/createHouseboatContract`, payload)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          const errRes = err && err.response.data;
          return { errRes };
        })
    );
  };

  const houseBoatSaveContractRateUpdate = (Id, payload) => {
    return serviceUtil
     .put(`registration/houseboatSettings/updateHouseboatContract/${Id}`, payload, config)
     .then((res) => {
      const data = res && res.data;
      return { data };
     })
     .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
     });
   };

   const houseBoatSaveContractRateDelete = (userId) => {
    return serviceUtil
     .deleteById(`registration/houseboatSettings/deleteHouseboatContract/${userId}`)
     .then((res) => {
      const { data, message, err } = res && res.data;
      return { data, message, err };
     })
     .catch((err) => ({ err }));
   };


  // for save contract room api
  const houseBoatSaveContractRoom = (id) => {
    return serviceUtil
     .get(`registration/houseboatSettings/getOneHouseboat/${id}`)
     .then((res) => {
      const data = res && res.data;
      return { data };
     })
     .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
     });
   };

   export {
    houseBoatContractListGetById,
    houseBoatSaveContractRateCreate,
    houseBoatSaveContractRateUpdate,
    houseBoatSaveContractRateDelete,
    houseBoatSaveContractRoom,
   }