import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BtnComp from "../../../../components/atoms/buttonComponent/BtnComp";
import NewInputComp from "../../../../components/atoms/input/NewInputComp";
import SelectComponent from "../../../../components/atoms/SelectComponent/SelectComponent";
import NewTextArea from "../../../../components/atoms/textAreaComponent/NewTextArea";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { createShikaraGuestDetails } from "../../../../services/utils/userUtils/shikara";
import UserSucessModel from "../../../../components/molecules/modalComponent/UserSucessModel";
import { shikaraBookingEmail } from "../../../../services/utils/userUtils/shikara/index";
import { shikaraSchemaForBookNow } from "../../userFormSchema/UserFormSchema";
import NewCard from "../../../../components/atoms/card/NewCard";
import moment from "moment";
const { Panel } = Collapse;
const option = [
  {
    label: "Select",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
];

//  const d=new Date().toJSON().slice(0, 10);

export const UserShikaraBookNow = () => {
  const nav = useNavigate();
  const selector = useSelector((s) => s.userDetails.user);
  const [users, setuserDetails] = useState();
  // debugger;
  const {
    state: { data, guest, travel },
  } = useLocation();
  // debugger;
  const [successModel, setSuccessModel] = useState(false);

  useEffect(() => {
    setuserDetails(selector);
  }, []);
  // debugger;
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setValues,
    handleReset,
  } = useFormik({
    initialValues: {
      shikaraID: "",
      user_id: "",
      prefix: "",
      firstName: "",
      lastName: "",
      email: "",
      childAge: "0",
      mobileNumber: "",
      specialRequest: "",
      billingDetails: {
        travelDate: "",
        totalTime: "",
        guest: "",
        subtotal: "",
        discount: "",
        couponCode: "",
        totalPayable: "",
      },
    },
    validationSchema: shikaraSchemaForBookNow,
    onSubmit: () => {
      setSuccessModel(true);
    },
  });
  const modelHandleOk = async () => {
    const res = await createShikaraGuestDetails(values);
    // debugger;
    const emailData = await shikaraBookingEmail(res);
    // if (res.data.message) {
    //   nav("/shikara/other");
    //   setSuccessModel(false);
    // }
    if (emailData) {
      nav("/shikara/alleppey-shikara-boat.html");
      setSuccessModel(false);
    }
    handleReset();
  };

  const [defaultValue, setDefaultValue] = useState(
    (data?.promotionId?.promotionList.promotionType.percentage
      ? parseInt(
          parseInt(data?.contractId?.rate)
            ? parseInt(data?.contractId?.rate) /
                (1 - data?.promotionId?.promotionList?.value / 100)
            : ""
        )
      : parseInt(data?.contractId?.rate) +
        data?.promotionId?.promotionList.value) -
      (data?.promotionId?.promotionList.promotionType.percentage
        ? parseInt(
            parseInt(data?.contractId?.rate)
              ? parseInt(data?.contractId?.rate) /
                  (1 - data?.promotionId?.promotionList?.value / 100) -
                  parseInt(data?.contractId?.rate)
              : ""
          )
        : data?.promotionId?.promotionList.value)
  );

  const couponApply = () => {
    let numbers = couponCode?.match(/\d+/g)?.join("");

    if (numbers) {
      setDefaultValue(defaultValue - (defaultValue * parseInt(numbers)) / 100);
    }
    handleChange({ target: { name: "couponCode", value: "" } });
  };

  const {
    prefix,
    firstName,
    lastName,
    email,
    mobileNumber,
    specialRequest,
    couponCode,
  } = values;
  useEffect(() => {
    const contractId = data?.contractId;

    setValues({
      ...values,
      user_id: users?.id,
      shikaraID: data._id,
      billingDetails: {
        travelDate: travel?.travelDate?.split("/")?.reverse()?.join("-"),
        totalTime: contractId && contractId?.timeHrs,
        guest:
          guest &&
          `${guest.guest.adults} adults ${guest.guest.children} children `,
        subtotal: data?.promotionId?.promotionList.promotionType.percentage
          ? parseInt(
              parseInt(data?.contractId?.rate)
                ? parseInt(data?.contractId?.rate) /
                    (1 - data?.promotionId?.promotionList?.value / 100)
                : ""
            )
          : parseInt(data?.contractId?.rate) +
            data?.promotionId?.promotionList.value,

        discount: data?.promotionId?.promotionList.promotionType.percentage
          ? parseInt(
              parseInt(data?.contractId?.rate)
                ? parseInt(data?.contractId?.rate) /
                    (1 - data?.promotionId?.promotionList?.value / 100) -
                    parseInt(data?.contractId?.rate)
                : ""
            )
          : data?.promotionId?.promotionList.value,
        couponCode: couponCode,
        totalPayable: defaultValue,
      },
    });
  }, [couponCode, defaultValue, users]);

  useEffect(() => {
    document.getElementById("HbPaydetail").scrollIntoView({ behavior: "smooth", block: "end"});
  }, []);
  // debugger;
  return (
    <div style={{ background: "#ffffff", height: "900px", marginTop: "10px" }}>
      <Row justify={"center"} gutter={[10, 10]}>
        <Col xs={22} sm={22} md={11} lg={11} xl={11} xxl={11}>
          <Row>
            <Col span={24}>
              <h3 id="HbPaydetail">Guest Information</h3>
            </Col>
            <Row
              style={{ width: "100%", marginBottom: "2%" }}
              gutter={[20, 20]}
            >
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <SelectComponent
                  label={<h6>Prefix</h6>}
                  name="prefix"
                  // errors={errors}
                  // touched={touched}
                  value={prefix}
                  options={option}
                  handleChange={(e) => setValues({ ...values, prefix: e })}
                />
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <NewInputComp
                  label={<h6>First Name</h6>}
                  name="firstName"
                  value={firstName}
                  errors={errors}
                  touched={touched}
                  placeholder={"First Name"}
                  className={"inputField"}
                  handleChange={handleChange}
                />
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <NewInputComp
                  label={<h6>Last Name</h6>}
                  name="lastName"
                  value={lastName}
                  errors={errors}
                  touched={touched}
                  placeholder={"Last Name"}
                  className={"inputField"}
                  handleChange={handleChange}
                />
              </Col>
            </Row>
            <Row
              style={{ width: "100%", marginBottom: "2%" }}
              gutter={[20, 20]}
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <NewInputComp
                  label={<h6>Mobile Number</h6>}
                  placeholder={"Mobile Number"}
                  name="mobileNumber"
                  value={mobileNumber}
                  errors={errors}
                  touched={touched}
                  className={"inputField"}
                  handleChange={handleChange}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <NewInputComp
                  label={<h6>E-mail Address</h6>}
                  name="email"
                  value={email}
                  errors={errors}
                  touched={touched}
                  placeholder={"E-mail Address"}
                  className={"inputField"}
                  handleChange={handleChange}
                />
              </Col>
            </Row>
            <Row style={{ width: "100%" }} gutter={20}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <NewTextArea
                  label={<h6>Special Requests</h6>}
                  name="specialRequest"
                  errors={errors}
                  touched={touched}
                  value={specialRequest}
                  className={"inputField"}
                  handleChange={handleChange}
                />
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col>
                <NewCard
                  title={<p style={{ fontWeight: "700" }}>FAQ</p>}
                  style={{ width: "100%" }}
                >
                  <Collapse accordion size="large">
                    <Panel
                      header="What is the minimum age required for doing Shikara Boat Ride in Alappuzha?"
                      key="1"
                    >
                      <p className="text-body">
                        There is no minimum age requirement for doing Shikara
                        Boat Ride in Alappuzha.
                      </p>
                    </Panel>
                    <Panel
                      header="How much does Shikara Boat Ride in Alappuzha cost?"
                      key="2"
                    >
                      <p className="text-body">
                        Alappuzha offers Shikara Boat Ride in for INR 750 and it
                        includes 1 Hour Boat Ride.
                      </p>
                    </Panel>
                    <Panel
                      header="How is safety ensured while doing Shikara Boat Ride in Alappuzha? "
                      key="3"
                    >
                      <p className="text-body">
                        Safety while doing Shikara Boat Ride in Alappuzha is
                        ensured by making use of safety gear with proper
                        guidance and instructions.
                      </p>
                    </Panel>
                  </Collapse>
                </NewCard>
              </Col>
            </Row>
          </Row>
        </Col>

        <Col xs={22} sm={22} md={11} lg={11} xl={11} xxl={11}>
          <Row style={{ border: "1px solid #ddd", padding: "0% 3%" }}>
            <Row
              style={{ width: "100%", marginBottom: "2%", paddingTop: "5px" }}
            >
              <h3>Billing Details</h3>
            </Row>

            <Row
              justify={"center"}
              style={{
                width: "100%",
                marginBottom: "2%",
                background: "#f5f5f5",
                border: "1px solid #eee",
                padding: "10px 15px",
              }}
            >
              <h6>
                Billing Summary 1 ({data.shikaraName && data.shikaraName})
              </h6>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              {/* <Row > */}
              <Col>
                Travel Date <br />
                {/* Total time */}
              </Col>

              <Col>
                {moment(travel.travelDate, "DD/MM/YYYY").format("ll")} <br />
                {/* {data?.contractId &&
                  data?.contractId?.timeHrs &&
                  data?.contractId?.timeHrs} */}
                {/* 13/03/2023 <br />1 hrs 0 mins */}
              </Col>
              {/* </Row> */}
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <Col>Guests</Col>
              {/* <Col>1 Adults , 0 Children</Col> */}
              <Col>{`${guest.guest.adults} Adults , ${guest.guest.children} children`}</Col>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <Col>
                <h6>Subtotal (INR) :</h6>
              </Col>
              <Col>
                {" "}
                <h6 style={{ marginRight: "5px" }}>
                  {data?.promotionId?.promotionList.promotionType.percentage
                    ? parseInt(
                        parseInt(data?.contractId?.rate)
                          ? parseInt(data?.contractId?.rate) /
                              (1 -
                                data?.promotionId?.promotionList?.value / 100)
                          : ""
                      )
                    : parseInt(data?.contractId?.rate) +
                      data?.promotionId?.promotionList.value}
                </h6>
              </Col>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <Col>
                <h6>Discount (INR):</h6>
              </Col>
              <Col>
                {" "}
                <h6 style={{ marginRight: "5px" }}>
                  {data?.promotionId?.promotionList.promotionType.percentage
                    ? parseInt(
                        parseInt(data?.contractId?.rate)
                          ? parseInt(data?.contractId?.rate) /
                              (1 -
                                data?.promotionId?.promotionList?.value / 100) -
                              parseInt(data?.contractId?.rate)
                          : ""
                      )
                    : data?.promotionId?.promotionList.value}
                </h6>
              </Col>
            </Row>
            <Row
              style={{
                background: "#f5f5f5",
                marginBottom: "3%",
                padding: "8px 0px",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  marginBottom: "2%",
                  borderBottom: "1px solid #ddd",
                  alignItems: "baseline",
                }}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ padding: "8px 15px" }}
                >
                  <h5>Coupon Code</h5>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ padding: "8px 15px" }}
                >
                  <NewInputComp
                    label={""}
                    name="couponCode"
                    value={couponCode}
                    star={"none"}
                    className={"inputField"}
                    handleChange={handleChange}
                  />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ padding: "8px 15px" }}
                >
                  <BtnComp
                    btnName={"Apply"}
                    width="100%"
                    height="100%"
                    bg_color="#ff4d63"
                    color="#ffffff"
                    fontSize="17px"
                    btnClickFun={couponApply}
                  />
                </Col>
              </Row>

              <Row
                justify={"space-between"}
                style={{
                  width: "100%",
                  marginBottom: "2%",
                  borderBottom: "1px solid #ddd",
                  padding: "8px 0px",
                }}
              >
                <Col>
                  <h6>Total Payable (including tax) :</h6>
                </Col>
                <Col>
                  <h6 style={{ marginRight: "5px" }}>{defaultValue}</h6>
                </Col>
              </Row>
            </Row>
            <Row style={{ width: "100%", marginBottom: "2%" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <BtnComp
                  btnName={"Submit Query"}
                  width="100%"
                  height="100%"
                  bg_color="#3d75df"
                  color="#ffffff"
                  fontSize="17px"
                  btnClickFun={handleSubmit}
                />
              </Col>
            </Row>
            <Row
              justify={"center"}
              style={{ width: "100%", alignItems: "baseline" }}
            >
              <FontAwesomeIcon
                icon={faLock}
                style={{ color: "#3d75df", marginRight: "1%" }}
              />
              <h5 style={{ color: "#3d75df" }}>Safe And Secure</h5>
            </Row>
            <UserSucessModel
              isModalOpen={successModel}
              handleOk={modelHandleOk}
              handleCancel={() => setSuccessModel(false)}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};
