/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Paper } from '@material-ui/core'
import { Col, Row, Tag, Grid, Carousel, Skeleton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseCircleOutlined } from "@ant-design/icons";
import sfPro from "../../../../assets/FontsFree-Net-SFProDisplay-Regular.ttf";
import {
  faWifi,
  faStar,
  faCheck,
  faFan,
  faPhone,
  faVideo,
  faHotTub,
  faShieldAlt,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import couple from "./../../../../assets/couple1.png";
import assured from "./../../../../assets/gosAssured.png";
import { Button } from "antd";
import { useNavigate } from "react-router-dom/dist";
import {
  addFilteredData,
  selectHotel,
} from "../../../../reduxData/sliceData/hotelReducer";
import { useDispatch } from "react-redux";
import { useState } from "react";
import gosAssured from "../../../../assets/gos-assured.png";
import gosAssuredItem from "../../../../assets/check.png";
import soldout from "../../../../assets/soldout.png";
const { useBreakpoint } = Grid;
const iconObj = {
  Wifi: faWifi,
  AC: faFan,
  Telephone: faPhone,
  CCTV: faVideo,
  Spa: faHotTub,
  Restaurant: faUtensils,
  ["Security alarms"]: faShieldAlt,
  //uncomment below icons and imports as per requirement

  // Power: faPlug,
  // Refrigerator: faSnowflake,
  // Printer: faPrint,
  // Reception: faHotel,
  // Banquet: faChessBoard,
  // ["TV."]: faTv,
  // ["Dry Cleaning Service"]: faTshirt,
  // ["Security Guard"]: faUserShield,
  // ["Free Parking"]: faParking,
  // ["Wake up Call"]: faClock,
  // ["Fax Service"]: faFax,
  // ["Power Backup"]: faBolt,
};

function ProCard({ data, index }) {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { lg, md } = useBreakpoint();
  const [moreAmenities, setMoreAmenities] = useState(false);
  const [showGosAssured, setShowGosAssured] = useState(true);

  return (
    <Row justify={"center"}>
      <Col
        span={24}
        style={{ padding: "2%" }}
        onClick={() => {
          const nameHotel = data?.basicDetails?.hotelName?.includes(" ")
            ? data?.basicDetails?.hotelName?.split(" ").join("-")
            : data?.basicDetails?.hotelName;
          nav(
            `/hotel/${nameHotel}-hotels-in-${data?.hotelLocation?.place?.destinationName}`,
            { state: { data } }
          );
          dispatch(selectHotel(data));
          dispatch(addFilteredData([data]));
          window.scrollTo(0, 0);
          document.documentElement.scrollTop = 0;
        }}
      >
        <Row
          elevation={24}
          style={{
            padding: "2%",
            paddingTop: "0%",
            boxShadow: "0px 4px 10px #0000000d",
            borderBottom: "1px solid #eee",
          }}
        >
          <Row>
            <Col sm={24} xs={24} md={24} lg={8}>
              <Carousel autoplay dots={"dotstyle"}>
                {data?.profilePic?.length > 0
                  ? data?.profilePic?.slice(0, 5).map((val, idx) => {
                    return (
                      <div key={idx} className="homeImage">
                        <img
                          src={val}
                          alt=""
                          style={{ height: "100%", width: "100%" }}
                        />
                      </div>
                    );
                  })
                  : new Array(3).fill(null).map((img, imgInd) => (
                    <div key={imgInd} className="homeImage">
                      <Skeleton.Image
                        className="destinationSkeleton "
                        active
                      />
                    </div>
                  ))}
              </Carousel>
            </Col>
            <Col
              lg={15}
              sm={24}
              style={{ padding: "0% 5% " }}
              className="mt-2"
            >
              <Row>
                <Col span={16}>
                  {" "}
                  <Col className="h4 b">{data?.basicDetails?.hotelName}</Col>
                  <p>{data?.hotelLocation?.hotelAddress}</p>
                </Col>
                {data?.stopSale && (
                  <Col
                    span={8}
                    className="h4 b mt-4"
                    style={{ color: "Red", fontSize: "1.5rem" }}
                  >
                    <img
                      className="sold-out-img"
                      src={soldout}
                      alt="Sold Out"
                    />
                  </Col>
                )}
              </Row>
              <Row
                style={{
                  padding: "5% 0% 0% 0%",
                  width: "max-content",
                  display: "flex",
                }}
              >
                <Tag
                  color="#52b520"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "1.5px 6px",
                  }}
                >
                  {/* {data?.hotelReviewId?.rating}/5{" "} */}
                  <span
                    style={
                      window.innerWidth <= 600
                        ? { fontSize: "15px", fontWeight: "bold" }
                        : { fontSize: "14px", fontWeight: "bold" }
                    }
                  >
                    {/* {data?.hotelReviewId?.rating
                        ? data?.hotelReviewId?.rating
                        : 0}
                      /5{" "} */}
                    {data?.hotelReviewId?.length > 0
                      ? // Calculate the average rating
                      (
                        data.hotelReviewId.reduce(
                          (sum, review) => sum + review.rating,
                          0
                        ) / data.hotelReviewId.length
                      ).toFixed(1)
                      : 0}{" "}
                  </span>
                  &nbsp;
                  <FontAwesomeIcon
                    icon={faStar}
                    style={
                      window.innerWidth <= 600
                        ? {
                          fontSize: "10px",
                        }
                        : {
                          fontSize: "9px",
                          // lineHeight: "14px",
                          // marginBottom: "3px",
                        }
                    }
                  />
                </Tag>
                {data?.basicDetails?.coupleFriendlyHotel ? (
                  <Tag className="rounded-4 bg-transparent">
                    <div>
                      {" "}
                      <img
                        style={{ height: "18px", width: "18px" }}
                        src={couple}
                        alt=""
                      />{" "}
                      Couple Friendly
                    </div>
                  </Tag>
                ) : (
                  ""
                )}{" "}
                {data?.basicDetails?.sanitizedStay ? (
                  <>
                    <Tag className="rounded-4 bg-transparent">
                      <div style={{ position: "relative" }}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowGosAssured(!showGosAssured);
                          }}
                        >
                          {" "}
                          <img
                            style={{ height: "18px", width: "18px" }}
                            // src="https://static.thenounproject.com/png/1716003-200.png"
                            src={assured}
                            alt=""
                          />{" "}
                          gos Stay Assured
                        </div>
                        {showGosAssured && index === 0 && (
                          <>
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="gos-assured-card"
                              onMouseLeave={() => setShowGosAssured(false)}
                            >
                              <CloseCircleOutlined
                                onClick={() => setShowGosAssured(false)}
                                className="cls-btn"
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  border: "none",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                              />

                              <img
                                className="gos-assured-image"
                                src={gosAssured}
                                alt="GOS Assured"
                              />
                              <ul className="gos-assured-list">
                                <li>
                                  <img
                                    src={gosAssuredItem}
                                    alt="gosAssuredItem"
                                  />
                                  101% Refund On Cancellation*
                                </li>
                                <li>
                                  <img
                                    src={gosAssuredItem}
                                    alt="gosAssuredItem"
                                  />
                                  Hassle Free Check-In
                                </li>
                                <li>
                                  <img
                                    src={gosAssuredItem}
                                    alt="gosAssuredItem"
                                  />
                                  Couple friendly
                                </li>
                              </ul>
                              <p className="show-gosstay-properties-mobile">
                                show gosStay properties
                              </p>
                            </div>

                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="gos-assured-card-mobile"
                              onMouseLeave={() => setShowGosAssured(false)}
                            >
                              <CloseCircleOutlined
                                onClick={() => setShowGosAssured(false)}
                                className="cls-btn"
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  border: "none",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                              />
                              <img
                                className="gos-assured-image-mobile"
                                src={gosAssured}
                                alt="GOS Assured"
                              />
                              <ul className="gos-assured-list-mobile">
                                <li>
                                  <img
                                    src={gosAssuredItem}
                                    alt="gosAssuredItem"
                                  />
                                  101% Refund On Cancellation*
                                </li>
                                <li>
                                  <img
                                    src={gosAssuredItem}
                                    alt="gosAssuredItem"
                                  />
                                  Hassle Free Check-In
                                </li>
                                <li>
                                  <img
                                    src={gosAssuredItem}
                                    alt="gosAssuredItem"
                                  />
                                  Couple friendly
                                </li>
                              </ul>
                              <p className="show-gosstay-properties">
                                show gosStay properties
                              </p>
                            </div>
                          </>
                        )}
                      </div>

                      {/* <Popover placement="bottomRight" content={content}>
                          <Button><img
                            style={{ height: "18px", width: "18px" }}
                            src="https://static.thenounproject.com/png/1716003-200.png"
                            alt=""
                          />{" "}
                          Gos Assured Stay</Button>
                        </Popover> */}
                    </Tag>
                  </>
                ) : (
                  " "
                )}
              </Row>
              <Row
                style={{
                  paddingTop: "2%",
                  borderBottom: `${lg || md ? "" : "1px dashed #ccc"}`,
                }}
                gutter={20}
              >
                {data.hotelAmenities &&
                  data.hotelAmenities.slice(0, 4).map((amenityName, id) => {
                    return (
                      <div key={id}>
                        <a style={{ padding: "0px 8px 8px" }} href="/">
                          {iconObj[amenityName?.amenityName] ? (
                            <FontAwesomeIcon
                              className="amenityicon"
                              icon={iconObj[amenityName?.amenityName]}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="amenityicon"
                              icon={faCheck}
                            />
                          )}
                          &nbsp;{amenityName.amenityName} &nbsp;
                        </a>
                      </div>
                    );
                  })}
                {!moreAmenities && (
                  <p
                    onClick={() => setMoreAmenities(true)}
                    style={{ padding: "0px 8px 8px", color: "#0194FE" }}
                  >
                    + more..{" "}
                  </p>
                )}
                {moreAmenities &&
                  data.hotelAmenities.slice(4).map((amenityName, id) => {
                    return (
                      <div key={id}>
                        <a style={{ padding: "0px 8px 8px" }} href="/">
                          {iconObj[amenityName?.amenityName] ? (
                            <FontAwesomeIcon
                              icon={iconObj[amenityName?.amenityName]}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faCheck} />
                          )}
                          &nbsp;{amenityName.amenityName} &nbsp;
                        </a>
                      </div>
                    );
                  })}
              </Row>

              <Row
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col lg={12} md={12} sm={16} xs={16}>
                  <span
                    style={{
                      fontSize: "1.8rem",
                      color: "#cc0000",
                      fontWeight: "bold",
                    }}
                  >
                    &#x20B9;
                    {/* {~~(data.hotelContractId?.contractRateRoomDTO[0]?.roomRate)} */}
                    {parseInt(~~data?.mainroomPriceWithDiscount)}
                  </span>
                  {data?.mainDiscountRate != 0 && (
                    <s
                      className=" p-1"
                      style={{
                        color: "#888",
                        fontSize: "1.1rem",
                        textDecoration: "line-through",
                        textDecorationColor: "red",
                        textDecorationThickness: "2px",
                        fontFamily: "SF Pro, Arial, sans-serif",
                        src: `${sfPro}`,
                      }}
                    >
                      &#x20B9;{" "}
                      {/* {
                       ~~(data.hotelContractId?.contractRateRoomDTO[0]?.actualRoomRate)
                      } */}
                      {/* {parseInt(mainroomPriceWithoutDiscount)} */}
                      {!data?.stopSale && parseInt(~~data?.mainroomPriceWithoutDiscount)}
                    </s>
                  )}
                  {data?.mainDiscountRate != 0 ? (
                    <span
                      className=""
                      style={{ color: "#ff6600", fontSize: "1rem" }}
                    >
                      {/* discount formula */}
                      {/* {~~(data.hotelContractId?.contractRateRoomDTO[0]?.discountRate *100/data.hotelContractId?.contractRateRoomDTO[0]?.actualRoomRate)} */}
                      {data?.mainDiscountRate}% off
                    </span>
                  ) : (
                    ""
                  )}
                  <p style={{ color: "#666", marginTop: "-10px" }}>
                    {data?.hotelContractId?.refundableObj[0]?.isRefundable
                      ? "(Refundable)"
                      : "(Non-Refundable)"}
                  </p>
                  <span style={{ color: "#ff0000" }}>EMI</span>{" "}
                  <span className="h6" style={{ color: "#666" }}>
                    {" "}
                    starting from ₹{data.EMIDeatils}
                  </span>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={8}
                  xs={8}
                  style={{
                    marginTop: "1%",
                    display: `${lg ? "flex" : "block"}`,
                    float: "right",
                  }}
                >
                  {!data?.stopSale && (
                    <Button
                      style={{
                        margin: "4%",
                        color: "#fff",
                        backgroundColor: "#3d75df",
                      }}
                      onClick={() => {
                        const nameHotel =
                          data?.basicDetails?.hotelName?.includes(" ")
                            ? data?.basicDetails?.hotelName
                              ?.split(" ")
                              .join("-")
                            : data?.basicDetails?.hotelName;
                        nav(
                          `/hotel/${nameHotel}-hotels-in-${data?.hotelLocation?.place?.destinationName}`
                        );

                        dispatch(selectHotel(data));
                        dispatch(addFilteredData([data]));
                        window.scrollTo(0, 0);
                        document.documentElement.scrollTop = 0;
                      }}
                    >
                      Book Now
                    </Button>
                  )}
                  <Button
                    style={{
                      margin: "4%",
                      color: "#fff",
                      backgroundColor: "#ff4d63",
                    }}
                    onClick={() => {
                      const nameHotel =
                        data?.basicDetails?.hotelName?.includes(" ")
                          ? data?.basicDetails?.hotelName
                            ?.split(" ")
                            .join("-")
                          : data?.basicDetails?.hotelName;
                      nav(
                        `/hotel/${nameHotel}-hotels-in-${data?.hotelLocation?.place?.destinationName}`,
                        { state: { data } }
                      );
                      dispatch(selectHotel(data));
                      dispatch(addFilteredData([data]));
                      window.scrollTo(0, 0);
                      document.documentElement.scrollTop = 0;
                    }}
                  >
                    View Details
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Col>
    </Row>
  );
}

export default ProCard;
