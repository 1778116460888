import serviceUtil from ".././../../index";

const GetShikaraBookingDataById = async (id) => {
  return await serviceUtil
    .get(`myBooking/shikaraBookingView/${id}`)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const ShikaraBookingListUpComing = async (email,id) => {
  return await serviceUtil
    .get(`myBooking/shikaraBookingList?email=${email}&id=${id}`)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const ShikaraBookingLitSendShikaraSummary = async (payload) => {
  return await serviceUtil
    .post("myBooking/sendShikaraSummary", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

export {
  GetShikaraBookingDataById,
  ShikaraBookingListUpComing,
  ShikaraBookingLitSendShikaraSummary,
};
