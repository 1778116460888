import BtnComp from "../../../components/atoms/buttonComponent/BtnComp";
import FullCalendar from "@fullcalendar/react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import React from "react";
import SelectComponent from "../../../components/atoms/SelectComponent/SelectComponent";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "antd";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

function AvailabilityComponent() {
  return (
    <>
      <Row className="page-back">
        <p onClick={"backBtnFun"} style={{ cursor: "pointer" }}>
          <KeyboardDoubleArrowLeftIcon style={{ fontSize: "1.2rem" }} />
          <span style={{ paddingTop: "2px", cursor: "pointer" }}>Back</span>
        </p>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            height={700}
            contentHeight={700}
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Row style={{ paddingLeft: "5%" }}>
            <div style={{ width: "90%" }}>
              <p style={{ color: "#4383b4", fontSize: "1.4rem" }}>
                Search Criteria
              </p>
              <hr style={{ color: "#4383b4" }} />
            </div>
          </Row>
          <Row>
            <Col span={24}>
              <SelectComponent label="Room Category" star="none" />
            </Col>
          </Row>
          <Row>
            <Col>
              <BtnComp
                btnName="Search"
                endIcon={faSearch}
                className="px-1"
                pr="5px"
                bg_color="#1aa206"
                color="#fff"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default AvailabilityComponent;
