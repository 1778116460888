import {
  faCalendar,
  faCalendarDays,
  faImage,
  faMoneyBill1,
} from "@fortawesome/free-regular-svg-icons";

import {
  faLeaf,
  faGaugeHigh,
  faUsers,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

export const slideTabData = () => {
  return [
    {
      id: 9,
      Option: "Hotel Availability",
      title: "Hotel Availability",
      content: [],
      icon: faLeaf,
    },

    {
      id: 8,
      Option: "Availability",
      title: "Availability",
      content: [],
      icon: faCalendar,
    },
    {
      id: 7,
      Option: "Hotel Profile",
      title: "Hotel Profile",
      content: [],
      icon: faUsers,
    },

    {
      id: 6,
      Option: "Policy",
      title: "Policy",
      content: [],
      icon: faPenToSquare,
    },
    {
      id: 5,
      Option: "Contract Rate",
      title: "Contract Rate",
      content: [],
      icon: faMoneyBill1,
    },
    {
      id: 4,
      Option: "Occupancy",
      title: "Occupancy",
      content: [],
      icon: faUsers,
    },
    {
      id: 3,
      Option: "Gallery",
      title: "Gallery",
      content: [],
      icon: faImage,
    },
    {
      id: 2,
      Option: "Calendar",
      title: "Calendar",
      content: [],
      icon: faCalendarDays,
    },
    {
      id: 1,
      Option: "Dashboard",
      title: "Dashboard",
      content: [],
      icon: faGaugeHigh,
    },
  ];
};
