import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../../../styles/global.scss";

const TermsAndConditionsLink = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    document
      .getElementById("termsScroll")
      .scrollIntoView({ behavior: "smooth", block: "end"});
  }, []);
  return (
    <>
      <div id="termsScroll" style={{ height: "35px" }}></div>
      <Container
        fluid={true}
        style={{
          backgroundColor: "white",
          height: "100%",
          width: "100%",
          padding: "2% 15% 0% 15%",
        }}
      >
        <h3 style={{ fontWeight: "700" }}>Terms & Conditions</h3>
        <div>
          <p className="container-heading mt-3 mb-1">
            ROLE OF GOS AND LIMITATION OF LIABILITY OF GOS
          </p>
          <ul>
            <li className="terms-Li">
              GOS acts as a facilitator and merely provides an online platform
              to the User to select and book a particular hotel. Hotels in this
              context includes all categories of accommodations such as hotels,
              home-stays, bed and breakfast stays, farm-houses and any other
              alternate accommodations.
            </li>
            <li className="terms-Li">
              All the information pertaining to the hotel including the category
              of the hotel, images, room type, amenities and facilities
              available at the hotel are as per the information provided by the
              hotel to GOS . This information is for reference only. Any
              discrepancy that may exist between the website pictures and actual
              settings of the hotel shall be raised by the User with the hotel
              directly, and shall be resolved between the User and hotel. GOS
              will have no responsibility in that process of resolution, and
              shall not take any liability for such discrepancies.
            </li>
          </ul>
          <p className="container-heading mt-5 mb-1">
            INFORMATION FROM THE HOTEL AND THE TERMS OF THE HOTEL
          </p>
          <ul>
            <li className="terms-Li">
              The hotel booking voucher which GOS issues to a User is solely
              based on the information provided or updated by the hotel
              regarding the inventory availability. In no circumstances can GOS.
            </li>
            <li className="terms-Li">
              Gos will not be held liable for failure on part of a hotel to
              accommodate the User with a confirmed booking, the standard of
              service or any insufficiency in the services, or any other service
              related issues at the hotel. The liability of GOS in case of
              denial of check-in by a hotel for any reason what-so-ever
              including over-booking, system or technical errors, or
              unavailability of rooms etc., will be limited to either providing
              a similar alternate accommodation at the discretion of GOS
              (subject to availability at that time), or refunding the booking
              amount (to the extent paid) to the User. Any other service related
              issues should be directly resolved by the User with the hotel.
            </li>
            <li className="terms-Li">
              Hotels reserves the sole right of admission and GOS has no say
              whatsoever in admission or denial for admission by the hotel.
              Unmarried or unrelated couples may not be allowed to check-in by
              some hotels as per their policies. Similarly, accommodation may be
              denied to guests posing as a couple if suitable proof of
              identification is not presented at the time check-in. Some hotels
              may also not allow local residents to check-in as guests. GOS will
              not be responsible for any check-in denied by the hotel due to the
              aforesaid reasons or any other reason not under the control of
              GOS. No refund would be applicable in case the hotel denies
              check-in under such circumstances.
            </li>
          </ul>
          <p className="container-heading mt-5 mb-1">
            RESPONSIBILITIES OF THE USER
          </p>
          <ul>
            <li className="terms-Li">
              The User would be liable to make good any damage(s) caused by any
              act of him/ her/ or their accompanying guests (wilful/negligent)
              to the property of the hotel in any manner whatsoever. The extent
              and the amount of the damage so caused would be determined by the
              concerned hotel. GOS would not, in any way, intervene in the same.
            </li>
            <li className="terms-Li">
              The primary guest must be at least 18 years old to be able to
              check into the hotel.
            </li>
            <li className="terms-Li">
              The User has to be in possession of a valid identity proof and
              address proof, at the time of check-in. The hotel shall be within
              its rights to deny check-in to a User if a valid identity proof is
              not presented at the time of check-in.
            </li>
            <li className="terms-Li">
              Check-in time, check-out time, and any changes in those timings,
              will be as per hotel policy & terms. Early check-in or late
              check-out request is subject to availability and the hotel may
              charge an additional fee for providing such services.
            </li>
          </ul>
          <p className="container-heading mt-5 mb-1">
            ADDITIONAL CHARGES BY THE HOTEL
          </p>
          <ul>
            <li className="terms-Li">
              The booking amount paid by the User is only for stay at the hotel.
              Some bookings may include breakfast and/ or meals as confirmed at
              the time of booking. Any other services utilized by the User at
              the hotel, including laundry, room service, internet, telephone,
              extra food, drinks, beverages etc. shall be paid by the User
              directly to the hotel.
            </li>
            <li className="terms-Li">
              Some. Hotels may charge a mandatory meal surcharge on festive
              periods like Christmas, New Year&#39;s Eve or other festivals as
              decided by the hotel. All additional charges (including mandatory
              meal surcharges) need to be cleared directly at the hotel. GOS
              will have no control over waiving the same.
            </li>
          </ul>
          <p className="container-heading mt-5 mb-1">
            PAYMENT FOR BOOKINGS AND ANY ADDITIONAL PAYMENTS
          </p>
          <ul>
            <li className="terms-Li">
              Booking of a hotel can either be “Prepaid”, or “Pay at hotel” as
              per the options made available by a hotel on the Website of GOS.
              In “Prepaid” model, the total booking amount is paid by the User
              at the time of booking itself. Such total booking amount includes
              the hotel reservation rate, taxes, service fees as may be charged
              on behalf of the actual service provider, and any additional
              booking fee or convenience fee charged by GOS.
            </li>
            <li className="terms-Li">
              At the hotel’s or GOS’s sole discretion on case to case basis, the
              User may also be provided with an option to make a part payment to
              GOS at the time of confirmation of a booking. The balance booking
              amount shall be paid as per the terms of the bookings. For
              security purposes, the User must provide GOS with correct credit
              or debit card details. GOS may cancel the booking at its sole
              discretion in case such bank or credit card details as provided by
              the User are found incorrect.
            </li>
            <li className="terms-Li">
              Some banks and card issuing companies charge their account holders
              a transaction fee when the card issuer and the merchant location
              (as defined by the card brand, e.g. Visa, MasterCard, American
              Express) are in different countries. If a User has any questions
              about the fees or any exchange rate applied, they may contact
              their bank or the card issuing company through which payment was
              made.
            </li>
            <li className="terms-Li">
              Some accommodation suppliers may require User and/or the other
              persons, on behalf of whom the booking is made, to present a
              credit card or cash deposit upon check-in to cover additional
              expenses that may be incurred during their stay. Such deposit is
              unrelated to any payment received by GOS and solely at the behest
              of the Hotel.
            </li>
          </ul>
          <p className="container-heading mt-5 mb-1">
            PAYMENT FOR BOOKINGS AND ANY ADDITIONAL PAYMENTS
          </p>
          <ul>
            <li className="terms-Li">
              The Website may contain links to third party websites. GOS does
              not control such websites and is not responsible for its contents.
              If a User accesses any third-party website, the same shall be done
              entirely at the User’s risk and GOS shall assume no liability for
              the same.
            </li>
            <li className="terms-Li">
              GOS is not responsible for any errors, omissions or
              representations on any of its pages, links or any linked website
              pages to the extent such information is updated or provided
              directly by the Service Providers or the advertisers.
            </li>
            <li className="terms-Li">
              GOS does not endorse any advertisers on its Website, or any linked
              sites in any manner. The Users are requested to verify the
              accuracy of all information provided on the third-party web pages.
            </li>
            <li className="terms-Li">
              The linked sites are not under the control of GOS and hence GOS is
              not responsible for the contents of any linked site(s) or any
              further links on such site(s), or any changes or updates to such
              sites. GOS is providing these links to the Users only as a
              convenience.
            </li>
          </ul>
          <h3 style={{ fontWeight: "700" }}>2. HOUSE BOAT</h3>
          <p className="container-heading mt-2 mb-1">ROLE OF GOS</p>
          <ul>
            <li className="terms-Li">
              GOS only provides a technology platform that connects intending
              travellers with House Boat operators. It doesn’t operate any House
              Boat or offer the service of transportation to the User. GOS also
              doesn’t act as an agent of any house boat operator in the process
              of providing the above-mentioned technology platform services.
            </li>
            <li className="terms-Li">
              The house boat ticket booking voucher which GOS issues to a User
              is solely based on the information provided or updated by the
              house boat operator regarding the seat availability.
            </li>
            <li className="terms-Li">
              The amenities, services, routes, fares, schedule, house boat type,
              seat availability and any other details pertaining to the house
              boat service are provided by the respective house boat operator
              and GOS has no control over such information provided by the bus
              operator.
            </li>
          </ul>
          <p className="container-heading mt-2 mb-1">
            LIMITATION OF LIABILITY OF GOS
          </p>
          <ul>
            <li className="terms-Li">
              In its role as a technology platform to facilitate transactions
              between the house boat operators and the Users, GOS shall not be
              responsible for the operations of the house boat operator
              including, but not limited to the following:
            </li>
            <li className="terms-Li">
              Timely departure or arrival of the house boat;
            </li>
            <li className="terms-Li">
              The conduct of house boat operator&#39;s employees,
              representatives or agents;
            </li>
            <li className="terms-Li">
              The condition of the house boat, etc. not being up to the
              customer&#39;s expectation or as per the description provided by
              the house boat operator;
            </li>
            <li className="terms-Li">
              Cancellation of the trip due to any reasons;
            </li>
            <li className="terms-Li">
              Loss or damage of the baggage of the customer;
            </li>
            <li className="terms-Li">
              The house boat operator changing a customer&#39;s room for any
              reason whatsoever;
            </li>
            <li className="terms-Li">
              House boat operator informing a wrong boarding point for the
              issuance of the booking confirmation voucher, or changing such
              boarding point eventually with or without any notification to GOS
              or the User;
            </li>
          </ul>
          <p className="container-heading mt-2 mb-1">
            RESPONSIBILITIES OF THE USERS
          </p>
          <ul>
            <li className="terms-Li">
              Users are advised to call the House boat operator to find out the
              exact boarding point, or any information which they may need for
              the purpose of boarding or travel in that trip.
            </li>
            <li className="terms-Li">
              At the time of boarding the House boat, Users shall furnish a copy
              of the ticket, and any valid identity proof like Aadhar card,
              passport, PAN card or voter identification card or any other
              identity proof issued by a government authority.
            </li>
            <li className="terms-Li">
              Users are required to reach the boarding place at least 30 minutes
              before the scheduled departure time.
            </li>
            <li className="terms-Li">
              All booking confirmation issued shall be non-transferable
            </li>
          </ul>
          <p className="container-heading mt-2 mb-1">CANCELLATION OF TICKET</p>
          <ul>
            <li className="terms-Li">
              Cancellation of bookings can be done either through the User’s
              login in the GOS’s website or mobile application, or by calling on
              the customer care number;
            </li>
            <li className="terms-Li">
              Any cancellation is subject to such cancellation charges as
              mentioned on the booking information.
            </li>
          </ul>
          <h3 style={{ fontWeight: "700" }}>3. CABS</h3>
          <p className="container-heading mt-2 mb-1">DEFINITIONS</p>
          <ul>
            <li className="terms-Li">
              “Cab” as may be used in this document includes all categories of
              transport vehicles like sedans, hatchbacks, sport utility
              vehicles, multi utility vehicles, and any other vehicle meant for
              transporting passengers.
            </li>
            <li className="terms-Li">
              “Cab Operator” means all the cab operators providing their
              vehicles along with their drivers;
            </li>
          </ul>
          <p className="container-heading mt-2 mb-1">
            TYPES OF CAB BOOKINGS FACILITATED BY GOS
          </p>
          <ul>
            <li className="terms-Li">
              Outstation Cab: This enables User(s) to book vehicles operated by
              cab operators having All India Tourist Permit (AITP) vehicles and
              operating between two separate cities. On the basis of the
              demand/requirement of the User, GOS as a facilitator connects the
              User with the concerned cab operator.
            </li>
            <li className="terms-Li">
              Car Rental (intra city and intercity): This enables User to book
              vehicles operated by cab operators having AITP license on
              hire/rental basis. This service shall be provided for both
              intercity and intra city on exclusive basis by booking the entire
              Vehicle of the cab/taxi operators.
            </li>
            <li className="terms-Li">
              Airport Drop: This shall exclusively cater to the needs of User
              intending to travel from and to the Airport. The service provided
              is intra city in nature and shall not facilitate the User to book
              intercity Airport drops.
            </li>
          </ul>
          <p className="container-heading mt-2 mb-1">
            ROLE OF GOS AND LIMITATION OF LIABILITY
          </p>
          <ul>
            <li className="terms-Li">
              GOS does not operate cab services on its own. In order to provide
              a comprehensive choice of cab services, cab types and prices to
              User, it has tied up with many Cab Operators.
            </li>
            <li className="terms-Li">
              GOS acts only acts a facilitator between the Cab Operator and
              User. The transaction is always between the Cab Operator and the
              User.
            </li>
            <li className="terms-Li">
              It is the sole responsibility of the Cab Operator to ensure it has
              all the requisite licenses, approvals, insurance and permits from
              appropriate authorities required to undertake the travel booked.
              GOS gives no warranty or assurance in regard to requisite
              licenses, approvals, insurance and permits which the Cab Operator
              is supposed to obtain from appropriate authorities.
            </li>
            <li className="terms-Li">
              GOS shall not be responsible in any manner whatsoever for any
              claims with respect to the trip including without limitation,
              claims pertaining to timeliness, safety, continuity, uninterrupted
              travel, quality, conditions of vehicle or driving skill, the Cab
              not departing or reaching on time, behaviour of the cab driver,
              interiors of the Cab, cancellation of the trip by the Cab Operator
              due to any reason whatsoever, the baggage of the User getting
              lost, stolen or damaged, the Cab Operator providing a different
              type of Cab to the User, the User waiting at the wrong boarding
              point, the User being dropped to nearest location by the driver of
              the Cab due to any unavoidable circumstances, or any act or
              omission of Cab Operator causing accident, loss of life to the
              User.
            </li>
            <li className="terms-Li">
              GOS will not be liable to User or to any other person for any
              direct, indirect, incidental, punitive or consequential loss,
              damage, cost or expense of any kind whatsoever and howsoever
              caused from out of the information derived by User through usage
              of Website, in so far as such information is provided by the Cab
              Operator.
            </li>
            <li className="terms-Li">
              In case of break down or accident of Cab where Cab Operator has
              been unable to make alternative arrangement, GOS shall provide
              proportionate refund to User for uncovered distance. Any claims,
              liabilities or legal costs arising out of such event shall be
              borne by the Cab Operator.
            </li>
          </ul>
          <p className="container-heading mt-2 mb-1">
            DO’s AND DON’Ts FOR THE USER
          </p>
          <p className="container-heading mt-2 mb-1">THE USER SHALL:</p>
          <ul>
            <li className="terms-Li">
              Arrive on time at the appointed pick up point as mutually agreed
              with Cab Operator;
            </li>
            <li className="terms-Li">
              Maintain and ensure good and humble conduct with Cab Operator
              during the trip and on failure to do so, Cab Operator may require
              such User to disembark the Cab;
            </li>
            <li className="terms-Li">
              Prior to commencement of trip, check and agree with Cab Operator
              on dimension/weight of the luggage that can be accommodated in the
              boot space of the Cab;
            </li>
            <li className="terms-Li">
              Check the starting time and the reading on the odometer at the
              start of the trip. Disputes regarding the same will not be
              acceptable after completion of journey.
            </li>
          </ul>
          <p className="container-heading mt-2 mb-1">THE USER SHALL:</p>
          <ul>
            <li className="terms-Li">
              Ask Cab Operator to violate any traffic rules, or the city police
              or government rules or other applicable laws for any reason
              whatsoever;
            </li>
            <li className="terms-Li">
              Coerce Cab Operator to overload the boot space of the Vehicle with
              the User’s luggage;
            </li>
            <li className="terms-Li">
              Use services offered under this head for any purpose which is
              unlawful or illegal under the applicable laws;
            </li>
            <li className="terms-Li">
              Insist on disembarking at any place expect the point of drop as
              provided by the User at the time of booking;
            </li>
            <li className="terms-Li">
              Board the Cab if he/ she is intoxicated or under influence of any
              drug or similar abusive substances.
            </li>
          </ul>
          <h3 style={{ fontWeight: "700" }}>
            4. ACTIVITIES AND OTHER SERVICES
          </h3>
          <p className="container-heading mt-2 mb-1">DEFINITIONS</p>
          <ul>
            <li className="terms-Li">
              ‘Activity’ includes day tours & sightseeing, spa & wellness,
              adventure sports, cruise, theme/ amusement parks, buffets & dining
              or any other service/product booked on the Website.
            </li>
            <li className="terms-Li">
              The provider of the Activity will be referred as ‘Activity
              Provider’, who shall be solely responsible for provision of the
              products/ services booked by the User.
            </li>
          </ul>
          <p className="container-heading mt-2 mb-1">
            ROLE OF SOS AND LIMITATION OF LIABILITY
          </p>
          <ul>
            <li className="terms-Li">
              GOS’s role is limited to facilitating the procurement of an
              Activity service by the User from the Activity Provider. The
              transaction or the service fulfilment relation is always between
              the User and the Activity Provider
            </li>
            <li className="terms-Li">
              GOS shall not provide pick up and drop facility for any Activity
              unless otherwise explicitly mentioned in the confirmation of the
              reservation.
            </li>
            <li className="terms-Li">
              GOS shall not be liable or responsible for:
            </li>
            <li className="terms-Li">
              Any damage or loss, injury, accident, death, breakdown,
              irregularity, delay/change in schedule, cancellation without
              cause, inaccurate information, deficiency of service/product, or
              cancellation due to conditions not under the control of Activity
              Provider;
            </li>
            <li className="terms-Li">
              The health, safety and well-being of the User in the course of
              availing the Activity or thereafter;
            </li>
            <li className="terms-Li">
              Any incidental or ancillary services which is directly purchased
              by the User from the Activity Provider;
            </li>
            <li className="terms-Li">
              Any incorrect information provided by the Activity Provider.
            </li>
            <li className="terms-Li">
              The maximum liability of GOS will be limited to refund of booking
              amount which was received by GOS for reservation of the Activity.
            </li>
          </ul>
          <p className="container-heading mt-2 mb-1">
            RESPONSIBILITIES OF THE USERS
          </p>
          <ul>
            <li className="terms-Li">
              The User agrees to comply with the terms and conditions of
              Activity Provider in addition to GOS’s User Agreement and Terms of
              Service.
            </li>
            <li className="terms-Li">
              The User will be required to present the booking / confirmation
              voucher issued by GOS in order to avail the Activity
            </li>
            <li className="terms-Li">
              The User shall further be in possession of valid documents viz.
              identity proof, address proof, passport in case of Activity booked
              outside India or any other document as specified by the Activity
              Provider in order to utilize the Activity booked.
            </li>
            <li className="terms-Li">
              The User may be required to sign a waiver/consent form, safety
              procedure form, medical declaration or other document before
              availing the Activity where mandated by the Activity Provider.
            </li>
            <li className="terms-Li">
              The User shall reach out to Activity Provider directly for any
              concerns subsequent to the confirmation of reservation.
            </li>
            <li className="terms-Li">
              The User should ensure that he qualifies all the eligibility
              criteria for availing the Activity including but not limited to
              age limit, weight limit, medical conditions etc. User understands
              that in case he is found unfit or illegible for the Activity,
              Activity provider may or may not allow the User to avail the said
              Activity.
            </li>
          </ul>
        </div>
      </Container>
    </>
  );
};

export default TermsAndConditionsLink;
