const HotelSeo = [
  {
    hotelName: "north grande",
    title: "North Grande Hotels - Budget Hotels in Kochi",
    description:
      "This propery is located near North Railway station,couple friendly,this property is a Gos stay Assured property, Guest will get 101% refund against the cancellation",
  },
  {
    hotelName: "i ones home stay",
    title: "i-  Ones Homestay Fort Kochi Starting @ 799",
    description:
      "small budget friendly homestay in Fort Kochi . i-  Ones Homestay Fort Kochi Starting @ 799.Book wide range of properties from Godsownstay.com",
  },
  {
    hotelName: "hotel kings empire",
    title: "Hotel Kings Empire 101% Money back on cancellation",
    description:
      "This property located in near to the North Railway station,couple friendly property, Hassel free check in ,Hotel kings empire is 3 star hotel in the prime location.Rating 4/5",
  },
  {
    hotelName: "hotel prayana",
    title: "Hotel Prayana hotels in kochi - 3 Star Hotel in Kochi",
    description:
      "This property located in near to the North Railway station,couple friendly property, Hassel free check in ,Hotel prayana  is a 3 star hotel in the prime location.Rating 4/5",
  },
  {
    hotelName: "nila home stay",
    title: "Nila Homestay - Homestay in Cherai",
    description:
      "This property located in Cherai, 20 meter from cherai beach.couple friendly , Gos stay Assured property.Gos Money +.",
  },
  {
    hotelName: "the one tree beach resort",
    title:
      "The One Tree Retreat Beach Resort - Resort In cherai - Hotels in kochi",
    description:
      "This property located in Cherai, 10 meter from cherai beach.couple friendly , Gos stay Assured property.Gos Money +.best property in cherai,hotels in kochi",
  },
  {
    hotelName: "cherai beach conventional centre",
    title: "Cherai Beach  Conventional Centre - Hotels in Cherai Beach",
    description:
      "This property is near to the beach .walkable distance from cherai beach.couple friendly , Rating  4/5",
  },
  {
    hotelName: "cotelac heritage lake resort",
    title: "Cotelac Heritage Lake Resort - Alleppey",
    description:
      "Cotelac Heritage lake resort offers a backwater .one of  the best backwater and beach in alleppey",
  },
  {
    hotelName: "carnoustie wellness resort",
    title: "Carnoustie Wellness Resort- Marari ",
    description:
      "Carnouties Wellness Resort is one of the best resort in marari. 24*7 Goscare Support,Gos Money +",
  },
  {
    hotelName: "trium resorts",
    title: "Trium Resort - Hotels in Marari",
    description:
      "Trium Resort located on punnamada, 24*7 Goscare Support ,101% refund on cancelation.Gosmoney +.this property is very well maintained property",
  },
  {
    hotelName: "alleppey holiday beach resort",
    title: "Alleppey Holiday Beach Resort | Hotels in Alleppey",
    description:
      "Alappuzha is one of the best tourist place in india. One of the best backwater and beach in allappuzha.Alleppey Resort .houseboat service are located at punnamada and kinakary.",
  },
  {
    hotelName: "veliyil castle",
    title: "Veliyil Castles Hotels in  Alleppey - Hotels in Alleppey",
    description:
      "Hotels in Alleppey . Book Gos Stay Assured Property and get 101% Refund on Cnacellation, Godsownstay.com offer wide range of properties ,budget friendly ,couple friendly 24*7 Gos care Support ",
  },
  {
    hotelName: "solaris beach resort",
    title: "Solaris Beach Resort - Godsownstay.com",
    description:
      "Solaris Beach Resort Starting,24*7 Goscare Support,101% refund on cancellation,Rating starting from 1999",
  },
  {
    hotelName: "ocean pearl varakala",
    title: "Ocean Pearl Varakala - Godsownstay.com",
    description:
      "Book your best hotels in Trivandrumocaen pearl varkala.budget hotels in Thiruvanathapuram,Hawah beach resort,solaris beach resort,24*7 Goscare Support",
  },
  {
    hotelName: "hawah beach resort",
    title: "Budget Hotels in Trivandrum- Hawah beach Resort, Hotels in kovalam",
    description:
      "This properties is located in near to the beach,101% refund on cancellation,book wild range of properties from Godsownstay.com",
  },
  {
    hotelName: "blosssom hills resort",
    title: "Blosssom Hills Resort with Pool",
    description:
      "Bloosom Hills Resort ensure one of the best affordable stay in Munnar its located in floral garden. Propertiy offers couple friendly stay",
  },
  {
    hotelName: "the breeze resort",
    title: "The Breeze Resort 101% Refund on cancellation - Godsownstay.com",
    description:
      "Book Now Breeze Resort ensure 101% refund on cancellation,couple  friendly,gosmoney +.Rating 4.1/5",
  },
  {
    hotelName: "shamrock resort",
    title: "Shamrock Resort Hotels - Godsownstay.com ",
    description:
      "Book Now Shamrock Resort . Couple friendly, we offers Gos Money +.Our Gos stay Assured program offers 101% refund on cancellation.",
  },
  {
    hotelName: "richmond holiday inn",
    title: "Richmond Holiday Inn - 55% OFF on First Bookings",
    description:
      "Richmond Holiday Inn Rated 3.9/5 . This is one of the best property in munnar, Also offer couple friendly stay .Coupon code WELCOMEDEAL55",
  },
  {
    hotelName: "moonlight forest resort",
    title: "Moon Light Forest Resort - Coupon Code HURRY33",
    description:
      "Moonlight Forest Resort Offers wild range of stay facility.Book the best stay Moonlight Forest Resort in unnar ,Coupon code HURRY33",
  },
  {
    hotelName: "gokulam home stay",
    title: "Gokulam Homestay - Godsownstay.com",
    description:
      " Book Gokulam Homestay Wild range of properties Top Hotels In Munnar  from Godsownstay.com.Book Your Budget Hotels in Munnar,Couple Friendly, 24*7 GosCare Support.Coupon code BISSS18",
  },
  {
    hotelName: "spice jungle resort",
    title: "Spice Jungle Resort - Best Resort In Munnar Town",
    description:
      "Spice Jungle Resort located in the awsome place inside the jungle ,there you can see lot of wild animals , Property offers pick up and drop as per the tharif.",
  },
  {
    hotelName: "areva inn",
    title: "Areva Inn - Best Resort In Munnar",
    description:
      "Book Areva Inn one of the best property in Munnar offers Couple friendly, Gos Money +.Coupon code HURRY33",
  },
  {
    hotelName: "summer castle",
    title: "Summer Castle - 40% OFF on Summer Castle Munnar",
    description:
      "Book Summer Castle Wild range of properties Top Hotels In Munnar  from Godsownstay.com.Book Your Budget Hotels in Munnar,Couple Friendly, 24*7 GosCare Support, Coupon code BISSS18",
  },
  {
    hotelName: "casa smari jungle resort",
    title: "Casa Amari Jungle Resort - Godsownstay.com",
    description:
      "Book Casa Amari Jungle Resort. Couple friendly, we offers Gos Money +.Our Gos stay Assured program offers 101% refund on cancellation.",
  },
  {
    hotelName: "silver oaks natural retreat",
    title: "Book Silver Oaks Natural Retreat Hotel - Munnar",
    description:
      "Book Silver Oaks Natural Retreat Hotel From Godsownstasy.com offers Couple friendly, we offers Gos Money +.Our Gos stay Assured program offers 101% refund on cancellation.",
  },
  {
    hotelName: "ammus home stay",
    title: "Ammu Homestay - Hotels in Wayanad",
    description:
      "Book your best Homestay from Godsownstay.com . We Provide budget hotels in Wayanad., Valentine Wayanad, The Cove Lakkidi.24*7 Goscare Support",
  },
  {
    hotelName: "sanihara",
    title: "Sanihara Hotels - Free Cancellation",
    description:
      "Sanihara Resort is one of the best resort property in wayanad offers Free cancellation,Couple friendly,property is one of the best peaceful stay and awesome mountin views . Rating 4.2/5.",
  },
  {
    hotelName: "aldora holiday homes",
    title: "Aldo Holidayhomes - Hotels in Wayanad",
    description:
      "One of the best Property in wayanad Aldo Holiday Homes offers Affordable stay to there customers,Book Aldo Holidays through godsownstay,com and get Rs 5000 Gos Money +",
  },
  {
    hotelName: "le eden resorts",
    title: "Le-E Edenresorts - Hotels in Wayanad",
    description:
      "Book Le-E Edenresorts - Hotels in Wayanad though godsownstay.com and get Gos stay Assured and Rs 5000 for Gos Money +.",
  },
  {
    hotelName: "woodside homestay",
    title: "Woodside Homestay Hotels -Coupon Code - SUMMERDEAL",
    description:
      "Book Woodside Homestay Hotels and get -Coupon Code - SUMMERDEAL and getb Rs 50.24*7 Goscare support,",
  },
  {
    hotelName: "cottage white",
    title: "Cottage White Hotels -Coupon Code -GETUPTO35",
    description:
      "Book Cottage White Hotels , This propety is located in wayanad .Coupon Code -GETUPTO35. Godsownstay.com offers wide range of properties and offer 101% refund on cancellation.",
  },
  {
    hotelName: "niyatma resort",
    title: "Niyatma Resort Hotels -Coupon Code - SUMMERDEAL",
    description:
      "Good Resort rated 4/5.satff are good and providing free cancellation ,24*7 Goscare Support. Godsownstay.com offer Gos stay Assured offer 101% refund on cancellation",
  },
  {
    hotelName: "baywatch by grha",
    title: "Baywatch by GRHA - Free Cancellation",
    description:
      "Baywatch by GRHA - Free Cancellation one of the best class property in calicut , provide 24*7 goscare Support to guest.offers affordable and comffortable stay in calicut city",
  },
  {
    hotelName: "lakshmi hotel and resorts",
    title: "Lakshmi Hotels and Resort - Kumarakom - Free cancellation",
    description:
      "The Hotel is Located in Kumarakom backwater Resort, one of  the finest property in kumarakom offers couple friendly rooms and Free Cancellation",
  },
  {
    hotelName: "karippals inn",
    title: "Karippa Inn - Kumarakom | 101% Refund on Hotels Bookings",
    description:
      "Book karippa in located in Center of the City .This is the couple friendly Property .Godsownstay.com offers 101% refund on Cancellation,24*7 Goscare Support & Gos Money +.",
  },
  {
    hotelName: "johny moose backwater farm",
    title: "Johny Moose Backwater Farm - Kumarakom",
    description:
      "Johny Moose Backwater Inn Farm Offers Free Cancellation one of the best class property in Kumarakom  , provide 24*7 goscare Support to guest.offers affordable and comffortable stay in kumarakom,",
  },
  {
    hotelName: "tiyan castle",
    title: "Tiyan Castle Cottage By VOYO HOMES",
    description:
      "Tiyans Castle Cottage in vagamon by VOYE HOMES.one of the best affordable and comfortable stay in Vagamon Rating 4/5.Couple friendly property.",
  },
  {
    hotelName: "anudhyana resort",
    title: "Anudhyana Resort Hotels In Vagamon - Godsownstay.com",
    description:
      "Anudhyana Resort Hotels In Vagamon - Godsownstay.com offers Gos Stay Assured 101% Refund on Cancellation ,24*7 Free cancellation.1.VAGA COPPER CASTLE 2. Voye Homes Tiyans Castle",
  },
  {
    hotelName: "cotton hill bungalow",
    title: "Cotton Hill Bungalow Hotels In Vagamon - Godsownstay.com",
    description:
      "Cotton Hill Bungalow Hotels In Vagamon - Godsownstay.com. One of the best propertie in Vagamon.24*7 Goscare Support.Rating4.3/5",
  },
  {
    hotelName: "white stone cottage vagamon",
    title: "White Stone Cottage Vagamon Hotels In Vagamon - Godsownstay.com",
    description:
      "White Stone Cottage Vagamon Hotels In Vagamon - Godsownstay.com Offers wide range of Properties starting from Rs 999. Coupon code DAMAKKA20 and get 20% total off on first booking",
  },
  {
    hotelName: "alpine vagamon",
    title: "Alpine Vagamon Hotels In Vagamon -Free Cancellation ",
    description:
      "Alpine Vagamon Hotels In Vagamon -Free Cancellation .some best properties in Vagamon,Godsownstay.com provide wide range of properties .",
  },
  {
    hotelName: "pinedale bungalow",
    title: "Pinedale Bungalow Hotels In Vagamon -101% Refund On Cancellation",
    description:
      "Pinedale Bungalow Hotels In Vagamon -101% Refund On Cancellation.some best properties in vagamon,Godsownstay.com provide wide range of properties .",
  },
  {
    hotelName: "silver pine villa",
    title: "Silver Pine Villa Hotels In Vagamon - Godsownstay.com",
    description:
      "Silver Pine Villa Hotels In Vagamon - Godsownstay.com. Couple friendly, we offers Gos Money +.Our Gos stay Assured program offers 101% refund on cancellation.",
  },
];

export default HotelSeo;
