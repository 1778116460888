import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
// import Figure from "react-bootstrap/Figure";
import Typography from "@mui/material/Typography";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getProfileImage,
  // getUserProfileById,
  uploadProfileImage,
} from "../../../services/utils/userUtils/hotelutils/otpverification";
import DEFAULT_PIC from "../../../assets/loging 01.png";
import Skeleton from "@mui/material/Skeleton";
import { EditUserProfileForm } from "../../../components/forms/EditUserProfile";
// import { uploadProfileImage } from "../../../services/utils/userUtils/hotelutils/otpverification";
// import profile from "../../../assets/loging 01.png";

function ViewUserProfile() {
  const nav = useNavigate();
  const userLoginData = useSelector((s) => s.userDetails.user);
  const [userProfilePic, setUserProfilePic] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfileData , setUserProfileData] = useState();
  // const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const USER_ID = userLoginData?.id;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      // setIsDesktop(window.innerWidth > 1024);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getUserProfileImage = () => {
      setLoading(true);
      getProfileImage(USER_ID).then((res) => {
        console.log(res);
        setUserProfileData(res.data.response)
        const { profileImage } = res.data.response;
        setUserProfilePic(profileImage);
        setLoading(false);
      });
    };
    getUserProfileImage();
  }, [isModalOpen]);

  const imageUpload = async () => {
    // if (!file) {
    //   message.error("Please select an image before uploading.");
    //   return;
    // }
    console.log("file in requst ", file);
    const formdata = new FormData();
    formdata.append("profileImage", file);
    formdata.append("userId", userLoginData?.id);
    await uploadProfileImage(formdata)
      .then((res) => {
        console.log("Upload successfull.");
        message.success(res.data.message);
        setTimeout(() => {
          location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        message.error(error.response.data.error);
      });
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error('Image must smaller than 1MB!');
      return isLt2M;
    } else {
      return false;
    }
  };

  const onChange = (info) => {
    setFile(info.file);
  };

  const showModal = () => {
    setIsModalOpen(true);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isMobile && (
        <div className="mobile2ndHead justify-content-start">
          <IconButton onClick={() => nav("/")}>
            <ArrowBackIcon sx={{ color: "white" }} />
          </IconButton>
          <p>&nbsp;&nbsp;User Profile</p>
        </div>
      )}
      <div
        className={isMobile ? "" : "vh-100"}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Container>
          <Row>
            <Col xs={12} md={4} className="text-center">
              <Container style={{ paddingTop: "20px" }}>
                <Card body className="pt-3 text-center">
                  {loading ? (
                    <Skeleton>
                      <Image
                        rounded
                        style={{
                          border: "none",
                          outline: "none",
                          height: "75%",
                          width: "75%",
                          objectFit: "contain",
                        }}
                      />
                    </Skeleton>
                  ) : (
                    <Image
                      src={userProfilePic ? userProfilePic : DEFAULT_PIC}
                      rounded
                      style={{
                        border: "none",
                        outline: "none",
                        height: "75%",
                        width: "75%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  {/* <Figure>
                    <Figure.Image
                      width={250}
                      height={180}
                      alt="profile-pic"
                      // src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
                      src={userProfilePic ? userProfilePic : DEFAULT_PIC }
                    />
                  </Figure> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Upload
                      maxCount={1}
                      onChange={onChange}
                      beforeUpload={beforeUpload}
                    >
                      <Button icon={<UploadOutlined />}>
                        Edit Profile Pic
                      </Button>
                    </Upload>
                    <Button
                      type="primary"
                      onClick={imageUpload}
                      disabled={!file}
                      style={{ width: "50%", alignSelf: "center" }}
                    >
                      Upload
                    </Button>
                  </div>
                </Card>
              </Container>
            </Col>
            <Col xs={12} md={8}>
              <Container style={{ paddingTop: "20px" }}>
                <Card body>
                  <Typography variant="h5" gutterBottom>
                    Profile Info
                  </Typography>
                  <Divider
                    variant="fullWidth"
                    sx={{ backgroundColor: "#1675df" }}
                  />
                  <Row className="py-3">
                    <div
                      className="col-lg-3 col-md-4 fs-5 "
                      style={{ fontWeight: "600", color: "#1675df" }}
                    >
                      Full name
                    </div>
                    <div
                      className="col-lg-9 col-md-8 fs-5 fw-medium"
                      style={{ color: "dodgerblue" }}
                    >
                      {userProfileData?.firstName}
                    </div>
                  </Row>
                  <Row>
                    <div
                      className="col-lg-3 col-md-4 fs-5"
                      style={{ fontWeight: "600", color: "#1675df" }}
                    >
                      Email
                    </div>
                    <div
                      className="col-lg-9 col-md-8 fs-5 fw-medium text-wrap"
                      style={{ color: "dodgerblue" }}
                    >
                      {userProfileData?.email}
                    </div>
                  </Row>
                  <Row>
                    <div className="py-2" style={{display: 'flex' , justifyContent: 'flex-end'}} >
                    <Button
                      type="primary"
                      style={{alignSelf: "flex-end" }}
                      onClick={showModal}
                    >
                      Edit
                    </Button>
                    </div>
                  </Row>
                </Card>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <EditUserProfileForm isOpen={isModalOpen} handleCancel={handleCancel} initialValues={userProfileData} USER_ID={USER_ID} message={message} />
    </>
  );
}

export default ViewUserProfile;
