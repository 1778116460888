const  EXTRANETCONTRACTRATE_HEADERS = [
    {
      dataIndex: "col1",
      title: "S.N",
      nummeric: false,
      disablePadding: true,
      label: "S.N",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 40,
    },
    {
      dataIndex: "col2",
      title: "Hotel Name",
      nummeric: false,
      disablePadding: true,
  
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 120,
    },
    {
      dataIndex: "col3",
      title: "Rate Code",
      nummeric: false,
      disablePadding: true,
      filter: "select",
      width: 80,
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
    },
    // {
    //   dataIndex: "col4",
    //   title: "Description",
    //   nummeric: false,
    //   disablePadding: true,
    //   label: "Category",
    //   filter: "select",
    //   width: 80,
    //   sorter: {
    //     compare: (a, b) => a.math - b.math,
    //     multiple: 2,
    //   },
    // },
    // // {
    // //   dataIndex: "col5",
    // //   title: "Mail_Id",
    // //   nummeric: false,
    // //   disablePadding: true,
    // //   label: "Category",
    // //   filter: "select",
    // //   width: 80,
    // // },
    {
      dataIndex: "col6",
      title: "Actions",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 80,
    },
  ];
  
  export default EXTRANETCONTRACTRATE_HEADERS;
  