import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import "../../../styles/global.scss";

const CancellationPolicyLink = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    document
      .getElementById("cancellationScroll")
      .scrollIntoView({ behavior: "smooth", block: "end"});
  }, []);
  return (
    <Container
      fluid={true}
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        padding: "2% 15% 18% 15%",
      }}
    >
      <h4 style={{ fontWeight: "700" }} id="cancellationScroll">
        Cancellation Policy
      </h4>
      <h3 style={{ fontWeight: "700" }}>
        PAYMENTS FOR BOOKINGS, CANCELLATIONS AND REFUNDS
      </h3>
      <ul>
        <li className="terms-Li">
          Reservations will be subject to cancellation and refund policies of
          the Activity Provider which may vary from time to time.
        </li>
        <li className="terms-Li">
          GOS may charge applicable taxes or service charge, convenience fees
          etc. wherever applicable.
        </li>
      </ul>
      <p className="container-heading mt-3 mb-1">
        PAYMENT FOR BOOKINGS AND ANY ADDITIONAL PAYMENTS
      </p>
      <ul>
        <li className="terms-Li">
          Booking of a hotel can either be “Prepaid”, or “Pay at hotel” as per
          the options made available by a hotel on the Website of GOS. In
          “Prepaid” model, the total booking amount is paid by the User at the
          time of booking itself. Such total booking amount includes the hotel
          reservation rate, taxes, service fees as may be charged on behalf of
          the actual service provider, and any additional booking fee or
          convenience fee charged by GOS.
        </li>
        <li className="terms-Li">
          At the hotel’s or GOS’s sole discretion on case to case basis, the
          User may also be provided with an option to make a part payment to GOS
          at the time of confirmation of a booking. The balance booking amount
          shall be paid as per the terms of the bookings. For security purposes,
          the User must provide GOS with correct credit or debit card details.
          GOS may cancel the booking at its sole discretion in case such bank or
          credit card details as provided by the User are found incorrect.
        </li>
        <li className="terms-Li">
          Some banks and card issuing companies charge their account holders a
          transaction fee when the card issuer and the merchant location (as
          defined by the card brand, e.g. Visa, MasterCard, American Express)
          are in different countries. If a User has any questions about the fees
          or any exchange rate applied, they may contact their bank or the card
          issuing company through which payment was made.
        </li>
        <li className="terms-Li">
          Some accommodation suppliers may require User and/or the other
          persons, on behalf of whom the booking is made, to present a credit
          card or cash deposit upon check-in to cover additional expenses that
          may be incurred during their stay. Such deposit is unrelated to any
          payment received by GOS and solely at the behest of the Hotel.
        </li>
      </ul>
      <p className="container-heading mt-3 mb-1">SPECIAL OFFERS AND COUPONS</p>
      <p className="container-heading mt-3 mb-1">
        The following terms will additionally apply to any coupon provided by
        GOS:
      </p>
      <ul>
        <li className="terms-Li">
          GOS’s responsibility is limited to sharing the coupon with the User
          subject to receipt of payment from the User.
        </li>
        <li className="terms-Li">
          The redemption of coupon is User’s responsibility, and providing the
          service will be the Activity Provider’s responsibility.
        </li>
        <li className="terms-Li">
          The expiry date of the coupon shall be mentioned on the coupon and the
          User will not be able the use the coupon subsequent to the date of
          expiry.
        </li>
        <li className="terms-Li">The coupon is not redeemable for cash.</li>
        <li className="terms-Li">
          The coupon once purchased cannot be refunded or cancelled.
        </li>
        <li className="terms-Li">
          Refunds, if any, on cancelled bookings will always be processed to the
          respective account or the banking instrument (credit card, wallet
          etc.) from which payment was made for that booking.
        </li>
        <li className="terms-Li">
          Booking(s) made by the User through GOS are subject to the applicable
          cancellation policy as set out on the booking page or as communicated
          to the customers in writing.
        </li>
        <li className="terms-Li">Refund will be initiated within 24 hours.</li>
      </ul>
    </Container>
  );
};

export default CancellationPolicyLink;
