import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Flex, Button, Row } from "antd";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const styles = {
  card: {
    display: "flex",
    // maxWidth: 1000,
    width: "100%",
    margin: "auto",
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  content: {
    padding: 16,
  },
};

const HouseBoatBookingCard = ({ data }) => {
  // debugger;
  const navigate = useNavigate();
  return (
    <Card style={styles.card}>
      <CardActionArea>
        <Grid container>
          <Grid item xs={12} md={4}>
            <CardMedia
              component="img"
              alt="package"
              image={data?.HouseBoatdetail?.hbImage}
              style={styles.media}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <CardContent style={styles.content}>
              <Typography variant="h5" component="div">
                {data?.HouseBoatdetail?.houseBoatName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {data?.HouseBoatdetail?.description}
              </Typography>
              <Flex gap={"large"} className="pt-3">
                {/* <Row>
                  <label
                    htmlFor="bookingDate"
                    className="form-label"
                    style={{ fontWeight: 600, fontSize: "15px" }}
                  >
                    Booking Date:
                    <p>{moment(data?.bookingDate).format("DD/MM/YYYY")}</p>
                  </label>
                </Row> */}
                <Row>
                  <label
                    htmlFor="bookingDate"
                    className="form-label"
                    style={{ fontWeight: "500", fontSize: "15px" }}
                  >
                    Travel Date:
                    <p>
                      {moment(data?.billingDetails?.travelDate).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </label>
                </Row>
                <Row>
                  <label
                    htmlFor="amoundPaid"
                    className="form-label"
                    style={{ fontWeight: 600, fontSize: "15px" }}
                  >
                    Amount Paid:
                    <p>
                      <CurrencyRupeeIcon fontSize="small" />
                      {data?.billingDetails?.totalPayable
                        ? Number(
                            data?.billingDetails?.totalPayable
                          ).toLocaleString("en-IN")
                        : ""}
                    </p>
                  </label>
                </Row>
              </Flex>
              <Flex className="pt-4" justify="flex-end" align="flex-end">
                <Button
                  type="link"
                  size="large"
                  onClick={() => navigate(`/bookings/houseboat/${data?._id}`)}
                  style={{
                    margin: "4%",
                    color: "rgb(255, 255, 255)",
                    backgroundColor: "rgb(225, 45, 112)",
                  }}
                >
                  View Details
                </Button>
              </Flex>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default HouseBoatBookingCard;
