import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/molecules/tableComponent";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material/styles";
import { Col, Input, Row, Select } from "antd";
import { NotificationBox } from "../../../components/atoms/notificationBox/NotificationBox";
import { basicSettingsDesignationGetAll } from "../../../services/utils/admin/manageMaster/basicsettings/Designation";
import CONSTANTS from "../../../constants";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function ContractRateComponent() {
  const [contextHolder] = NotificationBox();

  const [rows, setRows] = useState([]);

  const [value, setValue] = useState("");
  useEffect(() => {
    getTableData();
  }, []);

  const pageOption = [
    {
      value: "5",
      label: "5",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "25",
      label: "25",
    },
    {
      value: "50",
      label: "50",
    },
    {
      value: "100",
      label: "100",
    },
  ];

 

  const getTableData = async () => {
    const { data } = await basicSettingsDesignationGetAll();
    if (data) {
      let arrayOfRows = [];
      data &&
        data.response.map((item, index) => {
          arrayOfRows.push({
            col1: index + 1,
            col2: item.designation,
            col3: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BootstrapTooltip
                  className="tooltipSize"
                  title="Edit"
                  placement="top"
                >
                  <FontAwesomeIcon
                    icon={faPencil}
                    color="#69aa46"
                    fontSize="20px"
                    style={{ marginRight: "6%" }}
                    onClick={""}
                  />
                </BootstrapTooltip>
                <BootstrapTooltip title="Delete" placement="top">
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    color="#dd5a43"
                    fontSize="20px"
                    onClick={() => {
                    " setDeleteModal(true);"
                      "setTableId(item._id);"
                    }}
                  />
                </BootstrapTooltip>
              </div>
            ),
          });
        });
      setRows(arrayOfRows);
    }
  };

  const filterData = () => {
    if (!value) return rows;
    return rows.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLocaleLowerCase().includes(value.toLocaleLowerCase())
      )
    );
  };
  const filteredRows = filterData();

  useEffect(() => {
    filterData();
  }, [value]);

  return (
    <>
      {contextHolder}
      <Row className="page-back">
        <p onClick={"backBtnFun"} style={{ cursor: "pointer" }}>
          <KeyboardDoubleArrowLeftIcon style={{ fontSize: "1.2rem" }} />
          <span style={{ paddingTop: "2px", cursor: "pointer" }}>Back</span>
        </p>
      </Row>
      <Row style={{ backgroundColor: "#f0f0f0" }}>
        <h1 className="page-header">Contract Rate</h1>
      </Row>
      <Row className="table-header">
        <Col flex="auto"> List of contract rate</Col>
      </Row>
      <Row className="dataTableHead">
        <Col span={12}>
          <Row style={{ padding: "0px 12px" }}>
            <Col className="header-select-child"> Display</Col>
            <Col className="header-select-child">
              <Select
                defaultValue="10"
                className="header-select"
                style={{ marginTop: "-5px", backgroundColor: "#fff" }}
                bordered={false}
                options={pageOption}
              />
            </Col>
            <Col className="header-select-child"> records</Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row className="header-serch" style={{ float: "right" }}>
            <Col style={{ padding: "5px" }}>Search:</Col>
            <Col>
              {" "}
              <Input
                onChange={(e) => setValue(e.target.value)}
                placeholder="Search"
                value={value}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <TableComponent
        data={filteredRows}
        columns={CONSTANTS.CONTRACT_RATE_HEADERS}
      />

    </>
  );
}

export default ContractRateComponent;