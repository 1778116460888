import AvailabilityComponent from "../pages/partner/availability/Availability";
import CalendarComponent from "../pages/partner/calendar/Calendar";
import ContractRateComponent from "../pages/partner/contractRate/ContractRate";
import GalleryComponent from "../pages/partner/gallery/Gallery";
import HotelAvailabilityComponent from "../pages/partner/hotelAvailability/HotelAvailability";
import HotelProfileComponent from "../pages/partner/hotelProfile/HotelProfile";
import OccupancyComponent from "../pages/partner/occupancy/Occupancy";
import PartnerDashboard from "../pages/partner/partnerDashboard/PartnerDashboard";
import PolicyComponent from "../pages/partner/policy/Policy";
import SavePolicyDetails from "../pages/partner/policy/SavePolicyDetails";
import { partnerRoutesPath } from "./PartnerRoutePaths";

const PartnerRoutesObj = [
    {
     element: (
      <>
       <PartnerDashboard />
      </>
     ),
     path: partnerRoutesPath[1],
    },
    {
     element: (
      <>
       <CalendarComponent />
      </>
     ),
     path: partnerRoutesPath[2],
    },
    {
     element: (
      <>
       <GalleryComponent />
      </>
     ),
     path: partnerRoutesPath[3],
    },
    {
     element: (
      <>
       < OccupancyComponent/>
      </>
     ),
     path: partnerRoutesPath[4],
    },
    {
     element: (
      <>
       <ContractRateComponent />
      </>
     ),
     path: partnerRoutesPath[5],
    },
    {
     element: (
      <>
       <PolicyComponent />
      </>
     ),
     path: partnerRoutesPath[6],
    },
    {
     element: (
      <>
       <HotelProfileComponent />
      </>
     ),
     path: partnerRoutesPath[7],
    },
    {
     element: (
      <>
       <AvailabilityComponent />
      </>
     ),
     path: partnerRoutesPath[8],
    },
    {
     element: (
      <>
       <HotelAvailabilityComponent />
      </>
     ),
     path: partnerRoutesPath[9],
    },
    {
     element: (
      <>
       <SavePolicyDetails />
      </>
     ),
     path: partnerRoutesPath[10],
    },

]
    export default PartnerRoutesObj;