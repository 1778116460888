import serviceUtil from ".././../../index";
// const config = {
//     headers: {
//       "content-type": "multipart/form-data",
//     },
//   };
const HouseBoatBookingListUpComing = (email , id) => {
  return serviceUtil
    .get(`myBooking/houseboatBookingList?email=${email}&id=${id}`)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const houseboatSummeryEmailSend = (payload) => {
  return serviceUtil

    .post(`/myBooking/sendHouseBoatSummary`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const GetHouseboatBookingDataById = async (id) => {
  return await serviceUtil
    .get(`myBooking/houseboatBookingView/${id}`)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

export { HouseBoatBookingListUpComing, houseboatSummeryEmailSend , GetHouseboatBookingDataById };
