const  COUPON_HEADERS = [
    {
      dataIndex: "col1",
      title: "Sl.no",
      nummeric: false,
      disablePadding: true,
      label: "S.N",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 40,
    },
    {
      dataIndex: "col2",
      title: "coupon_code",
      nummeric: false,
      disablePadding: true,
  
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 120,
    },
     {
       dataIndex: "col3",
       title: "coupon_type",
       nummeric: false,
       disablePadding: true,
       filter: "select",
       width: 80,
       sorter: {
         compare: (a, b) => a.math - b.math,
         multiple: 2,
       },
     },
     {
       dataIndex: "col4",
       title: "validity_from",
       nummeric: false,
       disablePadding: true,
       label: "Category",
       filter: "select",
       width: 80,
       sorter: {
         compare: (a, b) => a.math - b.math,
         multiple: 2,
       },
     },
      {
        dataIndex: "col5",
        title: "validity_to",
        nummeric: false,
        disablePadding: true,
        label: "Category",
        filter: "select",
        width: 80,
      },
      {
        dataIndex: "col6",
        title: "discount_value",
        nummeric: false,
        disablePadding: true,
        label: "Category",
        filter: "select",
        width: 80,
      },
      {
        dataIndex: "col7",
        title: "discount_type",
        nummeric: false,
        disablePadding: true,
        label: "Category",
        filter: "select",
        width: 80,
      },
      {
        dataIndex: "col8",
        title: "status",
        nummeric: false,
        disablePadding: true,
        label: "Category",
        filter: "select",
        width: 80,
      },
    {
      dataIndex: "col9",
      title: "Actions",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 80,
    },
  ];
  
  export default COUPON_HEADERS;
  