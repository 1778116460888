/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Row } from "antd";
import React from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import MainTabs from "./tabs/MainTabs";


const backBtnFun = () => {
    // setModalOpen(false);
    //alert("Back button clicked")
  };

const HotelAvailabilityComponent = () => {
    return (
        <>
        <Row className="page-back">
        <p onClick={backBtnFun} style={{ cursor: "pointer" }}>
          <KeyboardDoubleArrowLeftIcon style={{ fontSize: "1.2rem" }} />
          <span style={{ paddingTop: "2px", cursor: "pointer" }}>Back</span>
        </p>
      </Row>
      <Row style={{ backgroundColor: "#f0f0f0" }}>
        <h1 className="page-header">Hotel Availability</h1>
      </Row>
      <MainTabs/>
      </>
    )
}

export default HotelAvailabilityComponent
