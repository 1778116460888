const CitySeo = [
  {
    cityName: "kochi",
    title: "55% OFF on Hotels Bookings - Best Hotels in Cochin",
    description:
      "Book your Best Hotels in cochin, Hotels in cochin, 24*7 Goscare.Book Wide range of properties in Godsownstay.com",
  },
  {
    cityName: "cochin",
    title: "55% OFF on Hotels Bookings - Best Hotels in Cochin",
    description:
      "Book your Best Hotels in cochin, Hotels in cochin, 24*7 Goscare.Book Wide range of properties in Godsownstay.com",
  },
  {
    cityName: "alleppey",
    title:
      "Hotels in Alleppey Book Gos Stay Assured Property and get 101% Refund on Cnacellation",
    description:
      "Hotels in Alleppey . Book Gos Stay Assured Property and get 101% Refund on Cnacellation, Godsownstay.com offer wide range of properties ,budget friendly ,couple friendly 24*7 Gos care Support ",
  },
  {
    cityName: "trivandrum",
    title: "Hotels in Trivandrum - Godsownstay.com",
    description:
      "Book your best hotels in Trivandrum.budget hotels in Thiruvanathapuram,Hawah beach resort,solaris beach resort,24*7 Goscare Support",
  },
  {
    cityName: "munnar",
    title: "Budget Hotels In Munnar - Godsownstay.com",
    description:
      "Top Rated  Resorts in Munnar, Rtaed 4.1/5, 24*7 Goscare Support.Couple friendly,Gos Money +.Wide rangeof properties from Godsownstay.com",
  },
  {
    cityName: "wayanad",
    title: "Top 5 Resort In Wayanad - Free Cancellation",
    description:
      "Book the best Resort In Wayanad.Best Deal upto 45% OFF On Hotels Booking,Use Code GETUPTO35",
  },
  {
    cityName: "calicut",
    title: "Hotels in Calicut - Book Hotel in Calicut",
    description:
      "Best Price Guarntee . Book Your Hotels in Calicut,Law price Guarantee,Experience best Hospitality and amenities with best price Coupon Code - MIDWEEK",
  },
  {
    cityName: "kozhikode",
    title: "Hotels in Calicut - Book Hotel in Calicut",
    description:
      "Best Price Guarntee . Book Your Hotels in Calicut,Law price Guarantee,Experience best Hospitality and amenities with best price Coupon Code - MIDWEEK",
  },
  {
    cityName: "kumarakom",
    title:
      "Best Hotels In Kumarakom  With Price 2024 - 101% Refund on Cancellation",
    description:
      "Popular Best Hotels in Kumarakom .24*7 Goscare Support.choose from kumarakom hotels from Godsownstay.com and get 101% refund on hotel booking cancellation.",
  },
  {
    cityName: "kottaym",
    title:
      "Best Hotels In Kumarakom  With Price 2024 - 101% Refund on Cancellation",
    description:
      "Popular Best Hotels in Kumarakom .24*7 Goscare Support.choose from kumarakom hotels from Godsownstay.com and get 101% refund on hotel booking cancellation.",
  },
  {
    cityName: "vagamon",
    title: "Top 5 Hotels in Vagamon",
    description:
      "Hotels in Vagamon with 101% refund on Cancellation,Rating 4.2/5,Gos Stay Assured Offers 101% refund on cancellation.1.Holiday Home Resort Vagamon 2.Mist Woods Vagamon 3.Vagamon Tea County. Gos Money +.Rating 4.2/5",
  },
];

export default CitySeo;
