const BASIC_SETTINGS_CURRENCY = [
  {
    dataIndex: "col1",
    title: "S.N",
    nummeric: false,
    disablePadding: true,
    label: "S.N",
    // sorter: {
    //   compare: (a, b) => a.math - b.math,
    //   multiple: 2,
    // },
    width: 40,
  },
  {
    dataIndex: "col2",
    title: "Currency",
    nummeric: false,
    disablePadding: true,

    // sorter: {
    //   compare: (a, b) => a.math - b.math,
    //   multiple: 2,
    // },
    width: 120,
  },
  {
    dataIndex: "col3",
    title: "Currency Code",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 80,
    // sorter: {
    //   compare: (a, b) => a.math - b.math,
    //   multiple: 2,
    // },
  },
  {
    dataIndex: "col4",
    title: "Value",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 80,
    // sorter: {
    //   compare: (a, b) => a.math - b.math,
    //   multiple: 2,
    // },
  },
  {
    dataIndex: "col5",
    title: "Actions",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 80,
  },
];

export default BASIC_SETTINGS_CURRENCY;
