import BtnComp from "../../../atoms/buttonComponent/BtnComp";
import ModalComponent from "../../../molecules/modalComponent/ModelComponent";
import React, { useEffect } from "react";
import SelectComponent from "../../../atoms/SelectComponent/SelectComponent";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faCalendarDays, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, DatePicker, Row } from "antd";
import { useFormik } from "formik";
import { NotificationBox } from "../../../atoms/notificationBox/NotificationBox";

import {
  basicSettingDestinationCreate,
  basicSettingsDesignationUpdate,
} from "../../../../services/utils/admin/manageMaster/basicsettings/Designation";

function MinimumLengthModal({
  isModalOpen,
  setModalOpen,
  getTableData,
  modalValue,
  defaultFormData,
}) {
  const [contextHolder, openNotificationSucess, openNotificationError] =
    NotificationBox();
  const { values, handleSubmit, setValues, handleReset } = useFormik({
    initialValues: { designation: "" },
    validationSchema: "DesignationModalSchema",
    onSubmit: async (values, action) => {
      modalValue === "add" ? addDataFunction() : updateDataFunction();
      //  action.resetForm();
      console.log(action);
      setModalOpen(false);
      getTableData();
    },
  });

  const addDataFunction = async () => {
    const res = await basicSettingDestinationCreate(values);
    //For now these create and update apis are in use later will be replaced with the apis of minimum length modals.
    try {
      res.data.error
        ? ""
        : openNotificationSucess("Designation Created Successfully..!");
    } catch (error) {
      res ? openNotificationError("Something Went Wrong..!") : "";
    }
    getTableData();
  };

  const updateDataFunction = async () => {
    const res = await basicSettingsDesignationUpdate(
      defaultFormData._id,
      values
    );
    try {
      res.data.error
        ? ""
        : openNotificationSucess("Designation Updated Successfully..!");
    } catch (error) {
      res ? openNotificationError("Something Went Wrong..!") : "";
    }
    getTableData();
  };

  useEffect(() => {
    modalValue === "edit" && setValues(defaultFormData);
  }, []);

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      {contextHolder}
      <ModalComponent
        modalTitle={
          modalValue === "add"
            ? "Create Hotel Minimum Length Stay"
            : "Edit Hotel Minimum Length Stay"
        }
        onCloseIconClick={handleClose}
        isModalOpen={isModalOpen}
        showFooter={false}
        showClearBtn={false}
        showSaveBtn={false}
        cancelBtnfun={handleClose}
        createBtnFun={handleSubmit}
        resetBtnfun={handleReset}
        createBtnName={modalValue === "add" ? "Create" : "Update"}
      >
        {/* ________________________________________________________________________________________________ */}

        <Row className="p-4">
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row>
              <Col span={11}>
                {" "}
                <SelectComponent
                  label="Market Type"
                  style={{ color: "#666" }}
                />
              </Col>
              <Col span={11} offset={2}>
                <SelectComponent
                  label="Minimum Length Stay"
                  style={{ color: "#666" }}
                />
              </Col>
            </Row>
            <fieldset className="my-5" style={{ border: "1px solid #9d9e9d" }}>
              <legend
                className="mx-5 w-auto"
                style={{
                  borderRadius: "4px",
                  border: "1px solid #9d9e9d",
                  lineHeight: 2,
                  backgroundColor: "#fff",
                  cursor: "pointer",
                  fontSize: "12px",
                  color: "#9d9e9d",
                  paddingRight: "25px",
                  paddingLeft: "15px",
                  position: "relative",
                  top: "-16px",
                }}
              >
                Validity List
              </legend>
              <Row style={{ marginLeft: "4%", marginRight: "4%" }}>
                <Col span={10}>
                  <p>From Date</p>
                  <DatePicker
                    onChange={""}
                    placeholder={""}
                    suffixIcon={""}
                    style={{ width: "80%" }}
                  />
                  <span
                    style={{
                      border: "1px solid #ccc",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      backgroundColor: "#eee",
                    }}
                  >
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </span>
                </Col>
                <Col span={10} offset={2} className="mb-5">
                  <p>To Date</p>
                  <DatePicker
                    onChange={""}
                    placeholder={""}
                    suffixIcon={""}
                    style={{ width: "80%" }}
                  />
                  <span
                    style={{
                      border: "1px solid #ccc",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                      backgroundColor: "#eee",
                    }}
                  >
                    <FontAwesomeIcon icon={faCalendarDays} />
                  </span>
                </Col>
                <Col span={2}>
                  <BtnComp
                    startIcon={faTrashCan}
                    width="30px"
                    bg_color="#d9534f"
                    color="#fff"
                    btnName=""
                    className="px-1 mt-4"
                  />

                  <BtnComp
                    width="60px"
                    btnName="Add"
                    className="mt-2 px-2"
                    startIcon={faPlus}
                    pl="5px"
                    bg_color="#6fb3e0"
                    color="#FFF"
                  />
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Row>

        {/* ____________________________________________________________________________________________ */}
      </ModalComponent>
    </>
  );
}

export default MinimumLengthModal;
