import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { updateUserProfileById } from "../../services/utils/userUtils/hotelutils/otpverification";

export const EditUserProfileForm = ({
  isOpen,
  handleCancel,
  initialValues,
  USER_ID,
  message,
}) => {
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      const values = await form.validateFields();
      await updateUserProfileById(USER_ID, values).then((res) => {
        message.success(res.data.message);
        handleCancel();
        setTimeout(() => {
          location.reload();
        }, 1000);
      });
    } catch (error) {
      console.error("Error updating the profile info.", error);
    } finally {
      setConfirmLoading(false);
    }
  };
  return (
    <Modal
      title="Edit User Profile"
      open={isOpen}
      onCancel={handleCancel}
      centered
      confirmLoading={confirmLoading}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Update
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item
          name="firstName"
          label="Name"
          rules={[{ required: true, message: "Please enter your name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { required: true, message: "Please enter your email!" },
            { type: "email", message: "Please enter a valid email address!" },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
