/* eslint-disable import/default */
/* eslint-disable import/namespace */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Col, Layout, Space, Flex, Row } from "antd";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { ShikaraBookingListUpComing } from "../../../services/utils/admin/myBooking/shikaraBookingList";
import { PackageBookingListUpComing } from "../../../services/utils/admin/myBooking/packageBookingList";
import { HotelBookingList } from "../../../services/utils/admin/myBooking/hotelBookingList";
import { HouseBoatBookingListUpComing } from "../../../services/utils/admin/myBooking/houseBoatBookingLIst";
import MoblieLoginModel from "../mobileLoginModel/MoblieLoginModel";
import BottomFooter from "./../../usersPage/newUserLayout/userFooter/BottomFooter";
import { UserLoader } from "../../../components/atoms/userLoader";
import PackageBookingHistoryCard from "../../../components/atoms/card/packageBookingCard/packageBookingCard";
import ShikaraBookingCard from "../../../components/atoms/card/shikaraBookingCard/shikaraBookingCard";
import HotelBookingCard from "../../../components/atoms/card/hotelBookingCard/HotelBookingCard";
import HouseBoatBookingCard from "../../../components/atoms/card/houseboatBookingCard/HouseBoatBookingCard";
// import BookingLogo from "../../../assets/bookingLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import OfferCards from "../../../components/atoms/card/offerCards/OfferCards";
import { getAllCoupons } from "../../../services/utils/userUtils/landingPage";
import { setActiveFooterTab } from "../../../reduxData/sliceData/homeReducer";
import {
  faArrowLeft,
  faHeadset,
  faSquarePhone,
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Discount } from "../../../assets/offer2.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

import {
  // faBars,
  faBriefcase,
  // faFileCircleMinus,
  faHome,
  // faIdBadge,
} from "@fortawesome/free-solid-svg-icons";
import couple from "../../../assets/bookinglogo.png";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const { Sider } = Layout;
export const Bookings = () => {
  const GET_CURRENT_MONTH = new Date().getMonth();
  const GET_CURRENT_YEAR = new Date().getFullYear();
  const nav = useNavigate();
  const [userDetails, setuserDetails] = useState();
  const [activeBookingTab, setactiveBookingTab] = useState("Hotel");
  const [activeBookingType, setactiveBookingType] = useState("All");
  const [timePeriod, setTimePeriod] = useState("All");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [loading, setLoading] = useState(false);
  const [model, setmodel] = useState(false);
  const dispatch = useDispatch();
  const [offerDataClone, setofferCloneData] = useState([]);
  const [offerData, setofferData] = useState([]);

  const [activeOfferTab, setactiveOfferTab] = useState("all");
  const [packagebookingHistoryList, setPackagebookingHistoryList] = useState(
    []
  );
  const [shikaraBookingList, setShikaraBookingList] = useState([]);
  const [houseboatBookingList, setHouseboatBookingList] = useState([]);
  const [hotelBookingList, setHotelBookingList] = useState([]);
  const [filteredhotelBookingList, setFilteredHotelBookingList] = useState([]);
  const [filteredPackageBookingList, setFilteredPackageBookingList] = useState(
    []
  );
  const [filteredShikaraBookingList, setFilteredShikaraBookingList] = useState(
    []
  );
  const [filteredHouseBoatBookingList, setFilteredHouseBoatBookingList] =
    useState([]);

  const selector = useSelector((s) => s.userDetails.user);  
  const [copiedCode, setcopiedCode] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
      setIsDesktop(window.innerWidth > 1024);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // debugger;
    const bookingPackageHistoryCardData = async () => {
      setLoading(true);
      try {
        const {
          data: { response },
        } = await PackageBookingListUpComing(selector.email, selector.id);
        if (timePeriod === "currentMonth") {
          const filteredData = response?.filter((booking) => {
            const bookingDate = new Date(booking?.bookingDate);
            return (
              bookingDate.getMonth() === GET_CURRENT_MONTH &&
              bookingDate.getFullYear() === GET_CURRENT_YEAR
            );
          });
          setPackagebookingHistoryList(filteredData);
        } else if (timePeriod === "currentYear") {
          const filteredData = response?.filter((booking) => {
            const bookingDate = new Date(booking?.bookingDate);
            return bookingDate.getFullYear() === GET_CURRENT_YEAR;
          });
          setPackagebookingHistoryList(filteredData);
        } else {
          setPackagebookingHistoryList(response);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    const shikaraBookingListCardData = async () => {
      setLoading(true);
      try {
        const {
          data: { response },
        } = await ShikaraBookingListUpComing(selector.email, selector.id);
        if (timePeriod === "currentMonth") {
          const filteredData = response?.filter((booking) => {
            const bookingDate = new Date(booking?.bookingDate);
            return (
              bookingDate.getMonth() === GET_CURRENT_MONTH &&
              bookingDate.getFullYear() === GET_CURRENT_YEAR
            );
          });
          setShikaraBookingList(filteredData);
        } else if (timePeriod === "currentYear") {
          const filteredData = response?.filter((booking) => {
            const bookingDate = new Date(booking?.bookingDate);
            return bookingDate.getFullYear() === GET_CURRENT_YEAR;
          });
          setShikaraBookingList(filteredData);
        } else {
          setShikaraBookingList(response);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const hotelBookingListCardData = async () => {
      setLoading(true);
      try {
        const {
          data: { response },
        } = await HotelBookingList(selector.email, selector.id);
        if (timePeriod === "currentMonth") {
          const filteredData = response?.filter((booking) => {
            const bookingDate = new Date(booking?.bookingDate);
            return (
              bookingDate.getMonth() === GET_CURRENT_MONTH &&
              bookingDate.getFullYear() === GET_CURRENT_YEAR
            );
          });
          setHotelBookingList(filteredData);
        } else if (timePeriod === "currentYear") {
          const filteredData = response?.filter((booking) => {
            const bookingDate = new Date(booking?.bookingDate);
            return bookingDate.getFullYear() === GET_CURRENT_YEAR;
          });
          setHotelBookingList(filteredData);
        } else {
          setHotelBookingList(response);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    const houseBoatBookingListCardData = async () => {
      setLoading(true);
      try {
        const {
          data: { response },
        } = await HouseBoatBookingListUpComing(selector.email, selector.id);
        if (timePeriod === "currentMonth") {
          const filteredData = response?.filter((booking) => {
            const bookingDate = new Date(booking?.bookingDate);
            return (
              bookingDate.getMonth() === GET_CURRENT_MONTH &&
              bookingDate.getFullYear() === GET_CURRENT_YEAR
            );
          });
          setHouseboatBookingList(filteredData);
        } else if (timePeriod === "currentYear") {
          const filteredData = response?.filter((booking) => {
            const bookingDate = new Date(booking?.bookingDate);
            return bookingDate.getFullYear() === GET_CURRENT_YEAR;
          });
          setHouseboatBookingList(filteredData);
        } else {
          setHouseboatBookingList(response);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    switch (activeBookingTab) {
      case "Shikara":
        shikaraBookingListCardData();
        break;
      case "HouseBoat":
        houseBoatBookingListCardData();
        break;
      case "Package":
        bookingPackageHistoryCardData();
        break;
      default:
        hotelBookingListCardData();
        break;
    }
  }, [activeBookingTab, timePeriod]);

  const filterBookingList = () => {
    setLoading(true);
    const currentDate = new Date();

    let filteredHotel = hotelBookingList;
    let filteredPackage = packagebookingHistoryList;
    let filteredShikara = shikaraBookingList;
    let filteredHouseBoat = houseboatBookingList;

    if (activeBookingTab === "Hotel") {
      switch (activeBookingType) {
        case "Upcoming":
          filteredHotel = filteredHotel.filter(
            (booking) =>
              booking.status === "confirm" &&
              new Date(booking?.checkIn) > currentDate
          );
          break;

        case "Completed":
          filteredHotel = filteredHotel.filter(
            (booking) =>
              booking.status === "confirm" &&
              new Date(booking?.checkOut) < currentDate
          );
          break;

        case "cancel":
          filteredHotel = filteredHotel.filter(
            (booking) => booking.status === activeBookingType
          );
          break;
        default:
          filteredHotel = filteredHotel.map((booking) => booking);
          break;
      }
    } else if (activeBookingTab === "Package") {
      switch (activeBookingType) {
        case "Upcoming":
          filteredPackage = filteredPackage.filter(
            (booking) => new Date(booking?.travelDate) > currentDate
          );
          break;

        case "Completed":
          filteredPackage = filteredPackage.filter(
            (booking) => new Date(booking?.travelDate) < currentDate
          );
          break;
        default:
          filteredPackage = filteredPackage.map((booking) => booking);
          break;
      }
    } else if (activeBookingTab === "Shikara") {
      switch (activeBookingType) {
        case "Upcoming":
          filteredShikara = filteredShikara.filter(
            (booking) =>
              new Date(booking?.billingDetails?.travelDate) > currentDate
          );
          break;

        case "Completed":
          filteredShikara = filteredShikara.filter(
            (booking) =>
              new Date(booking?.billingDetails?.travelDate) < currentDate
          );
          break;
        default:
          filteredShikara = filteredShikara.map((booking) => booking);
          break;
      }
    } else if (activeBookingTab === "HouseBoat") {
      switch (activeBookingType) {
        case "Upcoming":
          filteredHouseBoat = filteredHouseBoat.filter(
            (booking) =>
              new Date(booking?.billingDetails?.travelDate) > currentDate
          );
          break;

        case "Completed":
          filteredHouseBoat = filteredHouseBoat.filter(
            (booking) =>
              new Date(booking?.billingDetails?.travelDate) < currentDate
          );
          break;

        default:
          filteredHouseBoat = filteredHouseBoat.map((booking) => booking);
          break;
      }
    }

    setFilteredHotelBookingList(filteredHotel);
    setFilteredPackageBookingList(filteredPackage);
    // debugger;
    setFilteredHouseBoatBookingList(filteredHouseBoat);
    setFilteredShikaraBookingList(filteredShikara);
    setLoading(false);
  };

  useEffect(() => {
    filterBookingList();
  }, [
    hotelBookingList,
    packagebookingHistoryList,
    houseboatBookingList,
    shikaraBookingList,
    activeBookingType,
  ]);

  const offerCardData = async () => {
    try {
      const {
        data: { response },
      } = await getAllCoupons();
      setofferData(response);
      setofferCloneData(response);
    } catch (err) {
      console.log(err);
    }
  };

  const filterOfferData = (type) => {
    setactiveOfferTab(type);
    if (type === "all") {
      setofferData(offerDataClone);
    } else {
      const filterdOffer = offerDataClone.filter(
        (offer) => offer.couponType === type
      );
      setofferData(filterdOffer);
    }
  };

  useEffect(() => {
    setuserDetails(selector);
    offerCardData();
  }, []);

  useEffect(() => {
    if (userDetails?.name) {
      setmodel(false);
    } else {
      setmodel(true);
    }
  }, [userDetails]);

  const [tabRecord, setTabRecord] = useState(2);
  const active = "bottomTabActive";
  const deActive = "bottomTab";

  return (
    <>
      {tabRecord == 2 ? (
        <>
          {!userDetails?.name ? (
            isMobile ? (
              <MoblieLoginModel
                isModalOpen={model}
                onCancel={() => setmodel(false)}
              />
            ) : (
              ""
            )
          ) : (
            <>
              {loading && <UserLoader />}
              {isMobile &&
              loading &&
              hotelBookingList?.length +
                packagebookingHistoryList?.length +
                shikaraBookingList?.length +
                houseboatBookingList?.length <=
                0 ? (
                <div
                  style={{
                    marginBottom: "200px",
                  }}
                >
                  <img
                    src={couple}
                    alt="mySvgImage"
                    style={{
                      width: "150px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "50%",
                      marginBottom: "30px",
                    }}
                  />

                  <h5
                    style={{
                      textAlign: "center",
                    }}
                  >
                    You have not made your first Booking Yet !
                  </h5>
                </div>
              ) : (
                <>
                  {isMobile && (
                    <>
                      {" "}
                      <div className="mobile2ndHead justify-content-start">
                        <IconButton onClick={() => nav("/")}>
                          <ArrowBackIcon sx={{ color: "white" }} />
                        </IconButton>
                        <p>&nbsp;&nbsp;Booking History</p>
                      </div>
                      <div className="ps-3 pt-3">
                        <h6>Time Period</h6>
                        <div className="divScrollRow" style={{ fontSize: 13 }}>
                          <div
                            className={`${
                              timePeriod === "currentMonth"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span onClick={() => setTimePeriod("currentMonth")}>
                              Current month
                            </span>
                          </div>
                          <div
                            className={`${
                              timePeriod === "currentYear"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span onClick={() => setTimePeriod("currentYear")}>
                              Current year
                            </span>
                          </div>
                          <div
                            className={`${
                              timePeriod === "All" ? "activeDiv" : "deactiveDiv"
                            }`}
                          >
                            <span onClick={() => setTimePeriod("All")}>
                              All
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="ps-3 py-2">
                        <h6>Booking Type</h6>
                        <div className="divScrollRow" style={{ fontSize: 13 }}>
                          <div
                            className={`${
                              activeBookingType === "Upcoming"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span
                              onClick={() => setactiveBookingType("Upcoming")}
                            >
                              Upcoming
                            </span>
                          </div>
                          <div
                            className={`${
                              activeBookingType === "Completed"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span
                              onClick={() => setactiveBookingType("Completed")}
                            >
                              Completed
                            </span>
                          </div>
                          <div
                            className={`${
                              activeBookingType === "cancel"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span
                              onClick={() => setactiveBookingType("cancel")}
                            >
                              Cancelled
                            </span>
                          </div>
                          <div
                            className={`${
                              activeBookingType === "All"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span onClick={() => setactiveBookingType("All")}>
                              All
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="ps-3">
                        <h6>Booking of</h6>
                        <div className="divScrollRow" style={{ fontSize: 13 }}>
                          <div
                            className={`${
                              activeBookingTab === "Hotel"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span onClick={() => setactiveBookingTab("Hotel")}>
                              Hotel
                            </span>
                          </div>
                          <div
                            className={`${
                              activeBookingTab === "Package"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span
                              onClick={() => setactiveBookingTab("Package")}
                            >
                              Package
                            </span>
                          </div>
                          <div
                            className={`${
                              activeBookingTab === "HouseBoat"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span
                              onClick={() => setactiveBookingTab("HouseBoat")}
                            >
                              HouseBoat
                            </span>
                          </div>
                          <div
                            className={`${
                              activeBookingTab === "Shikara"
                                ? "activeDiv"
                                : "deactiveDiv"
                            }`}
                          >
                            <span
                              onClick={() => setactiveBookingTab("Shikara")}
                            >
                              Shikara
                            </span>
                          </div>
                        </div>
                      </div>
                      {activeBookingTab === "Hotel" && (
                        <Space
                          direction="vertical"
                          style={{
                            marginLeft: "11px",
                            marginRight: "11px",
                            marginBottom: "75px",
                          }}
                          size={1}
                        >
                          {filteredhotelBookingList?.length > 0 &&
                            filteredhotelBookingList?.map((v, i) => {
                              return (
                                <Col key={i} span={24} className="my-4">
                                  <HotelBookingCard
                                    mobile={isMobile}
                                    data={v}
                                  />
                                </Col>
                              );
                            })}
                        </Space>
                      )}
                      {activeBookingTab === "Package" && (
                        <Space
                          direction="vertical"
                          style={{
                            marginLeft: "11px",
                            marginRight: "11px",
                            marginBottom: "75px",
                          }}
                          size={1}
                        >
                          {filteredPackageBookingList?.length > 0 &&
                            filteredPackageBookingList?.map((v, i) => {
                              return (
                                <Col key={i} span={24} className="my-4">
                                  <PackageBookingHistoryCard
                                    mobile={isMobile}
                                    data={v}
                                  />
                                </Col>
                              );
                            })}
                        </Space>
                      )}
                      {activeBookingTab === "Shikara" && (
                        <Space
                          direction="vertical"
                          size={1}
                          style={{
                            marginLeft: "11px",
                            marginRight: "11px",
                            marginBottom: "75px",
                          }}
                        >
                          {filteredShikaraBookingList?.length > 0 &&
                            filteredShikaraBookingList?.map((v, i) => {
                              return (
                                <Col key={i} span={24} className="my-4">
                                  <ShikaraBookingCard data={v} />
                                </Col>
                              );
                            })}
                        </Space>
                      )}
                      {activeBookingTab === "HouseBoat" && (
                        <Space
                          direction="vertical"
                          size={1}
                          style={{
                            marginLeft: "11px",
                            marginRight: "11px",
                            marginBottom: "75px",
                          }}
                        >
                          {filteredHouseBoatBookingList?.length > 0 &&
                            filteredHouseBoatBookingList?.map((v, i) => {
                              return (
                                <Col key={i} span={24} className="my-4">
                                  <HouseBoatBookingCard data={v} />
                                </Col>
                              );
                            })}
                        </Space>
                      )}
                    </>
                  )}
                </>
              )}
              <Layout>
                {isDesktop && (
                  <>
                    <Sider className="ml-2 vh-100" theme="light" width={"275"}>
                      <Space direction="vertical" size={"large"}>
                        <div className="pt-3" style={{ textAlign: "center" }}>
                          <h3>
                            {isDesktop && (
                              <FilterAltIcon
                                className="mb-2"
                                fontSize="large"
                              />
                            )}
                            &nbsp;Filters
                          </h3>
                        </div>
                        <div className="p-1 ps-4">
                          <h4>Time Period</h4>
                          <Col className="ps-4">
                            <Row>
                              <div
                                className={`${
                                  timePeriod === "currentMonth"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                              >
                                <span
                                  onClick={() => setTimePeriod("currentMonth")}
                                >
                                  Current month
                                </span>
                              </div>
                              <div
                                className={`${
                                  timePeriod === "currentYear"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                              >
                                <span
                                  onClick={() => setTimePeriod("currentYear")}
                                >
                                  Current year
                                </span>
                              </div>
                              <div
                                className={`${
                                  timePeriod === "All"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                              >
                                <span onClick={() => setTimePeriod("All")}>
                                  All
                                </span>
                              </div>
                            </Row>
                          </Col>
                        </div>
                        <div className="p-1 ps-4">
                          <h4>Booking Type</h4>
                          <Col className="ps-4">
                            <Row>
                              <div
                                className={`${
                                  activeBookingType === "Upcoming"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                              >
                                <span
                                  onClick={() =>
                                    setactiveBookingType("Upcoming")
                                  }
                                >
                                  Upcoming
                                </span>
                              </div>
                              <div
                                className={`${
                                  activeBookingType === "Completed"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                              >
                                <span
                                  onClick={() =>
                                    setactiveBookingType("Completed")
                                  }
                                >
                                  Completed
                                </span>
                              </div>
                            </Row>
                            <Row>
                              <div
                                className={`${
                                  activeBookingType === "cancel"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                                style={{
                                  display:
                                    activeBookingTab === "Hotel" ? "" : "none",
                                }}
                              >
                                <span
                                  onClick={() => setactiveBookingType("cancel")}
                                >
                                  Cancelled
                                </span>
                              </div>
                              <div
                                className={`${
                                  activeBookingType === "All"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                              >
                                <span
                                  onClick={() => setactiveBookingType("All")}
                                >
                                  All
                                </span>
                              </div>
                            </Row>
                          </Col>
                        </div>
                        <div className="p-1 ps-4">
                          <h4>Booking of</h4>
                          <Col className="ps-4">
                            <Row>
                              <div
                                className={`${
                                  activeBookingTab === "Hotel"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                                onClick={() => setactiveBookingTab("Hotel")}
                              >
                                Hotel
                              </div>
                              <div
                                className={`${
                                  activeBookingTab === "Package"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                                onClick={() => setactiveBookingTab("Package")}
                              >
                                Package
                              </div>
                            </Row>
                            <Row>
                              <div
                                className={`${
                                  activeBookingTab === "HouseBoat"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                                onClick={() => setactiveBookingTab("HouseBoat")}
                              >
                                HouseBoat
                              </div>
                              <div
                                className={`${
                                  activeBookingTab === "Shikara"
                                    ? "activeDiv"
                                    : "deactiveDiv"
                                }`}
                                onClick={() => setactiveBookingTab("Shikara")}
                              >
                                Shikara
                              </div>
                            </Row>
                          </Col>
                        </div>
                      </Space>
                    </Sider>
                    {isDesktop &&
                      loading &&
                      hotelBookingList &&
                      hotelBookingList?.length +
                        packagebookingHistoryList?.length +
                        shikaraBookingList?.length +
                        houseboatBookingList?.length <=
                        0 && (
                        <div
                          style={{
                            marginBottom: "200px",
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                            top: "0%",
                            left: "50%",
                          }}
                        >
                          <img
                            src={couple}
                            alt="mySvgImage"
                            style={{
                              marginTop: "60px",
                              width: "400px",
                              // display: "block",
                              // marginLeft: "auto",
                              // marginRight: "auto",
                              // marginBottom: "40px",
                            }}
                          />

                          <h5
                            style={{
                              textAlign: "center",
                            }}
                          >
                            You have not made your first Booking Yet !
                          </h5>
                        </div>
                      )}
                    <Flex
                      justify="flex-start"
                      align="flex-start"
                      style={{ width: "100%" }}
                    >
                      {/* <Content> */}

                      {activeBookingTab === "Hotel" && (
                        <Space
                          direction="vertical"
                          size={1}
                          style={{ width: "99%" }}
                          className="ms-5 me-5"
                        >
                          {filteredhotelBookingList?.length > 0 &&
                            filteredhotelBookingList?.map((v, i) => {
                              return (
                                <Col key={i} span={24} className="my-4">
                                  <HotelBookingCard
                                    mobile={isMobile}
                                    data={v}
                                  />
                                </Col>
                              );
                            })}
                        </Space>
                      )}
                      {activeBookingTab === "Package" && (
                        <Space
                          direction="vertical"
                          size={1}
                          style={{ width: "99%" }}
                          className="ms-5 me-5"
                        >
                          {filteredPackageBookingList?.length > 0 &&
                            filteredPackageBookingList?.map((v, i) => {
                              return (
                                <Col key={i} span={24} className="my-4">
                                  <PackageBookingHistoryCard
                                    mobile={isMobile}
                                    data={v}
                                  />
                                </Col>
                              );
                            })}
                        </Space>
                      )}
                      {activeBookingTab === "Shikara" && (
                        <Space
                          direction="vertical"
                          size={1}
                          style={{ width: "99%" }}
                          className="ms-5 me-5"
                        >
                          {filteredShikaraBookingList?.length > 0 &&
                            filteredShikaraBookingList?.map((v, i) => {
                              return (
                                <Col key={i} span={24} className="my-4">
                                  <ShikaraBookingCard data={v} />
                                </Col>
                              );
                            })}
                        </Space>
                      )}
                      {activeBookingTab === "HouseBoat" && (
                        <Space
                          direction="vertical"
                          size={1}
                          style={{ width: "99%" }}
                          className="ms-5 me-5"
                        >
                          {filteredHouseBoatBookingList?.length > 0 &&
                            filteredHouseBoatBookingList?.map((v, i) => {
                              return (
                                <Col key={i} span={24} className="my-4">
                                  <HouseBoatBookingCard data={v} />
                                </Col>
                              );
                            })}
                        </Space>
                      )}
                      {/* </Content> */}
                    </Flex>
                  </>
                )}
              </Layout>
            </>
          )}
        </>
      ) : tabRecord == 3 ? (
        <>
          <div className="mobile2ndHeadGos">
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => setTabRecord(1)}
            />{" "}
            &nbsp; Gos Offers
          </div>
          <div className="divScrollRow p-3">
            <span
              onClick={() => filterOfferData("all")}
              className={`mobile-bedge ${
                activeOfferTab == "all" ? "bactive" : ""
              }`}
            >
              All
            </span>
            <span
              onClick={() => filterOfferData("Hotel")}
              className={`mobile-bedge ${
                activeOfferTab == "Hotel" ? "bactive" : ""
              }`}
            >
              Hotels
            </span>
            <span
              onClick={() => filterOfferData("Package")}
              className={`mobile-bedge ${
                activeOfferTab == "Package" ? "bactive" : ""
              }`}
            >
              Packages
            </span>
            <span
              onClick={() => filterOfferData("HouseBoat")}
              className={`mobile-bedge ${
                activeOfferTab == "HouseBoat" ? "bactive" : ""
              }`}
            >
              Houseboat
            </span>
            <span
              onClick={() => filterOfferData("Shikara")}
              className={`mobile-bedge ${
                activeOfferTab == "Shikara" ? "bactive" : ""
              }`}
            >
              Shikara
            </span>
            {/* <span className="mobile-bedge ">Bank offers</span> */}
          </div>
          <Row>
            <div
              className="divScrollCol px-3"
              style={{ paddingBottom: "75px" }}
            >
              {offerData?.map((v, i) => {
                return (
                  <Col key={i} span={24} className="my-4">
                    <OfferCards data={v}  copiedCode={copiedCode} setcopiedCode={setcopiedCode}/>
                  </Col>
                );
              })}
            </div>
          </Row>
        </>
      ) : tabRecord == 4 ? (
        <>
          <div className="mobile2ndHeadGos">
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => setTabRecord(1)}
            />
            &nbsp; Gos Care Support
          </div>
          <Row>
            <Col>
              <div className="support-text">
                <FontAwesomeIcon icon={faHeadset} color="#3293FC" /> &nbsp; Need
                Help ?
              </div>
            </Col>
          </Row>
          <Row justify={"start"}>
            <Col>
              <div className="support-text">
                <FontAwesomeIcon icon={faSquarePhone} color="#3293FC" /> &nbsp;{" "}
                <a
                  href="tel:8286882878"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  8286882878
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="support-text">
                <FontAwesomeIcon icon={faEnvelope} color="#3293FC" /> &nbsp;{" "}
                <a
                  href="mailTo:customercare@godsownstay.com"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  customercare@godsownstay.com
                </a>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingBottom: "100px" }}>
            <Col>
              {/* <div className="support-text">
                      <FontAwesomeIcon
                        icon={faFileCircleMinus}
                        color="#3293FC"
                      />{" "}
                      &nbsp; Terms & Conditions
                    </div>  */}
              <div
                className=""
                style={{
                  textAlign: "left",
                  fontWeight: "400",
                  // padding: "10%",
                  paddingTop: "5%",
                  fontSize: "13px",
                }}
              >
                <ul className="list-group pb-5">
                  <li className="list-group-item fw-bold">
                    Eligility of customer for GOS E-coupon?
                  </li>
                  <p className="list-group-item-text mx-3">
                    Customers who have received E- Coupon from Gos are eligible.
                    Repeated loyal customers will get a call or email from
                    GosCare Support Team to get Additional E- Coupon.
                  </p>
                  <li className="list-group-item fw-bold">
                    How to redeem your Coupon?
                  </li>
                  <p className="list-group-item-text mx-3">
                    Select service from Godsownstay.com to apply the E- Coupon
                    for discount, copy and apply your E-Coupon at Gos Checkout
                    page.
                  </p>
                  <li className="list-group-item fw-bold">
                    Could I get 100% money back on cancellation on Gos?
                  </li>
                  <p className="list-group-item-text mx-3">
                    Yes , Gos has introduced Gos Assured Stay through which a
                    customer can cancel there booking anytime without losing any
                    fees. It is 100% refundable .T&C Apply
                  </p>
                  <li className=" list-group-item fw-bold ">
                    GosCare Support available 24 &#10005; 7 Gos care Support.
                  </li>
                  <li className="list-group-item fw-bold ">
                    Gos usually accepts various payment methods, including
                    credit/debit cards, Net Banking , EMI and digital wallets
                    and UPI . Check the accepted payment methods during the
                    booking process.
                  </li>
                  <li className="list-group-item fw-bold ">
                    E- Coupon Expire 90 days after issuance Coupon code is valid
                    for Multiple use.
                  </li>
                  <li className="list-group-item fw-bold ">
                    Whether you need to make a new reservation or modify an
                    existing booking, the customer support team can assist you
                    in navigating the booking process seamlessly.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}

      <BottomFooter>
        <div className="footer">
          <div className="d-flex align-items-center justify-content-around">
            <div
              className={tabRecord === 1 ? active : deActive}
              onClick={() => {
                setTabRecord(1);
                dispatch(setActiveFooterTab(0));
                nav("/");
              }}
            >
              <FontAwesomeIcon
                className="footericon"
                style={{ fontSize: "1.5rem" }}
                icon={faHome}
              />
              <p style={{ fontSize: "0.7rem" }} className="maxWidth">
                Home
              </p>
            </div>
            <div
              className={tabRecord === 2 ? active : deActive}
              onClick={() => {
                setTabRecord(2);
                dispatch(setActiveFooterTab(0));
                // nav("/bookings");
              }}
            >
              <FontAwesomeIcon
                className="footericon"
                style={{ fontSize: "1.5rem" }}
                icon={faBriefcase}
              />
              <p className="maxWidth">My Booking</p>
            </div>

            <div
              className={tabRecord === 3 ? active : deActive}
              onClick={() => {
                setTabRecord(3);
              }}
            >
              <Discount
                style={{ height: "25.5px", width: "25.5px" }}
                stroke={tabRecord === 3 ? "#0551A6" : "#00000029"}
              />

              <p className="maxWidth">Discount</p>
            </div>
            <div
              className={tabRecord === 4 ? active : deActive}
              onClick={() => {
                setTabRecord(4);
                dispatch(setActiveFooterTab(0));
              }}
            >
              <HeadsetMicIcon />

              <p className="maxWidth">Gos care</p>
            </div>
          </div>
        </div>
      </BottomFooter>
    </>
  );
};
