import { Button, Modal } from "antd";
export const DeleteModel = ({
  deleteModal,
  getTableData,
  onCloseDelModal,
  deleteTableData,
})=> {
    return (
      <Modal
        title="Are you sure! Do you want to delete?"
        open={deleteModal}
        onCancel={onCloseDelModal}
        getTableData={getTableData}
        footer={null}
      >
        <div className="deleteBtn">
            <Button className="deleteCancelBtn" onClick={onCloseDelModal}>
              <span style={{ cursor:"pointer" }}>Cancel</span>
            </Button>
            <Button className="deleteOkBtn" onClick={deleteTableData}>
              <span style={{ cursor:"pointer" }}>Ok</span>
            </Button>
        </div>
      </Modal>
    )
}