/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from "react";
import logo from "../../../assets/landingPageImages/cardIcons/credit-card-pay-pal-icons.png";
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { footarLocationForAll } from "../../../services/utils/userUtils/landingPage";
import { useDispatch } from "react-redux";
import {
  setPlaceData,
  setSearchToggle,
} from "../../../reduxData/sliceData/searchCardReducer";

function HomePageFooter({ destinationData }) {
  const nav = useNavigate();
  let dispatch = useDispatch();
  const footerDataFun = async () => {
    const res = await footarLocationForAll();
    dispatch(setPlaceData(res));
  };

  function chunkArray(arr, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  }

  const arrayOfArrays = chunkArray(destinationData, 5);

  useEffect(() => {
    footerDataFun();
  }, []);

  return (
    <MDBFooter
      bgColor="dark"
      className="text-center text-lg-left linkUnderline"
    >
      <MDBContainer className="p-3">
        <MDBRow style={{ textAlign: "left" }} className="media-text-center">
          <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
            <h4 className="text-light mb-0 h-35" style={{ fontSize: "1.4rem" }}>
              {" "}
              Godsownstay.com
            </h4>

            <ul className="list-unstyled mb-0 ">
              <li>
                <Link to="/aboutUsLink" className="text-light">
                  <p className="style-gray h5-5">About us</p>
                </Link>
              </li>
              <li>
                <Link to="/feedbacklink" className="text-light">
                  <p className="style-gray h5-5">Contact</p>
                </Link>
              </li>
              <li>
                <a href="#" className="text-light">
                  <p className="style-gray h5-5   ">Press</p>
                </a>
              </li>
              <li>
                <a href="#!" className="text-light">
                  <p className="style-gray h5-5   ">Travel Blog</p>
                </a>
              </li>
              <li>
                <a href="/sitemap" className="text-light">
                  <p className="style-gray h5-5   ">Sitemap</p>
                </a>
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg="3" md="6" className="mb-4 mb-md-0 ">
            <h4
              className="text-light mb-0  h-35"
              style={{ fontSize: "1.4rem" }}
            >
              Partner With Us
            </h4>

            <ul className="list-unstyled">
              <li>
                <Link to="/feedbacklink" className="text-light">
                  <p className="style-gray h5-5">Be our Franchisee</p>
                </Link>
              </li>
              <li>
                <Link to="/feedbacklink" className="text-light">
                  <p className="style-gray h5-5">Travel Agent</p>
                </Link>
              </li>
              <li>
                <Link to="/feedbacklink" className="text-light">
                  <p className="style-gray h5-5">Corporate Enquiries</p>
                </Link>
              </li>
            </ul>
          </MDBCol>

          <MDBCol
            lg="3"
            md="6"
            className="mb-4 mb-md-0 media-text-center"
            style={{ textAlign: "left" }}
          >
            <h4 className="text-light mb-0 h-35" style={{ fontSize: "1.4rem" }}>
              Policies
            </h4>

            <ul className="list-unstyled mb-0">
              <li>
                <Link to="/termsandconditionslink" className="text-light">
                  <p className="style-gray h5-5">Terms And Conditions</p>
                </Link>
              </li>
              <li>
                <Link to="/privacyandpolicylink" className="text-light">
                  <p className="style-gray h5-5   ">Privacy Policy</p>
                </Link>
              </li>
              <li>
                <Link to="/cancellationpolicylink" className="text-light">
                  <p
                    className="style-gray h5-5   "
                    style={{ textDecoration: "none" }}
                  >
                    Cancellation Policy
                  </p>
                </Link>
              </li>
            </ul>
          </MDBCol>

          <MDBCol
            lg="3"
            md="6"
            className="mb-4 mb-md-0"
            style={{ textAlign: "left" }}
          >
            <h4
              className="text-light mb-0 media-text-center h-35"
              style={{ fontSize: "1.4rem" }}
            >
              We accept:
            </h4>

            <ul className="list-unstyled">
              <li>
                <img
                  src={logo}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </li>
            </ul>
          </MDBCol>
          <h5 className="text-light mb-1 h-35" style={{ fontSize: "1.4rem" }}>
            Book our hotels in all these cities
          </h5>

          {arrayOfArrays.slice(0, 8).map((v, i) => {
            return (
              <MDBCol
                lg="3"
                md="6"
                className="mb-4 mb-md-0"
                style={{ textAlign: "left" }}
                key={i}
              >
                <ul className="list-unstyled">
                  {v.map((u, j) => {
                    return (
                      <li key={j}>
                        <a className="text-light ">
                          <p
                            className="style-gray h5-5 textAlignMedia "
                            onClick={() => {
                              dispatch(setSearchToggle(1));
                              // nav(`/hotel/${u.destinationName}`, {
                              //   state: { destination: u.destinationName },
                              // });
                              nav(
                                u.destinationName == "Kochi"
                                  ? `/hotels/search-hotels-in-${u.destinationName}/cochin-hotels.html`
                                  : u.destinationName == "Kozhikode"
                                    ? `/hotels/search-hotels-in-calicut/${u.destinationName}-hotels.html`
                                    : `/hotels/search-hotels-in-${u.destinationName}-hotels.html`,
                                {
                                  state: {
                                    destination: u.destinationName,
                                  },
                                }
                              );
                            }}

                          // navigate(
                          //   v.destinationName == "Kochi"
                          //     ? `/${routePage}/search-hotels-in-cochin/${v.destinationName}-hotels.html`
                          //     : `/${routePage}/search-hotels-in-${v.destinationName}-hotels.html`,
                          //   {
                          //     state: {
                          //       destination: v.destinationName,
                          //     },
                          //   }
                          // );
                          >
                            Hotels in {u.destinationName}
                          </p>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBContainer>
      <MDBContainer style={{ textAlign: "left" }}>
        <MDBRow className="media-text-center">
          <MDBCol
            lg="12"
            md="12"
            className="mb-4 mb-md-0 media-text-center"
            style={{ textAlign: "right" }}
          >
            <h4 className="text-light mb-0 h-35" style={{ fontSize: "1.4rem" }}>
              Connect With Us
            </h4>

            <ul className="list-unstyled mb-8">
              <Box>
                <a
                  href="https://www.facebook.com/profile.php?id=61552727756239"
                  className="text-light"
                >
                  <FacebookIcon
                    className="icons-size"
                    onMouseOver={({ target }) =>
                      (target.style.color = "#1a75ff")
                    }
                    onMouseOut={({ target }) => (target.style.color = "white")}
                  />
                </a>
                <a
                  href="https://twitter.com/godsownstay"
                  className="text-light"
                >
                  <TwitterIcon
                    size="large"
                    className="icons-size"
                    onMouseOver={({ target }) =>
                      (target.style.color = "#1a75ff")
                    }
                    onMouseOut={({ target }) => (target.style.color = "white")}
                  />
                </a>
                <a
                  href="https://www.instagram.com/godsownstay.com_/"
                  className="text-light"
                >
                  <InstagramIcon
                    onMouseOver={({ target }) => (target.style.color = "red")}
                    onMouseOut={({ target }) => (target.style.color = "white")}
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCtaiULpb0p0PqJbXZgaY3xw"
                  className="text-light"
                >
                  <YouTubeIcon
                    size="large"
                    onMouseOver={({ target }) => (target.style.color = "red")}
                    onMouseOut={({ target }) => (target.style.color = "white")}
                  />
                </a>
              </Box>
            </ul>
          </MDBCol>

          <MDBCol lg="12" md="12" className="mb-4 mb-md-0">
            <h4 className="text-light mb-0 h-35" style={{ fontSize: "1.4rem" }}>
              Why Choose GodsOwnStay.com
            </h4>

            <ul className="list-unstyled">
              <span className="text-light mb-0">
                <p
                  className="style-gray h5-5   "
                  style={{ textAlign: "justify" }}
                >
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Godsownstay.com is India's fastest growing online travel
                  booking brand which enables rooms booking in budget hotels by
                  clicking Nearby. Besides, customer can book rooms using our
                  booking engine search bar based on Destnation, City, Landmark
                  or Hotel name. Gos assures you a standardlised stay at
                  certified properties. We are also providing 24X7 Gos care
                  support. Gos is best for easier payment and fastest refund
                  process. Gos is first in introducing virtual travel booking
                  currency -Gos money + in the industry.
                </p>
              </span>
            </ul>
          </MDBCol>
          <p style={{ borderBottom: "1px dotted #fff" }}></p>
          <MDBCol lg="12" md="12" className="mb-4 mb-md-0">
            <ul className="list-unstyled ">
              <span className="text-light mt-0">
                <p
                  className="style-gray h5-5    pt-2"
                  style={{ textAlign: "left" }}
                >
                  ©{new Date().getFullYear()} Godsownstay.com All rights
                  reserved.
                </p>
              </span>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
}

export default HomePageFooter;
