import * as yup from "yup";
import { RegexValidation } from "../../../constants/regexValidation/RegexValidation";

export const feedbackSchema = yup.object({
  name: yup.string().required("Enter name "),
  message: yup.string().required("Enter Message"),
  email: yup
    .string()
    .required("Enter Email")
    .matches(RegexValidation.onlyEmail, RegexValidation.onlyEmailMsg),
  phoneNumber: yup
    .string()
    .required("Enter Mobile Number")
    .matches(RegexValidation.mobile, RegexValidation.mobileMsg),
});
