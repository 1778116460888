import React from "react";
// import backgroundlogo from "../../assets/landingPageImages/mainlogoGodsownstay1.png"
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import LoginForm from "../../components/forms/loginForm";


function LoginPage() {
  const myStyle =
   {
    backgroundImage:
      `url('https://www.godsownstay.com/resources/images/login-bg.jpg')`,
    height: "100vh",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div style={myStyle}>
      <LoginForm />
      {/* <LoginRoute /> */}
    </div>
  );
}


export default LoginPage;
