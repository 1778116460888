import BtnComp from "../../../components/atoms/buttonComponent/BtnComp";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import React, { useEffect, useMemo, useState } from "react";
import { faRightLong, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { Col, Row } from "antd";
import { useDropzone } from "react-dropzone";

// import Dropzone from "react-dropzone-uploader";

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 5,
  borderRadius: 2,
  borderColor: "#04809b",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#04809b",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#04809b",
};

const acceptStyle = {
  borderColor: "#04809b",
};

const rejectStyle = {
  borderColor: "#04809b",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function GalleryComponent() {
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/*": [],
      },
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const thumbs = files.map((file) => (
    <Box
      style={thumb}
      key={file.name}
      sx={{
        "&:hover": {
          borderColor: "rgba(255,240,10,0.8)",
        },
      }}
    >
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
          alt=""
        />
      </div>
    </Box>
  ));

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <>
      <Row className="page-back">
        <p onClick={"backBtnFun"} style={{ cursor: "pointer" }}>
          <KeyboardDoubleArrowLeftIcon style={{ fontSize: "1.2rem" }} />
          <span style={{ paddingTop: "2px", cursor: "pointer" }}>Back</span>
        </p>
      </Row>
      <Row>
        <Col span={16} offset={4}>
          <section className="container mt-4">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <Row>
                <Col>
                  <Row>
                    <Col span={7} offset={12}>
                      <FontAwesomeIcon icon={faUpload} color="#04809b" />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={9} offset={8} className="my-2">
                      <h2
                        style={{
                          fontSize: "26px",
                          fontWeight: 400,
                          color: "#04809b",
                        }}
                      >
                        Upload Files
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        Click here to browse files to upload, you can drag and
                        drop files too.
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
          </section>
        </Col>
      </Row>

      <Row>
        <Col>
          <BtnComp
            btnName="Save"
            bg_color="#1aa206"
            color="#fff"
            endIcon={faRightLong}
            btnClickFun={""}
            pr="5px"
            className="mb-5"
          />
        </Col>
      </Row>
    </>
  );
}
export default GalleryComponent;
