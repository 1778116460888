import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hotelReducer from "../sliceData/hotelReducer";
import packageReducer from "../sliceData/packageReducer";
import userDetailsReducer from "../sliceData/userDetailsReducer";
import thunk from "redux-thunk";
import searchCardReducer from "../sliceData/searchCardReducer";
import  homeSlice  from "../sliceData/homeReducer";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  hotelAllData: hotelReducer,
  packageAllData: packageReducer,
  userDetails: userDetailsReducer,
  searchCardData: searchCardReducer,
  homeData: homeSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
const persistor = persistStore(store);
export { store, persistor };
