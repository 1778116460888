import * as yup from "yup";
import { RegexValidation } from "../../../../constants/regexValidation/RegexValidation";

const bookingFormSchema = yup.object({
  firstName: yup
    .string()
    .required("Please enter First Name")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg)
    .min(3, "First Name must be at least 3 letters.")
    .max(30, "First Name must be upto 30 letters."),

  lastName: yup
    .string()
    .required("Please enter Last Name")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg)
    .min(1, "Last Name must be at least 1 letters.")
    .max(30, "Last Name must be upto 30 letters."),

  whatsAppNo: yup
    .string()
    .required("Enter Mobile No.")
    .matches(RegexValidation.mobile, RegexValidation.mobileMsg)
    .min(10, "Please enter 10 digits number")
    .max(10, "Mobile number can not be more than 10 digits"),

  email: yup
    .string()
    .required("Enter Email")
    .matches(RegexValidation.onlyEmail, RegexValidation.onlyEmailMsg)
    .min(6, "Email must be at least 6 letters.")
    .max(30, "Email must be upto 30 letters."),
});
const bookingFormBusinessSchema = yup.object({
  firstName: yup
    .string()
    .required("Please enter First Name")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg)
    .min(3, "First Name must be at least 3 letters.")
    .max(30, "First Name must be upto 30 letters."),

  lastName: yup
    .string()
    .required("Please enter Last Name")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg)
    .min(1, "Last Name must be at least 1 letters.")
    .max(30, "Last Name must be upto 30 letters."),

  whatsAppNo: yup
    .string()
    .required("Enter Mobile No.")
    .matches(RegexValidation.mobile, RegexValidation.mobileMsg)
    .min(10, "Please enter 10 digits number")
    .max(10, "Mobile number can not be more than 10 digits"),

  // email: yup
  //   .string()
  //   .required("Enter Email")
  //   .matches(RegexValidation.onlyEmail, RegexValidation.onlyEmailMsg)
  //   .min(6, "Email must be at least 6 letters.")
  //   .max(30, "Email must be upto 30 letters."),

  // gstnNumber: yup
  //   .string()
  //   .required("Please enter GST Number")
  //   .matches(RegexValidation.GSTN, RegexValidation.GSTNMsg)
  //   .max(15, "GST Number must be upto 15 letters."),
  componyName: yup
    .string()
    .required("Please enter Company Name")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg)
    .min(3, "Hotel Company Name must be at least 3 letters.")
    .max(30, "Hotel Company Name must be upto 30 letters."),
  // companyAddress: yup
  //   .string()
  //   .required("Please enter Company Address")
  //   .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg)
  //   .min(3, "Hotel  Company Address must be at least 3 letters.")
  //   .max(30, "Hotel  Company Address must be upto 30 letters."),
  businessEmailId: yup
    .string()
    .required("Enter Business Email")
    .matches(RegexValidation.onlyEmail, RegexValidation.onlyEmailMsg)
    .min(6, " Business Email must be at least 6 letters.")
    .max(30, " Business Email must be upto 30 letters."),
  // companyPhoneNo: yup
  //   .string()
  //   .required("Enter Company Phone No.")
  //   .matches(RegexValidation.mobile, RegexValidation.mobileMsg)
  //   .min(10, "Please enter 10 digits Company Phone no.")
  //   .max(10, "Company Phone number can not be more than 10 digits"),
});

export { bookingFormBusinessSchema, bookingFormSchema };
