/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect,
  // useRef,
  useState,
} from "react";
// import flashsale from "../../../assets/flashsales.gif";
import blast from "../../../assets/blast2.gif";
import { ReactComponent as OfferIco } from "../../../assets/offer2.svg";

// import Maldives from '../../../assets/landingPageImages/BestSellingDestination/maldives.jpg'
import MainUserCard from "../../../components/atoms/card/userCards/mainpageUserCard";
import { Col, Row, Grid, Skeleton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
// import OfferCards from "../../../components/atoms/card/offerCards/OfferCards";
// import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { getLatestOffer } from "../../../services/utils/userUtils/hotelutils";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setActiveFooterTab } from "../../../reduxData/sliceData/homeReducer";
import OfferCardsHomePage from "../../../components/homepage/OfferCardsHomePage";
import "react-lazy-load-image-component/src/effects/blur.css";

const config = {
  rootMargin: "0px 0px 0px 0px",
  thresold: 0,
};

function HomePageBody({ destinationData, offerData, setLoading }) {

  const nav = useNavigate();
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const { md } = useBreakpoint();
  const [copiedCode, setcopiedCode] = useState("");

  const [fleshTime, setfleshTime] = useState("Aug 02, 2023 20:30:00");
  const [timeObj, setTimeObj] = useState({
    offerDate: new Date(fleshTime),
    time: {
      hours: "00",
      minutes: "00",
      seconds: "00",
    },
  });

  useEffect(() => {
    fleshsaleFunction();
  }, []);

  useEffect(() => {
    startTimeFun();
  }, [fleshTime]);
  useEffect(() => {
    return () => {
      dispatch(setActiveFooterTab(0));
    };
  }, []);

  useEffect(() => {
    let observer = new window.IntersectionObserver(function (entries, self) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImages(entry.target);
          self.unobserve(entry.target);
        }
      });
    }, config);

    const imgs = document.querySelectorAll("[data-src]");
    imgs.forEach((img) => {
      observer.observe(img);
    });
    return () => {
      imgs.forEach((img) => {
        observer.observe(img);
      });
    };
  }, []);

  useEffect(() => {
    scrollToTop();
    return () => scrollToTop();
  }, []);

  function loadImages(image) {
    image.src = image.dataset.src;
  }

  const fleshsaleFunction = () => {
    getLatestOffer().then((res) => {
      setfleshTime(res?.data?.data);
      //TODO: Need to fix below 2 line
      //const date = new Date(res.data.data.slice(0, 22));
      // eslint-disable-next-line import/namespace
      let duration = moment.duration(moment(res?.data?.data).diff(moment()));
      //let days = Math.floor(duration.asDays());
      let hours = duration.hours();
      let minutes = duration.minutes();
      let seconds = duration.seconds();
      setTimeObj(() => ({
        time: { hours, minutes, seconds },
        offerDate: res?.data?.data,
      }));
    });
  };

  const startTimeFun = () => {
    const interval = setInterval(() => {
      const secondsDifference = moment(timeObj.offerDate).diff(
        moment(),
        "seconds"
      );
      if (secondsDifference < 0) {
        clearInterval(interval);
      } else {
        const duration = moment.duration(secondsDifference, "seconds");
        const hours = duration.hours().toString().padStart(2, "0");
        const minutes = duration.minutes().toString().padStart(2, "0");
        const seconds = duration.seconds().toString().padStart(2, "0");
        setTimeObj((prevState) => ({
          ...prevState,
          time: { hours, minutes, seconds },
        }));
      }
    }, 1000);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    document.getElementById("homePageScroll")?.scrollIntoView({ block: "end" });
  };

  const navigateToPage = (destinationName) => {
    scrollToTop();
    setTimeout(function () {
      nav(
        destinationName == "Kochi"
          ? `/hotels/search-hotels-in-${destinationName}/cochin-hotels.html`
          : destinationName == "Kozhikode"
            ? `/hotels/search-hotels-in-calicut/${destinationName}-hotels.html`
            : `/hotels/search-hotels-in-${destinationName}-hotels.html`,
        {
          state: {
            destination: destinationName,
          },
        }
      )
    }, 0);
  };

  return (
    <div className="HomePageHeaderContainer" id="homePge">
      <Row
        className="Timer"
        justify={"space-around"}
        style={{ marginTop: "10%", marginBottom: "30px" }}
      >
        <Col span={12}>
          <p className="textflexhGos">Gos</p>
          {/* <img src={flashsale} className="gos-img" alt="" /> */}
          <img
            src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/gif/flashsales.gif`}
            className="gos-img"
            alt=""
          />
        </Col>
        <Col span={11} className="endsIn">
          <Row>
            <img src={blast} className="blaststyle" alt="" />
          </Row>
          <Row>
            <p className="textflexhGos end-0 ">Ends in</p>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <div className="d-flex align-items-center justify-content-end">
                <div className="m-1">
                  <h1 className="countDown">{timeObj.time.hours}</h1>
                  <p className="countDowntext">Hours</p>
                </div>
                <div className="m-1">
                  <h1 className="countDown">{timeObj.time.minutes} </h1>
                  <p className="countDowntext">Minutes</p>
                </div>
                <div className="m-1">
                  <h1 className="countDown">{timeObj.time.seconds}</h1>
                  <p className="countDowntext">Seconds</p>
                </div>
                {/* <h1 className="countDown">
                  {timeObj.time.hours} {timeObj.time.minutes}{' '}
                  {timeObj.time.seconds}
                </h1>
                <p className="countDowntext">Hours Minutes Seconds</p> */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={18}>
              <p className="into-content-of">
                <OfferIco
                  className="iconHoverBlue"
                  style={{
                    height: "25.5px",
                    width: "25.5px",
                    marginRight: "2px",
                  }}
                />{" "}
                Gos Offers for you
              </p>
            </Col>
            <Col span={6} className="justAg-center">
              <Link
                className="into-content-all"
                style={{
                  textAlign: "right",
                  // fontSize: '2rem',
                  color: "#1675df",
                }}
                role="button"
                to="/coupons"
              >
                View All <FontAwesomeIcon icon={faGreaterThan} />
              </Link>
            </Col>
          </Row>
          {offerData && offerData?.length > 0 && (
            <OfferCardsHomePage
              offerData={offerData}
              setLoading={setLoading}
              copiedCode={copiedCode}
              setcopiedCode={setcopiedCode}
            />
          )}
        </Col>
      </Row>
      <Row style={{ width: "100%", paddingBottom: "1.5%" }}>
        <Col span={24}>
          <MainUserCard />
        </Col>
      </Row>
      <Row className="pb-2 pt-4">
        <p className="into-content-of py-2">Gos Best Selling Destinations</p>
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "2%",
        }}
      >
        <Row gutter={[15, 15]} style={{ width: "100%" }} className="scroll-div">
          {!destinationData
            ? [...new Array(6)].forEach((i) => {
              return (
                <Skeleton.Image
                  className="destinationSkeleton "
                  key={i}
                  active
                />
              );
            })
            : destinationData.slice(0, !md ? 4 : 6).map((v, i) => {
              return (
                <Col lg={8} md={12} sm={12} xs={12} key={i}>
                  <div className="content hover01 ">
                    {!v.destinationImage ? (
                      <div className="card-destination">
                        <Skeleton.Image
                          active
                          className="destinationSkeleton"
                        />
                      </div>
                    ) : (
                      <span
                        onClick={() => navigateToPage(v.destinationName)}
                      >
                        {/* <figure> */}
                        <div className="card-destination">
                          <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/destination/${v?.destinationImage}`}
                            alt=""
                            className=""
                            style={{ height: "100%", width: "100%" }}
                          />
                        </div>
                        {/* </figure> */}

                        <div className="card-text1 ">
                          <h4>{v.destinationName}</h4>
                        </div>
                      </span>
                    )}
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>
    </div>
  );
}

export default HomePageBody;
