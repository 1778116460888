/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick.css";
import "./index.css";
import "./index.css";
import React, { useEffect, useState } from "react";
import SearchCard from "../../../components/atoms/card/userCards/SearchCard";
import { ReactComponent as Hotelimgc } from "../../../assets/hoteliconC.svg";
import { ReactComponent as PackageC } from "../../../assets/hotelimg2.svg";
import { ReactComponent as HouseboatC } from "../../../assets/hotelimg1.svg";
import { ReactComponent as Boatico } from "../../../assets/boat3.svg";

import { Link, Typography } from "@material-ui/core";
import { Col, Row, Grid, Avatar, Flex, Space } from "antd";
import { useNavigate } from "react-router-dom";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CaretDownOutlined } from "@ant-design/icons";
import DropDownComp from "../../../components/atoms/dropdownComponent/DropDownComp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  faRightFromBracket,
  faBriefcase,
  faCircleUser,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";
import "../homePageHeader/carousel.css";
import { useDispatch } from "react-redux";
import { setHotel } from "../../../reduxData/sliceData/userDetailsReducer";
import { setSearchToggle } from "../../../reduxData/sliceData/searchCardReducer";
import Skeleton from "@mui/material/Skeleton";
import { getProfileImage } from "../../../services/utils/userUtils/hotelutils/otpverification";

function HomePageHeader({
  LoginClickFun,
  headerDisplay,
  destinationData,
  userDetails,
  logoutFun,
  loading,
}) {

  const [destinationTopList, setDestinationTopList] = useState([]);
  const { useBreakpoint } = Grid;
  const { md, lg } = useBreakpoint();
  const nav = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [routePage, setroutePage] = useState("hotels");
  const navigate = useNavigate();
  const [logoutDropdown, setlogoutDropdown] = useState(false);
  const [userProfileData, setUserProfileData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const des = destinationData.filter((o) => o.isSelected);
    setDestinationTopList(des);
  }, [destinationData]);

  useEffect(() => {
    checkDestination();
    scrollToTop();
  }, []);

  useEffect(() => {
    const getUserProfileImage = () => {
      getProfileImage(userDetails?.id).then((res) => {
        setUserProfileData(res.data.response);
      });
    };
    if (userDetails?.id) {
      getUserProfileImage();
    }
  }, [userDetails]);

  const locateNearBy = () => {
    dispatch(setSearchToggle(1));

    if (!cityName) {
      alert(
        "To see hotels in your area, please grant location access through your browser settings. This will allow us to provide a personalized hotel search experience based on your location."
      );
    } else {
      navigateToPage(cityName, "nearby");
    }
  }

  const [cityName, setCityName] = useState();

  function checkDestination() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const currentLat = position.coords.latitude;
        const currentLng = position.coords.longitude;
        if (currentLat && currentLng) {
          const response = await fetch(
            `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=&location=${currentLng},${currentLat}`
          );

          const data = await response.json();
          setCityName(data?.address?.City);
        }
      });
    }
  }

  const scrollToTop = () => {
    document.getElementById("homePageScroll")?.scrollIntoView({ block: "end" });
  };

  const navigateToPage = (destinationName, destination) => {
    scrollToTop();
    setTimeout(function () {

      let url =  destinationName == "Kochi" ? `/${routePage}/search-hotels-in-${destinationName}/cochin-hotels.html`
               : destinationName == "Kozhikode" ? `/${routePage}/search-hotels-in-calicut/${destinationName}-hotels.html`
                                                  : `/${routePage}/search-hotels-in-${destinationName}-hotels.html`

      navigate(url, { state: { destination: destination } });
    }, 100);
  };

  return (
    <div>
      <div className="headerimg" id="homePageScroll">
        <img
          src={
            md || lg
              ? `${process.env.REACT_APP_S3_BUCKET_URL}/images/background/w12.png`
              : `${process.env.REACT_APP_S3_BUCKET_URL}/images/background/w13.png`
          }
          alt=""
          className="waveimg"
        />
      </div>
      <div className="mainheaderdiv headercontent">
        <Row className="headerrow" style={{ width: "90%" }} justify="end">
          <Col>
            {userDetails?.name ? (
              <>
                <div
                  style={{ color: "white" }}
                  onClick={() => setlogoutDropdown(!logoutDropdown)}
                >
                  <div className="logoutdropdown">
                    Welcome {userProfileData?.firstName} &nbsp;
                    <DropDownComp
                      placement={"bottomRight"}
                      arrow={true}
                      icon={true}
                      open={logoutDropdown}
                      overlayStyle={{
                        marginTop: "50px",
                        marginLeft: "45px",
                      }}
                      DropOptions={[
                        <>
                          <Flex gap={5} vertical>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => nav("/user-profile")}
                            >
                              <FontAwesomeIcon
                                style={{ color: "#164888" }}
                                icon={faCircleUser}
                              />{" "}
                              &nbsp;My Profile
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => nav("/bookings")}
                            >
                              <FontAwesomeIcon
                                style={{ color: "#164888" }}
                                icon={faBriefcase}
                              />{" "}
                              &nbsp;My Bookings
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => nav("/feedbacklink")}
                            >
                              <FontAwesomeIcon
                                style={{ color: "#164888" }}
                                icon={faHeadset}
                              />{" "}
                              &nbsp;Contact Us
                            </span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={logoutFun}
                            >
                              <FontAwesomeIcon
                                style={{ color: "#164888" }}
                                icon={faRightFromBracket}
                              />{" "}
                              &nbsp;Logout
                            </span>
                          </Flex>
                        </>,
                      ]}
                    />
                    {/* </span> */}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="loginHide" onClick={LoginClickFun}>
                  <div className="loginheadbtn">
                    <div>
                      <Typography>
                        Login/SignUp{" "}
                        <CaretDownOutlined style={{ paddingTop: "-10px" }} />
                      </Typography>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
        <div>
          {headerDisplay ? (
            headerDisplay
          ) : (
            <div>
              <Row
                justify={`${lg || md ? "center" : "space-around"}`}
                gutter={`${lg || md ? 40 : 10}`}
                className="m-4 "
                style={{ flexWrap: "nowrap" }}
              >
                <Col>
                  <div
                    className="topiconCard"
                    onClick={() => nav("/")}
                    style={{ cursor: "pointer" }}
                  >
                    <Hotelimgc className="headerlogostyle" />
                    <p className="iconTexthead" style={{ cursor: "pointer" }}>
                      Hotel
                    </p>
                  </div>
                </Col>
                <Col>
                  <div
                    className="topiconCard"
                    onClick={() =>
                      nav(
                        "/holiday-kerala/kerala-travel-packages/Domestic.html"
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <PackageC className="headerlogostyle" />
                    <p className="iconTexthead" style={{ cursor: "pointer" }}>
                      Package
                    </p>
                  </div>
                </Col>
                <Col>
                  <div
                    className="topiconCard"
                    onClick={() => nav("/houseboat/houseboat-in-alleppey.html")}
                    style={{ cursor: "pointer" }}
                  >
                    <HouseboatC className="headerlogostyle" />
                    <p className="iconTexthead" style={{ cursor: "pointer" }}>
                      Houseboat
                    </p>
                  </div>
                </Col>
                <Col>
                  <div
                    className="topiconCard"
                    onClick={() => nav("/shikara/alleppey-shikara-boat.html")}
                    style={{ cursor: "pointer" }}
                  >
                    <Boatico className="headerlogostyle" />
                    <p className="iconTexthead" style={{ cursor: "pointer" }}>
                      Shikara
                    </p>
                  </div>
                </Col>
              </Row>
              <div className="scroll-mob">
                <div>
                  <div className="container-fluid top-location">
                    <div className="row">
                      <div className="col-3 col-sm-2">
                        {!loading && destinationTopList?.length > 0 && (
                          <div
                            className="avatar-Link"
                            onClick={locateNearBy}
                          >
                            <div>
                              {/* <Avatar size={64} src={nearBy} /> */}
                              <Avatar
                                size={64}
                                src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/gif/place-marker.gif`}
                              />
                              <h6 className="slick-text">Near By</h6>
                            </div>
                          </div>
                        )}
                      </div>
                      {loading && (
                        <Space size={"large"}>
                          {Array(4)
                            .fill(null)
                            .map((_, index) => (
                              <Skeleton variant="circular" key={index}>
                                <Avatar size={64} />
                              </Skeleton>
                            ))}
                        </Space>
                      )}
                      <div className="col-9 col-sm-10">
                        {/* <Slider {...settings} style={{ width: "100%" }}> */}
                        <div className="mobileScrollEffect">
                          {!loading &&
                            destinationTopList &&
                            destinationTopList.map((v, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{ padding: "0px 20px 0px 20px" }}
                                >
                                  <div className="avatar-Link">
                                    <Link>
                                      <div
                                        onClick={() => {
                                          dispatch(setHotel(v.destinationName));
                                          navigateToPage(v.destinationName, v.destinationName);
                                        }}
                                      >
                                        <Avatar
                                          size={64}
                                          src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/destination/${v.destinationImage}`}
                                        />
                                        <h5 className="slick-text">
                                          {v.destinationName}
                                        </h5>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scroll-win">
                <div className="scroll-div">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-10">
                        <Row>
                          <Col
                            span={3}
                            key="8998"
                            style={{ cursor: "pointer" }}
                          >
                            {!loading && destinationTopList?.length > 0 && (
                              <div
                                className="avatar-Link"
                                onClick={locateNearBy}
                              >
                                <div>
                                  <Avatar
                                    size={64}
                                    src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/gif/place-marker.gif`}
                                  />
                                  <h6 className="avtar-txt">Near By</h6>
                                </div>
                              </div>
                            )}
                          </Col>
                          {loading && (
                            <Space size={"middle"}>
                              {Array(8)
                                .fill(null)
                                .map((_, index) => (
                                  <Skeleton variant="circular" key={index}>
                                    <Avatar size={64} />
                                  </Skeleton>
                                ))}
                            </Space>
                          )}

                          {!loading &&
                            destinationTopList?.length > 0 &&
                            destinationTopList.slice(0, 7).map((v, i) => {
                              return (
                                <Col
                                  span={3}
                                  key={i}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="avatar-Link"
                                    onClick={() => {
                                      dispatch(setSearchToggle(1));
                                      navigateToPage(v.destinationName, v.destinationName);
                                    }}
                                  >
                                    <div style={{ cursor: "pointer" }}>
                                      <Avatar
                                        size={64}
                                        src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/destination/${v.destinationImage}`}
                                        style={{ cursor: "pointer" }}
                                      />
                                      <h6 className="avtar-txt">
                                        {v.destinationName}
                                      </h6>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="search_card">
            <Row justify="center">
              <Col lg={18} md={18} sm={24} xs={24} className="p-3">
                <SearchCard />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePageHeader;
