import React, { useEffect, useState } from "react";
import errorImage from "../../assets/404.png";
import { Link } from "react-router-dom";

export const ErrorPage = () => {
  const styleDesktopImage = {
    width: "60%",
    height: "70%",
    objectFit: "contain",
  };

  const styleMobileImage = {
    paddingTop: "25px",
    width: "150%",
    height: "100%",
    objectFit: "contain",
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsDesktop(window.innerWidth > 1024);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="container px-2 pb-3"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
        //   paddingTop: '15px',
        height: isDesktop ? "750px" : "100%",
        gap: isMobile ? "10px" : 0,
      }}
    >
      <img
        src={errorImage}
        alt="Page not found"
        style={
          (isDesktop && styleDesktopImage) || (isMobile && styleMobileImage)
        }
      />
      <h4>Page not found.</h4>
      <p className="text-center px-2" style={{ fontSize: "18px" }}>
        We&#39;re sorry&#46;&nbsp;The page that you are looking for does not
        exist
      </p>
      <p
        className="pt-2 pb-3 px-2"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "5px",
          fontSize: "18px",
        }}
      >
        <strong>Go Back to</strong>
        <Link to={"/hotel"}>Hotels</Link>&#124;
        <Link to={"/holiday-kerala/kerala-travel-packages/Domestic.html"}>
          Packages
        </Link>
        &#124;
        <Link to={"/houseboat/houseboat-in-alleppey.html"}>Houseboat</Link>
        &#124;
        <Link to={"/shikara/alleppey-shikara-boat.html"}>Shikara</Link>
      </p>
    </div>
  );
};
