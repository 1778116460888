import { Paper } from "@material-ui/core";
import { Col, Row, Grid, Rate } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {faStar } from '@fortawesome/free-solid-svg-icons'
// import shikaraimg from '../../../../assets/sikarapic.jpg'
import { Button } from "antd";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import sfPro from "../../../../assets/FontsFree-Net-SFProDisplay-Regular.ttf";

// import { useNavigate } from 'react-router-dom'
const { useBreakpoint } = Grid;

function ShikaraCard({ data, setIsModalOpen }) {
  // debugger;
  const { lg } = useBreakpoint();
  const { shikaraImage, shikaraName, state, place } = data;

  return (
    <>
      <Row justify={"center"}>
        <Col span={24} style={{ padding: "2%" }}>
          <Paper
            // elevation={24}
            style={{
              padding: "2%",
              paddingTop: "0%",
              boxShadow: "0px 4px 10px #0000000d",
              borderBottom: "1px solid #eee",
              // boxShadow: '0px 4px 10px #0000000d',
              // borderBottom: '1px solid #eee',
            }}
          >
            <Row>
              <Col sm={24} xs={24} md={24} lg={10}>
                <img
                  src={shikaraImage[0]}
                  alt=""
                  style={{ height: "100%", width: "100%" }}
                />
              </Col>
              <Col lg={14} sm={24} style={{ padding: "2% 5% " }}>
                <Col sm={24} lg={24}>
                  {" "}
                  <Col className="h4 b">{shikaraName}</Col>
                  <p>
                    {state?.stateName} - {place?.destinationName}
                  </p>
                </Col>

                {/* _______________________________________________________________ */}
                <Row className="py-2">
                  <Rate
                    allowHalf
                    style={{ color: "orange" }}
                    defaultValue={
                      // data?.reviewId?.rating ? data?.reviewId?.rating : 0
                      4.5
                    }
                    disabled={true}
                  />
                </Row>
                <p>
                  {" "}
                  <FontAwesomeIcon icon={faClock} /> Minimum Hours :{" "}
                  {data?.contractId?.timeHrs ? data?.contractId?.timeHrs : ""}
                  /0.min
                </p>

                {/* _______________________________________________________________ */}

                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "5%",
                  }}
                >
                  <Col lg={12}>
                    {/* <span className="h-3" style={{ color: "#5893f6" }}>
                      &#8377;
                      {data?.contractId?.rate ? data?.contractId?.rate : ""}
                    </span>
                    <s
                      className=" p-1"
                      style={{ color: "#888", fontSize: "1.1rem" }}
                    >
                      &#x20B9;{" "}
                      {data?.contractId?.rate ? data?.contractId?.rate : ""}
                    </s>
                    <span
                      className=""
                      style={{ color: "#dd5a43", fontSize: "1rem" }}
                    >
                      {data?.promotionId?.promotionList.value
                        ? data?.promotionId?.promotionList.value
                        : ""}
                      {data?.promotionId?.promotionList.promotionType.percentage
                        ? " % "
                        : " ₹"}{" "}
                      off
                    </span> */}
                    <span
                      style={{
                        fontSize: "1.8rem",
                        color: "#cc0000",
                        fontWeight: "bold",
                      }}
                    >
                      &#x20B9;
                      {parseInt(
                        data?.contractId?.rate ? data?.contractId?.rate : ""
                      )}
                    </span>
                    {/* {mainDiscountRate != 0 && ( */}
                    <s
                      className=" p-1"
                      style={{
                        color: "#888",
                        fontSize: "1.1rem",
                        textDecoration: "line-through",
                        textDecorationColor: "red",
                        textDecorationThickness: "2px",
                        fontFamily: "SF Pro, Arial, sans-serif",
                        src: `${sfPro}`,
                      }}
                    >
                      &#x20B9;{" "}
                      {data?.promotionId?.promotionList.promotionType.percentage
                        ? parseInt(
                            parseInt(data?.contractId?.rate)
                              ? parseInt(data?.contractId?.rate) /
                                  (1 -
                                    data?.promotionId?.promotionList?.value /
                                      100)
                              : ""
                          )
                        : parseInt(data?.contractId?.rate) +
                          data?.promotionId?.promotionList.value}
                    </s>
                    {/* )} */}
                    {/* {mainDiscountRate != 0 ? ( */}
                    <span
                      className=""
                      style={{ color: "#ff6600", fontSize: "1rem" }}
                    >
                      {data?.promotionId?.promotionList.promotionType.percentage
                        ? ""
                        : "₹"}
                      {data?.promotionId?.promotionList.value
                        ? data?.promotionId?.promotionList.value
                        : ""}
                      {data?.promotionId?.promotionList.promotionType.percentage
                        ? "% "
                        : ""}{" "}
                      off
                    </span>
                    {/* ) : (
                      ""
                    )} */}
                  </Col>
                  <Col
                    lg={8}
                    style={{
                      marginTop: "1%",
                      display: `${lg ? "flex" : "block"}`,
                      float: "right",
                    }}
                  >
                    <Button
                      style={{
                        margin: "4%",
                        color: "#fff",
                        backgroundColor: "rgb(61, 117, 223)",
                      }}
                      onClick={() => {
                        setIsModalOpen({ status: true, data: data });
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>Contact Us</span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
    </>
  );
}

export default ShikaraCard;
