/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-autofocus */
import axios from "axios";
import React, { useEffect, useState } from "react";
import PayUPaymentPage from "./PayUPaymentPage";

const PayUMoneyForm = () => {
  const [form, setForm] = useState({ name: "", number: "", amount: "" });
  const [toggle, setToggle] = useState(1);
  const [hash, setHash] = useState(null);
  const [transactionId, settransactionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "amount") {
      setForm({ ...form, [e.target.name]: parseFloat(e.target.value) });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  function generateTransactionID() {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const merchantPrefix = "T";
    const transactionId = `${merchantPrefix}${timestamp}${randomNum}`;
    return settransactionId(transactionId);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await getHash();
      setToggle(2);
    } catch (error) {
      console.error("Error getting hash:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getHash = () => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}orders/payments/payu`, {
        ...form,
        transactionId: transactionId
      })
      .then((res) => {
        //console.log("hash", res);
        setHash(res.data.hash);
        settransactionId(res.data.transactionId);
      })
      .catch((error) => {
        //console.log(error);
        console.error(error);
      });
  };

  useEffect(() => {
    generateTransactionID();
  }, []);

  return (
    <>
      {toggle == 1 && (
        <div className="main-2 container gap-5">
          <div className="h-100 d-flex flex-column py-5 justify-content-between">
            <img width={350} src="" alt="" />
            <h1 className="mt-4 fw-bold card"> Payment Gatway Integration</h1>
          </div>
          <form
            onSubmit={handleSubmit}
            className="h-100 d-flex flex-column justify-content-center form"
          >
            <div className="mb-3">
              <p>Name</p>
              <input
                value={form?.name}
                required
                type="text"
                name="name"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <p>Email</p>
              <input
                value={form?.email}
                required
                type="text"
                name="email"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <p>Number</p>
              <input
                value={form?.number}
                required
                type="text"
                name="number"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <p>Amount</p>
              <input
                value={form?.amount}
                required
                type="number"
                name="amount"
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
              />
              <p>check me out</p>
            </div>
            {isLoading ? (
              <p>Generating secure hash...</p>
            ) : (
              <button type="submit">Check Details</button>
            )}{" "}
          </form>
        </div>
      )}
      {toggle === 2 && (
        <PayUPaymentPage
          setToggle={setToggle}
          form={form}
          hash={hash}
          transactionId={transactionId}
        />
      )}
    </>
  );
};

export default PayUMoneyForm;
