/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import { Paper } from '@mui/material'
import { Col, Divider, Grid, Rate, Row } from "antd";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faCar } from "@fortawesome/free-solid-svg-icons";
import tik from "../../../../assets/tik.png";
import qt from "../../../../assets/qt.png";
import activity from "../../../../assets/activity.png";
import food from "../../../../assets/food.png";
import sight from "../../../../assets/vision.png";
import visa from "../../../../assets/passport.png";
import { Paper } from "@mui/material";
import package1 from "../../../../assets/package1.jpg";
import package2 from "../../../../assets/package2.jpg";
import package3 from "../../../../assets/package3.jpg";
import package4 from "../../../../assets/package4.jpg";

// import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const { useBreakpoint } = Grid;
const iconObject = {
  Hotel: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <FontAwesomeIcon
        icon={faBed}
        style={{ fontSize: "2rem", color: "#5893f6" }}
        className="px-4"
      />
      <br />
      Hotel
    </Col>
  ),
  Cab: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <FontAwesomeIcon
        icon={faCar}
        style={{ fontSize: "2rem", color: "#5893f6" }}
        className="px-4"
      />
      <br />
      Travel
    </Col>
  ),
  Activity: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <img src={activity} alt="" className="px-4" />
      <br />
      Activity
    </Col>
  ),
  Food: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <img src={food} alt="" className="px-4" />
      <br />
      Food
    </Col>
  ),
  Sight: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <img src={sight} alt="" className="px-4" />
      <br />
      Sight
    </Col>
  ),
  Visa: (
    <Col style={{ textAlign: "center", padding: "4px" }}>
      <img src={visa} alt="" className="px-4" />
      <br />
      Visa
    </Col>
  ),
};

function PackageSecondPage() {
  const [expertbtnshow, setexpertbtnshow] = useState(true);

  const { selectedData: data } = useSelector((state) => state.packageAllData);
  const images = data?.packageImages.map((v) => {
    return {
      original: v,
      thumbnail: v,
    };
  });
  // debugger;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  const settingsSmall = {
    infinite: true,
    // className: "center",
    // centerMode: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: -1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
  };
  const nav = useNavigate();
  const { lg } = useBreakpoint();

  useEffect(() => {
    document
      .getElementById("packageHead")
      .scrollIntoView({ behavior: "smooth", block: "end" });
  }, []);

  return (
    <div style={{ backgroundColor: "ghostwhite" }} id="scroller">
      <Row>
        <Col lg={17} md={24} sm={24} xs={24}>
          <Row justify={"end"}>
            <Col lg={20} md={24} sm={24} xs={24}>
              <div style={{ marginTop: "1%", backgroundColor: "#fff" }}>
                <div className="px-4">
                  <p
                    id="packageHead"
                    className="budgettxt pt-5 pb-3"
                    style={{ textAlign: "left" }}
                  >
                    {" "}
                    {data.packageName && data.packageName}
                  </p>
                </div>
                <Row justify={"space-between"} className="px-4 pb-4">
                  <Col>
                    <Button
                      style={{
                        borderRadius: "0px",
                        backgroundColor: "#5893F6",
                        color: "#fff",
                        fontWeight: "700",
                      }}
                    >
                      {data.noOfNights + 1} Days {data.noOfNights} Nights
                    </Button>
                    <br />
                    <Rate allowHalf disabled defaultValue={4.5} />
                    <br />
                    <p>
                      {data.state?.stateName} {data.noOfNights + 1} Days
                    </p>
                    <div className="d-block d-lg-none">
                      <Button
                        className="contactusbtn mt-3 "
                        onClick={() => {
                          nav("/package/viewpackagedetails", { state: data });
                        }}
                      >
                        Contact Us
                      </Button>
                    </div>
                  </Col>
                  <Col className="">
                    <div className="d-md-block d-lg-none">
                      <p style={{ fontWeight: "400", fontSize: "1.2rem" }}>
                        Package Summary
                      </p>
                      <p className="packageSummry-title2 py-1">
                        {data.state?.stateName} {data.noOfNights + 1} Days
                      </p>
                      <p className="packageSummry-title2  pt-1">
                        Starting from
                      </p>
                      <div style={{ display: "flex" }}>
                        <span className="packageSummery-price pt-2 pr-1">
                          {/* <s>₹{data.packageBasicRate && data.packageBasicRate}</s> */}
                          <s>
                            ₹
                            {data?.promotionId?.promotionType.percentage
                              ? parseInt(
                                  parseInt(data?.packageBasicRate)
                                    ? parseInt(data?.packageBasicRate) /
                                        (1 - data?.promotionId?.value / 100)
                                    : ""
                                )
                              : parseInt(data?.packageBasicRate) +
                                data?.promotionId?.value}
                          </s>
                        </span>
                        <span className="packagesummry-actualprice px-1">
                          {/* <p>₹{data.discountRate && data.discountRate}</p> */}
                          <p>
                            ₹{data.packageBasicRate && data.packageBasicRate}
                          </p>
                        </span>
                        <span className="packageSummry-title2 pt-2 px-1 fw-bold">
                          <p>&#40;per person&#41;</p>
                        </span>
                      </div>
                      <div style={{ display: "flex" }}>
                        <span>
                          <p
                            className="emitietle"
                            style={{ padding: "2px 5px 0px 0px" }}
                          >
                            EMI
                          </p>
                        </span>
                        <span>
                          <p style={{ fontSize: "16px" }}>
                            Starting from - ₹ {data.emi}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div className="d-none d-lg-block">
                      <Button
                        className="contactusbtn "
                        onClick={() => {
                          nav("/package/viewpackagedetails", { state: data });
                        }}
                      >
                        Contact Us
                      </Button>
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col>
                    <div className="d-none d-lg-block">
                      <Button
                        className="contactusbtn "
                        onClick={() => {
                          nav("/package/viewpackagedetails", { state: data });
                        }}
                      >
                        Contact Us
                      </Button>
                    </div>
                  </Col>
                </Row> */}
                <Paper elevation={4} className="pb-4">
                  <Slider
                    {...settings}
                    // ref={sliderRef}
                    style={{ width: "100%", position: "relative" }}
                  >
                    {images.map((item, i) => {
                      return (
                        <>
                          <Col span={24} key={i}>
                            <span className="slick-card-buyNow-nb">
                              <img
                                src={item?.original}
                                className="slick-card-img-nb"
                                alt=""
                              />
                            </span>
                          </Col>
                        </>
                      );
                    })}
                  </Slider>
                  <div style={{ marginTop: "4px" }}>
                    <Slider
                      {...settingsSmall}
                      // ref={sliderRef}
                      style={{ width: "100%" }}
                    >
                      {images.map((item, i) => {
                        return (
                          <>
                            <Col key={i}>
                              <div
                                className="slick-card-buyNow-nb"
                                style={{ height: "90px", width: "90px" }}
                              >
                                <img
                                  src={item?.original}
                                  style={{ height: "100%", width: "100%" }}
                                  className=""
                                  alt=""
                                  // onClick={()=>setImageNo(i)}
                                />
                              </div>
                            </Col>
                          </>
                        );
                      })}

                      {images.length < 10 &&
                        new Array(11 - images.length).fill().map((v, i) => {
                          return (
                            <>
                              <Col key={i}>
                                <div
                                  className="slick-card-buyNow-nb"
                                  style={{
                                    height: "90px",
                                    width: "90px",
                                  }}
                                ></div>
                              </Col>
                            </>
                          );
                        })}
                    </Slider>
                  </div>
                </Paper>
                <p
                  style={{
                    fontWeight: "400",
                    padding: "2%",
                    fontSize: "1.2rem",
                  }}
                >
                  Inclusions
                </p>

                <div className="d-flex align-items-lg-start">
                  {data.include.map((v, i) => {
                    return <span key={i}>{iconObject[v]}</span>;
                  })}
                </div>
              </div>
              <div className="d-md-block d-lg-none">
                {expertbtnshow ? (
                  <div className="m-4 mb-0  just-center">
                    <div className="animatedBox">
                      <Button
                        className="contactusbtn "
                        onClick={() => {
                          setexpertbtnshow(false);
                        }}
                      >
                        TALK TO AN EXPERT
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="animatedBox">
                    <div className="m-4 mb-0  just-center">
                      <button
                        className="expertbtn"
                        onClick={() => {
                          setexpertbtnshow(true);
                        }}
                      >
                        <a
                          href="tel:8286882878"
                          style={{ backgroundColor: "#0771ce", color: "#fff" }}
                        >
                          {" "}
                          8286882878{" "}
                          <span style={{ fontSize: "14px", marginLeft: "4%" }}>
                            Close
                          </span>
                        </a>
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-md-block d-lg-none">
                <div className="scrollnotDiv">
                  <Slider
                    {...settings}
                    // ref={sliderRef}
                    style={{ width: "100%", position: "relative" }}
                  >
                    <Col span={24}>
                      <div>
                        <Row>
                          <div className="card-content">
                            <div>
                              <img className="" src={qt} alt="" />
                            </div>
                            <span
                              className="px-2 mt-4"
                              style={{ borderLeft: "1px solid lightgray" }}
                            ></span>
                            <div className="mt-4 card-description">
                              <p>
                                Trip with Godsownstay.com is a Excellent
                                Experience for us... Awesome experience from Mr
                                Arun Neeli , As expected, Godsownstay.com made
                                our trip comfortable and memorable. Will Connect
                                in our Future Trips.
                              </p>
                              <p className="text-start">
                                <Rate allowHalf disabled defaultValue={4.5} />
                              </p>
                              <div className="my-3" style={{ display: "flex" }}>
                                <div>
                                  <img
                                    className="testimoni-pic"
                                    src={package2}
                                    alt=""
                                  />
                                </div>
                                <div className="testimoni-name">
                                  <span>Nirja</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div>
                        <Row>
                          <div className="card-content">
                            <div>
                              <img className="" src={qt} alt="" />
                            </div>
                            <span
                              className="px-2 mt-4"
                              style={{ borderLeft: "1px solid lightgray" }}
                            ></span>
                            <div className="mt-4 card-description">
                              <p>
                                Such a lovely experience From Godsownstay.com
                                All the arrangements done by Ms Doncy . They
                                made our trip comfortable and enjoyable
                              </p>
                              <p className="text-start">
                                <Rate allowHalf disabled defaultValue={4.5} />
                              </p>
                              <div className="my-3" style={{ display: "flex" }}>
                                <div>
                                  <img
                                    className="testimoni-pic"
                                    src={package4}
                                    alt=""
                                  />
                                </div>
                                <div className="testimoni-name">
                                  <span>Himanshu</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div>
                        <Row>
                          <div className="card-content">
                            <div>
                              <img className="" src={qt} alt="" />
                            </div>
                            <span
                              className="px-2 mt-4"
                              style={{ borderLeft: "1px solid lightgray" }}
                            ></span>
                            <div className="mt-4 card-description">
                              <p>
                                The quality of hotels and food was good
                                arrangement such as cab driver all booking
                                activities was Mr Arun Neeli & Ms Doncy was very
                                helpful and kind while all booking procedures
                                and also while traveling In our entire trip
                              </p>
                              <p className="text-start">
                                <Rate allowHalf disabled defaultValue={4.5} />
                              </p>
                              <div className="my-3" style={{ display: "flex" }}>
                                <div>
                                  <img
                                    className="testimoni-pic"
                                    src={package1}
                                    alt=""
                                  />
                                </div>
                                <div className="testimoni-name">
                                  <span>Dipali Sharma</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div>
                        <Row>
                          <div className="card-content">
                            <div>
                              <img className="" src={qt} alt="" />
                            </div>
                            <span
                              className="px-2 mt-4"
                              style={{ borderLeft: "1px solid lightgray" }}
                            ></span>
                            <div className="mt-4 card-description">
                              <p>
                                The experience was amazing. Thanks to Ms Vipina
                                for taking care of all aspects of travel for us.
                                From the beginning till the end,it was fun and
                                hazzle free..The trip was overall Awesome, and
                                we had alot of fun. Thanks to Godsownstay.com
                              </p>
                              <p className="text-start">
                                <Rate allowHalf disabled defaultValue={4.5} />
                              </p>
                              <div className="my-3" style={{ display: "flex" }}>
                                <div>
                                  <img
                                    className="testimoni-pic"
                                    src={package3}
                                    alt=""
                                  />
                                </div>
                                <div className="testimoni-name">
                                  <span>Rahul</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Slider>
                </div>
              </div>
            </Col>
          </Row>
          <Row justify={"end"}>
            <Col lg={20} md={24} sm={24} xs={24}>
              <div
                style={{
                  marginTop: "1%",
                  backgroundColor: "#fff",
                  padding: `${!lg ? "0% 8%" : ""}`,
                }}
              >
                <p className="h-3 px-4 pt-4" style={{ color: "#666" }}>
                  Daily Plan
                </p>
                {data?.itineraryDetails &&
                  data?.itineraryDetails.map((val, ide) => {
                    return (
                      <div key={ide}>
                        <div className="daysTag" id={`day${ide}`}>
                          <p>Day {parseInt(val?.day.split(" ")[1].trim())}</p>
                        </div>
                        <p className="daytag-head">{val.headline}</p>
                        <Row justify={"center"}>
                          <Col lg={6} md={24} sm={24} xs={24}>
                            <img
                              className="daily-plan-img"
                              src={
                                val.itineraryImage
                                  ? val.itineraryImage
                                  : images[0]?.original
                              }
                              alt=""
                            />
                          </Col>
                          <Col lg={17} md={24} sm={24} xs={24}>
                            <div className="mt-1">
                              {val.dayActivity &&
                                val.dayActivity.map((v, i) => {
                                  return (
                                    <ul
                                      key={i}
                                      style={{ listStyleImage: `url(${tik})` }}
                                    >
                                      <li>{v}</li>
                                    </ul>
                                  );
                                })}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </div>
              <div
                style={{
                  marginTop: "4%",
                  backgroundColor: "#fff",
                  paddingBottom: "1%",
                }}
              >
                <p
                  style={{
                    fontWeight: "400",
                    padding: "2%",
                    fontSize: "1.2rem",
                  }}
                >
                  Inclusions
                </p>
                {data &&
                  data?.inclusion
                    ?.filter((x) => x?.details?.selected)
                    .map((val, idx) => {
                      return (
                        <ul
                          key={idx}
                          style={{ marginLeft: "2%", paddingRight: "10px" }}
                        >
                          <li>{val?.details?.value}.</li>
                        </ul>
                      );
                    })}
              </div>
              <div
                style={{
                  marginTop: "4%",
                  backgroundColor: "#fff",
                  paddingBottom: "1%",
                }}
              >
                <p
                  style={{
                    fontWeight: "400",
                    padding: "2%",
                    fontSize: "1.2rem",
                  }}
                >
                  Exclusions
                </p>
                {data &&
                  data?.exclusion
                    ?.filter((x) => x?.details?.selected)
                    .map((v, i) => {
                      return (
                        <ul
                          key={i}
                          style={{ marginLeft: "2%", paddingRight: "10px" }}
                        >
                          <li>{v?.details?.value}.</li>
                        </ul>
                      );
                    })}
              </div>
              <div
                style={{
                  marginTop: "4%",
                  marginBottom: "4%",
                  paddingBottom: "1%",
                  backgroundColor: "#fff",
                }}
              >
                <p
                  style={{
                    fontWeight: "400",
                    padding: "2%",
                    fontSize: "1.2rem",
                  }}
                >
                  Terms & Conditions
                </p>
                {data &&
                  data?.termsAndConditions
                    ?.filter((x) => x?.details?.selected)
                    .map((vl, id) => {
                      return (
                        <ul
                          key={id}
                          style={{ marginLeft: "2%", paddingRight: "10px" }}
                        >
                          <li>{vl?.details?.value}.</li>
                        </ul>
                      );
                    })}
              </div>
            </Col>
          </Row>
        </Col>
        <Col lg={7} md={24} sm={24} xs={24}>
          <div className="d-none d-lg-block">
            {expertbtnshow ? (
              <div className="m-4 mb-0  just-center">
                <div className="animatedBox">
                  <Button
                    className="contactusbtn "
                    onClick={() => {
                      setexpertbtnshow(false);
                    }}
                  >
                    TALK TO AN EXPERT
                  </Button>
                </div>
              </div>
            ) : (
              <div className="animatedBox">
                <div className="m-4 mb-0  just-center">
                  <button
                    className="expertbtn"
                    onClick={() => {
                      setexpertbtnshow(true);
                    }}
                  >
                    <a
                      href="tel:8286882878"
                      style={{ backgroundColor: "#0771ce", color: "#fff" }}
                    >
                      {" "}
                      8286882878{" "}
                      <span style={{ fontSize: "14px", marginLeft: "4%" }}>
                        Close
                      </span>
                    </a>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div style={{ marginLeft: "10%" }}>
            <div className="d-none d-lg-block">
              <p style={{ fontWeight: "400", fontSize: "1.2rem" }}>
                Package Summary
              </p>
              <p className="packageSummry-title2 py-1">
                {data.state?.stateName} {data.noOfNights + 1} Days
              </p>
              <p className="packageSummry-title2  pt-1">Starting from</p>
              <div style={{ display: "flex" }}>
                <span className="packageSummery-price pt-2 pr-1">
                  {/* <s>₹{data.packageBasicRate && data.packageBasicRate}</s> */}
                  <s>
                    ₹
                    {data?.promotionId?.promotionType.percentage
                      ? parseInt(
                          parseInt(data?.packageBasicRate)
                            ? parseInt(data?.packageBasicRate) /
                                (1 - data?.promotionId?.value / 100)
                            : ""
                        )
                      : parseInt(data?.packageBasicRate) +
                        data?.promotionId?.value}
                  </s>
                </span>
                <span className="packagesummry-actualprice px-1">
                  {/* <p>₹{data.discountRate && data.discountRate}</p> */}
                  <p>₹{data.packageBasicRate && data.packageBasicRate}</p>
                </span>
                <span className="packageSummry-title2 pt-2 px-1 fw-bold">
                  <p>&#40;per person&#41;</p>
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <span>
                  <p
                    className="emitietle"
                    style={{ padding: "2px 5px 0px 0px" }}
                  >
                    EMI
                  </p>
                </span>
                <span>
                  <p style={{ fontSize: "16px" }}>
                    Starting from - ₹ {data.emi}
                  </p>
                </span>
              </div>
            </div>
            <div className="d-none d-lg-block">
              {data?.itineraryDetails &&
                data?.itineraryDetails.map((v, i) => {
                  return (
                    <div key={i} className="summrydiv">
                      <span className="yellowboll"> </span>
                      <div className="borderbottom">
                        <Row style={{ marginTop: "-25px" }}>
                          <Col span={24}>
                            <p className="day1">
                              Day {parseInt(v?.day.split(" ")[1].trim())}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p className="custStyle1 pt-1">
                              {v.headline} -{" "}
                              {v.places?.destinationName &&
                                v.places?.destinationName}
                            </p>
                          </Col>
                          <Col span={24}>
                            <p style={{ fontSize: "13px" }}>
                              Explore{" "}
                              {v.places?.destinationName &&
                                v.places?.destinationName}
                            </p>
                          </Col>
                          <Col span={24} style={{ marginTop: "5%" }}>
                            <a href={`#day${i}`} className="viewDetalis my-2">
                              View Details
                            </a>
                            <Divider style={{ marginBottom: "0px" }} />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="d-none d-lg-block">
              <div className="scrollnotDiv">
                <Slider
                  {...settings}
                  // ref={sliderRef}
                  style={{ width: "100%", position: "relative" }}
                >
                  <Col span={24}>
                    <div>
                      <Row>
                        <div className="card-content">
                          <div>
                            <img className="" src={qt} alt="" />
                          </div>
                          <span
                            className="px-2 mt-4"
                            style={{ borderLeft: "1px solid lightgray" }}
                          ></span>
                          <div className="mt-4 card-description">
                            <p>
                              Trip with Godsownstay.com is a Excellent
                              Experience for us... Awesome experience from Mr
                              Arun Neeli , As expected, Godsownstay.com made our
                              trip comfortable and memorable. Will Connect in
                              our Future Trips.
                            </p>
                            <p className="text-start">
                              <Rate allowHalf disabled defaultValue={4.5} />
                            </p>
                            <div className="my-3" style={{ display: "flex" }}>
                              <div>
                                <img
                                  className="testimoni-pic"
                                  src={package2}
                                  alt=""
                                />
                              </div>
                              <div className="testimoni-name">
                                <span>Nirja</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div>
                      <Row>
                        <div className="card-content">
                          <div>
                            <img className="" src={qt} alt="" />
                          </div>
                          <span
                            className="px-2 mt-4"
                            style={{ borderLeft: "1px solid lightgray" }}
                          ></span>
                          <div className="mt-4 card-description">
                            <p>
                              Such a lovely experience From Godsownstay.com All
                              the arrangements done by Ms Doncy . They made our
                              trip comfortable and enjoyable
                            </p>
                            <p className="text-start">
                              <Rate allowHalf disabled defaultValue={4.5} />
                            </p>
                            <div className="my-3" style={{ display: "flex" }}>
                              <div>
                                <img
                                  className="testimoni-pic"
                                  src={package4}
                                  alt=""
                                />
                              </div>
                              <div className="testimoni-name">
                                <span>Himanshu</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div>
                      <Row>
                        <div className="card-content">
                          <div>
                            <img className="" src={qt} alt="" />
                          </div>
                          <span
                            className="px-2 mt-4"
                            style={{ borderLeft: "1px solid lightgray" }}
                          ></span>
                          <div className="mt-4 card-description">
                            <p>
                              The quality of hotels and food was good
                              arrangement such as cab driver all booking
                              activities was Mr Arun Neeli & Ms Doncy was very
                              helpful and kind while all booking procedures and
                              also while traveling In our entire trip
                            </p>
                            <p className="text-start">
                              <Rate allowHalf disabled defaultValue={4.5} />
                            </p>
                            <div className="my-3" style={{ display: "flex" }}>
                              <div>
                                <img
                                  className="testimoni-pic"
                                  src={package1}
                                  alt=""
                                />
                              </div>
                              <div className="testimoni-name">
                                <span>Dipali Sharma</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div>
                      <Row>
                        <div className="card-content">
                          <div>
                            <img className="" src={qt} alt="" />
                          </div>
                          <span
                            className="px-2 mt-4"
                            style={{ borderLeft: "1px solid lightgray" }}
                          ></span>
                          <div className="mt-4 card-description">
                            <p>
                              The experience was amazing. Thanks to Ms Vipina
                              for taking care of all aspects of travel for us.
                              From the beginning till the end,it was fun and
                              hazzle free..The trip was overall Awesome, and we
                              had alot of fun. Thanks to Godsownstay.com
                            </p>
                            <p className="text-start">
                              <Rate allowHalf disabled defaultValue={4.5} />
                            </p>
                            <div className="my-3" style={{ display: "flex" }}>
                              <div>
                                <img
                                  className="testimoni-pic"
                                  src={package3}
                                  alt=""
                                />
                              </div>
                              <div className="testimoni-name">
                                <span>Rahul</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                </Slider>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* <Row justify={"start"} className="packageDiv3">
        <Col lg={17} md={24} sm={24} xs={24}>
          <Row justify={"end"}>
            <Col lg={20} md={24} sm={24} xs={24}>
              <div
                style={{
                  marginTop: "1%",
                  backgroundColor: "#fff",
                  padding: `${!lg ? "0% 8%" : ""}`,
                }}
              >
                <p className="h-3 px-4 pt-4" style={{ color: "#666" }}>
                  Daily Plan
                </p>
                {data?.itineraryDetails &&
                  data?.itineraryDetails.map((val, ide) => {
                    return (
                      <div key={ide}>
                        <div className="daysTag" id={`day${ide}`}>
                          <p>Day {parseInt(val?.day.split(" ")[1].trim())}</p>
                        </div>
                        <p className="daytag-head">{val.headline}</p>
                        <Row justify={"center"}>
                          <Col lg={6} md={24} sm={24} xs={24}>
                            <img
                              style={{
                                height: "135px",
                                width: "-webkit-fill-available",
                              }}
                              src={
                                val.itineraryImage
                                  ? val.itineraryImage
                                  : images[0]?.original
                              }
                              alt=""
                            />
                          </Col>
                          <Col lg={17} md={24} sm={24} xs={24}>
                            <div
                              style={{ height: "135px", overflowY: "scroll" }}
                            >
                              {val.dayActivity &&
                                val.dayActivity.map((v, i) => {
                                  return (
                                    <ul
                                      key={i}
                                      style={{ listStyleImage: `url(${tik})` }}
                                    >
                                      <li>{v}</li>
                                    </ul>
                                  );
                                })}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </div>
              <div style={{ marginTop: "4%", backgroundColor: "#fff" }}>
                <p
                  style={{
                    fontWeight: "400",
                    padding: "2%",
                    fontSize: "1.2rem",
                  }}
                >
                  Inclusions
                </p>
                {inclusion &&
                  inclusion.map((val, idx) => {
                    return (
                      <ul key={idx} style={{ marginLeft: "2%" }}>
                        <li>{val}.</li>
                      </ul>
                    );
                  })}
              </div>
              <div style={{ marginTop: "4%", backgroundColor: "#fff" }}>
                <p
                  style={{
                    fontWeight: "400",
                    padding: "2%",
                    fontSize: "1.2rem",
                  }}
                >
                  Exclusions
                </p>
                {exclusion &&
                  exclusion.map((v, i) => {
                    return (
                      <ul key={i} style={{ marginLeft: "2%" }}>
                        <li>{v}.</li>
                      </ul>
                    );
                  })}
              </div>
              <div
                style={{
                  marginTop: "4%",
                  marginBottom: "4%",
                  paddingBottom: "4%",
                  backgroundColor: "#fff",
                }}
              >
                <p
                  style={{
                    fontWeight: "400",
                    padding: "2%",
                    fontSize: "1.2rem",
                  }}
                >
                  Terms & Conditions
                </p>
                {terms &&
                  terms.map((vl, id) => {
                    return (
                      <ul key={id} style={{ marginLeft: "2%" }}>
                        <li>{vl}.</li>
                      </ul>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </Col>
      </Row> */}
    </div>
  );
}

export default PackageSecondPage;
