import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "react-bootstrap/Button";
import loginIcon from "../../../assets/biglogo.png";
// import logo from "../../../assets/landingPageImages/mainlogoGodsownstay1.png";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Stack from "react-bootstrap/Stack";
import { useState } from "react";
import { LoginCall } from "../../../services/utils/loginForm";
import { useNavigate } from "react-router-dom/dist";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import ForGetPasswordModel from "./ForGetPasswordModel";
const validEmail = new RegExp("^[a-zA-Z0-9._:$!%-]+.[a-zA-Z]$");
const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");

const LoginForm = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [backendErr, setBackEndErr] = useState(false);
  const [backendErrtxt, setBackEndErrtxt] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  // const [isModalOpen, setisModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // const [emailErr, setEmailErr] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const navigate = useNavigate();

  const ForgetPassword = () => {
    // alert("clicked")
    setModalOpen(true);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const validate = async (e) => {
    e.preventDefault();
    if (!validEmail.test(name)) {
      // setEmailErr(true);
    }
    if (!validPassword.test(password)) {
      setPwdError(true);
    }
    let res = await LoginCall({ name, password });
    try {
      if (res?.data?.status) {
        localStorage.setItem(
          "pageRole",
          res.data.role ? res.data.role : "admin"
        );
        localStorage.setItem(
          "token",
          res.data.token ? res.data.token : "token"
        );
        navigate("/admin/dashboard");
      } else {
        // Handle unsuccessful login
        setBackEndErr(true);
        setBackEndErrtxt(res.data.message || "Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setBackEndErr(true);
      setBackEndErrtxt(res.errRes.messege);
    }
  };

  return (
    <>
      <form onSubmit={validate}>
        <Stack gap={2} className="col-md-3 mx-auto">
          <img
            src={loginIcon}
            alt="logo"
            style={{ marginBottom: "2px", padding: "10%", height: "130px" }}
          />
          <InputGroup className="mb-1.5" size="md">
            <InputGroup.Text id="basic-addon1">
              <AccountCircleIcon />
            </InputGroup.Text>
            <Form.Control
              placeholder="Your Username*"
              aria-label="Username"
              aria-describedby="basic-addon1"
              type="username"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-1.5" size="md">
            <InputGroup.Text id="basic-addon1">
              <LockIcon />
            </InputGroup.Text>
            <Form.Control
              placeholder="Your Password"
              aria-label="Password"
              aria-describedby="basic-addon1"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputGroup.Text id="basic-addon1">
              <InputAdornment
                position="end"
                style={{
                  marginLeft: "0px",
                }}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                  style={{
                    marginRight: "1px",
                  }}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            </InputGroup.Text>
          </InputGroup>
          {/* {emailErr && <p style={{ 'color': 'red', 'font-size': '14px' }}>Your username is invalid*</p>} */}
          {pwdError ? (
            <p
              style={{
                color: "#dd5a43",
                fontSize: "10px",
              }}
              className="mb-2"
            >
              Invalid Usename or Password *
            </p>
          ) : backendErr ? (
            <p
              style={{
                color: "#dd5a43",
                fontSize: "10px",
              }}
              className="mb-2"
            >
              {backendErrtxt}
            </p>
          ) : (
            ""
          )}

          <Button variant="primary" size="md" className="mb-4" type="submit">
            <ExitToAppIcon /> Login
          </Button>
          <Typography
            style={{
              color: "red",
              width: "120%",
              fontSize: "12px",
              marginBottom: "2px",
            }}
            onClick={ForgetPassword}
          >
            <LockIcon />
            Forgot Password ?
          </Typography>
        </Stack>
        {modalOpen ? (
          <ForGetPasswordModel
            setModalOpen={setModalOpen}
            // modalValue={modalValue}
            // defaultFormData={defaultFormData}
            // getTableData={getTableData}
          />
        ) : (
          ""
        )}
      </form>
    </>
  );
};

export default LoginForm;
