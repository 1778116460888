import React, { useEffect, useState } from "react";
import { Pagination, Button } from "antd";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import DEFAULT_PIC from "../../../../assets/loging 01.png";

export const CustomerReview = ({ reviews }) => {
  // debugger;
  //dummy customer review data
  // const testimonialsData = [
  //   {
  //     name: "Customer 1",
  //     rating: 3.5,
  //     text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
  //         nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
  //         sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
  //         rebum. Stet clita kasd`,
  //     image:
  //       "https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg",
  //   },
  //   {
  //     name: "Customer 2",
  //     rating: 4.2,
  //     text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
  //     nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
  //     sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
  //     rebum. Stet clita kasd`,
  //     image:
  //       "https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg",
  //   },
  //   {
  //     name: "Customer 3",
  //     rating: 4.0,
  //     text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
  //     nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
  //     sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
  //     rebum. Stet clita kasd`,
  //     image:
  //       "https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg",
  //   },
  //   {
  //     name: "Customer 4",
  //     rating: 3.2,
  //     text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
  //     nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
  //     sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
  //     rebum. Stet clita kasd`,
  //     image:
  //       "https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg",
  //   },
  //   {
  //     name: "Customer 5",
  //     rating: 4.5,
  //     text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
  //     nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
  //     sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
  //     rebum. Stet clita kasd`,
  //     image:
  //       "https://cdn.pixabay.com/photo/2016/03/27/17/26/water-1283199__340.jpg",
  //   },
  //   // Add more testimonial data here
  // ];

  const ITEMS_PER_PAGE = 5;
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const lastIndexCard = currentPageNo * ITEMS_PER_PAGE;
  const firstIndexCard = lastIndexCard - ITEMS_PER_PAGE;
  const paginatedData = reviews?.slice(firstIndexCard, lastIndexCard);
  const TOTAL_PAGES = Math.ceil(reviews?.length / ITEMS_PER_PAGE);
  // debugger;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const itemRender = (_, type, originalElement) => {
    if (type === "next") {
      if (currentPageNo === TOTAL_PAGES) {
        return <></>;
      } else {
        return (
          <Button
            size={isMobile ? "small" : "middle"}
            onChange={() => setCurrentPageNo(currentPageNo + 1)}
          >
            Next
          </Button>
        );
      }
    }
    if (type === "prev") {
      if (currentPageNo === 1) {
        return null;
      } else {
        return (
          <Button
            size={isMobile ? "small" : "middle"}
            onChange={() => setCurrentPageNo(currentPageNo + 1)}
          >
            Prev
          </Button>
        );
      }
    }

    return originalElement;
  };

  const handlePageChange = (page) => {
    setCurrentPageNo(page);
  };

  return (
    <div>
      {paginatedData?.length > 0 &&
        paginatedData?.map((item, index) => (
          <div className="my-3 px-4" key={index}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img
                  className="testimoni-pic"
                  src={
                    !item.profileImage || item.profileImage == "undefined"
                      ? DEFAULT_PIC
                      : item.profileImage
                  }
                  alt="profile"
                />
              </div>
              <div>
                <p style={{ fontWeight: "bold", fontSize: "13px" }}>
                  {item?.customerName ? item?.customerName : "anonymous"}{" "}
                  <span style={{ color: "#1675DF", fontSize: "13px" }}>
                    &#40;{item?.rating?.toFixed(0)}/5&#41;
                  </span>
                </p>

                <p style={{ fontSize: "0.8rem", color: "#2C2C2C" }}>
                  {item?.review}
                </p>
              </div>
            </div>
            {item?.image && (
              <img
                src={item?.image}
                style={{
                  width: "90px",
                  height: "90px",
                  marginLeft: "60px",
                  borderRadius: "6px",
                }}
                alt=""
                key={`image-${index}`}
              />
            )}
          </div>
        ))}
      {/* {paginatedData.length > 0 &&
        paginatedData.map((item, index) => (
          <div className="my-3 px-4" style={{ display: "flex" }} key={index}>
            <div>
              <img className="testimoni-pic" src={DEFAULT_PIC} alt="" />
            </div>
            <div>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                {"Customer 1"}{" "}
                <span style={{ color: "#1675DF" }}>&#40;{item?.rating?.toFixed(0)} / 5&#41;</span>
              </p>

              <p style={{ fontSize: "1rem", color: "#2C2C2C" }}>{item?.review}</p>
            </div>
          </div>
        ))}
      {paginatedData.length > 0 &&
        paginatedData.map((item, index) => (
          <div className="my-3 px-4" style={{ display: "flex" }} key={index}>
            <div>
              <img className="testimoni-pic" src={DEFAULT_PIC} alt="" />
            </div>
            <div>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                {"Customer 1"}{" "}
                <span style={{ color: "#1675DF" }}>&#40;{item?.rating?.toFixed(0)} / 5&#41;</span>
              </p>

              <p style={{ fontSize: "1rem", color: "#2C2C2C" }}>{item?.review}</p>
            </div>
          </div>
        ))}
      {paginatedData.length > 0 &&
        paginatedData.map((item, index) => (
          <div className="my-3 px-4" style={{ display: "flex" }} key={index}>
            <div>
              <img className="testimoni-pic" src={DEFAULT_PIC} alt="" />
            </div>
            <div>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                {"Customer 1"}{" "}
                <span style={{ color: "#1675DF" }}>&#40;{item?.rating?.toFixed(0)} / 5&#41;</span>
              </p>

              <p style={{ fontSize: "1rem", color: "#2C2C2C" }}>{item?.review}</p>
            </div>
          </div>
        ))}
      {paginatedData.length > 0 &&
        paginatedData.map((item, index) => (
          <div className="my-3 px-4" style={{ display: "flex" }} key={index}>
            <div>
              <img className="testimoni-pic" src={DEFAULT_PIC} alt="" />
            </div>
            <div>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                {"Customer 1"}{" "}
                <span style={{ color: "#1675DF" }}>&#40;{item?.rating?.toFixed(0)} / 5&#41;</span>
              </p>

              <p style={{ fontSize: "1rem", color: "#2C2C2C" }}>{item?.review}</p>
            </div>
          </div>
        ))} */}
      {/*START: Pagination Code */}
      {reviews?.length > ITEMS_PER_PAGE && (
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            padding: "0 0 0 14px",
          }}
        >
          {currentPageNo > 1 && (
            <Button
              size={isMobile ? "small" : "middle"}
              onClick={() => setCurrentPageNo(1)}
            >
              <DoubleLeftOutlined />
            </Button>
          )}
          <Pagination
            current={currentPageNo}
            onChange={handlePageChange}
            total={reviews?.length}
            defaultPageSize={ITEMS_PER_PAGE}
            showSizeChanger={false}
            itemRender={itemRender}
          />
          {currentPageNo === TOTAL_PAGES ? (
            <></>
          ) : (
            <Button
              size={isMobile ? "small" : "middle"}
              onClick={() => setCurrentPageNo(TOTAL_PAGES)}
            >
              <DoubleRightOutlined />
            </Button>
          )}
        </div>
      )}
      {/*END: Pagination Code */}
    </div>
  );
};
