import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { PackageGetAll } from '../../services/utils/userUtils/userPackageUtils';




const fetchPackageData = createAsyncThunk('data/package', async () => {
  const response = await PackageGetAll()
  return response.data;
});


export const packageSlice = createSlice({
  name: 'packageAllData',
  initialState: {package: [], selectedData:{}, error: null },
  reducers: {

    selectPackage:(state,action)=>{
      state.selectedData=action.payload

    },

  },
  extraReducers:(builder)=>{
    builder.addCase(fetchPackageData.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchPackageData.fulfilled,(state,action)=>{
      state.package= action.payload;
      state.loading = false;
      state.error = null;
    })
    .addCase(fetchPackageData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    })
  }

});


export { fetchPackageData };
export const {selectPackage} = packageSlice.actions
export default packageSlice.reducer
