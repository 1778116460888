/* eslint-disable no-unused-vars */
/* eslint-disable import/default */
/* eslint-disable import/namespace */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import LoginModel from "../../components/molecules/modalComponent/LoginModel";
import HomePageBody from "./homePageBody";
import HomePageFooter from "./homePageFooter";
// import logo from '../../assets/biglogo.jpg'
// import { ReactComponent as Discount } from '../../assets/discount5.svg'
import { ReactComponent as Discount } from "../../assets/offer2.svg";
// import { ReactComponent as Invitec } from '../../assets/invitecsvg.svg'
// import { ReactComponent as Careico } from '../../assets/careico.svg'
// import { ReactComponent as ListPro } from '../../assets/listPros.svg'
// import { ReactComponent as Phoneico } from '../../assets/phoneico.svg'
import { useLocation, useNavigate } from "react-router-dom";
import { LoginRoute } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import { UserLoader } from "../../components/atoms/userLoader";
import {
  // faBars,
  faBriefcase,
  // faFileCircleMinus,
  faHeadset,
  faHome,
  // faIdBadge,
  faSquarePhone,
} from "@fortawesome/free-solid-svg-icons";
// import listicon from '../../assets/list4.svg'
// import invite from '../../assets/invite.jpg'
import { Col, Row, Collapse } from "antd";
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import HomePageHeader from "./homePageHeader";
// import UserDrawer from '../usersPage/newUserLayout/userDrawer'
// import '../../styles/media.css'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import BottomFooter from "./../usersPage/newUserLayout/userFooter/BottomFooter";
import HeaderForAll from "./homePageHeader/HeaderForAll";
import {
  DestinationGetAll,
  getAllCoupons,
} from "../../services/utils/userUtils/landingPage";
import { useDispatch, useSelector } from "react-redux";
import { removeUserDetails } from "../../reduxData/sliceData/userDetailsReducer";
import { setActiveFooterTab } from "../../reduxData/sliceData/homeReducer";
import {
  setSearchCardData,
  setSearchToggle,
} from "../../reduxData/sliceData/searchCardReducer";
import moment from "moment/moment";
import Coupons from "./../coupons/index";

const { Panel } = Collapse;

function HomePage() {
  const [isModalOpen, setisModalOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [destinationData, setdestinationData] = useState([]);
  const [destinationCard, setdestinationCard] = useState([]);
  const [userDetails, setuserDetails] = useState();
  const isDesktop = window.innerWidth > 900 ? true : false;
  const selector = useSelector((s) => s.userDetails.user);

  const dispatch = useDispatch();
  const [offerData, setofferData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [tabRecord, setTabRecord] = useState(1);
  const active = "bottomTabActive";
  const deActive = "bottomTab";

  const footerActiveTab = useSelector(
    (state) => state?.homeData?.footerActiveTab
  );

  const currentDate = moment().startOf("day").format("DD/MM/YYYY");
  const nextDate = moment().add(1, "day").endOf("day").format("DD/MM/YYYY");
  useEffect(() => {
    dispatch(
      setSearchCardData({
        destinationName: "",
        checkIn: currentDate,
        checkOut: nextDate,
        guestDetails: {
          rooms: 1,
          childAge: [],
          adults: 2,
          children: 0,
        },
      })
    );
  }, []);

  useEffect(() => {
    if (footerActiveTab === 3) {
      setTabRecord(footerActiveTab);
    }
  }, [footerActiveTab]);

  useEffect(() => {
    dispatch(setSearchToggle(0));
  }, []);

  useEffect(() => {
    if (tabRecord === 3) {
      //nav("/coupons");
    }
  }, [tabRecord]);

  const offerCardData = async () => {
    try {
      setLoading(true);
      const {
        data: { response },
      } = await getAllCoupons();
      setofferData(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const DestinationgetData = async () => {
    setLoading(true);
    const res = await DestinationGetAll();
    // debugger;
    const imgdata = await res?.data?.response.map((v) => {
      return {
        destinationImage: v.destinationImage[0],
        destinationName: v.destinationName,
        isSelected: v.isSelected,
      };
    });
    setdestinationData(imgdata);
    setdestinationCard(imgdata);
    setLoading(false);
  };
  const nav = useNavigate();
  const LoginClickFun = () => {
    setisModalOpen(isModalOpen ? false : true);
  };
  const closeBtnFun = () => {
    setisModalOpen(isModalOpen ? false : true);
  };
  const location = useLocation();
  const path = location.pathname;

  const pageRole = localStorage.getItem("pageRole");
  const token = localStorage.getItem("token");
  const logoutFun = () => {
    dispatch(removeUserDetails());
    setTimeout(function () {
      window.location.reload();
    }, 100);
  };

  useEffect(() => {
    DestinationgetData();
    setuserDetails(selector);
    offerCardData();

    if (token && pageRole === "admin") {
      nav("/admin/dashboard");
    } else if (token && pageRole === "partner") {
      nav("partner/dashboard");
    } else if (token && pageRole === "user") {
      nav("user/dashboard");
    } else {
      nav("/");
    }

    const handleBeforeUnload = () => {
      localStorage.setItem("desktopModalOpened", "false");
    };

    // Add an event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    const hasDesktopModalBeenOpened =
      localStorage.getItem("desktopModalOpened");
    if (!hasDesktopModalBeenOpened || hasDesktopModalBeenOpened === "false") {
      setisModalOpen(!token && isDesktop ? true : false);
      localStorage.setItem("desktopModalOpened", "true");
    }

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      {loading ? <UserLoader /> : ""}
      {path === "/login" ? (
        <LoginRoute />
      ) : (
        <>
          <div style={{ backgroundColor: "#fff" }}>
            <HeaderForAll />
            {tabRecord == 1 ? (
              <>
                <HomePageHeader
                  userDetails={userDetails}
                  LoginClickFun={LoginClickFun}
                  destinationData={destinationData}
                  logoutFun={logoutFun}
                  loading={loading}
                />

                <>
                  {offerData && offerData.length > 0 && (
                    <HomePageBody
                      destinationData={destinationCard}
                      offerData={offerData}
                      setLoading={(load) => setLoading(load)}
                    />
                  )}
                </>

                <Row
                  style={{ width: "100vw" }}
                  className="py-4"
                  justify="center"
                >
                  <Row style={{ width: "80vw" }}>
                    <Col spna={24}>
                      <p className="h3"> Gos FAQs</p>
                      <br />
                    </Col>
                  </Row>

                  <Col spna={24}>
                    <Collapse accordion style={{ width: "85vw" }}>
                    <Panel
                        header="How do I know if my  reservation is confirmed? "
                        key="3"
                      >
                        <p>
                          After booking, Guest will receive a confirmation
                          email. You can also login to your godsownstay.com
                          account to view and manage your bookings.
                        </p>
                      </Panel>
                      <Panel
                        header="Is it possible for me to get 101%  money back on hotel booking cancellations on gos?"
                        key="2"
                      >
                        <p>
                          Yes, Gos has introduced gos Stay Assured through which
                          customers can cancel their Hotel bookings anytime
                          without loosing any cancellation charges and customers
                          can get 101% money as their refund in all gos Stay
                          Assured properties
                        </p>
                      </Panel>                      
                      <Panel
                        header="Who is the founder of Godsownstay.com? "
                        key="4"
                      >
                        <p>
                          ArunKumar S Neeliparambil ( Arun Neeli) is an young
                          entrepreneur , who is the founder and CEO of
                          Godsownstay.com. He is the visionary behind the idea
                          of introducing first location based ( Geo Location)
                          hotel search and Gos Money+ virtual wallet
                        </p>
                      </Panel>
                      <Panel
                        header="What payment methods are accepted? "
                        key="5"
                      >
                        <p>
                          Gos usually accepts various payment methods, including
                          credit/debit cards, Net Banking , EMI and digital
                          wallets and UPI . Check the accepted payment methods
                          during the booking process.
                        </p>
                      </Panel>
                      <Panel
                        header="Are there any hidden charges & Is my credit card is secured? "
                        key="6"
                      >
                        <p>
                          Gos is aiming to provide transparent pricing. Any
                          additional fees or charges may be clearly communicated
                          during the booking process. & Gos employs secure
                          encryption technology to protect your personal and
                          financial information. Your data is handled with
                          utmost confidentiality.
                        </p>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>

                <HomePageFooter destinationData={destinationData} />
              </>
            ) : tabRecord == 3 ? (
              <Coupons />
            ) : tabRecord == 4 ? (
              <>
                <div className="mobile2ndHeadGos">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={() => setTabRecord(1)}
                  />
                  &nbsp; Gos Care Support
                </div>
                <Row>
                  <Col>
                    <div className="support-text">
                      <FontAwesomeIcon icon={faHeadset} color="#3293FC" />{" "}
                      &nbsp; Need Help ?
                    </div>
                  </Col>
                </Row>
                <Row justify={"start"}>
                  <Col>
                    <div className="support-text">
                      <FontAwesomeIcon icon={faSquarePhone} color="#3293FC" />{" "}
                      &nbsp;{" "}
                      <a
                        href="tel:8286882878"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        8286882878
                      </a>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="support-text">
                      <FontAwesomeIcon icon={faEnvelope} color="#3293FC" />{" "}
                      &nbsp;{" "}
                      <a
                        href="mailTo:customercare@godsownstay.com"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        customercare@godsownstay.com
                      </a>
                    </div>
                  </Col>
                </Row>
                <Row style={{ paddingBottom: "75px" }}>
                  <Col>
                    {/* <div className="support-text">
                      <FontAwesomeIcon
                        icon={faFileCircleMinus}
                        color="#3293FC"
                      />{" "}
                      &nbsp; Terms & Conditions
                    </div>  */}
                    <div
                      className=""
                      style={{
                        textAlign: "left",
                        fontWeight: "400",
                        // padding: "10%",
                        paddingTop: "5%",
                        fontSize: "13px",
                      }}
                    >
                      <ul className="list-group pb-5">
                        <li className="list-group-item fw-bold">
                          Eligibility of customer for Gos E – coupon?
                        </li>
                        <p className="list-group-item-text mx-3">
                          Customers who have received E- Coupon from Gos are
                          eligible. Repeated loyal customers will get a call or
                          email from Gos Care Support Team to get Additional E
                          -Coupon.
                        </p>
                        <li className="list-group-item fw-bold">
                          How to redeem your Coupon?
                        </li>
                        <p className="list-group-item-text mx-3">
                          Select service from Godsownstay.com to apply the E –
                          Coupon for discount, copy and apply your E – Coupon at
                          Gos Checkout page.
                        </p>
                        <li className="list-group-item fw-bold">
                          Could I get 101% Money back on cancellation on Gos?
                        </li>
                        <p className="list-group-item-text mx-3">
                          Yes , Gos has introduced Gos Stay Assured through
                          which a customer can cancel their booking anytime
                          without loosing any fees. It is 101% refundable .T&C
                          Apply.
                        </p>
                        <li className=" list-group-item fw-bold ">
                          Is Gos Care support available 24*7?
                        </li>
                        <p className="list-group-item-text mx-3">
                          Yes, Our dedicated Customer Care Executives are
                          available 24*7.
                        </p>
                        <li className="list-group-item fw-bold ">
                          What payment methods accepted in Gos?
                        </li>
                        <p className="list-group-item-text mx-3">
                          Gos usually accepts various payment methods, including
                          Credit Cards/Debit Cards, Net Banking , EMI and
                          digital wallets including (G-Pay,PhonePe,Paytm, Jio
                          Money, Airtel Money, MobiKwik, ICASH, HDFC Payzapp,
                          OXIGEN Wallet, Scan N Pay, PAYWORLD Money, freecharge
                          Wallet, Ebixcash Wallet, OLA Money). Check the
                          accepted payment methods during the booking process.
                        </p>
                        <li className="list-group-item fw-bold ">
                          Till how many days is the Gos E-coupon valid?
                        </li>
                        <p className="list-group-item-text mx-3">
                          E- Coupon Expire 90 days after issuance Coupon code is
                          valid for Multiple use.{" "}
                        </p>
                        <li className="list-group-item fw-bold ">
                          Can i modify or cancel my reservation?
                        </li>
                        <p className="list-group-item-text mx-3">
                          Whether you need to make a new reservation or modify
                          an existing booking, the customer support team can
                          assist you in navigating the booking process
                          seamlessly.
                        </p>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            {!userDetails?.name ? (
              <LoginModel
                isModalOpen={isModalOpen}
                setisModalOpen={setisModalOpen}
                closeBtnFun={closeBtnFun}
                setLoginOpen={setLoginOpen}
                loginOpen={loginOpen}
              />
            ) : (
              ""
            )}
          </div>
          <BottomFooter>
            <div className="footer">
              <div className="d-flex align-items-center justify-content-around">
                <div
                  className={tabRecord === 1 ? active : deActive}
                  onClick={() => {
                    setTabRecord(1);
                    dispatch(setActiveFooterTab(0));
                  }}
                >
                  <FontAwesomeIcon
                    className="footericon"
                    style={{ fontSize: "1.5rem" }}
                    icon={faHome}
                  />
                  <p style={{ fontSize: "0.7rem" }} className="maxWidth">
                    Home
                  </p>
                </div>
                <div
                  className={tabRecord === 2 ? active : deActive}
                  onClick={() => {
                    setTabRecord(2);
                    dispatch(setActiveFooterTab(0));
                    nav("/bookings");
                  }}
                >
                  <FontAwesomeIcon
                    className="footericon"
                    style={{ fontSize: "1.5rem" }}
                    icon={faBriefcase}
                  />
                  <p className="maxWidth">My Booking</p>
                </div>

                <div
                  className={tabRecord === 3 ? active : deActive}
                  onClick={() => {
                    setTabRecord(3);
                  }}
                >
                  <Discount
                    style={{ height: "25.5px", width: "25.5px" }}
                    stroke={tabRecord === 3 ? "#0551A6" : "#00000029"}
                  />

                  <p className="maxWidth">Discount</p>
                </div>
                <div
                  className={tabRecord === 4 ? active : deActive}
                  onClick={() => {
                    setTabRecord(4);
                    dispatch(setActiveFooterTab(0));
                  }}
                >
                  <HeadsetMicIcon />

                  <p className="maxWidth">Gos Care</p>
                </div>
              </div>
            </div>
          </BottomFooter>
        </>
      )}
    </div>
  );
}

export default HomePage;
