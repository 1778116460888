// // import { useLocation } from "react-router-dom";

// // import { useEffect, useState } from "react";
// import LayoutComponent from "../LayoutComponent";

// function PrivateRoute() {
//   //   const pathNames = [
//   //     "/admin/dashboard", //1
//   //     "/admin/basicSettings/designation",
//   //     "/admin/basicSettings/bank",
//   //     "/admin/basicSettings/currency",
//   //     "/admin/basicSettings/assign-menu",
//   //     "/admin/basicSettings/contact-type",
//   //     "/admin/basicSettings/markup-type",
//   //     "/admin/locationsettings/market-type",
//   //     "/admin/locationsettings/region",
//   //     "/admin/locationsettings/country",
//   //     "/admin/locationsettings/province",
//   //     "/admin/locationsettings/destination",
//   //     "/admin/hotalsettings/category",
//   //     "/admin/hotalsettings/hotaltype",
//   //     "/admin/hotalsettings/occupancy-type",
//   //     "/admin/hotalsettings/season-type",
//   //     "/admin/hotalsettings/room-categiry",
//   //     "/admin/hotalsettings/room-type",
//   //     "/admin/hotalsettings/hotel-amenity",
//   //     "/admin/hotalsettings/room-amenity",
//   //     "/admin/hotalsettings/mealplan",
//   //     "/admin/agentsettings/agent-category",
//   //     "/admin/packagesettings/package-category",
//   //     "admin/packagesettings/package-type",
//   //     "admin/packagesettings/day-activities",
//   //     "admin/agentsettings/itinerary-details",
//   //     "/admin/agentsettings/visa-information",
//   //     "admin/agentsettings/terms-and-conditions",
//   //     "/admin/paymentgateway",
//   //     "/admin/communication-mail",
//   //     "/admin/api/smyrooms",
//   //     "/admin/api/white-sands",
//   //     "/admin/houseboat/houseboat-type",
//   //     "/admin/houseboat/houseboat-roomcategory",
//   //     "/admin/houseboat/houseboat-roomtype",
//   //     "/admin/coupan",
//   //     "/admin/company-profile",
//   //     "/admin/registration/hotelregistration",
//   //     "/admin/registration/employeeRegistration/hotelRegistrationform",
//   //     "/admin/registration/agentregistration",
//   //     "/admin/registration/employeeRegistration",
//   //     "/admin/registration/packageRegistration",
//   //     "/admin/registration/houseBoatRegistration",
//   //     "/admin/registration/houseBoatRegistrationComponent",
//   //     "/admin/registration/shikaraRegistration",
//   //     "/admin/registration/shikaraRegistrationComponent",
//   //     "/admin/registration/shikara/shikaraContactList",
//   //     "/admin/registration/shikara/packagePromotion",
//   //     "/admin/newBooking/hotelBooking",
//   //     "/admin/newBooking/packageBooking",
//   //     "/admin/myBooking/hotelBookingList",
//   //     "/admin/myBooking/packageBookingList",
//   //     "/admin/myBooking/houseboatBookingList",
//   //     "/admin/myBooking/shikaraBookingList",
//   //     "/admin/invoice",
//   //     "/admin/inhouseAccounts/agentAccounts",
//   //     "/admin/inhouseAccounts/paymentGatewayTransactions",
//   //     "/admin/assignedAgents",
//   //     "/admin/adminCalendar",
//   //     "/admin/extranetContractRateComponent",
//   //     "/admin/adminReport/bookingReport",
//   //     "/admin/adminReport/cancellationReport",
//   //     "/admin/adminReport/inventoryStatus",
//   //     "/admin/adminReport/hotelWise",
//   //     "/admin/adminReport/accounts",
//   //     "/admin/adminReport/contractExpiryReport",
//   //     "/admin/adminReport/comparisonReportScreenLoad",
//   //     "/admin/adminReport/contractRateReportScreenLoad",
//   //     "/admin/adminReport/dayWiseReportScreenLoad",
//   //     "/admin/adminReport/agentWiseReportScreenLoad",
//   //     "/admin/adminReport/userReportScreenLoad",
//   //     "/admin/adminReport/stopSaleReportScreenLoad",
//   //     "/admin/adminReport/userLoginsReportScreenLoad",
//   //     "/admin/adminReport/monthlyReportScreenLoad",
//   //   ];
// //   const location = useLocation();

//   const isAuth = localStorage.getItem("token");
//   const role = localStorage.getItem("role");
//   return isAuth ? (
//     role === "admin" ? (
//       <LayoutComponent />
//     ) : (
//       <div>page not found</div>
//     )
//   ) :    (
//     <div>page not found............</div>
//   );
// }

// export default PrivateRoute;

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
// import LayoutComponent from "../LayoutComponent";
import PartnerLayoutComponent from "../patnerLayoutComponent1/PartnerLayoutComponent";

// import PartnerLayoutComponent from "../patnerLayoutComponent1/PartnerLayoutComponent";

const PrivateRoute = () => {
  //   const isAuthenticated = localStorage.getItem(true);
  const [isAuth, setIsAuth] = useState(false);
  const pageRole = localStorage.getItem("pageRole");
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, []);

  return isAuth ? (
    pageRole === "admin" ? (
      // <LayoutComponent />
      <></>
    ) : pageRole === "partner" ? (
      <PartnerLayoutComponent />
    ) : (
      <Navigate to="/login" />
    )
  ) : (
    <span className="fw-30 fs-20">
      Do not have an access <Link to="/login">Please Login</Link>{" "}
    </span>
  );
};

export default PrivateRoute;
