import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { Paper, Typography, Card, CardContent } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getPackageBookingById } from "../../../../services/utils/admin/myBooking/packageBookingList";
// import HotelIcon from "@mui/icons-material/Hotel";
// import DriveEtaIcon from "@mui/icons-material/DriveEta";
// import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
// import RestaurantIcon from "@mui/icons-material/Restaurant";
// import sight from "../../../../assets/vision.png";
import Grid from "@mui/material/Grid";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
// import { Button } from "antd";
const { TabPane } = Tabs;

const PackageBookingOne = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [packageBookingObject, setPackageBookingObject] = useState({});
  // const packageDetails = {
  //   name: "Sample Package Name",
  //   image: "https://via.placeholder.com/500",
  //   overview: "This is a sample package overview.",
  //   type: "This is a package type details.",
  //   billing: "Billing details will be shown here.",
  // };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const getPackageBookingDataById = async () => {
      try {
        const {
          data: { response },
        } = await getPackageBookingById(id);
        setPackageBookingObject(response[0]);
      } catch (err) {
        console.log(err.message);
      }
    };
    getPackageBookingDataById();
  }, []);

  return (
    <div>
      {isMobile && (
        <div className="mobile2ndHead justify-content-start">
          <IconButton onClick={() => nav("/bookings")}>
            <ArrowBackIcon sx={{ color: "white" }} />
          </IconButton>
          <p>&nbsp;&nbsp;Package Booking Details</p>
        </div>
      )}

      <Paper
        elevation={0}
        square
        sx={{
          p: 2,
          mt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card style={{ height: "50%", width: "50%" }}>
          <img
            src={packageBookingObject?.packageID?.packageImage[0]}
            alt="houseboat"
            style={{
              width: "100%",
              height: "300px",
              border: "1px solid black",
            }}
          />
          <CardContent>
            <Typography variant="h5" component="div">
              {packageBookingObject?.packageID?.packageName}
            </Typography>
          </CardContent>
        </Card>
      </Paper>

      {/* <Tabs
        defaultActiveKey="overview"
        size="large"
        animated
        tabBarGutter={40}
        tabBarStyle={{ paddingLeft: "20px" }}
      >
        <TabPane tab="Overview" key="overview">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} spacing={2}>
              <Grid container direction="column">
                <Typography
                  className="text-capitalize"
                  sx={{ pl: 2 }}
                  variant="h6"
                  gutterBottom
                >
                  {packageBookingObject?.packageID?.packageName}
                </Typography>
                <Typography
                  className="text-capitalize"
                  sx={{ pl: 2, color: "grey" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  {packageBookingObject?.packageID?.overview}
                </Typography>
                <Typography sx={{ pl: 2, pb: 2 }}>
                  <Button
                    style={{
                      borderRadius: "0px",
                      backgroundColor: "#5893F6",
                      color: "#fff",
                      fontWeight: "700",
                    }}
                  >
                    {packageBookingObject?.packageID?.noOfNights + 1} Days{" "}
                    {packageBookingObject?.packageID?.noOfNights} Nights
                  </Button>
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} sx={{ pt: 1, pl: 4 }}>
              <Grid container direction={"row"}>
                <Typography sx={{ pl: 2 }} variant="h6">
                  Guest:
                </Typography>
                <Typography
                  className="text-capitalize"
                  variant="h6"
                  sx={{ pl: 1 }}
                  gutterBottom
                >
                  &nbsp;
                  {packageBookingObject?.prefix}.&nbsp;
                  {packageBookingObject?.firstName}&nbsp;
                  {packageBookingObject?.lastName}
                </Typography>
              </Grid>
              <Grid container direction="row">
                <Typography variant="h6" sx={{ pr: 2, pl: 2 }}>
                  Booking Date:
                  <p>
                    {moment(packageBookingObject?.bookingDate).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                </Typography>
                <Typography variant="h6" sx={{ px: 3, pl: 2 }}>
                  Travel Date:
                  <p>
                    {moment(packageBookingObject?.travelDate).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography sx={{ pl: 2 }} variant="h6" gutterBottom>
                Inclusions
              </Typography>
              <Flex
                style={{ paddingLeft: "20px", paddingTop: "7px" }}
                gap={"large"}
              >
                <Row>
                  <Col>
                    <Typography>
                      <HotelIcon
                        fontSize="large"
                        sx={{ color: "rgb(88, 147, 246)" }}
                      />
                    </Typography>
                    <Typography>Hotel</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography className="ps-1">
                      <DriveEtaIcon
                        fontSize="large"
                        sx={{ color: "rgb(88, 147, 246)" }}
                      />
                    </Typography>
                    <Typography>Travel</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography className="ps-2">
                      <DirectionsRunIcon
                        fontSize="large"
                        sx={{ color: "rgb(88, 147, 246)" }}
                      />
                    </Typography>
                    <Typography>Activity</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography>
                      <RestaurantIcon
                        fontSize="large"
                        sx={{ color: "rgb(88, 147, 246)" }}
                      />
                    </Typography>
                    <Typography>Food</Typography>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ paddingTop: "5px" }}>
                    <Typography component={"img"} src={sight}></Typography>
                    <Typography>Sight</Typography>
                  </Col>
                </Row>
              </Flex>
            </Grid>
          </Grid>
        </TabPane>
        <TabPane tab="Billing" key="billing">
          <Typography sx={{ pl: 2 }}>{packageDetails.billing}</Typography>
        </TabPane>
      </Tabs> */}

      <Tabs
        defaultActiveKey="overview"
        size="large"
        animated
        tabBarGutter={40}
        tabBarStyle={{ paddingLeft: "20px" }}
      >
        <TabPane tab="Overview" key="overview">
          <Grid container spacing={3}>
            {/* First Column */}
            <Grid item xs={12} md={4} spacing={2}>
              <Grid container direction="column">
                <Typography
                  className="text-capitalize"
                  sx={{ pl: 2 }}
                  variant="h5"
                  gutterBottom
                >
                  {packageBookingObject?.packageID?.packageName}
                </Typography>
                <Typography
                  className="text-capitalize"
                  sx={{ pl: 2, color: "grey" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  {packageBookingObject?.packageID?.description}
                </Typography>
              </Grid>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} md={4} sx={{ pt: 1, pl: 4 }}>
              <Grid container direction="row">
                <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                  Guest:
                </Typography>
                <Typography
                  variant="h6"
                  className="text-capitalize"
                  gutterBottom
                >
                  {packageBookingObject?.firstName}&nbsp;
                  {packageBookingObject?.lastName}
                </Typography>
              </Grid>
              <Grid container direction="row">
                {/* <Typography variant="h6" sx={{ pr: 2, pl: 2 }}>
                  Booking Date:
                  <p>{moment(data?.bookingDate).format("DD/MM/YYYY")}</p>
                </Typography> */}
                <Typography variant="h6" sx={{ px: 3, pl: 2 }}>
                  Travel Date:
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {moment(packageBookingObject?.travelDate).format(
                    "DD/MM/YYYY"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PackageBookingOne;
