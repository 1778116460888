import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Collapse } from "antd";
import React, { useState } from "react";
import BtnComp from "../../../components/atoms/buttonComponent/BtnComp";
import NewInputComp from "../../../components/atoms/input/NewInputComp";
import SelectComponent from "../../../components/atoms/SelectComponent/SelectComponent";
import NewTextArea from "../../../components/atoms/textAreaComponent/NewTextArea";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { houseboatBookingEmail } from "../../../services/utils/userUtils/houseBoat/indix";
import { createHouseBoatGuestDetails } from "../../../services/utils/userUtils/houseBoat/indix";
import UserSucessModel from "../../../components/molecules/modalComponent/UserSucessModel";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { houseboatSchemaForBookNow } from "../userFormSchema/UserFormSchema";
import moment from "moment";
import NewCard from "../../../components/atoms/card/NewCard";
const { Panel } = Collapse;

const option = [
  {
    label: "Select",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
];

export const HouseBoatBookNow = () => {
  const nav = useNavigate();
  const selector = useSelector((s) => s.userDetails.user);
  const [users, setuserDetails] = useState();

  const {
    state: {
      data: { data, allData },
      guest,
      travel,
    },
  } = useLocation();
  
  const [successModel, setSuccessModel] = useState(false);
  useEffect(() => {
    setuserDetails(selector);
  }, []);

  const [defaultValue, setDefaultValue] = useState(
    (allData?.promotionId?.promotionList.promotionType.percentage
      ? parseInt(
          parseInt(data?.roomRate)
            ? parseInt(data?.roomRate) /
                (1 - allData?.promotionId?.promotionList?.value / 100)
            : ""
        )
      : parseInt(data?.roomRate) + allData?.promotionId?.promotionList.value) -
      (allData?.promotionId?.promotionList.promotionType.percentage
        ? parseInt(
            parseInt(data?.roomRate)
              ? parseInt(data?.roomRate) /
                  (1 - allData?.promotionId?.promotionList?.value / 100) -
                  parseInt(data?.roomRate)
              : ""
          )
        : allData?.promotionId?.promotionList.value)
  );
  // debugger;
  const couponApply = () => {
    let numbers = couponCode?.match(/\d+/g)?.join("");

    if (numbers) {
      setDefaultValue(defaultValue - (defaultValue * parseInt(numbers)) / 100);
    }
    handleChange({ target: { name: "couponCode", value: "" } });
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setValues,
    handleReset,
  } = useFormik({
    initialValues: {
      houseboatID: "",
      user_id: "",
      prefix: "",
      firstName: "",
      lastName: "",
      email: "",
      childAge: "0",
      mobileNumber: "",
      specialRequest: "",
      billingDetails: {
        arrival: "",
        travelDate: "",
        departure: "",
        room: "",
        guest: "",
        subtotal: "",
        discount: "",
        payable: "",
        couponCode: "",
        totalCost: "",
      },
    },

    validationSchema: houseboatSchemaForBookNow,
    onSubmit: () => {
      setSuccessModel(true);
    },
  });

  useEffect(() => {
    document.getElementById("HbPaydetail").scrollIntoView({ behavior: "smooth", block: "end"});
  }, []);

  const modelHandleOk = async () => {
    const res = await createHouseBoatGuestDetails(values);
    // debugger;
    const emailData = await houseboatBookingEmail(res);
    // if (data.error !== true) {
    //   nav("/houseboat/other");
    //   setSuccessModel(false);
    //   handleReset();
    // }
    if (emailData) {
      nav("/houseboat/houseboat-in-alleppey.html");
      setSuccessModel(false);
      handleReset();
    }
  };

  const {
    prefix,
    firstName,
    lastName,
    email,
    mobileNumber,
    couponCode,
    specialRequest,
  } = values;

  useEffect(() => {
    const contractId = data?.contractId;
    // debugger;
    setValues({
      ...values,
      houseboatID: allData._id,
      user_id: users?.id,
      billingDetails: {
        travelDate: travel?.travelDate?.split("/")?.reverse()?.join("-"),
        totalTime: contractId && contractId?.timeHrs,
        guest:
          guest &&
          `${guest.guest.adults} adults ${guest.guest.children} children `,
        subtotal: allData?.promotionId?.promotionList.promotionType.percentage
          ? parseInt(
              parseInt(data?.roomRate)
                ? parseInt(data?.roomRate) /
                    (1 - allData?.promotionId?.promotionList?.value / 100)
                : ""
            )
          : parseInt(data?.roomRate) +
            allData?.promotionId?.promotionList.value,
        discount: allData?.promotionId?.promotionList.promotionType.percentage
          ? parseInt(
              parseInt(data?.roomRate)
                ? parseInt(data?.roomRate) /
                    (1 - allData?.promotionId?.promotionList?.value / 100) -
                    parseInt(data?.roomRate)
                : ""
            )
          : allData?.promotionId?.promotionList.value,
        couponCode: couponCode,
        totalPayable: defaultValue,
      },
    });
  }, [couponCode, defaultValue, users]);

  return (
    <div style={{ background: "#ffffff", height: "900px", marginTop: "10px" }}>
      <Row justify={"center"} gutter={[10, 10]}>
        <Col xs={22} sm={22} md={11} lg={11} xl={11} xxl={11}>
          <Row>
            <Col span={24}>
              <h3 id="HbPaydetail">Guest Information</h3>
            </Col>
            <Row
              style={{ width: "100%", marginBottom: "2%" }}
              gutter={[20, 20]}
            >
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <SelectComponent
                  label={<h6>Prefix</h6>}
                  name="prefix"
                  value={prefix}
                  options={option}
                  handleChange={(e) => setValues({ ...values, prefix: e })}
                />
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <NewInputComp
                  label={<h6>First Name</h6>}
                  name="firstName"
                  value={firstName}
                  placeholder={"First Name"}
                  className={"inputField"}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <NewInputComp
                  label={<h6>Last Name</h6>}
                  name="lastName"
                  value={lastName}
                  placeholder={"Last Name"}
                  className={"inputField"}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>
            <Row
              style={{ width: "100%", marginBottom: "2%" }}
              gutter={[20, 20]}
            >
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <NewInputComp
                  label={<h6>Mobile Number</h6>}
                  placeholder={"Mobile Number"}
                  name="mobileNumber"
                  value={mobileNumber}
                  className={"inputField"}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <NewInputComp
                  label={<h6>E-mail Address</h6>}
                  name="email"
                  value={email}
                  placeholder={"E-mail Address"}
                  className={"inputField"}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>
            <Row style={{ width: "100%" }} gutter={20}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <NewTextArea
                  label={<h6>Special Requests</h6>}
                  name="specialRequest"
                  value={specialRequest}
                  className={"inputField"}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col>
                <NewCard
                  title={<p style={{ fontWeight: "700" }}>FAQ</p>}
                  style={{ width: "100%" }}
                >
                  <Collapse accordion size="large">
                    <Panel header="Different categories of Houseboats?" key="1">
                      <p className="text-body">
                        Generally , Houseboats comes under 3 categories namely
                        Standard Class ,Deluxe Class ,and First Class(Luxury
                        Class) house boats. In deluxe house boats , AC will be
                        operated only from 9:00 pm to 6:00 am while for First
                        Class houseboats AC will be operated round the clock.
                      </p>
                    </Panel>
                    <Panel
                      header="What is the minimum stay in the houseboat?"
                      key="2"
                    >
                      <p className="text-body">
                        Day trip & Overnight stay are the two major cruises
                        available. <br />
                        a. For overnight stay -check-in is usually around 12:00
                        noon while checkout is next day 9:00 am. (including
                        lunch and evening tea & snacks + dinner+ next day
                        breakfast) <br />
                        b. For day cruise -check-in time for day cruise is by
                        11:00 am while checkout time is 5:00 pm (includes
                        lunch(veg/non veg)+evening tea & snacks) <br />
                        c. Night stay -check-in time is 5:30 pm checkout is next
                        day 9:00 am (including dinner + next day breakfast)
                      </p>
                    </Panel>
                    <Panel
                      header="Is there any advance payment required for the booking and how can I clear my payments later?"
                      key="3"
                    >
                      <p className="text-body">
                        Yes, advance deposit required , it depends on houseboats
                        , this is to be sure that your booking is genuine and we
                        can go ahead with the reservations. The rest of the due
                        amount can be paid at the check in time.
                      </p>
                    </Panel>
                  </Collapse>
                </NewCard>
              </Col>
            </Row>
          </Row>
        </Col>

        <Col xs={22} sm={22} md={11} lg={11} xl={11} xxl={11}>
          <Row style={{ border: "1px solid #ddd", padding: "0% 3%" }}>
            <Row
              style={{ width: "100%", marginBottom: "2%", paddingTop: "5px" }}
            >
              <h3>Billing Details</h3>
            </Row>

            <Row
              justify={"center"}
              style={{
                width: "100%",
                marginBottom: "2%",
                background: "#f5f5f5",
                border: "1px solid #eee",
                padding: "10px 15px",
              }}
            >
              <h6>{`Billing Summary 1 (${allData.houseBoatName})`}</h6>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              {/* <Row > */}
              <Col>
                Arrival <br />
                Departure
              </Col>

              <Col>
                {/* {moment(guest.ckeckIn).format("ll")} <br /> */}
                {moment(travel.travelDate, "DD/MM/YYYY").format("ll")} <br />
              </Col>
              {/* </Row> */}
            </Row>

            <Row
              // justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <Col span={12}>Room</Col>
              <Col span={12} style={{ textAlign: "right" }}>
                {data && data.roomCategory.roomCategoryName}{" "}
                {`(${guest?.guest?.rooms})`}
              </Col>
              <Col span={12}>Guests</Col>
              <Col
                span={12}
                style={{ textAlign: "right" }}
              >{`${guest.guest.adults} Adults , ${guest.guest.children} children`}</Col>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <Col>
                <h6>Subtotal (INR) :</h6>
              </Col>
              <Col>
                {" "}
                <h6 style={{ marginRight: "5px" }}>
                  {allData?.promotionId?.promotionList?.promotionType
                    ?.percentage &&
                  allData?.contractId?.contractRateDetails?.length > 0
                    ? parseInt(
                        parseInt(data?.roomRate) /
                          (1 - allData?.promotionId?.promotionList?.value / 100)
                      )
                    : parseInt(data?.roomRate) +
                      allData?.promotionId?.promotionList?.value}
                </h6>
              </Col>
            </Row>

            <Row
              justify={"space-between"}
              style={{
                width: "100%",
                marginBottom: "2%",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
              }}
            >
              <Col>
                <h6>Discount (INR):</h6>
              </Col>
              <Col>
                {" "}
                {/* <h6 style={{ marginRight: "5px" }}>
                  {allData && allData.roomRate - allData.discountRate}
                </h6> */}
                <h6 style={{ marginRight: "5px" }}>
                  {allData?.promotionId?.promotionList.promotionType.percentage
                    ? parseInt(
                        parseInt(data?.roomRate)
                          ? parseInt(data?.roomRate) /
                              (1 -
                                allData?.promotionId?.promotionList?.value /
                                  100) -
                              parseInt(data?.roomRate)
                          : ""
                      )
                    : allData?.promotionId?.promotionList.value}
                </h6>
              </Col>
            </Row>

            <Row
              style={{
                background: "#f5f5f5",
                marginBottom: "3%",
                padding: "8px 0px",
              }}
            >
              <Row
                style={{
                  width: "100%",
                  marginBottom: "2%",
                  borderBottom: "1px solid #ddd",
                  alignItems: "baseline",
                }}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ padding: "8px 15px" }}
                >
                  <h5>Coupon Code</h5>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ padding: "8px 15px" }}
                >
                  <NewInputComp
                    label={""}
                    name="couponCode"
                    value={couponCode}
                    star={"none"}
                    className={"inputField"}
                    handleChange={handleChange}
                  />
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  style={{ padding: "8px 15px" }}
                >
                  <BtnComp
                    btnName={"Apply"}
                    width="100%"
                    height="100%"
                    bg_color="#ff4d63"
                    color="#ffffff"
                    fontSize="17px"
                    btnClickFun={couponApply}
                  />
                </Col>
              </Row>

              <Row
                justify={"space-between"}
                style={{
                  width: "100%",
                  marginBottom: "2%",
                  borderBottom: "1px solid #ddd",
                  padding: "8px 15px",
                }}
              >
                <Col>
                  <h6>Total Payable (including tax) :</h6>
                </Col>

                <Col>
                  <h6 style={{ marginRight: "5px" }}>{defaultValue}</h6>
                </Col>
              </Row>
            </Row>

            <Row style={{ width: "100%", marginBottom: "2%" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <BtnComp
                  btnName={"Submit Query"}
                  width="100%"
                  height="100%"
                  bg_color="#3d75df"
                  color="#ffffff"
                  fontSize="17px"
                  btnClickFun={handleSubmit}
                />
              </Col>
            </Row>

            <Row
              justify={"center"}
              style={{ width: "100%", alignItems: "baseline" }}
            >
              <FontAwesomeIcon
                icon={faLock}
                style={{ color: "#3d75df", marginRight: "1%" }}
              />
              <h5 style={{ color: "#3d75df" }}>Safe And Secure</h5>
            </Row>
          </Row>
        </Col>
        <UserSucessModel
          isModalOpen={successModel}
          handleOk={modelHandleOk}
          handleCancel={() => setSuccessModel(false)}
        />
      </Row>
    </div>
  );
};
