import React from "react";

import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Grid,
  Input,
  Modal,
  Row,
  Space,
} from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  // faCalendar,
  faLocationDot,
  faMinusCircle,
  faPlusCircle,
  faUserPlus,
  // faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  // CalendarOutlined,
  DownOutlined,
} from "@ant-design/icons";
const { RangePicker } = DatePicker;
// const dateFormat = "YYYY/MM/DD";
const dateFormat = "DD/MM/YYYY";

const ShikaraConfermModal = ({
  open,
  handleok,
  handlecancel,
  locationValue,
  guest,
  setGuest,
  datepicker,
  // setbuyNowModelData,
  // checkIn,
  // handleCheckIn,
  // modelData,
  setModelData,
  val,
  // room,,

  rooms,
}) => {
  // debugger;
  const { useBreakpoint } = Grid;
  const { xs, lg, md } = useBreakpoint();
  const [userModalOpen, setUserModalOpen] = useState(false);

  const guestAdd = (field) => {
    setGuest({ ...guest, [field]: Number(guest[field]) + 1 });
  };
  const guestSub = (field) => {
    setGuest({ ...guest, [field]: Number(guest[field]) - 1 });
  };

  const cancelbtnfunction = () => {
    setUserModalOpen(false);
    // setbuyNowModelData(o=>({...o,status:!o.status}))
    setTimeout(() => {
      handlecancel();
    }, 500);
  };

  // const applyBtnFun = () => {
  //   if (room) {
  //     setModelData({
  //       ...modelData,
  //       guest: {
  //         rooms: guest.rooms,
  //         adults: guest.adults,
  //         children: guest.children,
  //       },
  //     })
  //   } else {
  //     setModelData({
  //       ...modelData,
  //       guest: {
  //         adults: guest.adults,
  //         children: guest.children,
  //       },
  //     })
  //   }
  //   setUserModalOpen(false)
  // }

  const items = [
    {
      key: "1",
      label: (
        <Row className="guest-dropdown-modal">
          <Col>
            {rooms && (
              <Row justify={"center"}>
                <p className="h-4">
                  <label htmlFor="sddd" className="guest-lable">
                    Rooms
                  </label>
                </p>
                <Row
                  style={{ width: "100%", paddingTop: "2%" }}
                  justify="center"
                >
                  <Col span={5}>
                    <Row
                      className="childAgeboxbtn"
                      onClick={() => guestSub("rooms")}
                    >
                      <FontAwesomeIcon
                        className="plus-minus-icon"
                        icon={faMinusCircle}
                      />
                    </Row>
                  </Col>
                  <Col span={5} className="childBoxtxt">
                    {guest?.rooms}
                  </Col>
                  <Col span={5}>
                    <Row
                      className="childAgeboxbtn"
                      onClick={() => guestAdd("rooms")}
                    >
                      <FontAwesomeIcon
                        className="plus-minus-icon"
                        icon={faPlusCircle}
                      />
                    </Row>
                  </Col>
                </Row>
              </Row>
            )}
            {/* <Row justify={'center'}>
              <p className="h-4">
                <lable className="guest-lable">Rooms</lable>
              </p>
              <Row style={{ width: '100%', paddingTop: '2%' }} justify="center">
                <Col span={5}>
                  <Row
                    className="childAgeboxbtn"
                    onClick={() => guestSub('rooms')}
                  >
                    <FontAwesomeIcon
                      className="plus-minus-icon"
                      icon={faMinusCircle}
                    />
                  </Row>
                </Col>
                <Col span={5} className="childBoxtxt">
                  {guest?.rooms}
                </Col>
                <Col span={5}>
                  <Row
                    className="childAgeboxbtn"
                    onClick={() => guestAdd('rooms')}
                  >
                    <FontAwesomeIcon
                      className="plus-minus-icon"
                      icon={faPlusCircle}
                    />
                  </Row>
                </Col>
              </Row>
            </Row> */}
            <Row justify={"center"}>
              <p className="h-4">
                <label htmlFor="sddd" className="guest-lable">
                  Adults
                </label>
              </p>
              <Row style={{ width: "100%", paddingTop: "2%" }} justify="center">
                <Col span={5}>
                  <Row
                    className="childAgeboxbtn"
                    onClick={() => guestSub("adults")}
                  >
                    <FontAwesomeIcon
                      className="plus-minus-icon"
                      icon={faMinusCircle}
                    />
                  </Row>
                </Col>
                <Col span={5} className="childBoxtxt">
                  {guest?.adults}
                </Col>
                <Col span={5}>
                  <Row
                    className="childAgeboxbtn"
                    onClick={() => guestAdd("adults")}
                  >
                    <FontAwesomeIcon
                      className="plus-minus-icon"
                      icon={faPlusCircle}
                    />
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row justify={"center"}>
              <p className="h-4">
                <label htmlFor="sddd" className="guest-lable">
                  Children (1 - 12yrs)
                </label>
              </p>
              <Row style={{ width: "100%", paddingTop: "2%" }} justify="center">
                <Col span={5}>
                  <Row
                    className="childAgeboxbtn"
                    onClick={() => guestSub("children")}
                  >
                    <FontAwesomeIcon
                      className="plus-minus-icon"
                      icon={faMinusCircle}
                    />
                  </Row>
                </Col>
                <Col span={5} className="childBoxtxt">
                  {guest?.children}
                </Col>
                <Col span={5}>
                  <Row
                    className="childAgeboxbtn"
                    onClick={() => guestAdd("children")}
                  >
                    <FontAwesomeIcon
                      className="plus-minus-icon"
                      icon={faPlusCircle}
                    />
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row>
              <Col span={24}>
                <hr />
              </Col>
            </Row>
            <Row justify={"center"}>
              <Col span={10}>
                <Button
                  className="applybtn"
                  onClick={() => {
                    setUserModalOpen(false);
                  }}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    return () => setUserModalOpen(false);
  }, []);

  return (
    <div className="main-model">
      <Row className="main-model-one">
        <Col sx={18} sm={18} md={18} lg={18} xl={18} xxl={18}>
          <Modal
            className="model-content-div"
            width={"70%"}
            footer={null}
            open={open}
            onOk={handleok}
            closeIcon={<></>}
            // onCancel={handlecancel}
            onCancel={cancelbtnfunction}
            centered
          >
            <Row>
              <Col span={24} className="userMOdel-header">
                <p>Enter Occupancy Details</p>
              </Col>
            </Row>
            <Row
              style={{
                width: "98%",
                borderTop: `${xs ? "none" : "1px solid #ccc"}`,
                paddingTop: "5px",
                marginLeft: "2%",
              }}
              className="userModel-dropdown"
            >
              {lg || md ? (
                <Col
                  xs={24}
                  sm={12}
                  md={24}
                  lg={16}
                  xl={16}
                  className="checkIn-checkOut "
                >
                  <Row>
                    <Col span={12}>
                      <label htmlFor="sddd" className="check-lable">
                        {" "}
                        Going To
                      </label>
                    </Col>
                    <Col span={12}>
                      <Row style={{ width: "100%" }}>
                        <Col span={12}>
                          <label htmlFor="sddd" className="check-lable">
                            Check-In
                          </label>
                        </Col>
                        {!datepicker && (
                          <Col span={12}>
                            <label htmlFor="sddd" className="check-lable">
                              Check-out
                            </label>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <FontAwesomeIcon
                        color={"#ccc"}
                        fontSize={"20px"}
                        className="vertical-line"
                        icon={faLocationDot}
                      />
                      <Input
                        placeholder="My Data"
                        value={locationValue}
                        disabled={true}
                        className="location-icon-space"
                        style={{
                          width: "43%",
                          color: "rgb(204, 204, 204)",
                          marginLeft: "10px",
                        }}
                      />
                    </Col>
                    <Col span={12} style={{ whiteSpace: "nowrap" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <FontAwesomeIcon
                          color={"#ccc"}
                          fontSize={"20px"}
                          icon={faCalendar}
                        />
                        {/* <DatePicker
                                                    prefixIcon={<CalendarOutlined />}
                                                    format="YYYY-MM-DD"
                                                    // defaultValue={dayjs('2015-01-01')}
                                                    value={dayjs(checkIn)}
                                                    popupClassName="calenderPop"
                                                    separator={
                                                        <>
                                                            <FontAwesomeIcon color={"#ccc"} fontSize={"20px"} className="vertical-line"
                                                                icon={faCalendar} />
                                                        </>
                                                    }
                                                    onChange={handleCheckIn}
                                                    suffixIcon=""
                                                    className="serchInputcard "
                                                    style={{ width: "80%" }}
                                                    dateRender={(current) => {
                                                        return (
                                                            <div className="ant-picker-cell-inner serchInputcard ">
                                                                {current.date()}
                                                               
                                                            </div>
                                                        );
                                                    }}
                                                /> */}

                        {datepicker ? (
                          <DatePicker
                            format={dateFormat}
                            onChange={(_, s) => {
                              setGuest((o) => ({ ...o, chekin: s }));
                              setModelData((prev) => ({ ...prev, checkIn: s }));
                            }}
                          />
                        ) : (
                          <RangePicker
                            // defaultValue={[
                            //     dayjs(checkIn),
                            //     dayjs(checkIn),
                            // ]}
                            format={dateFormat}
                            onChange={(_, s) =>
                              setGuest((o) => ({ ...o, chekin: s }))
                            }
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              ) : (
                //mobile code
                <Col
                  span={24}
                  className="checkIn-checkOut "
                >
                  <Row>
                    <Col span={24} className="check-input-border">
                      <Row style={{ display: "flex" }}>
                        <Col lg={8} md={8} sm={10} xs={24}>
                          <label htmlFor="sddd" className="check-lable">
                            {" "}
                            Going To
                          </label>
                          <FontAwesomeIcon
                            color={"#ccc"}
                            fontSize={"20px"}
                            className="vertical-line"
                            icon={faLocationDot}
                          />
                          <Input
                            style={{ width: "43%" }}
                            placeholder="My Data haishfoiw asfhfousgahfu"
                            value={locationValue}
                            className="location-icon-space"
                          />
                        </Col>
                        <Col
                          lg={8}
                          md={8}
                          sm={12}
                          xs={24}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <label htmlFor="sddd" className="check-lable">
                            {" "}
                            Check-In
                          </label>
                          <div style={{ display: "flex", gap: "15px" }}>
                            <FontAwesomeIcon
                              color={"#ccc"}
                              fontSize={"20px"}
                              icon={faCalendar}
                            />
                            <DatePicker
                              format={dateFormat}
                              // superNextIcon={<CalendarOutlined />}
                              // defaultValue={[
                              //   dayjs('2015/01/01', dateFormat),
                              //   dayjs('2015/01/01', dateFormat),
                              // ]}
                              onChange={(_, s) => {
                                setGuest((o) => ({ ...o, chekin: s }));
                                setModelData((prev) => ({
                                  ...prev,
                                  checkIn: s,
                                }));
                              }}
                            />
                          </div>
                          {/* <DatePicker
                                                     onChange={handleCheckIn}
                                                    //  onChange={(e,s)=>setmodelData({...modelData,ckeckIn:s})}
                                                     format="YYYY-MM-DD"
                                                     defaultValue={dayjs('2015-01-01')}
                                                    value={ dayjs(checkIn)}
                                                    // onChange={onChange} 
                                                    /> */}
                        </Col>

                        {/* <Col lg={8} md={8} sm={24} xs={24}>
                                                    <Row>
                                                        <Col lg={24} md={24} sm={24} xs={24}>
                                                            <lable className="check-lable">Guest</lable>
                                                        </Col>
                                                        <Col lg={24} md={24} sm={24} xs={24}>
                                                            <Dropdown
                                                                className='user-dropdown'
                                                                autoAdjustOverflow={true}
                                                                placement="bottomRight"
                                                                overlayClassName="GuestDropdown"

                                                                open={userModalOpen}
                                                                onClick={() => setUserModalOpen(userModalOpen ? false : true)}
                                                                menu={{
                                                                    items,
                                                                }}
                                                            >
                                                                <Space>
                                                                    <p style={{ color: "#ccc" }}>
                                                                        <FontAwesomeIcon icon={faUser} color={"#ccc"} fontSize={"20px"} />
                                                                        &nbsp; 2 Rooms,4 Guest &nbsp;
                                                                        <DownOutlined style={{ color: "#000" }} />
                                                                    </p>
                                                                </Space>
                                                            </Dropdown>
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col
                lg={8}
                md={8}
                sm={24}
                xs={24}
                className="guest-dropdown-div pt-2"
              >
                <Row>
                  <Col
                    lg={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="guest"
                    style={{ marginLeft: "-8%" }}
                  >
                    <label htmlFor="sddd" className="check-lable">
                      {" "}
                      Guest
                    </label>
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Dropdown
                      className="user-dropdown"
                      autoAdjustOverflow={true}
                      placement="bottomRight"
                      overlayClassName="GuestDropdown GuestDropdown1"
                      open={userModalOpen}
                      onClick={() =>
                        setUserModalOpen(userModalOpen ? false : true)
                      }
                      menu={{
                        items,
                      }}
                    >
                      <Space>
                        <p style={{ color: "#ccc" }}>
                          <FontAwesomeIcon
                            icon={faUserPlus}
                            color={"#ccc"}
                            fontSize={"20px"}
                          />

                          {` ${rooms ? guest?.rooms + ` Rooms` : ""}  ${
                            Number(guest?.children) + Number(guest?.adults)
                          } Guest `}
                          <DownOutlined style={{ color: "#000" }} />
                        </p>
                      </Space>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify={"center"} className="margin-top-price">
              {val && guest && (
                <Col span={18}>
                  <p className="price-room-detail">
                    {val?.roomRate}
                    <span>{`(per room)`}</span>&nbsp;{" "}
                    <s>{val?.actualRoomRate}</s> &nbsp;{" "}
                    {~~((val?.discountRate * 100) / val.actualRoomRate)}%
                    &nbsp;Off /{guest?.rooms}-Room-
                    {Number(guest?.adults) + Number(guest?.children)}-Guest
                  </p>
                </Col>
              )}
            </Row>
            <Row className="div-index">
              <Col span={24}>
                <Button
                  className="user-model-closebtn"
                  onClick={cancelbtnfunction}
                  style={{ backgroundColor: "#ff4d63" }}
                >
                  <span style={{ cursor: "pointer" }}>
                    {/* <FontAwesomeIcon
                      color={'#fff'}
                      className="fa-solid-icon"
                      icon={faX}
                    /> */}
                    Cancel
                  </span>
                </Button>
                <Button
                  className="user-model-okbtn"
                  style={{ backgroundColor: "rgb(61, 117, 223)" }}
                  onClick={handleok}
                >
                  <span style={{ cursor: "pointer" }}>Confirm Booking</span>
                </Button>
              </Col>
            </Row>
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default ShikaraConfermModal;
