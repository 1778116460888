import serviceUtil from "../../..";

const config = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  body: JSON.stringify(),
};

const locationSettingMarketTypeGetAll = () => {
  return serviceUtil
    .get("/master/locationSettings/getMarketType")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const locationSettingMarketTypeCreate = (payload) => {
  return serviceUtil
    .post("/master/locationSettings/createMarketType", payload, config)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const locationSettingMarketTypeUpdate = (marketTypeId, payload) => {
  return serviceUtil
    .put(
      `/master/locationSettings/updateMarketType/${marketTypeId}`,
      payload,
      config
    )
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const locationSettingMarketTypeDelete = (marketTypeId) => {
  return serviceUtil
    .deleteById(`/master/locationSettings/deleteMarketType/${marketTypeId}`)
    .then((res) => {
      const { data, message, err } = res && res.data;
      return { data, message, err };
    })
    .catch((err) => ({ err }));
};

export {
  locationSettingMarketTypeGetAll,
  locationSettingMarketTypeCreate,
  locationSettingMarketTypeUpdate,
  locationSettingMarketTypeDelete,
};
