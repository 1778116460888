import { createSlice } from "@reduxjs/toolkit";
import { userDetailsState } from "../../constants/states";

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: {
    // user: structuredClone(userDetailsState),
    user: { ...userDetailsState },
    currentPage: { hotel: [] },
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action.payload;
    },
    setHotel: (state, action) => {
      state.currentPage = { hotel: action.payload };
    },
    removeUserDetails: (state) => {
      // state.user = userDetailsState;
      state.user = { ...userDetailsState };
    },
  },
});

export const { setUserDetails, removeUserDetails, setHotel } =
  userDetailsSlice.actions;
export default userDetailsSlice.reducer;
