



import { DatePicker, Row } from "antd";
import React from "react";

function InputDate({
  star = 'block',
  label = 'name',
  className,
  name,
  value,
  handleChange,
  placeholder,
  errors,
  touched,
  prefix,
  type,
  suffixIcon,
  format
}) {
  return (
    <div className="re-inp-box">
      <Row>
        <p style={{ display: `${star}` }} className="re-input-p">
          *
        </p>
        {label}
      </Row>
      <Row>
    
        <DatePicker
          name={name}
          type={type}
          suffixIcon={suffixIcon}
          format={format}
          style={{
            border: `${
              errors?errors[name] && touched[name] ? '1px solid #d16e6c' : '':null
            }`,
            color: '#858585',
          }}
          prefix={prefix} 
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className={className}
        />
        {errors?errors[name] && touched[name] ? (
          <p className="re-err fs-10">{errors[name]}</p>
        ) : null:null}
      </Row>
    </div>
  );
}

export default InputDate;

