const SHIKARA_BOOK_LIST_TABLE_HEADERS = [
  {
    dataIndex: "col1",
    title: "S.N",
    nummeric: false,
    disablePadding: true,
    label: "S.N",
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 30,
  },
  {
    dataIndex: "col2",
    title: "Customer Name",
    nummeric: false,
    disablePadding: true,

    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 80,
  },
  {
    dataIndex: "col3",
    title: "Customer Email",
    nummeric: false,
    disablePadding: true,

    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 80,
  },
  {
    dataIndex: "col4",
    title: "Customer Phone",
    nummeric: false,
    disablePadding: true,

    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 50,
  },
  {
    dataIndex: "col5",
    title: "Shikara Name",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 70,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  // {
  //   dataIndex: "col4",
  //   title: "Total Time",
  //   nummeric: false,
  //   disablePadding: true,
  //   label: "Category",
  //   filter: "select",
  //   width: 50,
  //   sorter: {
  //     compare: (a, b) => a.math - b.math,
  //     multiple: 2,
  //   },
  // },
  {
    dataIndex: "col6",
    title: "Booking Date",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 50,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  {
    dataIndex: "col7",
    title: "Actions",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 30,
  },
];

export default SHIKARA_BOOK_LIST_TABLE_HEADERS;
