import React, { useEffect } from "react";
import {
  //  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useFormik } from "formik";
import BtnComp from "../../../components/atoms/buttonComponent/BtnComp";
import NewInputComp from "../../../components/atoms/input/NewInputComp";
import NewTextArea from "../../../components/atoms/textAreaComponent/NewTextArea";
import { useLocation } from "react-router-dom";
import { feedbackSchema } from "./feedbackSchema";
import { feedbackSendFunction } from "../../../services/utils/userUtils/hotelutils/index";
import { NotificationBox } from "../../../components/atoms/notificationBox/NotificationBox";

const FeedBackLink = () => {
  const [contextHolder, openNotificationSuccess, openNotificationError] =
    NotificationBox();
  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      email: "",
      message: "",
    },
    validationSchema: feedbackSchema,
    onSubmit: async (value, helpers) => {
      try {
        const res = await feedbackSendFunction(value);
        if (res) {
          openNotificationSuccess("Feedback sent to Admin Successfully..");
        }
      } catch (err) {
        openNotificationError("Something went wrong..");
      }
      helpers.resetForm();
    },
  });
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="feedBack_div">
      {contextHolder}
      <Row className="  form-padding">
        <Col xs={12} sm={6} md={6} lg={6}>
          <Row style={{ textAlign: "left" }}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <h3>Feedback</h3>
              <NewInputComp
                className="input-border-radius"
                value={values.name}
                errors={errors}
                touched={touched}
                star="none"
                label=""
                name="name"
                placeholder="Enter Name"
                handleChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <NewInputComp
                className="input-border-radius"
                value={values.phoneNumber}
                errors={errors}
                touched={touched}
                star="none"
                label=""
                name="phoneNumber"
                placeholder="Mobile Number"
                handleChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <NewInputComp
                className="input-border-radius"
                value={values.email}
                errors={errors}
                touched={touched}
                star="none"
                label=""
                name="email"
                placeholder="Enter Email"
                handleChange={handleChange}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={{
                marginTop: "2%",
              }}
            >
              <NewTextArea
                className="input-border-radius"
                value={values.message}
                errors={errors}
                touched={touched}
                star="none"
                label=""
                rows="5"
                name="message"
                placeholder="Message"
                handleChange={handleChange}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              style={{
                textAlign: "center",
                paddingTop: "4%",
              }}
            >
              <BtnComp
                style={{
                  borderRadius: "5px",
                }}
                btnName={"Submit"}
                bg_color="#084fa7"
                color="#fff"
                btnClickFun={handleSubmit}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6}>
          <Row style={{ textAlign: "center" }}>
            <Col className="feedback-address">
              <h3>Address</h3>
              <p style={{ fontWeight: "bold" }}>
                Gods Own Stay Private Limited
              </p>
              <address>
                Valamkottil Towers,
                <br />
                6/858-M, Judgemukku,
                <br />
                Thrikkakara,
                <br />
                Kerala,
                <br />
                Ernakulam,Kerala,India.
                <br />
                <span style={{ fontWeight: "600" }}>Mail :</span>{" "}
                customercare@godsownstay.com
                <br />
                <span style={{ fontWeight: "600" }}> Mobile :</span>82868 82878
              </address>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FeedBackLink;
