import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

function DropDownComp({
  DropOptions = [],
  style,
  overlayStyle,
  placement,
  arrow,
  icon,
  open,
}) {
  const items = DropOptions.map((v, i) => {
    return {
      label: v,
      key: i,
    };
  });

  return (
    <div style={{ padding: "2px", width: "30px", ...style }} className="dr">
      <Dropdown
        open={open}
        overlayStyle={{ ...overlayStyle }}
        menu={{
          items,
        }}
        trigger={["click"]}
        placement={placement}
        arrow={arrow}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>{icon ? <CaretDownOutlined /> : <DownOutlined />}</Space>
        </a>
      </Dropdown>
    </div>
  );
}

export default DropDownComp;
