// import ADMIN_SIDEBAR_MENU_ITEMS from "./slidebar/AdminSlidebarMenuItems";
import AGENT_REGISTRATION_HEADERS from "./registrationHeaders/AgentRegistrationHeaders";
import AGENT_SETTINGS_HEADERS from "./agentSettings/AgentSettingsHeaders";
// import AGENT_WISE_INHOUSEAGENT_HEADERS from "./adminReport/agentWiseReportHeaders/AGENT_WISE-INHOUSEAGENT_HEADERS";
// import API_SMYROOMS from "./Api/APISmyRooms";
// import API_WHITE_SANDS from "./Api/APIWhiteSands";
import ASSIGNEDAGENTS_HEADERS from "./registrationHeaders/AssignedAgentsHeaders";
// import BASIC_SETTINGS_ACCOUNTS_TYPE from "./adminReport/basicSettingAccountsHeaders/BasicSettingAccountsHeaders";
import BASIC_SETTINGS_ASSIGN_MENU from "./basicSettingAssignMenu/BasicSettingAssignMenu";
import BASIC_SETTINGS_BANK from "./basicSettingsBank/BasicSettingsBank";
import BASIC_SETTINGS_CONTACT_TYPE from "./basicSettingsContentType/BasicSettingsContentType";
// import BASIC_SETTINGS_CONTRACT_EXPIRY_REPORT from "./adminReport/basicSettingContractExpiry/BasicSettingContractExpiry";
import BASIC_SETTINGS_CURRENCY from "./basicSettingsCurrency/BasicSettingsCurrency";
import BASIC_SETTINGS_DESIGNATION from "./basicStettingsDesignation/BasicSettingsDesignationsHeadders";
import BASIC_SETTINGS_MARKUP_TYPE from "./basicSettingsMarkupType/BasicSettingsMarkupType";
import BLOCK_CHECKIN_CHECKOUT from "./partnerHeaders/hotelAvailability/BLOCK_CHECKIN_CHECKOUT";
import BOOKED_PACKAGES_TABLE_HEADERS from "./MyBooking/BookedPackagesHeader";
// import BOOKING_REPORT_HEADERS from "./adminReport/bookingReportHeaders/BOOKING_REPORT_HEADERS";
// import CANCELLATION_REPORT_HEADERS from "./adminReport/cancellationReport/CANCELLATION_REPORT_HEADERS";
import COMMUNICATIONMAIL_HEADERS from "./registrationHeaders/CommunicationMailHeaders";
import CONTRACT_RATE_HEADERS from "./partnerHeaders/contractRateHeaders/CONTRACT_RATE_HEADERS";
import COUPON_HEADERS from "./registrationHeaders/CouponHeaders";
// import Day_WISE_API_HEADERS from "../pages/admin/DayWiseHeaders/dayWiseReportHeaders1";
// import Day_WISE_INHOUSE_HEADERS from "../pages/admin/DayWiseHeaders/dayWiseReportHeaders";
import EMPLOYEE_REGISTRATION_HEADERS from "./registrationHeaders/EmployeeRegistrationHeaders";
import EXTRANETCONTRACTRATE_HEADERS from "./registrationHeaders/ExtranetContractRateHeaders";
import HOTAL_SETTINGS_HOTAL_AMINITY from "./hotalSettingsHeaders/HotelAminityhHeader";
import HOTAL_SETTINGS_HOTAL_CATEGORY from "./hotalSettingsHeaders/HotelCategoryHeader";
import HOTAL_SETTINGS_HOTAL_TYPE from "./hotalSettingsHeaders/HotalTypeHeader";
import HOTAL_SETTINGS_MEAL_PLAN from "./hotalSettingsHeaders/MealPlanHeader";
import HOTAL_SETTINGS_OCUPANCY_TYPE from "./hotalSettingsHeaders/OccupancyTypeHeader";
import HOTAL_SETTINGS_ROOM_AMINITY from "./hotalSettingsHeaders/RoomAminityHeader";
import HOTAL_SETTINGS_ROOM_CATEGORY from "./hotalSettingsHeaders/RoomCategoryHeader";
import HOTAL_SETTINGS_ROOM_TYPE from "./hotalSettingsHeaders/RoomTypeHeader";
import HOTAL_SETTINGS_SEASON_TYPE from "./hotalSettingsHeaders/SeasonTypeHeader";
import HOTEL_AVAILABILITY_HEADERS from "./partnerHeaders/hotelAvailability/HOTEL_AVAILABILITY_HEADERS";
import HOTEL_BOOKING_LIST_TABLE_HEADERS from "./MyBooking/HotelBookingListHeader";
import HOTEL_OCCUPANCY_HEADERS from "./partnerHeaders/occupancyHeaders/HOTEL_OCCUPANCY_HEADERS";
// import HOTEL_WISE_HEADERS from "./adminReport/HotelWiseHeaders/HotelWiseHeaders";
import HOUSEBOAT_REGISTRATION_HEADERS from "./registrationHeaders/HouseBoatRegistrationHeaders";
import HOUSE_BOAT_BOOKING_LIST_TABLE_HEADERS from "./MyBooking/HouseboatBookingListJHeader";
import HOUSE_BOAT_SETTINGS_ROOM_CATEGORY from "./houseBoatSettingsHeaders/HouseBoatSettingsRoomCategoryHeaders";
import HOUSE_BOAT_SETTINGS_ROOM_TYPES from "./houseBoatSettingsHeaders/HouseBoatSettingsRoomTypeHeaders";
import HOUSE_BOAT_SETTINGS_TYPES from "./houseBoatSettingsHeaders/HouseBoatSettingsTypeHeaders";
import INHOUSE_AGENT_ACCOUNT from "./inHouseAccountsHeader/InhouseAgentAccountHeaders";
import INHOUSE_PAYMENT_GATEWAY_TRANSACTION from "./inHouseAccountsHeader/inhousePaymentGatewayTransaction";
import INVOICE_TABLE_HEADERS from "./Invoice/InvoiceHeader";
import LOCATION_SETTINGS_COUNTRY from "./locationSettingsHeader/LocationSettingsCountry";
import LOCATION_SETTINGS_DESTINATION from "./locationSettingsHeader/LocationSettingsDestination";
import LOCATION_SETTINGS_MARKET_TYPE from "./locationSettingsHeader/LocationSettingMarketTypeHeader";
import LOCATION_SETTINGS_PROVINCE from "./locationSettingsHeader/LocationSettingsProvince";
import LOCATION_SETTINGS_REGION from "./locationSettingsHeader/LocationSettingRegionHeaders";
import MINIMUM_LENGTH_HEADERS from "./partnerHeaders/occupancyHeaders/MINIMUM_LENGTH_HEADERS";
// import MONTHLY_WISE_API_HEADERS from "./adminReport/MonthlyReportHeaders/MonthlyReportHeaders1";
// import MONTHLY_WISE_HEADERS from "./adminReport/MonthlyReportHeaders/MonthlyReportHeaders";
import PACKAGE_REGISTRATION_HEADERS from "./registrationHeaders/PackageRegistrationHeaders";
import PACKAGE_SETTING_CATEGORY from "./PackageSettingHeaders/PackageSettingCategoryHeader";
import PACKAGE_SETTING_DAY_ACTIVITIES from "./PackageSettingHeaders/PackageSettingsDayActivitiesHeader";
import PACKAGE_SETTING_ITINERARY from "./PackageSettingHeaders/PackageSettingItineraryHeader";
import PACKAGE_SETTING_TERMS from "./PackageSettingHeaders/PackageSettingTermsHeader";
import PACKAGE_SETTING_TYPE from "./PackageSettingHeaders/PackageSettingTypeHeader";
import PACKAGE_SETTING_VISA_INFO from "./PackageSettingHeaders/PackageSettingVisaInfoHeader";
import POLICY_HEADERS from "./partnerHeaders/policyHeaders/POLICY_HEADERS";
import PROMOTION from "./partnerHeaders/promotion/PROMOTION";
import SHIKARA_BOOK_LIST_TABLE_HEADERS from "./MyBooking/ShikaraBookListHeader";
import SHIKARA_CONTACT_LIST_HEADERS from "./registrationHeaders/ShikaraReg/ShikaraContactListHeader";
import HOUSEBOAT_CONTRACT_LIST_HEADERS from "./registrationHeaders/houseBoatHeaders/HouseBoatContractHeaders";
import HOUSEBOAT_PROMOTION_HEADERS from "./registrationHeaders/houseBoatHeaders/HouseboatPromotionHeaders";
import HOUSEBOAT_ROOM_AVAILABILITY_HEADERS from "./registrationHeaders/houseBoatHeaders/HouseBoatRoomAvailabilityHeaders";
import HOUSEBOAT_POLICY_LIST_HEADERS from "./registrationHeaders/houseBoatHeaders/HouseBoatPolicyListHeaders";
import SHIKARA_REGISTRATION_HEADERS from "./registrationHeaders/ShikaraReg/ShikaraRegistrationHeaders";
import STOP_SALE from "./partnerHeaders/hotelAvailability/STOP_SALE";
// import STOP_SALE_REPORT_HEADERS from "./adminReport/stopSaleReportHeaders/STOP_SALE_REPORT_HEADERS";
// import USER_LOGINS_REPORT_HEADERS from "./adminReport/userLoginsReportHeaders/USER_LOGINS_REPORT_HEADERS";
// import USER_REPORT_AGENT_HEADERS from "./adminReport/userReportHeaders/USER_REPORT_AGENT_HEADERS";
// import USER_REPORT_AVAILABILITY_HEADERS from "./adminReport/userReportHeaders/USER_REPORT-AVAILABILITY_HEADERS";
// import USER_REPORT_BOOKING_HEADERS from "./adminReport/userReportHeaders/USER_REPORT_BOOKING_HEADERS";
// import USER_REPORT_CONTRACT_RATE_HEADERS from "./adminReport/userReportHeaders/USER_REPORT_CONTRACT_RATE_HEADERS";
// import USER_REPORT_PROMOTION_HEADERS from "./adminReport/userReportHeaders/USER_REPORT_PROMOTION-HEADERS";
// import USER_REPORT_SPECIAL_RATE_HEADERS from "./adminReport/userReportHeaders/USER_REPORT_SPECIAL_RATE_HEADERS";
// import VIEW_COMPULSORY from "./partnerHeaders/viewCompulsory/VIEW_COMPULSORY";
import VIEW_GOSCOUPON from "./partnerHeaders/viewgoscoupon/VIEW_GOSCOUPON";
import PACKAGE_PROMOTION_HEADERS from "./registrationHeaders/PackagePromotionHeaders";
import PACKAGE_RATE_HEADERS from "./registrationHeaders/PackageRateHeaders";
import SHIKARA_PROMOTIONS_HEADERS from "./registrationHeaders/ShikaraReg/ShikaraPromotionHeader";
// import POLICY_HEADERS from "./partnerHeaders/policyHeaders/POLICY_HEADERS";
/* eslint-disable import/no-unresolved */

const CONSTANTS = {
  // ADMIN_SIDEBAR_MENU_ITEMS,
  BASIC_SETTINGS_DESIGNATION,
  BASIC_SETTINGS_BANK,
  BASIC_SETTINGS_CURRENCY,
  BASIC_SETTINGS_CONTACT_TYPE,
  BASIC_SETTINGS_MARKUP_TYPE,
  BASIC_SETTINGS_ASSIGN_MENU,
  // BASIC_SETTINGS_ACCOUNTS_TYPE,
  // BASIC_SETTINGS_CONTRACT_EXPIRY_REPORT,

  LOCATION_SETTINGS_PROVINCE,
  LOCATION_SETTINGS_REGION,
  LOCATION_SETTINGS_COUNTRY,
  LOCATION_SETTINGS_MARKET_TYPE,
  LOCATION_SETTINGS_DESTINATION,

  PACKAGE_SETTING_CATEGORY,
  PACKAGE_SETTING_TYPE,
  PACKAGE_SETTING_DAY_ACTIVITIES,
  PACKAGE_SETTING_ITINERARY,
  PACKAGE_SETTING_VISA_INFO,
  PACKAGE_SETTING_TERMS,

  HOUSE_BOAT_SETTINGS_TYPES,
  HOUSE_BOAT_SETTINGS_ROOM_TYPES,
  HOUSE_BOAT_SETTINGS_ROOM_CATEGORY,

  INHOUSE_AGENT_ACCOUNT,
  INHOUSE_PAYMENT_GATEWAY_TRANSACTION,

  // Hotal Settings
  HOTAL_SETTINGS_HOTAL_TYPE,
  HOTAL_SETTINGS_HOTAL_AMINITY,
  HOTAL_SETTINGS_HOTAL_CATEGORY,
  HOTAL_SETTINGS_MEAL_PLAN,
  HOTAL_SETTINGS_OCUPANCY_TYPE,
  HOTAL_SETTINGS_ROOM_AMINITY,
  HOTAL_SETTINGS_ROOM_CATEGORY,
  HOTAL_SETTINGS_ROOM_TYPE,
  HOTAL_SETTINGS_SEASON_TYPE,

  //Invoice
  INVOICE_TABLE_HEADERS,

  //My Booking
  HOTEL_BOOKING_LIST_TABLE_HEADERS,
  BOOKED_PACKAGES_TABLE_HEADERS,
  HOUSE_BOAT_BOOKING_LIST_TABLE_HEADERS,
  SHIKARA_BOOK_LIST_TABLE_HEADERS,
  //Api
  // API_SMYROOMS,
  // API_WHITE_SANDS,

  //agent settings
  AGENT_SETTINGS_HEADERS,
  //registration -

  AGENT_REGISTRATION_HEADERS,
  EMPLOYEE_REGISTRATION_HEADERS,
  HOUSEBOAT_REGISTRATION_HEADERS,
  PACKAGE_REGISTRATION_HEADERS,
  PACKAGE_PROMOTION_HEADERS,
  PACKAGE_RATE_HEADERS,
  SHIKARA_REGISTRATION_HEADERS,
  SHIKARA_PROMOTIONS_HEADERS,
  SHIKARA_CONTACT_LIST_HEADERS,
  HOUSEBOAT_CONTRACT_LIST_HEADERS,
  HOUSEBOAT_PROMOTION_HEADERS,
  HOUSEBOAT_ROOM_AVAILABILITY_HEADERS,
  HOUSEBOAT_POLICY_LIST_HEADERS,
  EXTRANETCONTRACTRATE_HEADERS,
  ASSIGNEDAGENTS_HEADERS,
  COMMUNICATIONMAIL_HEADERS,
  COUPON_HEADERS,

  //Report
  // HOTEL_WISE_HEADERS,
  // Day_WISE_INHOUSE_HEADERS,
  // Day_WISE_API_HEADERS,
  // MONTHLY_WISE_HEADERS,
  // MONTHLY_WISE_API_HEADERS,
  // AGENT_WISE_INHOUSEAGENT_HEADERS,
  // USER_REPORT_AGENT_HEADERS,
  // USER_REPORT_CONTRACT_RATE_HEADERS,
  // USER_REPORT_BOOKING_HEADERS,
  // USER_REPORT_AVAILABILITY_HEADERS,
  // USER_REPORT_PROMOTION_HEADERS,
  // USER_REPORT_SPECIAL_RATE_HEADERS,
  // STOP_SALE_REPORT_HEADERS,
  // USER_LOGINS_REPORT_HEADERS,
  // BOOKING_REPORT_HEADERS,
  // CANCELLATION_REPORT_HEADERS,

  //Hotel Availability
  HOTEL_AVAILABILITY_HEADERS,
  BLOCK_CHECKIN_CHECKOUT,
  STOP_SALE,

  //Occupency Headers
  HOTEL_OCCUPANCY_HEADERS,
  MINIMUM_LENGTH_HEADERS,

  //Contract Rate
  CONTRACT_RATE_HEADERS,

  //Promotion
  PROMOTION,

  //Policy
  POLICY_HEADERS,

  //View Compulsory
  VIEW_GOSCOUPON,
};
export default CONSTANTS;
