/* eslint-disable no-undef */
import "./App.css";
import "./styles/colors.scss";
import "./styles/font.scss";
import "./styles/global.scss";
import "./styles/media.scss";
import "./styles/width.scss";
import "./styles/custom.scss";
import NewUserLayout from "./pages/usersPage/newUserLayout/index";
import PrivateRoute from "./components/organisms/protectedRouting/ProtectedRouting";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginRoute } from "./routes";
import ResetPasswordForm from "./components/forms/loginForm/ResetPasswordForm";
import { UserLoader } from "./components/atoms/userLoader";
import BookingConfirmation from "./pages/usersPage/bookingConfirmation";
import BookingFail from "./pages/usersPage/bookingFail";
import { ErrorPage } from "./pages/404ErrorPage";
import CustomerReviewForm from "./pages/usersPage/userReview/CustomerHotelReview";
import { useEffect } from "react";
// import Facebook from "./tracking/Facebook";
import { initializeTagManager } from "./gtm/Gtm";
import Usepagetracking from "./usePageTracking/Usepagetracking";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";
import Success from "./components/payu/Success";
import Failure from "./components/payu/Failure";
import PayUMoneyForm from "./components/payu/PayUMoneyForm";
import { useDispatch } from "react-redux";
import { setSearchCardFocus } from "./reduxData/sliceData/searchCardReducer";

// import LayoutComponent from "./components/organisms/LayoutComponent/index";
// import PrivateRoute from "./components/organisms/protectedRouting/ProtectedRouting";

function App() {
  useEffect(() => {
    initializeTagManager();  }, []);
  
    const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSearchCardFocus(false));
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <HelmetProvider>
          <Helmet>
            <title>Godsownstay.com</title>
            <meta
              name="description"
              content="Godsownstay.com - India's Fastest Growing Online Travel Booking Brand For
      Budget Hotels & Holiday Homes."
            />
            <meta
              name="keywords"
              content="Hotels, Resorts, Holidays, Search Hotels"
            />
          </Helmet>
        </HelmetProvider>
        <Routes>
          <Route path="/" element={<NewUserLayout />}>
            <Route path="*" element={<Usepagetracking />} />
          </Route>
          <Route element={<LoginRoute />}>
            <Route path="/login" />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/admin/*" />
          </Route>
          <Route element={<ResetPasswordForm />}>
            <Route path="employeeLoginCredential/resetPassword" />
          </Route>
          <Route element={<UserLoader />}>
            <Route path="loader" />
          </Route>
          <Route element={<BookingConfirmation />}>
            <Route path="/hotel/booking/details/:id" />
          </Route>
          <Route element={<CustomerReviewForm />}>
            <Route path="/customer/review/:id/:hotelId" />
          </Route>
          <Route element={<CustomerReviewForm />}>
            <Route path="/customer/review/:id/:hotelId/:userId" />
          </Route>
          <Route element={<BookingFail />}>
            <Route path="/hotel/booking/fail" />
          </Route>
          <Route element={<PayUMoneyForm />}>
            <Route path="/payumoneyform" />
          </Route>
          <Route element={<Success />}>
            <Route path="/success" />
          </Route>
          <Route element={<Failure />}>
            <Route path="/failure" />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
