// import { Col } from "antd";
// import React from "react";
// import { Row } from "react-bootstrap";
import { Card, Rate } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  // Carousel
} from "antd";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import tik from "../../../../assets/tik.png";
import qt from "../../../../assets/qt.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./package.css";
import { Paper } from "@mui/material";
import sfPro from "../../../../assets/FontsFree-Net-SFProDisplay-Regular.ttf";
import client1 from "../../../../assets/client1.jpg";
import client2 from "../../../../assets/client2.jpg";
import client3 from "../../../../assets/client3.jpg";
import { houseBoatContractListGetById } from "../../../../services/utils/admin/manageMaster/registration/houseBoatServices/HouseBoatContractListServices";
import { useEffect, useState } from "react";

function HouseBoatCard({ data, setIsModalOpen }) {
  const { hbImage, contractId } = data;
  // debugger;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  const settingsSmall = {
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: -1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  let imgResult = hbImage.filter((x) => x);

  const [roomOption, setRoomOption] = useState();
  const filterHouseBoatList = async () => {
    const data1 = await houseBoatContractListGetById(data?._id);
    setRoomOption(data1?.data?.response);
  };

  useEffect(() => {
    filterHouseBoatList();
  }, []);

  return (
    <Row justify={"center"}>
      <Col span={24} style={{ padding: "2%" }}>
        <Row
          elevation={24}
          style={{
            padding: "2%",
            paddingTop: "0%",
            boxShadow: "0px 4px 10px #0000000d",
            borderBottom: "1px solid #eee",
          }}
        >
          <Row gutter={[16, 16]} style={{ paddingTop: "2%" }}>
            <Col md={8} sm={10} sx={12}>
              <img className="imgcard" src={imgResult[0]} alt="" />
            </Col>
            <Col md={10} sm={10} sx={12} className="">
              <span className="title">{data?.houseBoatName}</span>
              <p className="sub-title1">
                {data?.country?.countryName}-{data?.state?.stateName}-
                {data?.place?.destinationName}
              </p>
              <span className="sub-title2">
                Boat Jetty {data?.state?.stateName}{" "}
                {data?.place?.destinationName}
              </span>

              <Slider
                {...settings}
                // ref={sliderRef}
                style={{ width: "100%", position: "relative" }}
              >
                <Col span={24}>
                  <div>
                    <Row>
                      <div className="card-content">
                        <div>
                          <img className="" src={qt} alt="" />
                        </div>
                        <span
                          className="px-2 mt-4"
                          style={{ borderLeft: "1px solid lightgray" }}
                        ></span>
                        <div className="mt-4 card-description">
                          <p>
                            I booked through Godsownstay.com. This was a
                            tremendous stay with House boat. The highlight for
                            me was the staff - they were so kind. The food was
                            also delicious and it has a homely feel .highly
                            recommend.thanks to Godsownstay.com
                          </p>
                          <p className="text-start">
                            <Rate allowHalf disabled defaultValue={4.5} />
                          </p>
                          <div className="my-3" style={{ display: "flex" }}>
                            <div>
                              <img
                                className="testimoni-pic"
                                src={client1}
                                alt=""
                              />
                            </div>
                            <div className="testimoni-name">
                              <span>Naval Sharma</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
                <Col span={24}>
                  <div>
                    <Row>
                      <div className="card-content">
                        <div>
                          <img className="" src={qt} alt="" />
                        </div>
                        <span
                          className="px-2 mt-4"
                          style={{ borderLeft: "1px solid lightgray" }}
                        ></span>
                        <div className="mt-4 card-description">
                          <p>
                            We had a team outing and stayed one night in this
                            Houseboat. We had a fabulous experience here… very
                            friendly and helpful staff. Our trip codinator was
                            Glen Maam. She guide us very well. yummy food…
                            Overall awesome experience!! Highly recommend it.
                          </p>
                          <p className="text-start">
                            <Rate allowHalf disabled defaultValue={4.5} />
                          </p>
                          <div className="my-3" style={{ display: "flex" }}>
                            <div>
                              <img
                                className="testimoni-pic"
                                src={client2}
                                alt=""
                              />
                            </div>
                            <div className="testimoni-name">
                              <span>Sibi</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
                <Col span={24}>
                  <div>
                    <Row>
                      <div className="card-content">
                        <div>
                          <img className="" src={qt} alt="" />
                        </div>
                        <span
                          className="px-2 mt-4"
                          style={{ borderLeft: "1px solid lightgray" }}
                        ></span>
                        <div className="mt-4 card-description">
                          <p>
                            This was our first time in Houseboat and it was
                            purely to get the experience of staying in the
                            middle of a lake. The stay was awesome, the boat was
                            clean, the boat was also kid friendly. Thanks to
                            Godsownstay.com for the wonderful experience.
                          </p>
                          <p className="text-start">
                            <Rate allowHalf disabled defaultValue={4.5} />
                          </p>
                          <div className="my-3" style={{ display: "flex" }}>
                            <div>
                              <img
                                className="testimoni-pic"
                                src={client3}
                                alt=""
                              />
                            </div>
                            <div className="testimoni-name">
                              <span>Rahul</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              </Slider>
            </Col>

            <Col md={6}>
              <span className="houseboat-title">Houseboat Highlights</span>
              {data.houseBoatHighlights.map((val, id) => {
                return (
                  <Row key={id}>
                    <Col md={2} sm={3}>
                      <img className="tik" src={tik} alt="" />
                    </Col>
                    <Col md={22} sm={21}>
                      <span className="houseboat-content">{val}</span>
                    </Col>
                  </Row>
                );
              })}

              <Row style={{ paddingTop: "20px" }}>
                <Col md={24}>
                  <span className="bottom-text">
                    <span className="emi-text">EMI</span>&nbsp;Starting from{" "}
                    <span className="number-text">₹{data.emiDetails}</span>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-1 w-100">
            <Col span={24}>
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="profile" title="Gallery">
                  {/* ________________________________________________________________ */}

                  <Paper elevation={4} className="pb-4">
                    <Slider
                      {...settings}
                      // ref={sliderRef}
                      style={{ width: "100%", position: "relative" }}
                    >
                      {hbImage.map((item, i) => {
                        return (
                          <Col span={24} key={i}>
                            <span className="slick-card-buyNow-nb">
                              <img
                                src={item}
                                className="slick-card-img-nb"
                                alt=""
                              />
                            </span>
                          </Col>
                        );
                      })}
                    </Slider>
                    <div style={{ marginTop: "4px" }}>
                      <Slider
                        {...settingsSmall}
                        // ref={sliderRef}
                        style={{ width: "100%" }}
                      >
                        {hbImage.map((item, i) => {
                          return (
                            <Col key={i}>
                              <div
                                className="slick-card-buyNow-nb"
                                style={{ height: "90px", width: "90px" }}
                              >
                                <img
                                  src={item}
                                  style={{ height: "100%", width: "100%" }}
                                  className=""
                                  alt=""
                                // onClick={()=>setImageNo(i)}
                                />
                              </div>
                            </Col>
                          );
                        })}

                        {hbImage.length < 10 &&
                          new Array(11 - hbImage.length).fill().map((v, i) => {
                            return (
                              <Col key={i}>
                                <div
                                  className="slick-card-buyNow-nb"
                                  style={{
                                    height: "90px",
                                    width: "90px",
                                  }}
                                ></div>
                              </Col>
                            );
                          })}
                      </Slider>
                    </div>
                  </Paper>

                  {/* <ImageGallery items={images} autoPlay={true} /> */}
                  {/* ________________________________________________________________ */}
                </Tab>
                <Tab eventKey="home" title="Room Details">
                  <Card className="tableCard">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th style={{ width: "25%" }}>
                            Room Category / Trip Type
                          </th>
                          <th style={{ width: "25%" }}>Share Type</th>
                          <th style={{ width: "27%" }}>Price</th>
                          <th style={{ width: "15%" }}>Book</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contractId?.contractRateDetails?.length === 0 && (
                          <tr>
                            <td className="text-center" colSpan={12}>
                              Room Categories are not added yet.
                            </td>
                          </tr>
                        )}
                        {roomOption?.length > 0 &&
                          roomOption?.map((v, i) => {
                            return (
                              <tr key={i}>
                                <td className="table-content">
                                  {v.contractRateDetails[0] &&
                                    v.contractRateDetails[0].roomCategory
                                      .roomCategoryName}
                                  /{v.time?.fullDay ? "Full Day" : "Half Day"}
                                  {/* {contractId.time?.fullDay
                                    ? "Full Day"
                                    : "Half day"} */}
                                </td>
                                <td className="table-content">
                                  {v.time?.fullDay
                                    ? "Room With BreakFast +Welcome Drink + Lunch + Tea & Snacks + Dinner"
                                    : "Room With BreakFast +Welcome Drink + Lunch"}
                                </td>
                                <td className="price-td">
                                  <span
                                    style={{
                                      fontSize: "1.5rem",
                                      color: "#cc0000",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ₹
                                    {v.contractRateDetails[0]?.roomRate &&
                                      v.contractRateDetails[0]?.roomRate}
                                  </span>
                                  &nbsp;
                                  <s
                                    className=" p-1"
                                    style={{
                                      color: "#888",
                                      fontSize: "1.1rem",
                                      textDecoration: "line-through",
                                      textDecorationColor: "red",
                                      textDecorationThickness: "2px",
                                      fontFamily: "SF Pro, Arial, sans-serif",
                                      src: `${sfPro}`,
                                    }}
                                  >
                                    ₹
                                    {data?.promotionId?.promotionList
                                      .promotionType.percentage
                                      ? parseInt(
                                        parseInt(
                                          v.contractRateDetails[0]?.roomRate
                                        )
                                          ? parseInt(
                                            v.contractRateDetails[0]
                                              ?.roomRate
                                          ) /
                                          (1 -
                                            data?.promotionId
                                              ?.promotionList?.value /
                                            100)
                                          : ""
                                      )
                                      : parseInt(
                                        v.contractRateDetails[0]?.roomRate
                                      ) +
                                      data?.promotionId?.promotionList.value}
                                  </s>
                                  {/* </span> */}
                                  <span
                                    className="off-value"
                                    style={{
                                      color: "#ff6600",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    {data?.promotionId?.promotionList
                                      .promotionType.percentage
                                      ? ""
                                      : "₹"}
                                    {data?.promotionId?.promotionList.value
                                      ? data?.promotionId?.promotionList.value
                                      : ""}
                                    {data?.promotionId?.promotionList
                                      .promotionType.percentage
                                      ? "% "
                                      : ""}{" "}
                                    off
                                  </span>
                                  <br />
                                  <span className="extrabed-text">
                                    Extra Bed (Child :{" "}
                                    {v.contractRateDetails[0]?.extraBedChild &&
                                      v.contractRateDetails[0]?.extraBedChild}
                                    ,Adult :{" "}
                                    {v.contractRateDetails[0]?.extraBedAdult &&
                                      v.contractRateDetails[0]?.extraBedAdult}
                                    )
                                  </span>
                                </td>
                                <td>
                                  <button
                                    className="contact-btn"
                                    onClick={() => {
                                      setIsModalOpen({
                                        status: true,
                                        data: v.contractRateDetails[0],
                                        allData: data,
                                      });
                                    }}
                                    style={{
                                      backgroundColor: "#3d75df",
                                      border: "none",
                                    }}
                                  >
                                    Contact Us
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Card>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Row>
      </Col>
    </Row>
  );
}

export default HouseBoatCard;
