/* eslint-disable no-undef */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { persistor, store } from "./reduxData/store/store";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { ErrorBoundary } from "./components/organisms/ErrorrBoundary/ErrorBoundary";
// const authId = process.env.REACT_APP_API_KEY;
// const authId = "134823712424-ffbg6rue2dp8uq1mkk72vbhhieqdqrcc.apps.googleusercontent.com";

const authId = process.env.REACT_APP_API_KEY;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={authId}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
