/* eslint-disable no-debugger */
import {
  faFilter,
  // faLocationDot
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Grid } from "antd";
import BottomFooter from "../newUserLayout/userFooter/BottomFooter";
import UserSideBarModel from "../newUserLayout/userSidebarModel";
import UserSideBar from "../newUserLayout/userSideBar";
import { useState, useEffect } from "react";
import HouseBoatCard from "./../../../components/atoms/userAtoms/houseBoatCard/HouseBoatCard";
import {
  GetHouseboatRoomCategory,
  GetHouseboatType,
  HouseBoatGetAll,
} from "../../../services/utils/userUtils/houseBoat/indix";
// import _ from 'lodash'
// import UserModel from '../../../components/molecules/modalComponent/UserModel'
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { filterSearchfun } from "../../../services/utils/userUtils/dynamicFun";
// import OtherFunctionsObject from '../../../services/utils/userUtils/dynamicFun/functionlist'
import { UserLoader } from "../../../components/atoms/userLoader";
import HouseboatConfermModal from "../../../components/molecules/modalComponent/HouseboatConfermModal";
import HouseboatSeo from "../../../constants/seo/houseboatSeo";
const objectStructure = [
  ["houseBoatName"],
  ["state", "stateName"],
  ["place", "destinationName"],
  ["country", "countryName"],
];
const d = new Date().toJSON().slice(0, 10);
const { useBreakpoint } = Grid;

function HouseBatOtherPage() {
  const [houseBoatData, setHouseBoatData] = useState([]);
  const [filterData, setfilterData] = useState([]);
  const [searchData, setsearchData] = useState("");
  const [categoryData, setcategoryData] = useState([]);
  const [houseboatTypeData, sethouseboatTypeData] = useState([]);
  const [selectType, setselectType] = useState([]);
  const [selectedCategory, setselectedCategory] = useState([]);
  const [sidebarModelOpen, setsidebarModelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();
  const { lg } = useBreakpoint();
  // const {houseboatCategoryFilter,houseboatRoomTypeFilter}=OtherFunctionsObject;

  const searchOnChange = ({ target: { value } }) => {
    setsearchData(value);
    setfilterData(filterSearchfun(value, houseBoatData, objectStructure));
  };
  
  const getHouseBoatAllApi = async () => {
    setLoading(true);
    const res = await HouseBoatGetAll();
    setHouseBoatData(res.data);
    setfilterData(res.data);
    setLoading(false);
  };
  // debugger;
  const getHouseboatTypeAll = async () => {
    const { data } = await GetHouseboatType();
    sethouseboatTypeData(data.response);
  };

  const getHouseboatRoomCategoryfun = async () => {
    const { data } = await GetHouseboatRoomCategory();
    setcategoryData(data.response);
  };

  useEffect(() => {
    getHouseBoatAllApi();
    getHouseboatRoomCategoryfun();
    getHouseboatTypeAll();
  }, []);

  const categoryFunction = (e, v) => {
    if (e.target.checked) {
      setselectedCategory((prevState) => {
        return [...prevState, v.roomCategoryName];
      });
    } else if (!e.target.checked) {
      setselectedCategory((prevState) => {
        let tempData = [...prevState];
        let newData = tempData.filter((data) => data !== v.roomCategoryName);
        return [...newData];
      });
    }
  };

  const houseBoatTypeFunction = (e) => {
    if (e.target.checked) {
      setselectType((prevState) => {
        return [...prevState, e.target.value];
      });
    } else if (!e.target.checked) {
      setselectType((prevState) => {
        let tempData = [...prevState];
        let newData = tempData.filter((data) => data !== e.target.value);
        return [...newData];
      });
    }
  };

  const SideBar = (
    <UserSideBar
      title={"Houseboat"}
      searchValue={searchData}
      searchOnChange={searchOnChange}
      type={{
        title: "Houseboat Type",
        typeName: "roomTypeName",
        typeData: houseboatTypeData,
      }}
      typefun={houseBoatTypeFunction}
      category={{
        title: "Category",
        categoryName: "roomCategoryName",
        categoryData: categoryData,
      }}
      
      categoryfun={categoryFunction}
      
    />
  );

  const filterHouseBoatList = () => {
    let filteredDataList = houseBoatData;

    if (selectedCategory.length) {
      filteredDataList = filteredDataList.filter((item) =>
        item?.contractId?.contractRateDetails.some((detail) =>
          selectedCategory.includes(detail.roomCategory.roomCategoryName)
        )
      );
    }

    if (selectType.length) {
      filteredDataList = filteredDataList.filter((item) =>
        item?.contractId?.contractRateDetails.some((detail) =>
          detail.roomType.some(
            (roomType) => roomType && selectType.includes(roomType.roomTypeName)
          )
        )
      );
    }

    setfilterData(filteredDataList);
  };

  useEffect(() => {
    filterHouseBoatList();
  }, [selectType, selectedCategory]);
  
  // _______________________________________________________________________

  // BookNow model functon
  const [isModalOpen, setIsModalOpen] = useState({
    status: false,
    data: {},
    allData: {},
  });
  const [modelData, setModelData] = useState({
    houseboatID: "",
    goingTo: "",
    ckeckIn: d
  });

  const [guest, setGuest] = useState({
    rooms: "1",
    adults: "1",
    children: "0",
  });

  const handleOk = () => {
    if (isModalOpen?.allData) {
      setModelData({
        ...modelData,
        shikaraID: isModalOpen.allData?._id,
        goingTo: isModalOpen.allData.place?.destinationName,
      });
      setIsModalOpen({ ...isModalOpen, status: false });

      nav("/houseboat/houseboatbooknow", {
        state: {
          data: isModalOpen,
          guest: {
            ...modelData,
            guest: guest,
          },
          travel: { travelDate: modelData.checkIn },
        },
      });
      window.scrollTo(0, 0);
    }
  };

  const handleCancel = () => {
    setIsModalOpen({ ...isModalOpen, status: false });
  };
  const handleCheckIn = (e, s) => {
    setModelData({ ...modelData, ckeckIn: s });
  };

  // _______________________________________________________________________

  return (
    <>
      {loading ? <UserLoader /> : ""}

      <Row justify={"center"} style={{ minHeight: "100vh" }}>
        {lg ? (
          <Col xs={22} sm={22} md={22} lg={5} xl={5}>
            {SideBar}
          </Col>
        ) : (
          ""
        )}

        <Col xs={24} sm={24} md={24} lg={17} xl={17}>
          <Row>
            <Helmet>
              <title>{HouseboatSeo[0].title}</title>
              <meta name="description" content={HouseboatSeo[0].description} />
            </Helmet>
            {filterData.map((items, id) => {
              return (
                <Col span={24} key={id}>
                  <HouseBoatCard data={items} setIsModalOpen={setIsModalOpen} />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>

      {/* _____________________footer_______________________ */}
      <BottomFooter>
        
        <div>
          <FontAwesomeIcon
            className="footericon"
            color="#000"
            style={{ fontSize: "1rem" }}
            icon={faFilter}
            onClick={() => {
              setsidebarModelOpen(sidebarModelOpen ? false : true);
            }}
          />
          <p>Filters</p>
        </div>
      </BottomFooter>
      <UserSideBarModel
        isModalOpen={sidebarModelOpen}
        onCancel={() => {
          setsidebarModelOpen(false);
        }}
      >
        {" "}
        {SideBar}
      </UserSideBarModel>
      <HouseboatConfermModal
        open={isModalOpen.status}
        handleok={handleOk}
        handlecancel={handleCancel}
        locationValue={
          isModalOpen.allData && isModalOpen.allData.place?.destinationName
        }
        checkIn={modelData.ckeckIn}
        handleCheckIn={handleCheckIn}
        modelData={modelData}
        setModelData={setModelData}
        datepicker={true}
        // locationValue={data.hotelLocation.place.destinationName}
        guest={guest}
        setGuest={setGuest}
      />

      {/* ____________________________________________ */}
    </>
  );
}

export default HouseBatOtherPage;
