// import HotelsAllPage from '../pages/usersPage/userHotels/hotelsAllPage';
import HotelKochiPage from "../pages/usersPage/userHotels/hotelKochiPage/index";
// import HotelNearByPage from "./../pages/usersPage/userHotels/hotelNearByPage/index";
import PackageNearByPage from "./../pages/usersPage/userPackage/packageNearByPage/index";
import PackageOtherPage from "./../pages/usersPage/userPackage/packageOtherPage/index";
import ShikaraNearByPage from "./../pages/usersPage/userShikara/userShikaraNearByPage/index";
// import PackageAllPage from './../pages/usersPage/userPackage/packageAllPage/index';
// import UserShikaraAllPage from './../pages/usersPage/userShikara/userShikaraAllPage/index';
import PackageSecondPage from "../pages/usersPage/userPackage/packageSecondPage";
import { ViewPackageDetails } from "../pages/usersPage/userPackage/viewPackageDetails/ViewPackageDetails";
import { UserShikaraBookNow } from "../pages/usersPage/userShikara/userShikaraBookNow/UserShikaraBookNow";
import { HouseBoatBookNow } from "../pages/usersPage/userHouseBoat/HouseBoatBookNow";
// import { HotelBookNow } from '../pages/usersPage/userHotels/hotelBookNow';
// import SelectRoomMob from '../pages/usersPage/userHotels/hotelBookNow/SelectRoomMob';
import HouseBatOtherPage from "./../pages/usersPage/userHouseBoat/HouseBatOtherPage";
import UserShikaraOtherPage from "../pages/usersPage/userShikara/userShikaraOtherPage";
import AboutUsLink from "../pages/usersPage/footerLinks/AboutUsLink";
import FeedBackLink from "../pages/usersPage/footerLinks/FeedBackLink";
import CancellationPolicyLink from "../pages/usersPage/footerLinks/CancellationPolicyLink";
import PrivacyAndPolicyLink from "../pages/usersPage/footerLinks/PrivacyAndPolicyLink";
import TermsAndConditionsLink from "../pages/usersPage/footerLinks/TermsAndConditionsLink";
import SitemapLink from "../pages/usersPage/footerLinks/SitemapLink";
import { Bookings } from "../pages/usersPage/userBookings/Bookings";
import HotelBookingDetails from "../pages/usersPage/userBookings/hotelBookings/HotelBookingOne";
import PackageBookingOne from "../pages/usersPage/userBookings/packageBooking/PackageBookingOne";
import ShikaraBookingDetails from "../pages/usersPage/userBookings/shikaraBooking/shikaraBookingDetails";
import ViewUserProfile from "../pages/usersPage/usersProfile/ViewUserProfile";
import { ErrorPage } from "../pages/404ErrorPage";
import { Coupons } from "../pages/coupons";
import HouseboatBookingDetail from "../pages/usersPage/userBookings/houseboatBookings/HouseboatBookingDetail";

const userRoutPathesObj = [
  {
    element: (
      <>
        <ViewUserProfile />
      </>
    ),
    path: "user-profile",
    index: true,
  },
  {
    element: (
      <>
        <HotelKochiPage />
      </>
    ),
    path: "/hotel",
    index: true,
  },
  {
    element: (
      <>
        <HotelKochiPage />
      </>
    ),
    path: "/hotels/:id",
    index: true,
  },
  {
    element: (
      <>
        <HotelKochiPage />
      </>
    ),
    path: "/hotels/search-hotels-in-kochi/:id",
    index: true,
  },
  {
    element: (
      <>
        <HotelKochiPage />
      </>
    ),
    path: "/hotels/search-hotels-in-calicut/:id",
    index: true,
  },
  {
    element: (
      <>
        <HotelKochiPage />
      </>
    ),
    path: "/hotels/other",
    index: true,
  },
  // {
  //   element: (
  //     <>
  //       <HotelKochiPage />
  //     </>
  //   ),
  //   path: "/hotel/:id",
  //   index: true,
  // },

  {
    element: (
      <>
        <PackageSecondPage />
      </>
    ),
    path: "/package/secondPage",
    index: true,
  },

  // {
  //   element: (
  //     <>
  //       <HotelNearByPage />
  //     </>
  //   ),
  //   path: "/hotel/nearby",
  //   index: true,
  // },

  {
    element: (
      <>
        <PackageNearByPage />
      </>
    ),
    path: "/package/nearby",
    index: true,
  },

  {
    element: (
      <>
        <PackageOtherPage />
      </>
    ),
    path: "/holiday-kerala/kerala-travel-packages/Domestic.html",
    index: true,
  },
  {
    element: (
      <>
        <PackageOtherPage />
      </>
    ),
    path: "/domestic-package",
    index: true,
  },
  {
    element: (
      <>
        <PackageOtherPage />
      </>
    ),
    path: "/international-package",
    index: true,
  },
  {
    element: (
      <>
        <ViewPackageDetails />
      </>
    ),
    path: "/package/viewpackagedetails",
    index: true,
  },

  {
    element: (
      <>
        <ShikaraNearByPage />
      </>
    ),
    path: "/shikara/nearby",
    index: true,
  },
  {
    element: (
      <>
        <UserShikaraOtherPage />
      </>
    ),
    path: "/shikara/alleppey-shikara-boat.html",
    index: true,
  },
  {
    element: (
      <>
        <UserShikaraBookNow />
      </>
    ),
    path: "/shikara/usershikarabooknow",
    index: true,
  },

  {
    element: (
      <>
        <HouseBatOtherPage />
      </>
    ),
    path: "/houseboat/houseboat-in-alleppey.html",
    index: true,
  },
  {
    element: (
      <>
        <HouseBoatBookNow />
      </>
    ),
    path: "/houseboat/houseboatbooknow",
    index: true,
  },
  {
    element: (
      <>
        <Bookings />
      </>
    ),
    path: "/bookings",
    index: true,
  },
  {
    element: (
      <>
        <PackageBookingOne />
      </>
    ),
    path: "/bookings/package/:id",
    index: true,
  },
  {
    element: (
      <>
        <HotelBookingDetails />
      </>
    ),
    path: "/bookings/hotel/:id",
    index: true,
  },
  {
    element: (
      <>
        <ShikaraBookingDetails />
      </>
    ),
    path: "/bookings/shikara/:id",
    index: true,
  },
  {
    element: (
      <>
        <HouseboatBookingDetail />
      </>
    ),
    path: "/bookings/houseboat/:id",
    index: true,
  },
  {
    element: (
      <>
        <AboutUsLink />
      </>
    ),
    path: "/aboutUsLink",
    index: true,
  },
  {
    element: (
      <>
        <CancellationPolicyLink />
      </>
    ),
    path: "/cancellationpolicylink",
    index: true,
  },
  {
    element: (
      <>
        <FeedBackLink />
      </>
    ),
    path: "/feedbacklink",
    index: true,
  },
  {
    element: (
      <>
        <PrivacyAndPolicyLink />
      </>
    ),
    path: "/privacyandpolicylink",
    index: true,
  },
  {
    element: (
      <>
        <TermsAndConditionsLink />
      </>
    ),
    path: "/termsandconditionslink",
    index: true,
  },
  {
    element: (
      <>
        <SitemapLink />
      </>
    ),
    path: "/sitemap",
    index: true,
  },
  {
    element: (
      <>
        <Coupons />
      </>
    ),
    path: "/coupons",
    index: true,
  },
  {
    element: (
      <>
        <ErrorPage />
      </>
    ),
    path: "*",
  },
];

export default userRoutPathesObj;
