import Box from "@mui/material/Box";
import HeaderComponent from "./PartnerHeaderComponent";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import SideMenuComponent from "./PartnerSlideBar";
import { PartnerRouterComponent } from "../../../routes";

const PartnerLayoutComponent = () => {
  const [navShow] = useState(false);

  return (
    <>
      <Box>
        <HeaderComponent />
      </Box>
      <Box className="d-flex ">
        <Box>
          <SideMenuComponent />
        </Box>
        <Box className="w-100 pageHeight pagebg overflowY-scroll ">
          <Box className="toast-component-container"></Box>
          <Paper
            variant="elevation"
            elevation={2}
            square
            className={`${
              navShow ? "paperHeightChild" : "paperHeight"
            } overflowY-scroll overflowX-hidden `}
            style={{ backgroundColor: "#f0f0f0" }}
          >
            <div style={{ padding: "0% 2%" }}>
              <PartnerRouterComponent />
            </div>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default PartnerLayoutComponent;
