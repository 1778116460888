import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const AboutUsLink = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100vh",
        width: "100vw",
        padding: "2% 15% 0% 15%",
      }}
    >
      <h3 style={{ fontWeight: "700" }}>About Us</h3>
      <Container>
        <ul>
          <li className="terms-Li">
            Godsownstay.com is an Indian B2B & B2C Online OTA platform founded
            by Arun Neeli.
          </li>
          <li className="terms-Li">
            Godsownstay.com provides full-stack technology to book Hotels &
            Holiday Packages. The company provdies online travel services
            including Hotel Reservation, Domestic and International Holiday
            packages, Houseboat and Shikkara servies, etc.
          </li>
          <li className="terms-Li">
            Gos is the first OTA platform which introduces the first geo
            location based hotel search in the industry. Besides, Customer can
            also search hotels by Destnation/City/Hotel Name etc. We are one of
            the best user friendly OTA platform that a customer may ever come
            across. Be it in terms of quickest search and booking, fastest
            payment, settlement of refund process initiated within 24 hours. Our
            customers enjoy Gos Stay at certified properties. Our Gos Money+
            virtual travel currency is the best choice for new India on the
            move.
          </li>
        </ul>
        <p style={{ textAlign: "justify", color: "#666" }}></p>
      </Container>
    </div>
  );
};

export default AboutUsLink;
