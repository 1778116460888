import { Badge, Row, Col, Button } from "antd";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import HomePageHeader from "../homePage/homePageHeader/HeaderForAll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Grid } from "@mui/material";
// import StarIcon from "@mui/icons-material/Star";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useParams } from "react-router-dom";
import { userCancellHotelBooking } from "../../services/utils/userUtils/hotelutils/index";
import { cancelBookingEmail } from "../../services/utils/userUtils/hotelutils/paymentutils";

function BookingConfirmation() {
  const [booking, setBooking] = useState("");
  let { id } = useParams();
  const handleCancelBooking = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    });
    if (result.isConfirmed) {
      await Swal.fire({
        title: "Cancelled!",
        text: "Your booking has been deleted.",
        icon: "success",
      });

      await userCancellHotelBooking({ id: id });

      await cancelBookingEmail({ booking: booking });
      // return false;

      setTimeout(function () {
        window.location.reload();
      }, 100);
    }
  };
  const navigateToDetailPage = () => {
    window.location.href = `${process.env.REACT_APP_FRONTEND_BASE_URL}`;
  };
  const bookingDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}myBooking/hotelBookingView/${id}`
      );
      setBooking(response.data.response[0]);
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  useEffect(() => {
    bookingDetails();
  }, []);

  function foramateDate(inputDate) {
    const date = new Date(inputDate);

    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };

    const formattedDate = date.toLocaleString("en-US", options);

    return formattedDate;
  }

  return (
    <>
      <HomePageHeader />
      {booking?.status == "confirm" ? (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Your hotel booking was successful. — <strong>check it out!</strong>
        </Alert>
      ) : (
        ""
      )}

      <Grid container spacing={2} style={{ marginTop: "25px" }}>
        <Grid item lg={2} md={0} xs={0}>
          <Button
            style={{
              marginLeft: "85px",
              marginBottom: "-10px",
              // marginTop: "45px",
              color: "rgb(255, 255, 255)",
              backgroundColor: "rgb(225, 45, 112)",
            }}
            onClick={navigateToDetailPage}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ marginRight: "8px" }}
            />{" "}
            Back
          </Button>
        </Grid>
        <Grid item lg={7} md={12} xs={12} style={{ textAlign: "center" }}>
          <img
            alt="HotelPhoto"
            src={booking?.hotelId?.profilePic[1]}
            style={{
              width: "70%",
              border: "3px solid gray",
              borderRadius: "14px",
              height: "300px",
            }}
          />
        </Grid>
        <Grid item lg={3} md={12} xs={12}></Grid>

        <Grid item lg={2} md={0} xs={0} />
        <Grid item lg={7} md={12} xs={12}>
          <h4 style={{ marginTop: "25px" }}>Booking Details: </h4>
          <div id="description" className="bix-box">
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Booking ID:</span>{" "}
              {booking?.booking_id}
            </p>
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Hotel Name:</span>{" "}
              {booking?.hotelId?.basicDetails?.hotelName}
            </p>
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Phone:</span> +91{" "}
              {booking?.hotelId?.contactDetails[0]?.mobile}
            </p>
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Address:</span>{" "}
              {booking?.hotelId?.hotelLocation?.hotelAddress}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Booking Date:</span>{" "}
              {foramateDate(booking?.bookingDate)}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Check In:</span>{" "}
              {foramateDate(booking?.checkIn)}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Check Out:</span>{" "}
              {foramateDate(booking?.checkOut)}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Booking Status:</span>{" "}
              {booking?.status == "confirm" ? (
                <Badge
                  count={booking?.status}
                  style={{ backgroundColor: "#52c41a" }}
                />
              ) : (
                <Badge count={booking?.status} />
              )}
            </p>
          </div>
        </Grid>
        <Grid item lg={3} md={12} xs={12}></Grid>

        <Grid item lg={2} md={0} xs={0} />
        <Grid item lg={7} md={12} xs={12}>
          <h4 style={{ marginTop: "25px" }}>User Details: </h4>
          <div id="description" className="bix-box">
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>First Name:</span>{" "}
              {booking?.firstName}
            </p>

            {/* <p className="address">
              <span style={{ fontWeight: "bold" }}>Avg Rating: </span>{" "}
              <Rating
                name="text-feedback"
                value={4.5}
                readOnly
                precision={0.5}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
            </p> */}

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Last Name:</span>{" "}
              {booking?.lastName}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Phone Number:</span>{" "}
              {booking?.whatsAppNumber}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
              {booking?.email}
            </p>
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Adults:</span>{" "}
              {booking?.guest?.adults}
            </p>
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Child:</span>{" "}
              {booking?.guest?.child}
            </p>
          </div>
        </Grid>
        <Grid item lg={3} md={12} xs={12}></Grid>

        {/* <Grid item lg={2} md={0} xs={0} />
        <Grid item lg={7} md={12} xs={12}>
          <h4 style={{ marginTop: "25px" }}>Room Details: </h4>
          <div id="description" className="bix-box">
            <h6>Room Category: </h6>
            <p className="address">Punnamada Ramada Resort</p>
          </div>
        </Grid>
        <Grid item lg={3} md={12} xs={12}></Grid> */}

        <Grid item lg={2} md={0} xs={0} />
        <Grid item lg={7} md={12} xs={12}>
          <h4 style={{ marginTop: "25px" }}>Room Details: </h4>
          <div id="description" className="bix-box">
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Room Category:</span>{" "}
              {booking?.roomCategory}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Room Type:</span>{" "}
              {booking?.roomType}
            </p>
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>No Of Rooms:</span>{" "}
              {booking?.guest?.rooms}
            </p>

            {/* <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Occupancy Type:</span>{" "}
              {booking?.occupancyType}
            </p> */}
          </div>
        </Grid>
        <Grid item lg={3} md={12} xs={12}></Grid>

        <Grid item lg={2} md={0} xs={0} />
        <Grid item lg={7} md={12} xs={12}>
          <h4 style={{ marginTop: "25px" }}>Payment Details: </h4>
          <div id="description" className="bix-box">
            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Price:</span> {"Rs "}
              {booking?.roomPrice}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>GST:</span> {"Rs "}
              {booking?.customerGst}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Discount:</span> {"Rs "}
              {booking?.discount}
            </p>

            <p className="address mb-2">
              <span style={{ fontWeight: "bold" }}>Amount Paid:</span> {"Rs "}
              {booking?.customerPayableAmount}
            </p>
          </div>
        </Grid>
        <Grid item lg={3} md={12} xs={12}></Grid>

        {booking?.status == "cancel" ? (
          <>
            <Grid item lg={2} md={0} xs={0} />
            <Grid item lg={7} md={12} xs={12}>
              <h3
                className="scroll-to-content"
                id="policies"
                style={{ marginTop: "25px" }}
              >
                Hotel Policies
              </h3>
              <div>
                <div className="booknowList" style={{ marginTop: "10px" }}>
                  <ul>
                    {booking?.hotelId?.hotelPolicyId?.CancellationPolicy[0]
                      ?.CancellationFee > 0 ? (
                      <li>
                        Cancellation fee of{" "}
                        {
                          booking?.hotelId?.hotelPolicyId?.CancellationPolicy[0]
                            ?.CancellationFee
                        }{" "}
                        % of the total booking if cancelled less than{" "}
                        {
                          booking?.hotelId?.hotelPolicyId?.CancellationPolicy[0]
                            ?.priorHour
                        }{" "}
                        hours prior to arrival
                      </li>
                    ) : (
                      ""
                    )}

                    {booking?.hotelId?.hotelPolicyId?.ChildPolicy.map(
                      (policy, index) => (
                        <li key={index}>{policy.policy}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item lg={3} md={12} xs={12}></Grid>
          </>
        ) : (
          ""
        )}

        <Grid item lg={2} md={0} xs={0} />
        <Grid item lg={7} md={12} xs={12}>
          <h3
            className="scroll-to-content"
            id="policies"
            style={{ marginTop: "25px" }}
          >
            Terms And Conditions
          </h3>
          <div>
            <Row style={{ width: "100%" }} justify="space-between">
              <Col span={11} className="booknow-timebtn">
                <strong style={{ fontSize: "16px" }}>
                  {" "}
                  Check-in : 12:00 PM
                </strong>
              </Col>
              <Col span={11} className="booknow-timebtn">
                <strong style={{ fontSize: "16px" }}>
                  {" "}
                  Check-out : 11:00 AM
                </strong>
              </Col>
            </Row>
            <div className="booknowList" style={{ marginTop: "10px" }}>
              <ul>
                {/* <li>
                  Cancellation fee of{" "}
                  {
                    booking?.hotelId?.hotelPolicyId?.CancellationPolicy[0]
                      ?.CancellationFee
                  }{" "}
                  % of the total booking if cancelled less than{" "}
                  {
                    booking?.hotelId?.hotelPolicyId?.CancellationPolicy[0]
                      ?.priorHour
                  }{" "}
                  hours prior to arrival
                </li>
                <li>
                  Child Policy -{" "}
                  {booking?.hotelId?.hotelPolicyId?.ChildPolicy[0]?.policy}
                </li> */}

                {booking?.hotelId?.termsAndConditions?.map((terms, index) => (
                  <li key={index}>{terms}</li>
                ))}
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={12} xs={12}></Grid>

        <Grid item lg={2} md={0} xs={0} />
        <Grid item lg={7} md={12} xs={12}>
          {booking?.status == "confirm" ? (
            <Button
              type="primary"
              danger
              style={{ marginBottom: "20px" }}
              onClick={handleCancelBooking}
            >
              Cancel Booking
            </Button>
          ) : (
            ""
          )}
        </Grid>
        <Grid item lg={3} md={12} xs={12}></Grid>
      </Grid>
    </>
  );
}

export default BookingConfirmation;
