import { notification } from "antd";

export const NotificationBox = () => {
  const [
    ,
    // api
    contextHolder,
  ] = notification.useNotification();
  if (window.innerWidth <= 768) {
    // api.info({
    //   placement: "top",
    // });
    notification.config({
      top: 200,
      placement: "top",
    });
  } else {
    notification.config({
      top: 120,
      placement: "topRight",
    });
  }

  // const openNotification = (placement) => {
  //   api.info({
  //     message: `Notification ${placement}`,
  //     description:description,
  //     placement,
  //   });
  // };

  const openNotificationSucess = (message, description) => {
    {
      message == "Details sent to Admin Successfully.."
        ? notification.success({
            message: <span style={{ fontWeight: "bold" }}>{message}</span>,
            description: description,
            placement: window.innerWidth <= 768 ? "top" : "topRight",
            // style: {
            //   marginTop: "100px", // Adjust the z-index value as needed
            // },
          })
        : notification.success({
            message: <span style={{ fontWeight: "bold" }}>{message}</span>,
            description: description,
            placement: window.innerWidth <= 768 ? "top" : "topRight",
            style: {
              zIndex: 5000, // Adjust the z-index value as needed
            },
          });
    }
  };
  const openNotificationError = (message, description) => {
    notification.error({
      message: message,
      description: description,
      placement: window.innerWidth <= 768 ? "top" : "topRight",
    });
  };
  return [contextHolder, openNotificationSucess, openNotificationError];
};
