const HOUSEBOAT_POLICY_LIST_HEADERS = [
    {
      dataIndex: "col1",
      title: "S.N",
      nummeric: false,
      disablePadding: true,
      label: "S.N",
      width: 40,
    },
  
    {
      dataIndex: "col2",
      title: "Policy Code",
      nummeric: false,
      disablePadding: true,
      width: 80,
    },
  
    {
      dataIndex: "col3",
      title: "Market Type",
      nummeric: false,
      disablePadding: true,
      filter: "select",
      width: 80,
    },
  
    {
      dataIndex: "col4",
      title: "Remarks",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 120,
    },
  
    {
      dataIndex: "col5",
      title: "Actions",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 80,
    },
  ];
  
  export default HOUSEBOAT_POLICY_LIST_HEADERS;
  