const HOTEL_BOOKING_LIST_TABLE_HEADERS = [
  {
    dataIndex: "col1",
    title: "S.N",
    nummeric: false,
    disablePadding: true,
    label: "S.N",
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 10,
  },
  {
    dataIndex: "col2",
    title: "Customer Name",
    nummeric: false,
    disablePadding: true,

    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 30,
  },
  {
    dataIndex: "col3",
    title: "Hotel Name",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 20,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  {
    dataIndex: "col4",
    title: "Amount",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 20,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  {
    dataIndex: "col5",
    title: "Booking Date",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 20,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  {
    dataIndex: "col6",
    title: "CheckIn",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 20,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  {
    dataIndex: "col7",
    title: "CheckOut",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 20,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  {
    dataIndex: "col8",
    title: "Status",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 20,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
  },
  // {
  //   dataIndex: "col6",
  //   title: "Booking Details",
  //   nummeric: false,
  //   disablePadding: true,
  //   label: "Category",
  //   filter: "select",
  //   width: 130,
  // },
  {
    dataIndex: "col9",
    title: "Actions",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 30,
  },
];

export default HOTEL_BOOKING_LIST_TABLE_HEADERS;
