/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import UserDrawer from "../../usersPage/newUserLayout/userDrawer";
import logo from "../../../assets/biglogo.jpg";
import { ReactComponent as Invitec } from "../../../assets/invitecsvg.svg";
// import { ReactComponent as Careico } from '../../assets/careico.svg'
import { ReactComponent as ListPro } from "../../../assets/listPros.svg";
import profile from "../../../assets/loging 01.png";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import ModalComponent from "../../../components/molecules/modalComponent/ModelComponent";
import BtnComp from "../../../components/atoms/buttonComponent/BtnComp";
import ListPropertyModel from "../../../components/molecules/modalComponent/listProperty/ListPropertyModel";
import { NotificationBox } from "../../../components/atoms/notificationBox/NotificationBox";
import { useDispatch, useSelector } from "react-redux";
import { setSearchCardData } from "../../../reduxData/sliceData/searchCardReducer";
import Avatar from "@mui/material/Avatar";
// import Skeleton from "@mui/material/Skeleton";

import moment from "moment/moment";
import { getProfileImage } from "../../../services/utils/userUtils/hotelutils/otpverification";

const currentDate = moment().startOf("day").format("DD/MM/YYYY");
const nextDate = moment().add(1, "day").endOf("day").format("DD/MM/YYYY");

function HeaderForAll() {
  const [showDrower, setshowDrower] = useState(false);
  const [listPropertyModelOpen, setListPropertyModelOpen] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState();
  const [contextHolder, openNotificationSuccess, openNotificationError] =
    NotificationBox();
  const token = localStorage.getItem("token");
  const isMobile = !token && window.innerWidth <= 768 ? true : false;
  const nav = useNavigate();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };
  const loggedInUser = useSelector((s) => s.userDetails.user);
  const USER_ID = loggedInUser?.id;
  useEffect(() => {
    const getUserProfileImage = () => {
      getProfileImage(USER_ID).then((res) => {
        console.log(res);
        const { profileImage } = res.data.response;
        setUserProfilePic(profileImage);
      });
    };

    if (loggedInUser?.name) {
      getUserProfileImage();
    }
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  useEffect(() => {
    scrollToTop();
    return () => scrollToTop();
  }, []);

  const resetSearch = () => {
    dispatch(
      setSearchCardData({
        destinationName: "",
        checkIn: currentDate,
        checkOut: nextDate,
        guestDetails: {
          rooms: 1,
          childAge: [],
          adults: 2,
          children: 0,
        },
      })
    );
  };

  return (
    <Row
      className="just-center "
      style={{
        boxShadow: "2px 5px 7px #b4ceed,-2px -5px 7px #e2f3ff",
        backgroundColor: "#fff",
        position: "sticky",
        top: "0",
        zIndex: "9999",
      }}
      justify="space-between"
    >
      {contextHolder}
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 6 }}
        xl={{ span: 6 }}
      >
        <div className="hed-logo-div">
          <img
            className="hed-logo"
            src={logo}
            onClick={() => {
              resetSearch();
              nav("/");
            }}
            alt=""
          />
        </div>
      </Col>

      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 18 }}
        xl={{ span: 18 }}
      >
        <Row justify="end" className="">
          <Col span={24}>
            <Row
              justify="end"
              style={{ marginRight: "10%" }}
              className="helpLinks"
            >
              <Row gutter={30}>
                <Col className="headTop vl p-2">
                  <Tooltip
                    title={
                      <div style={{ background: "#000", padding: "6px" }}>
                        <div
                          style={{
                            fontSize: "15px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <LocalPhoneIcon style={{ fontSize: "15px" }} />
                          8889874561{" "}
                        </div>
                        <div style={{ fontSize: "15px" }}>
                          customercare@godsownstay.com
                        </div>
                      </div>
                    }
                  >
                    <LocalPhoneIcon mt={5} />
                  </Tooltip>
                  <span onClick={() => setOpen(true)}>
                    {" "}
                    24&times;7 gosCare Support
                  </span>
                </Col>

                <Col
                  className="headTop vl p-1 "
                  size="medium"
                  onClick={() => setListPropertyModelOpen((o) => !o)}
                >
                  <ListPro
                    className="invitec"
                    style={{ height: "35px", width: "35px" }}
                  />
                  List your property
                </Col>
                <Col className="headTop p-2" size="medium">
                  <Invitec
                    className="invitec"
                    style={{ height: "20px", widows: "20px" }}
                  />
                  &nbsp; Gos Money+
                </Col>
              </Row>
            </Row>
          </Col>
          <Col className="main-drower-sty">
            <>
              {loggedInUser?.name && isMobile && (
                // <button
                //   onClick={() => setshowDrower(prev => !prev)}
                //   style={{ cursor: "pointer" ,border: 'none', background: 'transparent'}}
                // >
                //   <img
                //     src={userProfilePic ? userProfilePic : profile}
                //     alt="profile"
                //     style={{ height: 35, width: 45, objectFit: "contain" }}
                //   />
                // </button>
                <div
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "-8px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {userProfilePic ? (
                    <>
                      <p
                        style={{
                          color: "rgb(22, 117, 223)",
                          fontWeight: "600",
                          fontSize: 14,
                        }}
                      >
                        Hi&nbsp;Buddy!
                      </p>
                      <span
                        style={{ alignSelf: "flex-end" }}
                        onClick={() => setshowDrower((prev) => !prev)}
                      >
                        <Avatar
                          src={userProfilePic}
                          alt="profile-pic"
                          sx={{ width: 27, height: 27 }}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <p
                        style={{
                          color: "rgb(22, 117, 223)",
                          fontWeight: "600",
                          fontSize: 14,
                        }}
                      >
                        Hi&nbsp;Buddy!
                      </p>
                      <span
                        style={{ alignSelf: "flex-end" }}
                        onClick={() => setshowDrower((prev) => !prev)}
                      >
                        {/* <Skeleton
                          animation="wave"
                          variant="circular"
                          // width={30}
                          // height={30}
                        > */}
                        <Avatar
                          // src={userProfilePic ? userProfilePic : profile}
                          src={profile}
                          // alt="profile-pic"
                          sx={{ width: 27, height: 27 }}
                        />
                        {/* </Skeleton> */}
                      </span>
                    </>
                  )}
                </div>
              )}
              <FontAwesomeIcon
                icon={!loggedInUser?.name && faBars}
                onClick={() => setshowDrower((prev) => !prev)}
                style={{
                  color: "#000000",
                  fontSize: "2.2rem",
                }}
              />

              <UserDrawer
                showDrower={showDrower}
                setshowDrower={setshowDrower}
                isMobile={isMobile}
              />
            </>
            <ModalComponent
              isModalOpen={open}
              modalTitle="24x7 gosCare Support"
              closable={false}
              modalWidth={600}
            >
              <div style={{ margin: "10px" }}>
                <div>
                  Email Us :{" "}
                  <a
                    onClick={(e) => {
                      window.location.href =
                        "mailto:customercare@godsownstay.com";
                      e.preventDefault();
                    }}
                  >
                    customercare@godsownstay.com
                  </a>
                </div>
                <div>
                  Contact Us:{" "}
                  <a
                    onClick={(e) => {
                      window.location.href = "tel:8286882878";
                      e.preventDefault();
                    }}
                  >
                    82868 82878
                  </a>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "12px",
                }}
              >
                <BtnComp
                  btnName="Cancel"
                  startIcon={faTimes}
                  color="white"
                  bg_color="#d15b47"
                  bordercolor="#d15b47"
                  pl="10px"
                  btnClickFun={handleClose}
                />
              </div>
            </ModalComponent>
            <ListPropertyModel
              open={listPropertyModelOpen}
              setListPropertyModelOpen={setListPropertyModelOpen}
              openNotificationSuccess={openNotificationSuccess}
              openNotificationError={openNotificationError}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default HeaderForAll;
