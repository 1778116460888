import React, { useEffect } from "react";
import "./PayUPaymentPage.css";
import backButton from "../../assets/payu/chevronleft1767-bow7.svg";
import percentageIcon from "../../assets/payu/persontage_icon_f.svg";
import roomCategoryIcon from "../../assets/payu/catagory-icon-f.svg";

<>
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
    rel="stylesheet"
    integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
    crossOrigin="anonymous"
  />
  <script
    src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"
    crossOrigin="anonymous"
  ></script>
</>;

const PayUPaymentPage = ({
  setToggle,
  hash,
  amount,
  transactionId,
  bookingDetail,
}) => {
  //console.log("bookingDetail", bookingDetail);
  useEffect(() => {
    const payNowButtons = document.getElementById("payNowButton");
    const paymentForm = document.getElementById("paymentForm");

    if (payNowButtons && paymentForm) {
      payNowButtons.addEventListener("click", function () {
        paymentForm.submit();
      });
    }
  }, []);

  useEffect(() => {
    document
      .getElementById("topheaderPricing")
      .scrollIntoView({ behavior: "smooth", block: "end"});
  }, []);


  const checkIn = new Date(bookingDetail?.checkIn);
  const checkOut = new Date(bookingDetail?.checkOut);
  const dateDifferenceInMillis = checkOut - checkIn;

  const daysDifference = dateDifferenceInMillis / (1000 * 60 * 60 * 24);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };
  return (
    <>
      <div id="payucheckout" className="payu-payment-page container py-3">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="payment_informationBox p-2 p-sm-4 ">
              <button
                onClick={() => {
                  setToggle(1);
                }}
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  cursor: "pointer",
                }}
                aria-label="Go back"
              >
                <img src={backButton} alt="" />
              </button>

              <div id="topheaderPricing" className="top_headerPricing">
                <p>Total Payable Amount</p>
                <h4>₹ {amount}</h4>
              </div>
              <div className="innerPaymentboxed mt-2 mb-2">
                <div className="PaymentHead">Payment Information</div>
                <div className="topnorth_checkoutstr p-md-4 p-2 d-flex gap-md-3 gap-2 align-items-center position-relative">
                  <div className="iNourthdatas">
                    <img
                      src={bookingDetail?.hotelDetails?.hotelPic}
                      alt=""
                      className="nourthImg"
                    />
                  </div>
                  <div className="content_topNourth">
                    <h4>{bookingDetail?.hotelDetails?.hotelName}</h4>
                    <p>{bookingDetail?.hotelDetails?.hotelAddress}</p>
                  </div>
                </div>
                <div className="paymentProgress  position-relative  d-flex justify-content-between align-items-center px-md-5 px-1">
                  <div className="paymentSend text-end p-2">
                    {/* <div className="time">12:00 PM</div> */}
                    <div className="date">
                      {formatDate(bookingDetail?.checkIn)}
                    </div>
                    <div className="checkinout">Check-In</div>
                  </div>
                  <div className="inbetweenTime p-1">
                    <div className="timeLeft"> {daysDifference} Night</div>
                  </div>
                  <div className="paymentRecieve p-2">
                    {/* <div className="time">12:00 PM</div> */}
                    <div className="date">
                      {formatDate(bookingDetail?.checkOut)}
                    </div>
                    <div className="checkinout">Check-Out</div>
                  </div>
                  <div className="dotedLineBorder"></div>
                </div>
                <hr className="paymentlinecentre mx-4" />
                <div className="bottom_paymentnourth px-md-4 px-2 pt-3 pb-4">
                  <div className="catagory_textcheck">
                    <h5>
                      <img src={roomCategoryIcon} alt="" className="pe-3" />
                      Room Category : <span>{bookingDetail?.roomCategory}</span>
                    </h5>
                  </div>
                  <div className="center_contentText-ipd  d-grid justify-content-center pt-md-2 pe-md-5">
                    <div className="row">

                      <div className="col-lg-6 col-md-12 col-sm-12 mob-pad">
                        <p className=" py-1 mb-0">
                          Name : <span>{bookingDetail?.firstName}</span>
                        </p>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 mob-pad">
                        <p className=" py-1 mb-0">
                          Email : <span>{bookingDetail?.email}</span>
                        </p>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 mob-pad">
                        <p className=" py-1 mb-0">
                          Phone : <span>{bookingDetail?.whatsAppNumber}</span>
                        </p>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 mob-pad">
                        <p className=" py-1 mb-0">
                          Adult : <span>{bookingDetail?.guest?.adults}</span>
                        </p>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 mob-pad">
                        <p className=" py-1 mb-0">
                          Child : <span> {bookingDetail?.guest?.child}</span>
                        </p>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 mob-pad">
                        <p className=" py-1 mb-0">
                          Pay Amount : <span>₹ {amount}</span>
                        </p>
                      </div>

                    </div>

                  </div>
                  <div
                    className="center_contentText-ipd d-grid justify-content-center pe-md-5"
                    id="pay_amount"
                  ></div>
                </div>
              </div>
              <div className="bottomBoxedPersant d-flex align-items-center gap-3 mb-2 mb-sm-4 percentage-icon">
                <img src={percentageIcon} alt="" />
                <p>
                  The Best Available coupon for payment option has been applied
                </p>
              </div>
              <div className="pay_now_button p-md-4 p-3">
                <button
                  className="btnPaynow"
                  data-bs-toggle="modal"
                  data-bs-target="#paymentModal"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>

      {/* payment Modal */}
      <div
        className="modal paymenyCtm fade"
        id="paymentModal"
        tabIndex="-1"
        aria-labelledby="paymentModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content py-lg-2 px-lg-2">
            <div className="modal-body">
              <h4>Hi Buddy!</h4>
              <p>
                This <span>booking can not be completed if you go back</span>{" "}
                now.
              </p>
              <p>
                This will <span>affect the room availability and price</span>.
              </p>
            </div>
            <div className="modal-footer flex-nowrap gap-2 pt-0 border-0">
              <button
                type="button"
                className="btn_back"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button type="button" className="btn_continue" id="payNowButton">
                Continue Booking
              </button>
            </div>
          </div>
        </div>
      </div>

      <form
        action={process.env.REACT_APP_PAYU_PAYMENT_URL}
        method="POST"
        id="paymentForm"
      >
        <input
          type="hidden"
          name="key"
          value={process.env.REACT_APP_PAYMENT_MERCHANT_KEY}
        />
        <input type="hidden" name="txnid" value={transactionId} />
        <input type="hidden" name="amount" value={amount} />
        <input type="hidden" name="productinfo" value={bookingDetail?.booking_id} />
        <input
          type="hidden"
          name="firstname"
          value={bookingDetail?.firstName}
        />
        <input type="hidden" name="email" value={bookingDetail?.email} />
        <input
          type="hidden"
          name="phone"
          value={bookingDetail?.whatsAppNumber}
        />
        <input
          type="hidden"
          name="surl"
          value={`${process.env.REACT_APP_BASE_URL}orders/payments/ccavResponse`}
        />
        <input
          type="hidden"
          name="furl"
          value={`${process.env.REACT_APP_BASE_URL}orders/payments/failure`}
        />
        <input type="hidden" name="udf1" value={bookingDetail?.roomCategory} />
        <input type="hidden" name="udf2" value={bookingDetail?._id} />
        <input type="hidden" name="udf3" value={""} />
        <input type="hidden" name="udf4" value={""} />
        <input type="hidden" name="udf5" value={""} />
        <input type="hidden" name="hash" value={hash} />
      </form>
    </>
  );
};

export default PayUPaymentPage;
