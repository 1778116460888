import serviceUtil from "../..";

const PackageGetAll = () => {
  return serviceUtil
    .get("user/package/getAllPackageDetailsToBook")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const Packagefilter = (data) => {
  return serviceUtil
    .post("user/filterSearch/packageFilter", data)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const CreatePackageBookingGuestDetails = (data) => {
  return serviceUtil
    .post("user/package/createPackageBookingGuestDetails", data)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const packageBookingEmail = (payload) => {
  return serviceUtil
    .post("user/package/packageBookingEmail", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err;
      return { errRes };
    });
};

const packageTerms = (payload) => {
  return serviceUtil
    .post("user/package/packageTerms", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err;
      return { errRes };
    });
};
export {
  PackageGetAll,
  Packagefilter,
  CreatePackageBookingGuestDetails,
  packageBookingEmail,
  packageTerms,
};
