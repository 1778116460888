import serviceUtil from "../..";

const UserShikaraGetAll = () => {
  return serviceUtil
    .get("user/shikara/getAllShikaraDetails")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const UserShikaraGetAllfilter = (data) => {
  return serviceUtil
    .post("user/filterSearch/shikaraFilter", data)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const createShikaraGuestDetails = (data) => {
  return serviceUtil
    .post("user/shikara/createShikaraGuestDetails", data)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const shikaraBookingEmail = (payload) => {
  return serviceUtil
    .post("user/shikara/shikaraBookingEmail", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err;
      return { errRes };
    });
};
export {
  UserShikaraGetAll,
  UserShikaraGetAllfilter,
  createShikaraGuestDetails,
  shikaraBookingEmail,
};
