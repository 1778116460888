/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { footarLocationForAll } from "../../../services/utils/userUtils/landingPage";
import { DestinationGetAll } from "../../../services/utils/userUtils/landingPage";
import { useDispatch } from "react-redux";
import {
  setPlaceData,
  setSearchToggle,
} from "../../../reduxData/sliceData/searchCardReducer";

function HomePageFooter() {
  const nav = useNavigate();
  const [destinationData, setdestinationData] = useState([]);
  let dispatch = useDispatch();
  const footerDataFun = async () => {
    const res = await footarLocationForAll();
    dispatch(setPlaceData(res));
  };

  const DestinationgetData = async () => {
    const res = await DestinationGetAll();

    const imgdata = await res?.data?.response.map((v) => {
      return {
        destinationImage: v.destinationImage[0],
        destinationName: v.destinationName,
        isSelected: v.isSelected,
      };
    });
    setdestinationData(imgdata);
  };

  useEffect(() => {
    DestinationgetData();
  }, []);

  function chunkArray(arr, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  }

  const arrayOfArrays = chunkArray(destinationData, 5);

  useEffect(() => {
    footerDataFun();
  }, []);

  return (
    <MDBFooter
      bgColor="dark"
      className="text-center text-lg-left linkUnderline"
      style={{ marginBottom: "50px" }}
    >
      <MDBContainer className="p-3 mt-10">
        <MDBRow
          style={{ textAlign: "left", marginTop: "50px" }}
          className="media-text-center"
        >
          <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
            <h4 className="text-light mb-0 h-35" style={{ fontSize: "1.4rem" }}>
              {" "}
              Godsownstay.com
            </h4>

            <ul className="list-unstyled mb-0 ">
              <li>
                <Link to="/aboutUsLink" className="text-light">
                  <p className="style-gray h5-5">About us</p>
                </Link>
              </li>
              <li>
                <Link to="/feedbacklink" className="text-light">
                  <p className="style-gray h5-5">Contact</p>
                </Link>
              </li>
              <li>
                <a href="#" className="text-light">
                  <p className="style-gray h5-5   ">Press</p>
                </a>
              </li>
              <li>
                <a href="#!" className="text-light">
                  <p className="style-gray h5-5   ">Travel Blog</p>
                </a>
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg="3" md="6" className="mb-4 mb-md-0 ">
            <h4
              className="text-light mb-0  h-35"
              style={{ fontSize: "1.4rem" }}
            >
              Partner With Us
            </h4>

            <ul className="list-unstyled">
              <li>
                <Link to="/feedbacklink" className="text-light">
                  <p className="style-gray h5-5">Be our Franchisee</p>
                </Link>
              </li>
              <li>
                <Link to="/feedbacklink" className="text-light">
                  <p className="style-gray h5-5">Travel Agent</p>
                </Link>
              </li>
              <li>
                <Link to="/feedbacklink" className="text-light">
                  <p className="style-gray h5-5">Corporate Enquiries</p>
                </Link>
              </li>
            </ul>
          </MDBCol>

          <MDBCol
            lg="3"
            md="6"
            className="mb-4 mb-md-0 media-text-center"
            style={{ textAlign: "left" }}
          >
            <h4 className="text-light mb-0 h-35" style={{ fontSize: "1.4rem" }}>
              Policies
            </h4>

            <ul className="list-unstyled mb-0">
              <li>
                <Link to="/termsandconditionslink" className="text-light">
                  <p className="style-gray h5-5">Terms And Conditions</p>
                </Link>
              </li>
              <li>
                <Link to="/privacyandpolicylink" className="text-light">
                  <p className="style-gray h5-5   ">Privacy Policy</p>
                </Link>
              </li>
              <li>
                <Link to="/cancellationpolicylink" className="text-light">
                  <p
                    className="style-gray h5-5   "
                    style={{ textDecoration: "none" }}
                  >
                    Cancellation Policy
                  </p>
                </Link>
              </li>
            </ul>
          </MDBCol>

          <h5
            className="text-light mb-1 h-35"
            style={{
              fontSize: "1.4rem",
              marginTop: "50px",
              marginBottom: "25px",
            }}
          >
            Book our hotels in all these cities
          </h5>

          {arrayOfArrays.slice(0, 8).map((v, i) => {
            return (
              <MDBCol
                lg="3"
                md="6"
                className="mb-4 mb-md-0"
                style={{ textAlign: "left" }}
                key={i}
              >
                <ul className="list-unstyled">
                  {v.map((u, j) => {
                    return (
                      <>
                        <li key={j}>
                          <a className="text-light ">
                            <p
                              className="style-gray h5-5 textAlignMedia "
                              onClick={() => {
                                dispatch(setSearchToggle(1));
                                // nav(`/hotel/${u.destinationName}`, {
                                //   state: { destination: u.destinationName },
                                // });
                                nav(
                                  u.destinationName == "Kochi"
                                    ? `/hotels/search-hotels-in-${u.destinationName}/cochin-hotels.html`
                                    : u.destinationName == "Kozhikode"
                                    ? `/hotels/search-hotels-in-calicut/${u.destinationName}-hotels.html`
                                    : `/hotels/search-hotels-in-${u.destinationName}-hotels.html`,
                                  {
                                    state: {
                                      destination: u.destinationName,
                                    },
                                  }
                                );
                              }}

                              // navigate(
                              //   v.destinationName == "Kochi"
                              //     ? `/${routePage}/search-hotels-in-cochin/${v.destinationName}-hotels.html`
                              //     : `/${routePage}/search-hotels-in-${v.destinationName}-hotels.html`,
                              //   {
                              //     state: {
                              //       destination: v.destinationName,
                              //     },
                              //   }
                              // );
                            >
                              Hotels in {u.destinationName}
                            </p>
                          </a>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </MDBCol>
            );
          })}
        </MDBRow>
      </MDBContainer>
      <MDBContainer style={{ textAlign: "left" }}>
        <MDBRow className="media-text-center">
          {/* <MDBCol>
            <h5 className="text-light mb-1 h-35" style={{ fontSize: '1.4rem' }}>
              Book our hotels in all these cities
            </h5>
           

            {arrayOfArrays.slice(0, 5).map((v, i) => {
              return (
                <div key={i}>
                  <MDBCol key={i} lg="3" md="6" className="mb-4 mb-md-0 ">
                    <ul className="list-unstyled">
                      {v.map((u, j) => {
                        return (
                          <li key={j}>
                            <a href="#!" className="text-light">
                              <p
                                className="style-gray h5-5"
                                onClick={() =>
                                  nav(
                                    `/hotel/${u.destinationName.toLowerCase()}`,
                                  )
                                }
                              >
                                Hotels in {u.destinationName}
                              </p>
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </MDBCol>
                </div>
              )
            })}
          </MDBCol> */}
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
}

export default HomePageFooter;
