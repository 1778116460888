import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper } from "@mui/material";
import { Button, Carousel, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "../../../../styles/global.scss";
import { bookNowCoupan } from "../../../../services/utils/userUtils/hotelutils";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { setSelectedRoomRate } from "../../../../reduxData/sliceData/searchCardReducer";

const HotelBookNowCards = ({
  hotelImages,
  proceedBtnFun,
  onDataFromChild,
  hide,
  roomState,
  selectedRoomData,
  gst,
  applyedDiscountValue,
  onData,
}) => {
  const { actualRoomRate, roomRate, discountRate, serviceAndGst } = roomState;

  const { filterData } = useSelector((state) => state.hotelAllData);

  const dispatch = useDispatch();

  const searchCardData = useSelector(
    (state) => state?.searchCardData?.searchCardData
  );

  const checkInFullDate = moment(searchCardData?.checkIn, "DD/MM/YYYY").format(
    "ddd MMM DD YYYY"
  );
  const checkOutFullDate = moment(searchCardData?.checkOut, "DD/MM/YYYY").format(
    "ddd MMM DD YYYY"
  );

  const [priceValue, setPriceValue] = useState({
    basePrice: 0,
    offerDiscountRate: 0,
    coupondiscountPrice: 0,
    coupondiscountPercentage: 0,
    subTotal: 0,
    serviceCharge: 0,
    payableAmount: 0,
  });
  const [couponError, setCouponError] = useState(false);

  const [coupanCode, setcoupanCode] = useState("");
  const [validCoupanCode, setValidCoupanCode] = useState("");
  const [validCoupanCodeValue, setValidCoupanCodeValue] = useState("");
  const [resposeDiscountValue, setResposeDiscountValue] = useState(0);

  let com =
    (filterData[0]?.basicDetails?.markupType?.markupValue *
      selectedRoomData?.roomRateWithOutDiscount) /
    100;

  useEffect(() => {
    if (applyedDiscountValue != 0) {
      setPriceValue({
        basePrice: actualRoomRate?.toFixed(2),
        offerDiscountRate: roomRate * (discountRate / 100).toFixed(2),
        coupondiscountPrice: applyedDiscountValue?.toFixed(2),
        coupondiscountPercentage:
          (applyedDiscountValue * 100) / selectedRoomData?.roomRate1,
        subTotal: roomRate ? roomRate.toFixed(2) : "",
        serviceCharge: serviceAndGst?.toFixed(2),
        service:
          (filterData[0]?.hotelLocation?.gsttax *
            selectedRoomData?.roomRateWithOutDiscount) /
            100 +
          (com * 18) / 100,
        payableAmount: (
          actualRoomRate +
          serviceAndGst -
          applyedDiscountValue
        ).toFixed(2),
      });
    } else {
      setPriceValue({
        basePrice: actualRoomRate?.toFixed(2),
        offerDiscountRate: roomRate * (discountRate / 100).toFixed(2),
        coupondiscountPrice: applyedDiscountValue?.toFixed(2),
        coupondiscountPercentage: applyedDiscountValue,
        subTotal: roomRate ? roomRate.toFixed(2) : "",
        serviceCharge: serviceAndGst?.toFixed(2),
        payableAmount: (
          actualRoomRate +
          serviceAndGst -
          applyedDiscountValue
        ).toFixed(2),
      });
    }
  }, [applyedDiscountValue, resposeDiscountValue, roomState]);

  const bookNowCoupanFunction = async () => {
    try {
      const payload = {
        coupanCode,
        hotelId: filterData[0]?._id,
      };

      const res = await bookNowCoupan(payload);
      if (res.data.value) {
        let couponValue = parseInt(res.data.value);

        setResposeDiscountValue(couponValue?.toFixed(2));
        onData(couponValue?.toFixed(2));
        const payableAmount = Math.ceil(
          selectedRoomData?.roomRateWithOutDiscount +
            selectedRoomData?.gstAmount -
            applyedDiscountValue -
            couponValue?.toFixed(2)
        );
        setValidCoupanCode(coupanCode.couponCode);
        setValidCoupanCodeValue(res?.data?.value);
        dispatch(setSelectedRoomRate({ ...selectedRoomData, payableAmount }));
      } else {
        setCouponError(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const calculatedValue = parseInt(
      (resposeDiscountValue !== 0
        ? selectedRoomData?.roomRate1 -
          applyedDiscountValue -
          resposeDiscountValue
        : selectedRoomData?.roomRate1 - applyedDiscountValue) +
        ((resposeDiscountValue !== 0
          ? selectedRoomData?.roomRate1 -
            applyedDiscountValue -
            resposeDiscountValue
          : selectedRoomData?.roomRate1 - applyedDiscountValue) *
          gst) /
          100
    ).toFixed(0);

    onDataFromChild(calculatedValue);
  }, [resposeDiscountValue, selectedRoomData, applyedDiscountValue, gst]);

  return (
    <Paper
      className="cart-paper mb-4"
      elevation={3}
      style={{ width: "auto", alignitems: "center", padding: "2% 5%" }}
    >
      <p className="cart-heading">{filterData[0]?.basicDetails?.hotelName}</p>
      <Row
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Col>
          <Carousel autoplay>
            {hotelImages.map((val, id) => {
              return (
                <div key={id}>
                  <img
                    className="cart-img"
                    src={val}
                    alt=""
                    style={{
                      width: "100%",
                      height: "200px",
                    }}
                  ></img>
                </div>
              );
            })}
          </Carousel>
        </Col>
        <Col style={{ display: "block" }}>
          <div className="cart-book-detail">
            <p>
              {checkInFullDate} - {checkOutFullDate}
            </p>
            <p>
              {searchCardData?.guestDetails?.rooms
                ? searchCardData?.guestDetails?.rooms
                : ""}{" "}
              Room, 
              {searchCardData?.guestDetails?.adults
                ? searchCardData?.guestDetails?.adults
                : 0}{" "}
              Adult, 
              {searchCardData?.guestDetails?.children
                ? searchCardData?.guestDetails?.children
                : 0}{" "}
              Child
            </p>
            <p>
              {roomState?.roomCategoryId?.roomCategory
                ? roomState?.roomCategoryId?.roomCategory
                : ""}{" "}
              -{" "}
              {roomState.roomTypeId.tagLine ? roomState.roomTypeId.tagLine : ""}
            </p>
          </div>
          <Row className="cart-coupon-div w-100" justify="space-between">
            <Col span={9} className="coupon-span1">
              Apply Coupons
            </Col>

            <Col span={15} className="coupon-span2">
              <div
                style={{
                  display: "flex",
                  flexBasis: "row",
                  justifyContent: "end",
                }}
              >
                <input
                  className="coupon-input"
                  type="text"
                  disabled={resposeDiscountValue != 0}
                  placeholder="Coupon Code"
                  onChange={(e) =>
                    setcoupanCode({ couponCode: e.target.value })
                  }
                />
                <Button
                  style={{ width: "40%" }}
                  onClick={bookNowCoupanFunction}
                  ghost={true}
                  className="promocodetext"
                >
                  <h6>
                    {resposeDiscountValue == 0 ? (
                      "Apply"
                    ) : (
                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                    )}
                  </h6>
                </Button>
              </div>
            </Col>
          </Row>
          {couponError && (
            <Row>
              <Col span={13}></Col>
              <Col span={11}>
                <div className="coupon-error mt-4">
                  This Coupon is not valid for this property.
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <hr className="new2"></hr>
        </Col>
      </Row>
      <Row className="cart-room-detail">
        <Col span={24}>
          <Row>
            <Col xs={18}>
              Room price for {selectedRoomData?.numberOfDays} Night
            </Col>
            <Col className="room-price">
              &nbsp;&nbsp;
              <FontAwesomeIcon
                className="fa-rupee-sign"
                icon={faIndianRupeeSign}
              />
              {/* 1,254 */}
              {Math.ceil(selectedRoomData?.roomRate1)}
            </Col>
            {/* <FontAwesomeIcon icon="fas fa-rupee-sign" /> */}
          </Row>
          <Row id="body">
            <Col xs={18} className="discount-span">
              Coupon Discount Amount
              <span className="vc">
                - {priceValue.coupondiscountPercentage.toFixed(0)}% Off
              </span>
            </Col>
            <Col className="room-price">
              -{" "}
              <FontAwesomeIcon
                className="fa-rupee-sign"
                icon={faIndianRupeeSign}
              />
              {Number(applyedDiscountValue)}
            </Col>
          </Row>

          {resposeDiscountValue != 0 && (
            <Row id="body">
              <Col xs={18} className="discount-span">
                Coupon Discount
              </Col>
              <Col className="room-price">
                -{" "}
                <FontAwesomeIcon
                  className="fa-rupee-sign"
                  icon={faIndianRupeeSign}
                />
                {resposeDiscountValue}
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={18}>Sub Total</Col>
            <Col className="room-price">
              &nbsp;{" "}
              <FontAwesomeIcon
                className="fa-rupee-sign"
                icon={faIndianRupeeSign}
              />
              {parseInt(
                resposeDiscountValue != 0
                  ? selectedRoomData?.roomRate1 -
                      applyedDiscountValue -
                      resposeDiscountValue
                  : selectedRoomData?.roomRate1 - applyedDiscountValue
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={18}>Service Charge (GST & Fees) </Col>
            <Col className="room-price">
              &nbsp;{" "}
              <FontAwesomeIcon
                className="fa-rupee-sign"
                icon={faIndianRupeeSign}
              />
              {/* {Math.ceil(
                (selectedRoomData?.roomRateWithOutDiscount -
                  applyedDiscountValue) *
                  (Number(gst) / 100)
              )} */}
              {parseInt(
                ((resposeDiscountValue != 0
                  ? selectedRoomData?.roomRate1 -
                    applyedDiscountValue -
                    resposeDiscountValue
                  : selectedRoomData?.roomRate1 - applyedDiscountValue) *
                  gst) /
                  100

                // +
                // ((resposeDiscountValue != 0
                //   ? selectedRoomData?.roomRate1 -
                //     applyedDiscountValue -
                //     selectedRoomData?.roomRate *
                //       selectedRoomData?.numberOfDays -
                //     resposeDiscountValue
                //   : selectedRoomData?.roomRate1 -
                //     applyedDiscountValue -
                //     selectedRoomData?.roomRate *
                //       selectedRoomData?.numberOfDays) *
                //   18) /
                //   100
              ).toFixed(0)}
            </Col>
          </Row>

          <Row className="inclusive-tax-div" justify="space-around">
            <Col className="inclusive-tax-col1" xs={16}>
              Payable Amount
              <Col className="all-taxes">Inclusive Of all taxes</Col>
            </Col>
            <Col className="room-price p-top">
              &nbsp;&nbsp;{" "}
              <FontAwesomeIcon
                className="fa-rupee-sign"
                icon={faIndianRupeeSign}
              />
              {/* {Math.ceil(
                selectedRoomData?.roomRateWithOutDiscount -
                  applyedDiscountValue -
                  resposeDiscountValue +
                  selectedRoomData?.gstAmount
              )} */}
              {parseInt(
                (resposeDiscountValue != 0
                  ? selectedRoomData?.roomRate1 -
                    applyedDiscountValue -
                    resposeDiscountValue
                  : selectedRoomData?.roomRate1 - applyedDiscountValue) +
                  ((resposeDiscountValue != 0
                    ? selectedRoomData?.roomRate1 -
                      applyedDiscountValue -
                      resposeDiscountValue
                    : selectedRoomData?.roomRate1 - applyedDiscountValue) *
                    gst) /
                    100

                // +
                // ((resposeDiscountValue != 0
                //   ? selectedRoomData?.roomRate1 -
                //     applyedDiscountValue -
                //     selectedRoomData?.roomRate *
                //       selectedRoomData?.numberOfDays -
                //     resposeDiscountValue
                //   : selectedRoomData?.roomRate1 -
                //     applyedDiscountValue -
                //     selectedRoomData?.roomRate *
                //       selectedRoomData?.numberOfDays) *
                //   18) /
                //   100
              ).toFixed(0)}
            </Col>
          </Row>
          <Row>
            <Col span={24} className="mb-2">
              {hide ? (
                <Button
                  type="primary"
                  style={{ borderRadius: "5px" }}
                  className="savedonBookingbtn p-3 fw-700 fs-6  just-center"
                  // disabled={proceedToPaymentOption}
                  onClick={() =>
                    proceedBtnFun(validCoupanCode, validCoupanCodeValue)
                  }
                >
                  <span style={{ cursor: "pointer" }}> PROCEED TO PAYMENT</span>
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Paper>
  );
};

export default HotelBookNowCards;
