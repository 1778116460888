/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Input, Row, Select } from 'antd'
import React, { useState } from 'react'
import AddIcon from "@mui/icons-material/Add";
import TableComponent from '../../../../components/molecules/tableComponent';
import CONSTANTS from '../../../../constants';

const CreateBtnFun = () => {
    //alert("Create Button clicked")
};

function TabOne() {
    const [value, setValue] = useState("");
    const pageOption = [
        {
            value: "5",
            label: "5",
          },
        {
            value: "10",
            label: "10",
        },
        {
            value: "25",
            label: "25",
        },
        {
            value: "50",
            label: "50",
        },
        {
            value: "100",
            label: "100",
        },
    ];

    const data = [
        {
            col1: 1,
            col2: "agdj",
            col3: "ehtyrt",
            col4: "gfvsghd",
            col5: "ehtyrt",
            col6: "ukjf"
        },
        {
            col1: 2,
            col2: "agdj",
            col3: "ghg",
            col4: "ghg",
            col5: "ghg",
            col6: "ukjf"
        },
        {
            col1: 3,
            col2: "agdj",
            col3: "ghg",
            col4: "ghg",
            col5: "ghg",
            col6: "ukjf"
        },
    ];


    return (
        <div>
            <Row className="table-header">
                <Col flex="auto">List of Hotel Availability</Col>
                <Col flex="40px">
                    <div onClick={CreateBtnFun} className="table-create-btn">
                        Create <AddIcon style={{ fontSize: "18px" }} />
                    </div>
                </Col>
            </Row>
            <Row className="dataTableHead">
                <Col span={12}>
                    <Row style={{ padding: "0px 12px" }}>
                        <Col className="header-select-child"> Display</Col>
                        <Col className="header-select-child">
                            <Select
                                defaultValue="10"
                                className="header-select"
                                style={{ marginTop: "-5px", backgroundColor: "#fff" }}
                                bordered={false}
                                options={pageOption}
                            />
                        </Col>
                        <Col className="header-select-child"> records</Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row className="header-serch" style={{ float: "right" }}>
                        <Col style={{ padding: "5px" }}>Search:</Col>
                        <Col>
                            {" "}
                            <Input
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Search"
                                value={value}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <TableComponent
                data={data}
                columns={CONSTANTS.HOTEL_AVAILABILITY_HEADERS}
            />
        </div>
    )
}

export default TabOne