const PACKAGE_SETTING_TERMS = [
 {
  dataIndex: "col1",
  title: "S.N",
  nummeric: false,
  disablePadding: true,
  label: "S.N",
  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 20,
 },
 {
  dataIndex: "col2",
  title: "Description",
  nummeric: false,
  disablePadding: true,

  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 160,
 },
 {
  dataIndex: "col3",
  title: "Code",
  nummeric: false,
  disablePadding: true,

  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 60,
 },
 {
  dataIndex: "col4",
  title: "Type",
  nummeric: false,
  disablePadding: true,

  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 60,
 },
 {
  dataIndex: "col5",
  title: "For",
  nummeric: false,
  disablePadding: true,

  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 60,
 },
 {
  dataIndex: "col6",
  title: "Actions",
  nummeric: false,
  disablePadding: true,
  label: "Category",
  filter: "select",
  width: 30,
 },
];

export default PACKAGE_SETTING_TERMS;
