/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Grid, Drawer } from "antd";
import reviewImage from "../../../../../assets/review.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { CustomerReview } from "../../../../../components/atoms/userAtoms/customerReview/CustomerReview";
const { useBreakpoint } = Grid;

function InnerDrawer({
  data,
  isDesktop,
  setCurrentPage,
  reviewdrower,
  setreviewdrower,
}) {
  const { sm, md } = useBreakpoint();

  return (
    <Drawer
      placement="top"
      onClose={() => setreviewdrower(false)}
      closable={false}
      open={reviewdrower}
      bodyStyle={{ padding: "0px" }}
      size={isDesktop ? "large" : "default"}
      style={
        window.innerWidth <= 600
          ? { paddingBottom: "20px", height: "400px", position: "relative" }
          : { paddingBottom: "20px", height: "400px", position: "relative" }
      }
    >
      <div
        onClick={() => {
          setCurrentPage(1);
        }}
        className="mobile2ndHeadGos mb-2 "
        style={{
          display: `${!sm || !md ? "block" : "none"}`,
          textAlign: "center",
        }}
      >
        Customer Review
      </div>
      <div
        onClick={() => {
          setCurrentPage(1);
        }}
        className={`${!sm || !md ? "mb-2" : "modalTitle"}`}
        style={{
          display: `${!sm || !md ? "none" : "block"}`,
          textAlign: "center",
        }}
      >
        <p>Customer Review</p>
      </div>
      <>
        {data[0]?.hotelReviewId?.length > 0 ? (
          <div className={`${!sm || !md ? "" : "container"}`}>
            <CustomerReview reviews={data[0].hotelReviewId} />
          </div>
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={reviewImage}
                alt=""
                style={
                  window.innerWidth <= 600 ? { width: "85%" } : { width: "30%" }
                }
              />
            </div>
            {window.innerWidth <= 600 ? (
              <h6 style={{ textAlign: "center", fontSize: "15px" }}>
                No Reviews Yet, Please Book this property and Review it.
              </h6>
            ) : (
              <h5 style={{ textAlign: "center" }}>
                No Reviews Yet, Please Book this property and Review it.
              </h5>
            )}
          </>
        )}
      </>
      <div
        className={`${!sm || !md ? "d-none" : "modalclosebtn"} pb-4`}
        style={{
          position: "absolute",
          bottom: "-9px",
          right: "-90px",
        }}
      >
        <button onClick={() => setreviewdrower(false)}>Close</button>
      </div>
      <p
        className={`${!sm || !md ? "d-block" : "d-none"}`}
        style={{
          color: "#1675DF",
          textAlign: "right",
          position: "absolute",
          bottom: "20px",
          right: "20px",
        }}
        onClick={() => setreviewdrower(false)}
      >
        Close
      </p>
    </Drawer>
  );
}

export default InnerDrawer;
