import BtnComp from "../../../atoms/buttonComponent/BtnComp";
import CheckBoxComponent from "../../../atoms/checkBoxComponent";
import ModalComponent from "../../../molecules/modalComponent/ModelComponent";
import React, { useEffect, useState } from "react";
import SelectComponent from "../../../atoms/SelectComponent/SelectComponent";
import dayjs from "dayjs";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, DatePicker, Row } from "antd";
// import { NotificationBox } from "../../../../atoms/notificationBox/NotificationBox";

import {
  faArrowRight,
  faCalendarDays,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { locationSettingMarketTypeGetAll } from "../../../../services/utils/admin/manageMaster/locationSetting/MarketTypeService";
import { hotelSettingRoomCategoryGetAll } from "../../../../services/utils/admin/manageMaster/hotelSettings/RoomCategoryService";
function StopSaleModel({ isModalOpen, setModalOpen, modalValue, formik }) {
  // const [contextHolder] = NotificationBox();
  const [marketTypeOption, setmarketTypeOption] = useState([]);
  const [roomCategoryOption, setroomCategoryOption] = useState([]);
  // const handleChangeArr = (e, i, k, f) => {
  //   const arr = formik.values[k];
  //   const obj = { ...arr[i], [f]: e };
  //   arr.splice(i, 1, obj);
  //   formik.setValues({ ...formik.values, [k]: arr });
  // };

  // const deleteItem = (i, k) => {
  //   const arr = [...formik.values[k]];
  //   arr.splice(i, 1);
  //   formik.setValues({ ...formik.values, [k]: arr });
  // };

  const handleClose = () => {
    setModalOpen(false);
  };
  const dataFun = async () => {
    const { data } = await locationSettingMarketTypeGetAll();
    const op = data.response.map((v) => {
      return {
        label: v.marketType,
        value: v._id,
      };
    });
    setmarketTypeOption(op);
  };
  const dataFun2 = async () => {
    const { data } = await hotelSettingRoomCategoryGetAll();
    const op = data.response.map((v) => {
      return {
        label: v.roomCategory,
        value: v._id,
      };
    });
    setroomCategoryOption(op);
  };
  useEffect(() => {
    dataFun();
  }, [formik.values]);

  useEffect(() => {
    dataFun2();
  }, [formik.values]);

  return (
    <>
      {/* {contextHolder} */}
      <ModalComponent
        createBtnName={modalValue === "Create" ? "Create" : "Update"}
        isModalOpen={isModalOpen}
        modalWidth={800}
        modalTitle={
          modalValue === "Create"
            ? "Create Hotel Block StopSale"
            : "Edit Hotel Block StopSale"
        }
        onCloseIconClick={handleClose}
        showFooter={false}
        showClearBtn={false}
        showSaveBtn={false}
        cancelBtnfun={handleClose}
        createBtnFun={formik.handleSubmit}
        resetBtnfun={formik.handleReset}
        endIcon={faArrowRight}
        closable={false}
      >
        {/* ________________________________________________________________________________________________ */}
        <Row className="p-4">
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row>
              <Col span={11}>
                {" "}
                <SelectComponent
                  options={marketTypeOption}
                  label="Market Type"
                  name="marketType"
                  value={formik.values.marketType}
                  handleChange={(e) => {
                    formik.setValues({ ...formik.values, marketType: e });
                  }}
                  style={{ color: "#666" }}
                  errors={formik.errors}
                  touched={formik.touched}
                />
              </Col>
              <Col span={11} offset={2}>
                <SelectComponent
                  options={roomCategoryOption}
                  label="Room Category"
                  name="roomCategory"
                  value={formik.values.roomCategory}
                  handleChange={(e) => {
                    formik.setValues({ ...formik.values, roomCategory: e });
                  }}
                  style={{ color: "#666" }}
                  errors={formik.errors}
                  touched={formik.touched}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="mt-4">
                <p className="labelInput">Type:</p>
              </Col>
              <Col className="mt-3">
                <CheckBoxComponent
                  label="Room Allocation"
                  divClassName=""
                  inputClassName="form-check-input styleCheckboxInput rounded-0 mx-1"
                  inputName="roomAllocation"
                  inputValue={formik.values.roomAllocation}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      roomAllocation: e.target.checked,
                    });
                  }}
                />
                {formik.errors.roomAllocation &&
                formik.errors.Block &&
                formik.errors.freeSale ? (
                  formik.errors?.roomAllocation &&
                  formik.touched?.roomAllocation ? (
                    <p className="re-err fs-10">
                      {formik.errors.roomAllocation}
                    </p>
                  ) : null
                ) : null}
              </Col>
              <Col className="mt-3" offset={3}>
                <CheckBoxComponent
                  label="Block"
                  divClassName=""
                  inputClassName="form-check-input styleCheckboxInput rounded-0 mx-1"
                  inputName="Block"
                  inputValue={formik.values.Block}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      Block: e.target.checked,
                    });
                  }}
                />
              </Col>
              <Col className="mt-3" offset={3}>
                <CheckBoxComponent
                  label="Free-Sale"
                  divClassName=""
                  inputClassName="form-check-input styleCheckboxInput rounded-0 mx-1"
                  inputName="freeSale"
                  inputValue={formik.values.freeSale}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      freeSale: e.target.checked,
                    });
                  }}
                />
              </Col>
            </Row>
            <fieldset className="my-5" style={{ border: "1px solid #9d9e9d" }}>
              <legend
                className="mx-5 w-auto"
                style={{
                  borderRadius: "4px",
                  border: "1px solid #9d9e9d",
                  lineHeight: 2,
                  backgroundColor: "#fff",
                  cursor: "pointer",
                  fontSize: "12px",
                  color: "#9d9e9d",
                  paddingRight: "25px",
                  paddingLeft: "15px",
                  position: "relative",
                  top: "-16px",
                }}
              >
                Validity List
              </legend>
              <Row style={{ marginLeft: "4%", marginRight: "4%" }}>
                <Row style={{ width: "100%" }}>
                  <Col span={10}>
                    <p>From Date</p>
                    <DatePicker
                      placeholder={""}
                      suffixIcon={""}
                      name="from"
                      style={{ width: "80%" }}
                      value={
                        formik.values.validityList.from &&
                        dayjs(formik.values.validityList.from, "YYYY-MM-DD")
                      }
                      onChange={(e, s) =>
                        formik.setValues({
                          ...formik.values,
                          validityList: {
                            ...formik.values.validityList,
                            from: s,
                          },
                        })
                      }
                    />
                    <span
                      style={{
                        border: "1px solid #ccc",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        backgroundColor: "#eee",
                      }}
                    >
                      <FontAwesomeIcon icon={faCalendarDays} />
                    </span>
                    {formik.errors ? (
                      formik.errors?.validityList?.from &&
                      formik.touched?.validityList?.from ? (
                        <p className="re-err fs-10">
                          {formik.errors.validityList?.from}
                        </p>
                      ) : null
                    ) : null}
                  </Col>
                  <Col span={10} offset={2} className="mb-5">
                    <p>To Date</p>
                    <DatePicker
                      placeholder={""}
                      name="to"
                      value={
                        formik.values.validityList.to &&
                        dayjs(formik.values.validityList.to, "YYYY-MM-DD")
                      }
                      onChange={(e, s) =>
                        formik.setValues({
                          ...formik.values,
                          validityList: {
                            ...formik.values.validityList,
                            to: s,
                          },
                        })
                      }
                      suffixIcon={""}
                      style={{ width: "80%" }}
                    />
                    <span
                      style={{
                        border: "1px solid #ccc",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        backgroundColor: "#eee",
                      }}
                    >
                      <FontAwesomeIcon icon={faCalendarDays} />
                    </span>
                    {formik.errors ? (
                      formik.errors?.validityList?.to &&
                      formik.touched?.validityList?.to ? (
                        <p className="re-err fs-10">
                          {formik.errors.validityList?.to}
                        </p>
                      ) : null
                    ) : null}
                  </Col>
                  <Col span={2}>
                    <BtnComp
                      startIcon={faTrashCan}
                      width="30px"
                      bg_color="#d9534f"
                      color="#fff"
                      btnName=""
                      btnClickFun={""}
                      className="px-1 mt-4"
                    />
                  </Col>
                </Row>

                <Col span={24} offset={22}>
                  <BtnComp
                    width="80px"
                    btnName="Add Validity"
                    btnClickFun={""}
                    className="mt-2 px-0"
                    startIcon={faPlus}
                    pl="3px"
                    bg_color="#6fb3e0"
                    color="#FFF"
                    fontSize="12px"
                  />
                </Col>
              </Row>
            </fieldset>
          </Col>
        </Row>
        {/* ____________________________________________________________________________________________ */}
      </ModalComponent>
    </>
  );
}

export default StopSaleModel;
