import serviceUtil from "../../..";

const config = {
 headers: {
  Accept: "application/json",
  "Content-Type": "application/json",
 },
 body: JSON.stringify(),
};

const packageSettingPackageTypeGetAll = () => {
 return serviceUtil
  .get("/master/packageSettings/getPackageType")
  .then((res) => {
   const data = res && res.data;
   return { data };
  })
  .catch((err) => {
   const errRes = err && err.response.data;
   return { errRes };
  });
};

const packageSettingPackageTypeCreate = (payload) => {
 return serviceUtil
  .post("/master/packageSettings/createPackageType", payload, config)
  .then((res) => {
   const data = res && res.data;
   return { data };
  })
  .catch((err) => {
   const errRes = err && err.response.data;
   return { errRes };
  });
};

const packageSettingPackageTypeUpdate = (packageTypeId, payload) => {
 return serviceUtil
  .put(`/master/packageSettings/updatePackageType/${packageTypeId}`, payload, config)
  .then((res) => {
   const data = res && res.data;
   return { data };
  })
  .catch((err) => {
   const errRes = err && err.response.data;
   return { errRes };
  });
};

const packageSettingPackageTypeDelete = (packageTypeId) => {
 return serviceUtil
  .deleteById(`/master/packageSettings/deletePackageType/${packageTypeId}`)
  .then((res) => {
   const { data, message, err } = res && res.data;
   return { data, message, err };
  })
  .catch((err) => ({ err }));
};

export { packageSettingPackageTypeGetAll, packageSettingPackageTypeCreate, packageSettingPackageTypeUpdate, packageSettingPackageTypeDelete };