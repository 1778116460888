import React from "react";
import availabilityImage from "./../../../assets/availabilityImage.png";
import bookingImage from "./../../../assets/bookingImage.png";
import galleryImage from "./../../../assets/galleryImage12.png";
import hotel from "./../../../assets/hotel-icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper } from "@mui/material";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom/dist";
import { partnerRoutesPath } from "../../../constants/PartnerRoutePaths";

import {
  faBan,
  faBook,
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";

/* eslint-disable react/no-unescaped-entities */

function PartnerDashboard() {
  const nav = useNavigate();
  const navFunction = (id) => {
    nav(partnerRoutesPath[id]);
  };
  const paperSX = {
    boxShadow: 0.9,
    "&:hover": {
      boxShadow: 3,
    },
  };
  return (
    <div
      style={{ backgroundColor: "#f0f0f0", height: "100vh", paddingTop: "3%" }}
    >
      <div>
        <Row className="dashboard-head">
          <Col
            xs={{ span: 22, offset: 2 }}
            sm={{ span: 10, offset: 2 }}
            md={{ span: 4, offset: 1 }}
            className="das-box"
            onClick={() => {
              navFunction(41);
            }}
          >
            <Row style={{}}>
              <img src={hotel} alt="" className="dashboard-icon" />
            </Row>
            <Row className="das-icon-txt">My Profile</Row>
          </Col>
          <Col
            xs={{ span: 22, offset: 2 }}
            sm={{ span: 10, offset: 2 }}
            md={{ span: 4, offset: 1 }}
            className="das-box"
            onClick={() => {
              navFunction(42);
            }}
          >
            <Row>
              <img src={galleryImage} alt="" className="dashboard-icon" />
            </Row>
            <Row className="das-icon-txt"> My Pics </Row>
          </Col>
          <Col
            xs={{ span: 22, offset: 2 }}
            sm={{ span: 10, offset: 2 }}
            md={{ span: 4, offset: 1 }}
            className="das-box"
            onClick={() => {
              navFunction(51);
            }}
          >
            <Row>
              <img
                src={bookingImage}
                alt=""
                className="dashboard-icon"
                style={{ marginLeft: "2px" }}
              />
            </Row>
            <Row className="das-icon-txt">My Booking</Row>
          </Col>
          <Col
            xs={{ span: 22, offset: 2 }}
            sm={{ span: 10, offset: 2 }}
            md={{ span: 4, offset: 1 }}
            className="das-box"
            onClick={() => {
              navFunction(43);
            }}
          >
            <Row>
              <img src={availabilityImage} alt="" className="dashboard-icon" />
            </Row>
            <Row className="das-icon-txt"> My Availability </Row>
          </Col>
        </Row>
        <div className="das-card-box">
          <Row>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              className="paperStyle"
            >
              <Paper sx={paperSX} className="das-card">
                <Row>
                  <Col
                    span={18}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      // paddingTop:"5%"
                      fontSize: "1.2rem",
                      color: "#222",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBook}
                      style={{ fontSize: "2rem", padding: "2%" }}
                    />
                    Total Booking
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "#164888",
                        borderRadius: "100%",
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        float: "right",
                      }}
                    >
                      0
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
              </Paper>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              className="paperStyle"
            >
              <Paper sx={paperSX} className="das-card">
                <Row>
                  <Col
                    xs={{ span: 21 }}
                    md={{ span: 21 }}
                    lg={{ span: 21 }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      // paddingTop:"5%"
                      fontSize: "1.2rem",
                      color: "#222",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faBan}
                      style={{ fontSize: "2rem", padding: "2%" }}
                    />
                    Total Cancellation
                  </Col>
                  <Col xs={{ span: 3 }} md={{ span: 3 }} lg={{ span: 3 }}>
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "#164888",
                        borderRadius: "100%",
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        float: "right",
                      }}
                    >
                      0
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
              </Paper>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              className="paperStyle"
            >
              <Paper sx={paperSX} className="das-card">
                <Row>
                  <Col
                    span={18}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      fontSize: "1.2rem",
                      color: "#222",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      style={{ fontSize: "2rem", padding: "2%" }}
                    />
                    Today's CheckIn
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }}>
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "#164888",
                        borderRadius: "100%",
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        float: "right",
                      }}
                    >
                      0
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
              </Paper>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              className="paperStyle"
            >
              <Paper sx={paperSX} className="das-card">
                <Row>
                  <Col
                    xs={{ span: 21 }}
                    md={{ span: 21 }}
                    lg={{ span: 21 }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      // paddingTop:"5%"
                      fontSize: "1.2rem",
                      color: "#222",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCircleArrowLeft}
                      style={{ fontSize: "2rem", padding: "2%" }}
                    />
                    Today's CheckOut
                  </Col>
                  <Col xs={{ span: 3 }} md={{ span: 3 }} lg={{ span: 3 }}>
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "#164888",
                        borderRadius: "100%",
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#fff",
                        float: "right",
                      }}
                    >
                      0
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
              </Paper>
            </Col>
          </Row>
          <Row></Row>
        </div>
      </div>
    </div>
  );
}

export default PartnerDashboard;
