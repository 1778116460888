const ShikaraSeo = [
  {
    title: "Alleppey Shikara Boat Best Rates Online",
    description:
      "Choose Alleppey Shikara Boat Best Rates Online We provide Full day Shikara Boat Serive In Alleppey backwater ride.we prove 24*7 Gos care Support .We have More than 30 Shikara Boat  Availabile in Alleppey and Kumarakom. Coupon Code - GETFLAT30 and Get 30% OFF on all our Shikara Boat.",
  },
  {
    title: "Top 5 Kumarakom  Shikara Boat Best Rates Online",
    description:
      "Find out Top 5 Kumarakom Shikara Boat Best Rates Online .We provide Full day Shikara Boat Serive In kumarakom backwater ride.We prove 24*7 Gos care Support . Coupon Code - ENJOY22 and Get 22% OFF on all our Shikara Boat.",
  },
];

export default ShikaraSeo;
