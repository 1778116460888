import * as yup from "yup";
import { RegexValidation } from "../../../../constants/regexValidation/RegexValidation";

export  const listpropertySchema=yup.object({
    userName: yup.string().required("Enter Contact Person "),
    hotelName:yup.string().required("Enter Hotel Name"),
    hotelAddress: yup.string().required("Enter Hotel Address"),
    roomCategory:yup.string().required("Enter Room Type"),
    email:yup.string().required("Enter Email").email(),
    phoneNumber:yup.string().required("Enter Mobile Number").matches(RegexValidation.mobile,RegexValidation.mobileMsg),

})