const INHOUSE_AGENT_ACCOUNT = [
 {
  dataIndex: "col1",
  title: "S.N",
  nummeric: false,
  disablePadding: true,
  label: "S.N",
  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 40,
 },
 {
  dataIndex: "col2",
  title: "Agent",
  nummeric: false,
  disablePadding: true,

  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 120,
 },
 {
  dataIndex: "col2",
  title: "Credit Limit",
  nummeric: false,
  disablePadding: true,
  label: "Category",
  filter: "select",
  width: 80,
  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
 },
 {
  dataIndex: "col2",
  title: "Used",
  nummeric: false,
  disablePadding: true,
  label: "Category",
  filter: "select",
  width: 80,
  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
 },
 {
  dataIndex: "col2",
  title: "Available",
  nummeric: false,
  disablePadding: true,
  label: "Category",
  filter: "select",
  width: 80,
  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
 },
 {
  dataIndex: "col3",
  title: "Actions",
  nummeric: false,
  disablePadding: true,
  label: "Category",
  filter: "select",
  width: 80,
 },
];

export default INHOUSE_AGENT_ACCOUNT;
