import React, { useEffect, useState } from "react";
import { UserLoader } from "../../components/atoms/userLoader";
import {
  getAllCoupons
} from "../../services/utils/userUtils/landingPage";
import { Col, Row } from "antd";
import OfferCards from "../../components/atoms/card/offerCards/OfferCards";

export const Coupons = () => {

  const [offerData, setofferData] = useState([]);
  const [offerDataClone, setofferCloneData] = useState([]);
  const [activeOfferTab, setactiveOfferTab] = useState("all");
  const [loading, setLoading] = useState(true);  
  const [copiedCode, setcopiedCode] = useState("");


  const offerCardData = async () => {
    try {
      setLoading(true);
      const {
        data: { response },
      } = await getAllCoupons();
      setofferData(response);
      setofferCloneData(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const filterOfferData = (type) => {
    setactiveOfferTab(type);
    if (type === "all") {
      setofferData(offerDataClone);
    } else {
      const filterdOffer = offerDataClone.filter(
        (offer) => offer.couponType === type
      );
      setofferData(filterdOffer);
    }
  };

  useEffect(() => {
    offerCardData();
  }, []);

  return (

    <div>
      {loading ? <UserLoader /> : ""}
      <>
        <div style={{ backgroundColor: "#fff" }} className="coupon-list">
          <div className="divScrollRow p-3 pb-0">
            <button
              onClick={() => filterOfferData("all")}
              className={`mobile-bedge ${activeOfferTab == "all" ? "bactive" : ""}`}
            >
              All
            </button>
            <button
              onClick={() => filterOfferData("Hotel")}
              className={`mobile-bedge ${activeOfferTab == "Hotel" ? "bactive" : ""
                }`}
            >
              Hotels
            </button>
            <button
              onClick={() => filterOfferData("Package")}
              className={`mobile-bedge ${activeOfferTab == "Package" ? "bactive" : ""
                }`}
            >
              Packages
            </button>
            <button
              onClick={() => filterOfferData("HouseBoat")}
              className={`mobile-bedge ${activeOfferTab == "HouseBoat" ? "bactive" : ""
                }`}
            >
              Houseboat
            </button>
            <button
              onClick={() => filterOfferData("Shikara")}
              className={`mobile-bedge ${activeOfferTab == "Shikara" ? "bactive" : ""
                }`}
            >
              Shikara
            </button>
            {/* <span className="mobile-bedge ">Bank offers</span> */}
          </div>
          <Row
            style={{ width: "100vw" }}
            className="py-3"
            justify="center">
            <div className="px-3" style={{ paddingBottom: "75px" }}>
              {offerData?.map((v, i) => {
                return (
                  <Col key={i} span={24} className="my-4">
                    <OfferCards data={v} copiedCode={copiedCode} setcopiedCode={setcopiedCode}/>
                  </Col>
                );
              })}
            </div>
          </Row>
        </div>
      </>

    </div>
  );
};

export default Coupons;
