import { Button, Modal } from "antd";
import React from "react";
import tickicon from "../../../assets/tikani.gif";
import failedicon from "../../../assets/failedicon.gif";
import fastForward from "../../../assets/scroll2.gif";
import alite from "../../../assets/fonts/Aspirin Advance Light.ttf";

function HotelBookingProceedModel({ open, setOpen, message, modelViewType }) {
  return (
    <Modal footer={null} closable onCancel={() => setOpen(false)} open={open}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "5%",
        }}
      >
        {modelViewType == "paymentSuccess" ? (
          <>
            <div>
              {/* <CheckCircleTwoTone twoToneColor="#52c41a"  style={{fontSize:"2.5rem"}}/> */}
              <img
                src={tickicon}
                style={{ height: "100px", width: "100px" }}
                alt=""
              />
              {/* <img src={blastani} style={{height:"100vh", width:"100vw",position:"absolute",backgroundColor:"transparent"}} alt=""/> */}
            </div>
            <p style={{ padding: "5%" }}>
              <span style={{ color: "#247ADD" }}>GOS1210</span> Applied
            </p>
            <h1>You Saved ₹250 </h1>

            <p style={{ marginTop: "-10px" }}>With this promo code</p>

            <Button
              type="primary"
              className="thanksbtn "
              onClick={() => {
                setOpen(false);
              }}
            >
              🎉 Yay! Thanks
            </Button>
          </>
        ) : modelViewType == "paymentFailed" ? (
          <>
            <div>
              <img
                src={failedicon}
                style={{ height: "100px", width: "100px" }}
                alt=""
              />
            </div>

            <h4 style={{ padding: "2%" }}>{message}</h4>
            <Button
              type="primary"
              className="thanksbtn "
              style={{ background: "red", color: "#ffff" }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Failed
            </Button>
          </>
        ) : modelViewType == "isFormFilled" ? (
          <p>{message}</p>
        ) : (
          modelViewType == "isNotFormFilled" && (
            <>
              <div>
                <img
                  src={failedicon}
                  style={{ height: "75px", width: "75px" }}
                  alt=""
                />
              </div>

              <h4
                style={{
                  padding: "2%",
                  textAlign: "center",
                  fontFamily: "alite, Arial, sans-serif",
                  src: `${alite}`,
                  opacity: "0.75",
                }}
              >
                {message}
              </h4>
              <Button
                // type="primary"
                className="thanksbtns"
                // style={{ backgroundColor: "red", color: "#ffff" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                {/* Yep&#33; */}
                <img
                  src={fastForward}
                  style={{ height: "50px", width: "50px" }}
                  alt=""
                />
              </Button>
            </>
          )
        )}
      </div>
    </Modal>
  );
}

export default HotelBookingProceedModel;
