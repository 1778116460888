/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-unresolved */
import { Grid } from "@mui/material";
import { Col, Row } from "antd";
import React, { useEffect } from "react";

// import Card from '@mui/material/Card'
// import hayat from "../../../../../assets/hotelreservation.png";
// import houseboat from "../../../../../assets/houseboat (2).png";
// import packImag from "../../../../../assets/packImag.png";
// import shikaraImg from "../../../../../assets/shikaraImg.png";
// import hotelImageWebp from "../../../../../assets/Gos-introducing Images/hotelimagecompress.jpg";
// import hotelImagejpg from "../../../../../assets/Gos-introducing Images/hotelimageconverts.jpg";
// import packageImageWebp from "../../../../../assets/Gos-introducing Images/packImagcompress.jpg";
// import packageImagejpg from "../../../../../assets/Gos-introducing Images/packImagconvert.jpg";
// import shikaraImageWebp from "../../../../../assets/Gos-introducing Images/shikaraImgcompress.jpg";
// import shikaraImagejpg from "../../../../../assets/Gos-introducing Images/shikaraImgconvert.jpg";
// import houseBoatImageWebp from "../../../../../assets/Gos-introducing Images/houseboatimagecompress.jpg";
// import houseBoatImagejpg from "../../../../../assets/Gos-introducing Images/houseboatimageconvert.jpg";

import "../index.css";
// import Skeleton from "@mui/material/Skeleton";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import { Col, Row } from 'antd'
// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//     ...theme.typography.body2,
//     textAlign: "center",
//     color: theme.palette.text.secondary,
//   }));

const config = {
  rootMargin: "0px 0px 0px 0px",
  thresold: 0,
};
export default function MainUserCard() {
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    let observer = new window.IntersectionObserver(function (entries, self) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImages(entry.target);
          self.unobserve(entry.target);
        }
      });
    }, config);

    const imgs = document.querySelectorAll("[data-src]");
    imgs.forEach((img) => {
      observer.observe(img);
    });
    return () => {
      imgs.forEach((img) => {
        observer.observe(img);
      });
    };
  }, []);

  function loadImages(image) {
    image.src = image.dataset.src;
  }
  const menus = [
    {
      category: "Hotel Reservation",
      name: "GOS Hotel Reservation",
      btnName: "Read more",
      rout: "/hotel",
      img: `${process.env.REACT_APP_S3_BUCKET_URL}/images/gosIntroducing/hotelimagecompress.jpg`,
      igs: `${process.env.REACT_APP_S3_BUCKET_URL}/images/gosIntroducing/hotelimageconverts.jpg`,
    },
    {
      category: "Houseboat",
      name: "GODS OWN STAY DJ 7-8-9 & 10 Bedroom Full Day Cruise",
      btnName: "Read more",
      rout: "/houseboat/houseboat-in-alleppey.html",
      img: `${process.env.REACT_APP_S3_BUCKET_URL}/images/gosIntroducing/houseboatimagecompress.jpg`,
      igs: `${process.env.REACT_APP_S3_BUCKET_URL}/images/gosIntroducing/houseboatimageconvert.jpg`,
    },
    {
      category: "Packages",
      name: "Gos Holidays 369",
      btnName: "Read more",
      rout: "/holiday-kerala/kerala-travel-packages/Domestic.html",
      img: `${process.env.REACT_APP_S3_BUCKET_URL}/images/gosIntroducing/packImagcompress.jpg`,
      igs: `${process.env.REACT_APP_S3_BUCKET_URL}/images/gosIntroducing/packImagconvert.jpg`,
    },
    {
      category: "Shikara",
      name: "GODS OWN STAY Puniyalan 2457",
      btnName: "Read more",
      rout: "/shikara/alleppey-shikara-boat.html",
      img: `${process.env.REACT_APP_S3_BUCKET_URL}/images/gosIntroducing/shikaraImgcompress.jpg`,
      igs: `${process.env.REACT_APP_S3_BUCKET_URL}/images/gosIntroducing/shikaraImgconvert.jpg`,
    },
  ];

  // const loadImage = (src) => {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.src = src;
  //     img.onload = () => resolve(src);
  //     img.onerror = (error) => reject(error);
  //   });
  // };

  useEffect(() => {
    // Create an array of promises for each image
    const imagePromises = menus.map((menuItem) => {
      const image = new Image();
      image.src = menuItem.img;
      return new Promise((resolve) => {
        image.onload = () => {
          resolve(menuItem);
        };
      });
    });

    // Wait for all promises to resolve before updating loading state
    Promise.all(imagePromises).then(() => {
      // setLoading(false);
    });
  }, []);
  useEffect(() => {
    scrollToTop();
  }, []);

  const scrollToTop = () => {
    document.getElementById("homePageScroll")?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const navigateToPage = (url) => {
    scrollToTop();
    setTimeout(function () {
      window.location.href = url;
    }, 10);
  };

  return (
    <>
      <Grid
        container
        spacing={{ xs: 1 }}
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 1, md: 3 }}
      >
        <div className="  introducing-area ">
          <h1 className="into-content-of pb-2 pt-4">Gos Introducing</h1>
          {/* into-content */}
          <Row gutter={[15, 15]}>
            {menus.length > 0 &&
              menus.map((val, i) => {
                return (
                  <Col span={12} key={i}>
                    <div className="single-intro">
                      <div className="card1" style={{ cursor: "pointer" }}>
                        <div onClick={() => navigateToPage(val.rout)}>
                          {/* {loading ? ( */}
                          {/* // <Skeleton
                            //   className="firstimagecard"
                            //   variant="rounded"
                            //   width={750}
                            //   height={300}
                            //   sx={{ bgcolor: "lightgrey" }}
                            // /> */}

                          {/* // ) : (*/}
                          <img
                            src={val.igs}
                            data-src={val.img}
                            alt={val.name}
                            className="firstimagecard"
                            loading="lazy"
                          />

                          {/* // )} */}
                          <div className="cover-div"></div>
                          <div className="card-text1">
                            {/* {loading ? (
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "14px" }}
                              />
                            ) : ( */}
                            <p>{val.category}</p>
                            {/* )} */}
                          </div>

                          <div className="intro-text row">
                            <div className="d-flex justify-content-between">
                              {/* {loading ? (
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "16px" }}
                                />
                              ) : ( */}
                              <h6>{val.name}</h6>
                              {/* )} */}
                              {/* <p className="btn btn-success">Read more</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            {/* {menus.length > 0 ? 
              menus.map((val, i) => {
                return (
                  <Col span={12} key={i}>
                    <div className="single-intro">
                      <div
                        className="card1"
                        style={{ cursor: "pointer" }}
                      >
                        <Link to={val.rout} onClick={scrollToTop}>
                          <img
                            src={val.img}
                            alt="img"
                            className="firstimagecard"
                          />
                          <div className="cover-div"></div>
                          <div className="card-text1">
                            <p>{val.category}</p>
                          </div>

                          <div className="intro-text row">
                            <div className="d-flex justify-content-between">
                              <h6>{val.name}</h6>
                              <p className="btn btn-success">Read more</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Col>
                );
              }) : 
              } */}

            {/* <Grid item xs={6} md={6}>
                <Item sx={{ m: 1 }}>
                  <div className="single-intro">
                    <div className="card1">
                      <img
                        src={houseboat}
                        alt="img"
                        className="intro-img rounded mx-auto d-block"
                      />
                      <div className="card-text1" xs={3}>
                        <h4>House boat</h4>
                      </div>
                      <div className="intro-text row">
                        <div className="d-flex justify-content-between">
                          <h6>
                            GODS OWN STAY DJ 7 - 8- 9 &amp; 10 Bedrooms Full Day
                            Cruise
                          </h6>
                          <p className="btn btn-success">Read more</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6} md={6}>
                <Item sx={{ m: 1 }}>
                  <div className="single-intro">
                    <div className="card1">
                      <img
                        src={hayat}
                        alt="img"
                        className="intro-img rounded mx-auto d-block"
                      />
                      <div className="card-text1">
                        <h4>Packages</h4>
                      </div>
                      <div className="intro-text row">
                        <div className="d-flex justify-content-between">
                          <h6>GODS OWN STAY Puniyalan 2457</h6>
                          <p className="btn btn-success">Read more</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid>
              <Grid item xs={6} md={6}>
                <Item sx={{ m: 1 }}>
                  <div className="single-intro">
                    <div className="card1">
                      <img
                        src={houseboat}
                        alt="img"
                        className="intro-img rounded mx-auto d-block"
                      />
                      <div className="card-text1">
                        <h4>Shikara</h4>
                      </div>
                      <div className="intro-text row">
                        <div className="d-flex justify-content-between">
                          <h6>GODS OWN STAY Puniyalan 2457</h6>
                          <p className="btn btn-success">Read more</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Item>
              </Grid> */}
          </Row>
        </div>
      </Grid>
    </>
  );
}
