/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faFilter,
  //  faLocationDot
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import BottomFooter from "../../newUserLayout/userFooter/BottomFooter";
import UserSideBarModel from "./../../newUserLayout/userSidebarModel/index";
import { Col, Row, Grid, Tag, Empty, Skeleton, Button } from "antd";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import UserSideBar from "../../newUserLayout/userSideBar";
import ProCard from "./../../../../components/atoms/userAtoms/hotelCard/index";
import OfferCards from "../../../../components/atoms/card/offerCards/OfferCards";
import Slider from "react-slick";
import { useRef } from "react";
// import { userHotelDetailsBook } from '../../../../services/utils/userUtils/hotelutils'
// import GoogleMapComponent from '../../../../components/atoms/GoogleMapComponent/GoogleMapComponent'
import { useDispatch, useSelector } from "react-redux";
import {
  setHotelAllData,
} from "../../../../reduxData/sliceData/hotelReducer";
import { useLocation, useParams } from "react-router-dom";
import {
  advanceSearchFilterApi,
  userHotelTypeFun,
} from "../../../../services/utils/userUtils/hotelutils";
import _, { filter, lowerCase } from "lodash";
import OtherFunctionsObject from "../../../../services/utils/userUtils/dynamicFun/functionlist";
// import { filterSearchfun } from '../../../../services/utils/userUtils/dynamicFun'
import { hotelObjStructure } from "../../../../constants/states";
import { DownOutlined } from "@ant-design/icons";
import { UserLoader } from "../../../../components/atoms/userLoader";
import { getAllCoupons } from "../../../../services/utils/userUtils/landingPage";
import SearchCard from "../../../../components/atoms/card/userCards/SearchCard";
import moment from "moment";
import notFound from "./../../../../assets/not-found.jpeg";
import { Pagination } from "antd";
import { FloatButton } from "antd";
import Xarrow, { Xwrapper } from "react-xarrows";
import CitySeo from "../../../../constants/seo/citySeo";
import { Helmet } from "react-helmet";
const {
  // filterByStar,
  // filterByPrice,
  filterByCustomerRating,
} = OtherFunctionsObject;

const { useBreakpoint } = Grid;

const priceRangeObj = {
  "Up to 999": { min: 0, max: 999 },
  "1000-2000": { min: 1000, max: 2000 },
  "2000-3000": { min: 2000, max: 3000 },
  "3000-4000": { min: 3000, max: 4000 },
  "4000-5000": { min: 4000, max: 5000 },
  "5000-6000": { min: 5000, max: 6000 },
  "6000+": { min: 6000, max: 100000 },
};

function HotelKochiPage() {
  // debugger;
  const ITEMS_PER_PAGE = 20;
  const param = useParams();
  const url = param.id;
  // debugger;
  let cityName;
  if (url) {
    const cityNameRegex = /-in-(.*?)-/; // Regular expression to capture the city name between "-in-" and "-"
    // search-hotels-in-Kochi/cochin-hotels.html
    const match = url.match(cityNameRegex);
    const cityNameRegex1 = /\/search-hotels-in-(.*?)\/([^/]+)-hotels\.html/i;
    const match1 = url.match(cityNameRegex1);
    // debugger;

    if (match && match.length > 1) {
      cityName = match[1]; // Extracted city name
    } else if (match1 && match1.length > 1) {
      cityName = match[2]; // Extracted city name
    } else {
      cityName = "Kochi";
    }
  }

  const { lg, md } = useBreakpoint();
  const { state } = useLocation();
  const sliderRef = useRef(null);
  const [loading, setLoader] = useState(true);

  const [modelOpen, setmodelOpen] = useState(false);
  const [filterData, setfilterData] = useState([]);

  const [hotelList, setHotelList] = useState([]);
  const [offerData, setofferData] = useState([]);
  // const [currentLng, setcurrentLng] = useState("");
  const [searchData, setsearchData] = useState("");
  const [hotelTypeObj, setHotelTypeObj] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [selectedType, setselectedType] = useState([]);
  const [priceRangeValue, setPriceRangeValue] = useState([]);
  const [starRatingValue, setstarRatingValue] = useState([]);
  const [isShowCoupleFriendly, setIsShowCoupleFriendly] = useState(true);
  const [selectedRating, setselectedRating] = useState(0);
  const [searchboxShow, setsearchboxShow] = useState(false);
  const { pathname } = useLocation();
  const searchCardData = useSelector(
    (state) => state?.searchCardData?.searchCardData
  );
  const searchToggle = useSelector(
    (state) => state?.searchCardData?.searchToggle
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const [copiedCode, setcopiedCode] = useState("");

  //pagination code
  const scrollToTopRef = useRef(null);
  // const showTotal = (total) => `Total ${total} Hotels`;
  const lastIndexCard = currentPageNo * ITEMS_PER_PAGE;
  const firstIndexCard = lastIndexCard - ITEMS_PER_PAGE;
  const paginatedCards = filterData?.slice(firstIndexCard, lastIndexCard);
  const TOTAL_PAGES = Math.ceil(filterData.length / ITEMS_PER_PAGE);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, currentPageNo]);

  const handleScrollToTop = () => {
    // Use document.documentElement or document.body depending on browser compatibility
    // document.documentElement.scrollTop = 0;
    // document.body.scrollTop = 0;
    if (scrollToTopRef.current) {
      scrollToTopRef?.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    // Alternatively, use document.body.scrollTop = 0;
  };

  useEffect(() => {
    //getLocation();
    getHotelTypeFun();
    offerCardData();
  }, []);

  useEffect(() => {
    getHotelDetilas();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  useEffect(() => {
    scrollToTop();
    return () => scrollToTop();
  }, []);

  useEffect(() => {
    if (searchToggle > 1) {
      try {
        const payload = {
          destinationName: searchCardData?.destinationName
            ? searchCardData?.destinationName
            : "India",
          checkIn: searchCardData?.checkIn,
          checkOut: searchCardData?.checkOut,
          guestDetails: {
            rooms: searchCardData?.guestDetails.rooms,
            adults: searchCardData?.guestDetails.adults,
            children: searchCardData?.guestDetails.children,
            childAge: searchCardData?.guestDetails.childAge,
          },
        };
        setLoader(true);
        advanceSearchFilterApi(payload)
          .then((res) => {
            setLoader(false);
            setfilterData(res.data.data);
            setHotelList(res.data.data);
          })
          .catch((error) => {
            setLoader(false);
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, [searchToggle]);

  useEffect(() => {
    lg || md ? setsearchboxShow(true) : setsearchboxShow(false);
  }, [lg, md]);

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: md || lg ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    height: 100,
  };

  const offerCardData = async () => {
    try {
      const {
        data: { response },
      } = await getAllCoupons();
      setofferData(response);
    } catch (err) {
      console.log(err);
    }
  };

  // ______________________________________________________________________

  const dispatch = useDispatch();

  const getHotelDetilas = async () => {

    if (!state) {
      const payload = {
        destinationName: param["id"] ? cityName : "India",
        checkIn: searchCardData?.checkIn,
        checkOut: searchCardData?.checkOut,
        guestDetails: {
          rooms: searchCardData?.guestDetails.rooms,
          adults: searchCardData?.guestDetails.adults,
          children: searchCardData?.guestDetails.children,
          childAge: searchCardData?.guestDetails.childAge,
        },
      };
      // debugger;
      setLoader(true);
      advanceSearchFilterApi(payload)
        .then((res) => {
          setLoader(false);
          setfilterData(res.data.data);
          setHotelList(res.data.data);
          dispatch(setHotelAllData(res.data.data));
        })
        .catch((error) => {
          setLoader(false);
        });
    } else if (state?.data?.destinationName?.length > 0) {
      try {
        const payload = {
          destinationName:
            state?.data.destinationName && state?.data.destinationName,
          checkIn: state?.data.checkIn,
          checkOut: state?.data.checkOut,
          guestDetails: {
            rooms: state?.data.guestDetails.rooms,
            adults: state?.data.guestDetails.adults,
            children: state?.data.guestDetails.children,
            childAge: state?.data.guestDetails.childAge,
          },
        };

        const res = await advanceSearchFilterApi(payload);
        setLoader(false);
        setfilterData(res.data.data);
        setHotelList(res.data.data);
        dispatch(setHotelAllData(res.data.data));
      } catch (err) {
        console.log(err);
      }
    } else if (state?.destination == "nearby") {
      if (!navigator.geolocation) {
        setLoader(false);
        setfilterData([]);
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          const currentLat = position.coords.latitude;
          const currentLng = position.coords.longitude;
          if (currentLat && currentLng) {
            const payload = {
              destinationName: "",
              checkIn: moment().startOf("day").format("DD/MM/YYYY"),
              checkOut: moment()
                .add(1, "day")
                .endOf("day")
                .format("DD/MM/YYYY"),
              guestDetails: {
                rooms: 1,
                adults: 2,
                children: 0,
                childAge: [],
              },
              lat: currentLat,
              lon: currentLng,
              // lat: 11.4102,
              // lon: 76.695,
            };
            // debugger;
            advanceSearchFilterApi(payload)
              .then((res) => {
                setfilterData(res.data.data);
                setHotelList(res.data.data);
                setLoader(false);
                dispatch(setHotelAllData(res.data.data));
              })
              .catch((err) => {
                setLoader(false);
              });
          } else {
            setfilterData([]);
            setLoader(false);
          }
        }, (error) => {
          console.log(error)
        });
      }
    } else if (state?.destination) {
      const payload = {
        destinationName: state?.destination,
        checkIn: searchCardData?.checkIn,
        checkOut: searchCardData?.checkOut,
        guestDetails: {
          rooms: searchCardData?.guestDetails.rooms,
          adults: searchCardData?.guestDetails.adults,
          children: searchCardData?.guestDetails.children,
          childAge: searchCardData?.guestDetails.childAge,
        },
      };
      // debugger;
      setLoader(true);
      advanceSearchFilterApi(payload)
        .then((res) => {
          setLoader(false);
          setfilterData(res.data.data);
          setHotelList(res.data.data);
          dispatch(setHotelAllData(res.data.data));
        })
        .catch((error) => {
          setLoader(false);
        });
    } else {
      setfilterData([]);
      setLoader(false);
    }
  };
  const hTypefun = (e, v) => {
    if (e.target.checked) {
      setselectedType((prevState) => {
        return [...prevState, e.target.value];
      });
    } else if (!e.target.checked) {
      setselectedType((prevState) => {
        let tempData = [...prevState];
        let newData = tempData.filter((data) => data !== e.target.value);
        return [...newData];
      });
    }
  };

  useEffect(() => {
    fiterHotelList();
  }, [
    selectedType,
    searchData,
    isShowCoupleFriendly,
    starRatingValue,
    priceRangeValue,
    selectedRating,
  ]);

  const getHotelTypeFun = async () => {
    const { data } = await userHotelTypeFun();
    window.scrollTo(0, 0);
    setHotelTypeObj(data.response);
  };

  const searchOnChange = (sval) => {
    setsearchData(sval);
  };

  const fiterHotelList = () => {
    let filterHotelList = hotelList;

    if (searchData != "") {
      filterHotelList = filterHotelList?.filter((hotel) =>
        hotel?.basicDetails?.hotelName
          ?.toLowerCase()
          .includes(searchData?.toLowerCase())
      );
    }
    //*Hotel tag filter
    if (isShowCoupleFriendly != null) {
      filterHotelList = filterHotelList.filter(
        (hotel) =>
          hotel?.basicDetails?.coupleFriendlyHotel === isShowCoupleFriendly
      );
    }

    //*Star filter
    if (starRatingValue.length) {
      filterHotelList = filterHotelList.filter((hotel) =>
        starRatingValue.includes(
          hotel?.basicDetails?.hotelCategory?.hotelCategory
        )
      );
    }

    //* Customer Rating Filter
    if (selectedRating) {
      filterHotelList = filterHotelList.filter((hotel) => {
        const totalRatings = hotel?.hotelReviewId?.reduce(
          (sum, review) => sum + review?.rating,
          0
        );
        const avgRatings = totalRatings / hotel?.hotelReviewId?.length;
        return avgRatings >= selectedRating;
      });
    }

    //*Price range filter
    if (priceRangeValue.length) {
      filterHotelList = filterHotelList.filter((hotel) => {
        const roomRate =
          // hotel?.hotelContractId?.contractRateRoomDTO[0]?.actualRoomRate;
          hotel?.mainroomPriceWithDiscount;

        const isInRange = priceRangeValue.every(
          (range) => roomRate >= range.min && roomRate <= range.max
        );

        return isInRange;
      });
    }

    //*Hotel type filter
    if (selectedType.length) {
      filterHotelList = filterHotelList.filter((hotel) =>
        selectedType.includes(hotel?.basicDetails?.hotelType?.hotelType)
      );
    }
    setfilterData(filterHotelList);
  };

  const starHandleChange = (e, i) => {
    if (e.target.checked) {
      setstarRatingValue((prevState) => {
        return [...prevState, i];
      });
    } else if (!e.target.checked) {
      setstarRatingValue((prevState) => {
        let tData = [...prevState];
        let nData = tData.filter((data) => data !== i);
        return [...nData];
      });
    }
  };

  const priceRangeFun = (checkedValues) => {
    setPriceRangeValue(() => {
      let priceRange = checkedValues?.map((key) => priceRangeObj[key]);
      return [...priceRange];
    });
  };

  const ratingFun = ({ target: { name, checked } }) => {
    if (checked) {
      setselectedRating(name);
    } else if (!checked) {
      setselectedRating(0);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsShowCoupleFriendly(!isShowCoupleFriendly);
  };

  const handlePageChange = (page) => {
    setCurrentPageNo(page);
    scrollToTopRef.current.scrollIntoView();
    handleScrollToTop();
  };

  // const mainSearchBtn=()=>{
  //   searchOnChange(searchBoxData.input)

  // }

  const sideBar = (
    <UserSideBar
      onCancel={() => {
        setmodelOpen(false);
      }}
      title="Search"
      searchValue={searchData}
      searchOnChange={(e) => searchOnChange(e.target.value)}
      // filterFunction={filterFunction}
      tag={{
        tagName: "HotelTag",
        tagData: ["Couple Friendly"],
      }}
      tagFn={handleCheckboxChange}
      isTagChecked={isShowCoupleFriendly}
      star={{
        starName: "star",
        starData: [1, 2, 3, 4, 5, 6, 7],
        handleChange: starHandleChange,
      }}
      rating={{
        ratingName: "Customer Ratings",
        ratingData: [4, 3.5, 2.5],
        ratingFun: ratingFun,
        value: selectedRating,
      }}
      priceRangeFun={priceRangeFun}
      priceRange={{
        priceRangeName: "Price Range",
        priceRangeData: [
          "Up to 999",
          "1000-2000",
          "2000-3000",
          "3000-4000",
          "4000-5000",
          "5000-6000",
          "6000+",
        ],
      }}
      typefun={hTypefun}
      type={{
        title: "Hotel Type",
        typeName: "hotelType",
        typeData: hotelTypeObj,
      }}
    />
  );

  const itemRender = (_, type, originalElement) => {
    if (type === "next") {
      if (currentPageNo === TOTAL_PAGES) {
        return <></>;
      } else {
        return (
          <Button
            size={isMobile ? "small" : "middle"}
            onChange={() => setCurrentPageNo(currentPageNo + 1)}
          >
            Next
          </Button>
        );
      }
    }
    if (type === "prev") {
      if (currentPageNo === 1) {
        return null;
      } else {
        return (
          <Button
            size={isMobile ? "small" : "middle"}
            onChange={() => setCurrentPageNo(currentPageNo + 1)}
          >
            Prev
          </Button>
        );
      }
    }

    return originalElement;
  };

  return (
    <>
      {loading ? <UserLoader /> : ""}
      <div ref={scrollToTopRef}>
        <Xwrapper>
          <Row
            justify={"center"}
            style={
              {
                // height: "100%"
              }
            }
            className={`${loading ? "bg-block" : ""}`}
          >
            <Row
              justify="center"
              className={"searchHeaderGradient"}
              style={{ width: "100%" }}
            >
              <Col lg={24} md={24} sm={24} xs={24}>
                <Row justify="center" style={{ width: "100%" }}>
                  <Col lg={18} md={18} sm={24} xs={24} className="">
                    <div
                      className="searchMainDivdes"
                      style={{ display: `${lg || md ? "none" : ""}` }}
                    >
                      <Row justify={"space-between"} className="px-3">
                        <Col
                          style={{
                            color: "#1675DF",
                            marginTop: "2px",
                            fontWeight: "700",
                          }}
                        >

                          {searchCardData?.destinationName
                            ? searchCardData?.destinationName
                            : state?.destination !== "nearby"
                              ? state?.destination
                              : "nearby"}
                          <br />
                          <p
                            style={{
                              color: "#808080",
                              fontWeight: "400",
                              fontSize: "0.6rem",
                            }}
                          >
                            {moment(
                              searchCardData?.checkIn,
                              "DD/MM/YYYY"
                            ).format("Do MMM")}{" "}
                            -{" "}
                            {moment(
                              searchCardData?.checkOut,
                              "DD/MM/YYYY"
                            ).format("Do MMM")}
                            , {searchCardData?.guestDetails?.rooms} Room,{" "}
                            {searchCardData?.guestDetails?.adults} Adult,{" "}
                            {searchCardData?.guestDetails?.children} Child
                          </p>
                        </Col>
                        <Col className="just-center">
                          <div id="element1"></div>
                          <Tag
                            className="just-center"
                            onClick={() => {
                              setsearchboxShow((o) => !o);
                            }}
                            color="#108ee9"
                          >
                            Edit <DownOutlined style={{ paddingLeft: "2px" }} />
                          </Tag>
                        </Col>
                      </Row>
                    </div>
                    {searchboxShow && (
                      <div className="hotelSearch">
                        <SearchCard />
                        <div id="target"></div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            {loading && (
              <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Col>
            )}
            {(lg) && !loading ? (
              <Col xs={22} sm={22} md={22} lg={5} xl={5}>
                {sideBar}
              </Col>
            ) : (
              ""
            )}
            {!loading && filterData?.length > 0 && (
              <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                <Row
                  className="pb-3"
                  justify={"center"}
                  style={{ padding: "10px 10px 0 10px" }}
                >
                  <Col span={24} style={{ paddingBottom: "40px" }}>
                    {/*START: Pagination Code */}
                    {filterData?.length > ITEMS_PER_PAGE && (
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: "0 0 0 14px",
                        }}
                      >
                        {currentPageNo > 1 && (
                          <Button
                            size={isMobile ? "small" : "middle"}
                            onClick={() => setCurrentPageNo(1)}
                          >
                            <DoubleLeftOutlined />
                          </Button>
                        )}
                        <Pagination
                          current={currentPageNo}
                          onChange={handlePageChange}
                          total={filterData?.length}
                          defaultPageSize={ITEMS_PER_PAGE}
                          responsive
                          showSizeChanger={false}
                          itemRender={itemRender}
                        />
                        {currentPageNo === TOTAL_PAGES ? (
                          <></>
                        ) : (
                          <Button
                            size={isMobile ? "small" : "middle"}
                            onClick={() => setCurrentPageNo(TOTAL_PAGES)}
                          >
                            <DoubleRightOutlined />
                          </Button>
                        )}
                      </div>
                    )}
                    {/*END: Pagination Code */}
                    {CitySeo?.map((v, i) => {
                      if (v?.cityName == cityName?.toLowerCase()) {
                        return (
                          <Helmet key={i}>
                            <title>{v?.title}</title>
                            <meta name="description" content={v?.description} />
                          </Helmet>
                        );
                      }
                    })}

                    {paginatedCards.length > 0 &&
                      paginatedCards.slice(0, 2)?.map((v, i) => {
                        return (
                          <div key={i}>
                            {" "}
                            <ProCard data={v} index={i} />
                          </div>
                        );
                      })}
                    <div className="mx-4">
                      <Slider
                        {...settings}
                        ref={sliderRef}
                        style={{ width: "100%", position: "relative" }}
                      >
                        {offerData?.length < 3 &&
                          [...new Array(4 - offerData?.length)].map((_, i) => {
                            return (
                              <Col key={i} span={24} className="my-2">
                                <OfferCards copiedCode={copiedCode} setcopiedCode={setcopiedCode} />
                              </Col>
                            );
                          })}
                        {offerData?.map((v, i) => {
                          return (
                            <Col key={i} span={24} className="my-2  px-2">
                              <OfferCards data={v} index={i} copiedCode={copiedCode} setcopiedCode={setcopiedCode} />
                            </Col>
                          );
                        })}
                      </Slider>
                    </div>
                    {!loading &&
                      paginatedCards.length > 0 &&
                      paginatedCards.slice(2).map((v, i) => {
                        return (
                          <div key={i}>
                            {" "}
                            <ProCard data={v} />
                          </div>
                        );
                      })}
                    <FloatButton.BackTop duration={300} />
                    {/*START: Pagination Code */}
                    {filterData?.length > ITEMS_PER_PAGE && (
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          padding: "20px 0 20px 12px",
                        }}
                      >
                        {currentPageNo > 1 && (
                          <Button
                            size={isMobile ? "small" : "middle"}
                            onClick={() => setCurrentPageNo(1)}
                          >
                            <DoubleLeftOutlined />
                          </Button>
                        )}
                        <Pagination
                          current={currentPageNo}
                          onChange={handlePageChange}
                          total={filterData?.length}
                          defaultPageSize={ITEMS_PER_PAGE}
                          responsive
                          showSizeChanger={false}
                          itemRender={itemRender}
                        />
                        {currentPageNo === TOTAL_PAGES ? (
                          <></>
                        ) : (
                          <Button
                            size={isMobile ? "small" : "middle"}
                            onClick={() => setCurrentPageNo(TOTAL_PAGES)}
                          >
                            <DoubleRightOutlined />
                          </Button>
                        )}
                      </div>
                    )}
                    {/*END: Pagination Code */}
                  </Col>
                </Row>
              </Col>
            )}
            {!loading && filterData?.length == 0 && (
              <>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={17}
                  xl={17}
                  style={{ marginTop: "-8px" }}
                >
                  <div style={{ height: "30px" }}></div>
                  <div id="source"></div>
                  <div id="hotel-not-found">
                    <Empty
                      image={notFound}
                      description=""
                      className="hotel-not-found-image"
                    />
                    <h4 className="text-center">
                      Sorry! We couldn&#39;t find any property match in your
                      search criteria.
                    </h4>
                  </div>
                </Col>
                {isMobile && !searchboxShow && (
                  <Xarrow
                    start={"source"}
                    end={"element1"}
                    startAnchor={"top"}
                    // endAnchor={"auto"}
                    color="red"
                    strokeWidth={2}
                    headSize={4}
                  />
                )}
              </>
            )}
          </Row>
        </Xwrapper>
      </div>

      {/* _____________________footer_______________________ */}
      <BottomFooter onClick={() => setmodelOpen((o) => !o)}>
        <div>
          <FontAwesomeIcon
            className="footericon"
            color="#000"
            style={{ fontSize: "1rem" }}
            icon={faFilter}
          // onClick={() => {
          //   setmodelOpen(modelOpen ? false : true)
          // }}
          />
          <p className="text-center">Filters</p>
        </div>
      </BottomFooter>

      <UserSideBarModel
        isModalOpen={modelOpen}
        onCancel={() => {
          setmodelOpen(false);
        }}
      >
        {sideBar}
      </UserSideBarModel>
      {/* ____________________________________________ */}
    </>
  );
}

export default HotelKochiPage;
