import * as yup from "yup";
import { RegexValidation } from "../../../constants/regexValidation/RegexValidation";

const shikaraSchemaForBookNow = yup.object({
  // prefix: yup.string().required("Select prefix"),
  firstName: yup
    .string()
    .required("Enter FirstName")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg),
  lastName: yup
    .string()
    .required("Enter LastName")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg),
  email: yup
    .string()
    .required("Enter email")
    .matches(RegexValidation.onlyEmail, RegexValidation.onlyEmailMsg),
  childAge: yup.number().required("Enter Child Age").max(2),
  mobileNumber: yup
    .string()
    .required("Enter Mobile Number")
    .matches(RegexValidation.mobile, RegexValidation.mobileMsg),
  // specialRequest: yup.string().required("Enter Special Request"),
});
const houseboatSchemaForBookNow = yup.object({
  // prefix: yup.string().required("Select prefix"),
  firstName: yup
    .string()
    .required("Enter FirstName")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg),
  lastName: yup
    .string()
    .required("Enter LastName")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg),
  email: yup
    .string()
    .required("Enter email")
    .matches(RegexValidation.onlyEmail, RegexValidation.onlyEmailMsg),
  childAge: yup.number().required("Enter Child Age").max(2),
  mobileNumber: yup
    .string()
    .required("Enter Mobile Number")
    .matches(RegexValidation.mobile, RegexValidation.mobileMsg),
  // specialRequest: yup.string().required("Enter Special Request"),
});
const packageSchemaForBookNow = yup.object({
  // prefix: yup.string().required("Select prefix"),
  firstName: yup
    .string()
    .required("Enter FirstName")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg),
  lastName: yup
    .string()
    .required("Enter LastName")
    .matches(RegexValidation.OnlyString, RegexValidation.OnlyStringMsg),
  emailAddress: yup.string().required("Enter email").email(),
  travelDate: yup.date().required("Enter date"),
  adult: yup.number().required("Enter Child Age"),
  child: yup.number().required("Enter Child Age"),
  whatsappNumber: yup
    .string()
    .required("Enter Mobile Number")
    .matches(RegexValidation.mobile, RegexValidation.mobileMsg),
  // specialRequest:yup.string().required("Enter Special Request"),
});
export {
  shikaraSchemaForBookNow,
  houseboatSchemaForBookNow,
  packageSchemaForBookNow,
};
