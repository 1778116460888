import PartnerRoutesObj from "../constants/partnerRouteObject";
import loginRoutesObj from "../constants/loginRoutes";
import userAllRoutPathesObj from "../constants/userAllRoutPathesObj";
import userRoutPathesObj from "../constants/userRoutPathes";
import { useRoutes } from "react-router-dom";
import routesObj from "../constants/routesObject";

const RouterComponent = () => {
  const routes = useRoutes([...routesObj]);
  return routes;
};
const PartnerRouterComponent  = () => {
  const routes = useRoutes([...PartnerRoutesObj]);
  return routes;
};

const LoginRoute = () => {
  const loginRoutes = useRoutes([...loginRoutesObj]);
  return loginRoutes;
};
const UserRoute = () => {
  const userRoutes = useRoutes([...userRoutPathesObj]);
  return userRoutes;
};
const UserRouteAll = () => {
  const userRoutesAll = useRoutes([...userAllRoutPathesObj]);
  return userRoutesAll;
};
export default RouterComponent;
export { LoginRoute,UserRoute,UserRouteAll,PartnerRouterComponent };
