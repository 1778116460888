/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// import React, { useState } from 'react';
import  './bottomFooter.css';
// import UserSideBarModel from './../userSidebarModel/index';
function BottomFooter({children,onClick}) {
    // const [modelOpen, setmodelOpen] = useState(false)
  return (
    <>
      <div className='footer' onClick={onClick}>
      <div className='d-flex align-items-center justify-content-around'>
      {children}
      </div>       
    </div>
    
    </>
  )
}

export default BottomFooter