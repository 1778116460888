/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    Col,
    Row,
    Grid,
    Modal
} from "antd";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import tik from "../../../../../assets/tikorg.png";
import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import HeaderForAll from "../../../../homePage/homePageHeader/HeaderForAll";
import HotelRoomCard from "./../../../../../components/atoms/userAtoms/hotelRoomCard/index";
import { useDispatch, useSelector } from "react-redux";
import {
    setRoomPrice,
    setSelectedRoom,
} from "../../../../../reduxData/sliceData/hotelReducer";
import { hotelConfermFunction } from "../../../../../services/utils/userUtils/hotelutils";
import { setSelectedRoomRate } from "../../../../../reduxData/sliceData/searchCardReducer";
import { differenceInDays, parse } from "date-fns";


const { useBreakpoint } = Grid;

function SelectRoom({ setCurrentPage, setsearchboxShow, setstopSale, hotelRoomCategoryObj, setloader, searchBoxData, selectedHotelData }) {

    const { sm, md, lg } = useBreakpoint();
    const { filterData: data } = useSelector(
        (state) => state.hotelAllData
    );
    const searchCardData = useSelector(
        (state) => state?.searchCardData?.searchCardData
      );

    useEffect(() => {
        lg || md ? setsearchboxShow(true) : setsearchboxShow(false);
    }, [lg, md]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const dispatch = useDispatch();
    const gst = data[0]?.hotelLocation?.gsttax;

    const [dataForBilling, setDataForBilling] = useState({
        rooms: 0,
        roomRateWithDiscount: 0,
        roomRateWithOutDiscount: 0,
        discountRate: 0,
        gstAmount: 0,
        payableAmount: 0,
        adults: 0,
        childrens: 0,
    });
    
    const [buyNowModelData, setbuyNowModelData] = useState({
        status: false,
        data: {},
    });

    const nav = useNavigate();

    const handleBook = (v) => {
        dispatch(setSelectedRoom(v));
        dispatch(setSelectedRoomRate(v));
        setbuyNowModelData({ ...buyNowModelData, status: true, data: v });
    };

    useEffect(() => {
        if (buyNowModelData?.status) {
            getRoomPrice(buyNowModelData?.data);
            modelConfirm();
        }
    }, [buyNowModelData]);

    useEffect(() => {
        buyNowModelData?.status && nav("/hotel/checkout");
    }, [dataForBilling]);

    const getRoomPrice = (data) => {
        const checkInDate = parse(
            searchCardData?.checkIn,
            "dd/MM/yyyy",
            new Date()
        );
        const checkOutDate = parse(
            searchCardData?.checkOut,
            "dd/MM/yyyy",
            new Date()
        );

        let numberOfDays = differenceInDays(checkOutDate, checkInDate);

        let children = searchCardData?.guestDetails?.childAge.filter((val) => {
            return val > 10;
        });
        let adults = searchCardData?.guestDetails?.adults + children?.length;
        let rooms = searchCardData?.guestDetails?.rooms;
        let roomPrice = 0;
        let roomRateWithOutDiscount = 0;
        let payableAmount = 0;
        let gstAmount = 0;
        const rate = data?.roomRate;
        const commission = selectedHotelData?.commission;
        const subTotal = rate + commission;
        if (adults > rooms && adults - rooms !== 1) {
            if (adults === 3) {
                roomPrice = (subTotal + data?.extraBedAdult) * numberOfDays;
            } else if (adults > 3) {
                rooms = Math.ceil(adults / 3);
                let extraAdult = adults % 3 === 0 ? rooms : rooms - 1;
                if (adults === 4) {
                    roomPrice = subTotal * rooms * numberOfDays;
                } else if (rooms > data?.noOfRooms) {
                    setstopSale(true);
                } else if (Math.sqrt(adults) === searchCardData?.guestDetails?.rooms) {
                    rooms = searchCardData?.guestDetails?.rooms;
                    roomPrice = subTotal * rooms * numberOfDays;
                } else {
                    roomPrice =
                        (subTotal * rooms + data?.extraBedAdult * extraAdult) *
                        numberOfDays;
                }
            }
        } else {
            if (rooms > data?.noOfRooms) {
                setstopSale(true);
            } else {
                roomPrice = subTotal * rooms * numberOfDays;
            }
        }
        roomRateWithOutDiscount = roomPrice * (100 / (100 - data?.discountRate));
        payableAmount = roomPrice + roomPrice * (Number(gst) / 100);
        gstAmount = roomPrice * (Number(gst) / 100);
        buyNowModelData?.status &&
            setDataForBilling({
                rooms: rooms,
                adults: adults,
                roomRateWithDiscount: roomPrice,
                discountRate: data?.discountRate,
                roomRateWithOutDiscount: roomRateWithOutDiscount,
                payableAmount: payableAmount,
                gstAmount: gstAmount,
            });
        return roomPrice;
    };

    const modelConfirm = async () => {
        const roomCatId = buyNowModelData?.data?.roomCategoryId?._id;
        const hotelAvId = data[0]?.hotelAvailabilityId.filter(
            (o) => o.roomCategory == roomCatId
        )[0]?._id;

        const {
            // destination,
            checkOut,
            checkIn,
            guestDetails: { adults, childAge, children, rooms },
        } = searchBoxData;

        const payload = {
            hotelId: data[0]?._id,
            hotelAvailabilityId: hotelAvId,
            checkIn: checkIn,
            checkOut: checkOut,
            rooms: rooms,
            roomsToDisplay: rooms,
            roomCategory: roomCatId,
            roomType: buyNowModelData?.data?.roomTypeId?._id,
            guestDetails: {
                adults: adults,
                child: children,
                childAge: childAge,
            },
        };

        dispatch(setRoomPrice(payload));
        setloader(true);
        try {
            const res = await hotelConfermFunction(payload);
            if (res?.contractRateRoomDTO[0]) {
                dispatch(setSelectedRoom(res.contractRateRoomDTO[0]));
            }
        } catch (err) {
            console.log(err);
        }

        setloader(false);
    };

    return (
        <div>
            <HeaderForAll />
            <div
                onClick={() => {
                    setCurrentPage(1);
                }}
                className="mobile2ndHeadGos mb-2"
                style={{ display: `${!sm || !md ? "block" : "none"}` }}
            >
                <FontAwesomeIcon icon={faArrowLeft} /> &nbsp; Select Room category
            </div>

            <Row justify={"center"} gutter={[20, 20]}>
                {hotelRoomCategoryObj?.map((v, i) => {
                    return (
                        <Col key={i} span={21}>
                            <HotelRoomCard
                                // setCancellationreportdrower={setCancellationreportdrower}
                                // setIsModalOpen={setIsModalOpen}
                                showModal={showModal}
                                data={v}
                                dispatch={dispatch}
                                selectRoomFunction={() => {
                                    handleBook(v);
                                    setbuyNowModelData({ status: true, data: v });
                                    getRoomPrice(buyNowModelData?.data);
                                    modelConfirm();
                                }}
                            />
                        </Col>
                    );
                })}
            </Row>
            <Modal
                title="Cancellation Policy"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                centered
            >
                <p
                    style={{ fontSize: "14px", textAlign: "left" }}
                    className="address"
                >
                    <ul style={{ listStyleImage: `url(${tik})` }}>
                        {selectedHotelData?.hotelPolicyId?.CancellationPolicy.map(
                            (policy, i) => {
                                return (
                                    policy.CancellationFee > 0 && (
                                        <li key={"h" + i}>
                                            Cancellation fee of {policy.CancellationFee} % of
                                            the total booking if cancelled less than{" "}
                                            {policy.priorHour} hours prior to arrival
                                        </li>
                                    )
                                );
                            }
                        )}
                        {selectedHotelData?.hotelPolicyId?.ChildPolicy.map(
                            (policy, i) => {
                                return <li key={"chi" + i}>{policy.policy}</li>;
                            }
                        )}
                    </ul>
                </p>
            </Modal>
        </div>
    );
}

export default SelectRoom;
