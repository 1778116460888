// import React from 'react'

// function UserShikaraAllPage() {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default UserShikaraAllPage









import React, { useState } from 'react'
import HomePageHeader from '../../../homePage/homePageHeader'
import arrowimg from "../../../../assets/d.gif"
import PaginationAlpha from '../../../../components/atoms/pagination/PaginationAlpha'
import { Col, Row } from 'antd'
const data=[
    { id: 1, name: 'Raunak' },
    { id: 2, name: 'Shubham' },
    { id: 3, name: 'Nitin' },
   
  ]

function UserShikaraAllPage() {
    const [filteredData, setFilteredData] = useState(data);
    const handlePaginationChange = letter => {
        const newFilteredData = data.filter(item => item.name[0] === letter);
        setFilteredData(newFilteredData);
      };
  return (
    <>
      <HomePageHeader
        headerDisplay={
          <p
            style={{
              color: '#fff',
              textAlign: 'center',
              fontSize: '1.5rem',
             
            }}
          >
          <img src={arrowimg} style={{height:"100px",width:"80px"}} alt=""/>
            Go to placelist
          </p>
        }
      />


      <PaginationAlpha onChange={handlePaginationChange}/>
      <Row className='pagecontainer' >
      {filteredData.map((item,id) => (
        
        <Col key={id} span={6}>
        <div key={item.id}>
        <p className='h5'>
          {item.name}

        </p>
        
        
        </div>

        </Col>

      ))}
        </Row>
    </>
  )
}

export default UserShikaraAllPage
