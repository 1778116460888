import { Card, Row } from "antd";

function NewCard({ children, headStyle, bodyStyle, title = "Title", extra,style }) {
  return (
    <Row style={{ paddingTop: "2%", width: "100%",...style }}>
      <Card
        title={title}
        bordered={false}
        extra={extra}
        headStyle={{
          backgroundColor: "#f5f5f5",
          height: "45px",
          fontSize: "13px",
          fontWeight: "400",
          color: "#666 ",
          ...headStyle,
        }}
        bodyStyle={{ border: "1px solid #ddd", ...bodyStyle }}
        style={{
          width: "100%",
        }}
      >
        {children}
        
      </Card>
    </Row>
  );
}

export default NewCard;
