import { Button, Col, Modal, Row } from "antd";
import React from "react";
import animation1 from "../../../../assets/74543.json";
import animation2 from "../../../../assets/88860.json";
import Lottie from "react-lottie";

function OfferModal({
  open,
  handleOk,
  handleCancel,
  offerCode,
  discountValue,
}) {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: animation1,
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animation2,
  };
  return (
    <div>
      <Modal
        footer={null}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={true}
        style={{
          top: 100,
          minHeight: "40%",
        }}
        width={300}
      >
        <div className="ant-modal-wrap" style={{ zIndex: "-1" }}>
          <Lottie options={defaultOptions1} />
        </div>
        <Row
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <Col span={6}>
            <Lottie options={defaultOptions2} />
          </Col>
          <Col span={24}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "17px",
                paddingBottom: "20px",
              }}
            >
              <span style={{ color: "#1674DE" }}>{offerCode}</span> Applied
            </p>
          </Col>
          <Col span={24}>
            <p
              style={{
                fontWeight: "bolder",
                fontSize: "28px",
              }}
            >
              You Saved ₹{discountValue}
            </p>
          </Col>
          <Col span={24}>
            <p style={{ fontSize: "17px" }}>With this promo codes</p>
          </Col>
          <Col span={24}>
            <Button style={{ background: "#0C44C6" }} onClick={handleOk}>
              <span style={{ color: "#FFFFFF" }}>🎉 Yay! Thanks</span>
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default OfferModal;
