import serviceUtil from "./../../index";
const userHotelDetailsBook = () => {
  return serviceUtil
    .get("user/hotel/getAllHotelDetailsToBook")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const userHotelTypeFun = () => {
  return serviceUtil
    .get("master/hotelSettings/getHotelType")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const userHotelBookRequest = (payload) => {
  return serviceUtil
    .post("user/hotel/userRequest", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const userCancellHotelBooking = (payload) => {
  return serviceUtil
    .post("user/hotel/cancellHotelBooking", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const moblienumberOtp = (payload) => {
  return serviceUtil
    .post("/api/auth/sendOTP", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const advanceSearchFilterApi = (payload) => {
  return serviceUtil
    .post("/user/filterSearch/hotelFilterByDestiantion", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const hotelDetailsSearchApiByHotelName = (payload) => {
  return serviceUtil
    .post("/user/filterSearch/hotelFilterByhotelName", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const getRoomPriceApi = (payload) => {
  return serviceUtil
    .post("/user/filterSearch/getRoomPrice", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const listPropertySendFunction = (payload) => {
  return serviceUtil
    .post("/user/listYourProperty/listProperty", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const feedbackSendFunction = (payload) => {
  return serviceUtil
    .post("/user/listYourProperty/feedback", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const getLatestOffer = () => {
  return serviceUtil
    .get("master/GosOffer/getLatestOffer")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const hotelAdvanceSearch = (key) => {
  return serviceUtil
    .get(`/user/filterSearch/autoSearchByLocation?searchTerm=${key}`)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const bookNowCoupan = (payload) => {
  return serviceUtil
    .post(`/user/hotelBooking/validateCoupon`, payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const hotelConfermFunction = (payload) => {
  return serviceUtil
    .post("/user/hotelBooking/calculateRoomAmount", payload)
    .then((res) => {
      const data = res && res.data;
      return data;
    });
};

const hotelCustomerReview = async (payload) => {
  return await serviceUtil
    .post("/user/hotelBooking/hotelCustomerReview", payload)
    .then((res) => {
      const data = res && res.data;
      return data;
    });
};
export {
  userHotelDetailsBook,
  userHotelTypeFun,
  userHotelBookRequest,
  moblienumberOtp,
  advanceSearchFilterApi,
  hotelDetailsSearchApiByHotelName,
  getRoomPriceApi,
  listPropertySendFunction,
  feedbackSendFunction,
  getLatestOffer,
  hotelAdvanceSearch,
  bookNowCoupan,
  hotelConfermFunction,
  userCancellHotelBooking,
  hotelCustomerReview,
};
