const PackageSeo = [
  {
    title: "Kerala package @ 5999 - Godsownstay.com",
    description:
      "Best Kerala Tour packages in 2024.4days 3 Night Packages , 2days 3 Night packages.best Honeymoon Packages From Godsownstay.com.Book kerala pacakges complimentry Houseboat trip ",
  },
  {
    title: "Kerala tour packages Starting @ 5999 - Coupon Code HOLIDAY20",
    description:
      "Best 2024-2025 Kerala Tour Packages starting from @ 5999,Coupon code HOLIDAY20 and get 20% OFF on Bookings.4days 3 Night kerala packages- Godsownstay.com Offers Affordable stay and BUDGET FRIENDLY RATES",
  },
  {
    title: "258 Kerala Holiday Packages From Godsownstay.com @ 7999",
    description:
      "258 Kerala Holiday Packages From Godsownstay.com @ 7999, Munnar 4 DAY 3 Night Packages.Godsownstay.com Offer one of best pacakges in affordable price.",
  },
  {
    title: "25 Kerala Trip Package @ 7999 - Godsownstay.com",
    description:
      "Book Now Best Kerala Trip Package Starting From @7999 From Godsownstay.com offer wide range of Tour Packages in afforadable Rate for Couples and Family",
  },
  {
    title: "Top 5 kerala Trip Starting From 8999 for Couple ",
    description:
      "Book Kerala Trip Package .Godsownstay.com offers  wide range of pacakges availabile in Godsownstay.com.Get Coupon code HOLIDAY CHEERS22 and get 22% OFF on your Kerala Trip Packages.",
  },
  {
    title: "Book Kerala Tour Packages - Godsownstay.com | Couponcode - MIDWEEK",
    description:
      "Book Kerala Tour Packages, Book Customized Pacakages From Godsownstay.com , 4D 3N Stay In Munnar,Coupon Code - MIDWEEK and get 25% OFF on Packages",
  },
  {
    title: "150 Kerala tour packages for family",
    description:
      "Choose Kerala Tour packages For family From Godsownstay.com and get 45% OFF on Desmestic Packages . We offrs affordable rate and 3 star Hotels Accomadations.3DAY 4 NIGHT.",
  },
  {
    title: "Book Maldives Tour Package from kerala - Godsownstay.com",
    description:
      "Choose Maldives Tour Package from kerala From Godsownstay.com.Afforadable Maldives Pacakges Starting From @ 19000,we offers Best Accomadation For our Guet . Rating 4.2/5",
  },
  {
    title: "167 kerala Tour packages with price Upto 75% OFF - Book Now",
    description:
      "kerala Tour packages with Price Best Price Guarantee .Godsownstay.com Offer Best Price and Affordable stay.price Upto 75% OFF on Krrala Tour Packages ",
  },
  {
    title: " 184 Munnar Tour Package Best Price @2024",
    description:
      " Munnar Tour Package Best Price Offers 55% OFF on Munnar Tour Packages,4 DAY 3 NIGHT only @12000.Coupon Code ADVENTUREDEAL28 and get 28% OFF on package Bookings.",
  },
];

export default PackageSeo;
