import * as yup from "yup";
import { RegexValidation } from "../../../constants/regexValidation/RegexValidation";

export const ResetPasswordAdminScheema = yup.object({
  password: yup
    .string()
    .max(30)
    .required("Please enter Password")
    .matches(RegexValidation.passwordReg, RegexValidation.passwordRegMsg),
});
