const PACKAGE_SETTING_DAY_ACTIVITIES = [
 {
  dataIndex: "col1",
  title: "S.N",
  nummeric: false,
  disablePadding: true,
  label: "S.N",
  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 40,
 },
 {
  dataIndex: "col2",
  title: "Day Activity name",
  nummeric: false,
  disablePadding: true,

  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 120,
 },
 {
  dataIndex: "col3",
  title: "Day Activity Code",
  nummeric: false,
  disablePadding: true,

  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 120,
 },
 {
  dataIndex: "col4",
  title: "Description",
  nummeric: false,
  disablePadding: true,

  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 120,
 },
 {
  dataIndex: "col5",
  title: "Place",
  nummeric: false,
  disablePadding: true,

  sorter: {
   compare: (a, b) => a.math - b.math,
   multiple: 2,
  },
  width: 80,
 },
 {
  dataIndex: "col6",
  title: "Actions",
  nummeric: false,
  disablePadding: true,
  label: "Category",
  filter: "select",
  width: 80,
 },
];

export default PACKAGE_SETTING_DAY_ACTIVITIES;
