const CONTRACT_RATE_HEADERS = [
  {
    dataIndex: "col1",
    title: "S.N",
    nummeric: false,
    disablePadding: true,
    label: "S.N",
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 30,
  },
  {
    dataIndex: "col2",
    title: "Hotel Name",
    nummeric: false,
    disablePadding: true,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 120,
  },
  {
    dataIndex: "col3",
    title: "Season",
    nummeric: false,
    disablePadding: true,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 120,
  },
  {
    dataIndex: "col4",
    title: "Rate Code",
    nummeric: false,
    disablePadding: true,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 120,
  },
  {
    dataIndex: "col5",
    title: "Days",
    nummeric: false,
    disablePadding: true,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 80,
  },
  {
    dataIndex: "col6",
    title: "Status",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 80,
  },
  {
    dataIndex: "col7",
    title: "Actions",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 80,
  },
];

export default CONTRACT_RATE_HEADERS;