import Box from "@mui/material/Box";
import BtnComp from "../../atoms/buttonComponent/BtnComp";
import React from "react";
import { faPlus, faRefresh, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Col, Modal, Row } from "antd";

function ModalComponent({
  modalWidth = 750,
  // showCloseIcon = true,
  createBtnName = "Create",
  // minHeightClassName = "mnh-300",
  // showClearBtn = true,
  // showSaveBtn = true,
  // saveBtnText = 'Save',
  // clearBtnText = 'Clear',
  // onSaveBtnClick = () => {},
  // onClearBtnClick = () => {},
  onCloseIconClick = () => {},
  // open = true,
  // ______________________________________
  children,
  childrenClassName,
  cancelBtnfun = () => {},
  createBtnFun = () => {},
  resetBtnfun = "",
  showFooter = true,
  showHeader = true,
  isModalOpen = true,
  btnName = "Cancel",
  modalTitle = "Modal Title",
  endIcon,
  closable = () => {},
  bodyStyle = { borderBottom: "10px solid #3d75df" },
}) {
  return (
    <>
      <Modal
        width={modalWidth}
        footer={null}
        className="newModelClass"
        closable={closable}
        open={isModalOpen}
        onOk={""}
        onCancel={onCloseIconClick}
        bodyStyle={bodyStyle}
        maskClosable={false}
      >
        <>
          <Box>
            {showHeader && (
              <div
                className="d-flex justify-content-between px-3 py-4"
                style={{
                  borderBottom: "1px solid #E5ECFC",
                  backgroundColor: "#3d75df",
                  fontSize: "1.2rem",
                  fontWeight: "200",
                }}
              >
                <label
                  className="modal-title fw-600 ff-open-sans"
                  style={{ color: "#fff" }}
                >
                  <p>{modalTitle}</p>
                </label>
              </div>
            )}

            {/* __________________________________________________________________________________________________________________ */}

            <div style={{ paddingBottom: "2%" }} className={childrenClassName}>
              {children}
            </div>

            {/* __________________________________________________________________________________________________________________ */}

            {!showFooter && (
              <div style={{ borderTop: "1px solid #E5ECFC" }}>
                <Row className="p-2" justify={"space-between"}>
                  <Col>
                    <Row justify={"start"}>
                      <BtnComp
                        btnName={btnName}
                        startIcon={faTimes}
                        color="white"
                        bg_color="#ff4d63"
                        bordercolor="#d15b47"
                        pl="10px"
                        btnClickFun={cancelBtnfun}
                        className="m-1"
                      />
                    </Row>
                  </Col>
                  <Col style={{ float: "right" }}>
                    <Row justify="end">
                      {createBtnFun && (
                        <BtnComp
                          btnName={createBtnName}
                          startIcon={endIcon ? "" : faPlus}
                          color="white"
                          bg_color="#3d75df"
                          bordercolor="#3d75df"
                          btnClickFun={createBtnFun}
                          pl="10px"
                          pr="10px"
                          className="m-1"
                          type={"submit"}
                          endIcon={endIcon}
                        />
                      )}

                      {resetBtnfun && (
                        <BtnComp
                          btnName="Reset"
                          endIcon={faRefresh}
                          color="white"
                          bg_color="#ff4d63"
                          bordercolor="#ff4d63"
                          pr="10px"
                          className="m-1"
                          btnClickFun={resetBtnfun}
                        />
                      )}
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </Box>
        </>
      </Modal>
    </>
  );
}

export default ModalComponent;
