import LoginPage from "../pages/loginPage";

const loginRoutesObj = [
  {
    element: (
      <>
        <LoginPage />
      </>
    ),
    path: "/login",
    index: true,
  },
];

export default loginRoutesObj;
