/* eslint-disable no-undef */
import {
  GoogleMap,
  Marker,
  useLoadScript,
} from '@react-google-maps/api'


export default function GoogleMapComponent({location}) {  
 const {lat:latitude,lng:longitude}= location


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_Google_Map_KEY,
  })

  return (
    <div
    className='map'
      style={{
        height: '500px',
        width: '100%',
        marginBottom: '50px',
      }}
    >
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerStyle={{ height: '100%', width: '100%' }}
          center={{ lat:Number(latitude), lng:Number(longitude) }}
          zoom={15}
        >
          <Marker position={{lat:Number(latitude), lng:Number(longitude) }}/>
         
        </GoogleMap>
      )}
    </div>
  )
}
