import React from "react";
import TextArea from "antd/es/input/TextArea";
import { Row } from "antd";

function NewTextArea({
  star = "block",
  label = "name",
  className,
  name,
  value,
  handleChange,
  placeholder,
  errors,
  touched,
  type,
  rows = 2,
  style,
}) {
  return (
    <div className="">
      <Row>
        {name == "specialRequest" ? (
          ""
        ) : (
          <p style={{ display: `${star}` }} className="re-input-p">
            *
          </p>
        )}

        {label}
      </Row>
      <Row>
        <TextArea
          rows={rows}
          name={name}
          type={type}
          style={{
            resize: "none",
            border: `${
              errors
                ? errors[name] && touched[name]
                  ? "1px solid #d16e6c"
                  : ""
                : null
            }`,
            color: "#858585",
            ...style,
          }}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className={className}
        />
        {errors ? (
          errors[name] && touched[name] ? (
            <p className="re-err fs-10">{errors[name]}</p>
          ) : null
        ) : null}
      </Row>
    </div>
  );
}

export default NewTextArea;
