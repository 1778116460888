import axios from "axios";
import serviceUtil from "../..";

const createPayment = (payload) => {
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}orders/payments/makePayment`,
      payload
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const getPayment = (payload) => {
  return serviceUtil
    .post("countAllRecords/payment", payload)
    .then((res) => {
      const data = res && res.data;
      return data;
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const verifyPayment = (payload) => {
  return serviceUtil
    .post("orders/payments/verify", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const CCAvenuePayment = (payload) => {
  return serviceUtil
    .post("orders/payments/makePayment", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const cancelBookingEmail = (payload) => {
  return serviceUtil
    .post("orders/payments/cancelBooking", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const reviewBookingEmail = (payload) => {
  return serviceUtil
    .post("orders/payments/reviewBooking", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err;
      return { errRes };
    });
};

const deleteHotelBooking = (payload) => {
  return serviceUtil
    .post("orders/payments/deleteBooking", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err;
      return { errRes };
    });
};
export {
  createPayment,
  verifyPayment,
  getPayment,
  CCAvenuePayment,
  cancelBookingEmail,
  reviewBookingEmail,
  deleteHotelBooking,
};
