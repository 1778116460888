import serviceUtil from "../../..";

const config = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  body: JSON.stringify(),
};

const packageSettingPackageCategoryGetAll = () => {
  return serviceUtil
    .get("/master/packageSettings/getPackageCategory")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const packageSettingPackageCategorygetOnePackage = (packageCategoryId) => {
  return (
    serviceUtil
      .get(`/registration/packageSettings/getOnePackage/${packageCategoryId}`)
      // http://localhost:2800/registration/packageSettings/getPackage
      .then((res) => {
        const data = res && res.data;
        return { data };
      })
      .catch((err) => {
        const errRes = err && err.response.data;
        return { errRes };
      })
  );
};

const packageSettingPackageCategoryCreate = (payload) => {
  return serviceUtil
    .post("/master/packageSettings/createPackageCategory", payload, config)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const packageSettingPackageCategoryUpdate = (packageCategoryId, payload) => {
  return serviceUtil
    .put(
      `/master/packageSettings/updatePackageCategory/${packageCategoryId}`,
      payload,
      config
    )
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const packageSettingPackageCategoryDelete = (packageCategoryId) => {
  return serviceUtil
    .deleteById(
      `/master/packageSettings/deletePackageCategory/${packageCategoryId}`
    )
    .then((res) => {
      const { data, message, err } = res && res.data;
      return { data, message, err };
    })
    .catch((err) => ({ err }));
};

export {
  packageSettingPackageCategoryGetAll,
  packageSettingPackageCategoryCreate,
  packageSettingPackageCategoryUpdate,
  packageSettingPackageCategoryDelete,
  packageSettingPackageCategorygetOnePackage,
};
