import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Layout, Menu, Row } from "antd";
import { useNavigate } from "react-router-dom/dist";
import { partnerRoutesPath } from "../../../constants/PartnerRoutePaths";
import { slideTabData } from "./partnerSliderData/PartnerSlideData";

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
const { Content, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const SideMenuComponent = () => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const routTab = (tabName, id) => {
    return [
      <Row style={{ width: "100%" }}>
        <Col span={24} className="sidebar-styles">
          <p
            onClick={() => {
              handleroutes(id);
            }}
          >
            {tabName}
          </p>
        </Col>
      </Row>,
      id,
    ];
  };
  const slideTabDetails = slideTabData(routTab, getItem);
  const collapsfun = () => {
    collapsed === true ? setCollapsed(false) : setCollapsed(true);
  };
  const leftarrow = (
    <>
      <div
        style={{
          backgroundColor: "#d0d0d0",
          height: "25px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="arrline" style={{ textAlign: "center" }}>
          <KeyboardDoubleArrowLeftIcon
            style={{
              fontSize: "1rem",
              backgroundColor: "#fff",
              color: "#5a5a5a",
              border: "1px solid #5a5a5a",
              borderRadius: "100%",
              zIndex: "1",

              margin: "-10px",
            }}
            onClick={collapsfun}
          />
        </div>
      </div>
    </>
  );

  const rightarrow = (
    <>
      <div
        style={{
          backgroundColor: "#d0d0d0",
          height: "25px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="arrline" style={{ textAlign: "center" }}>
          <span style={{ zIndex: "3" }}>
            <KeyboardDoubleArrowRightIcon
              style={{
                fontSize: "1rem",
                backgroundColor: "#fff",
                color: "#5a5a5a",
                border: "1px solid #5a5a5a",
                borderRadius: "100%",
                zIndex: "1",

                margin: "-10px",
              }}
              onClick={collapsfun}
            />
          </span>
        </div>
      </div>
    </>
  );
  const handleroutes = (i) => {
    navigate(partnerRoutesPath[i]);
  };
  const items = [getItem(collapsed === true ? leftarrow : rightarrow, "", "")];

  slideTabDetails.map((v, i) => {
    return items.unshift(
      getItem(
        <p
          key={i}
          onClick={() => {
            handleroutes(v.id);
          }}
        >
          {v.Option}
        </p>,
        slideTabData.id,
        <FontAwesomeIcon
          style={{ paddingLeft: "8px" }}
          onClick={() => {
            handleroutes(v.id);
          }}
          icon={v.icon}
        />,
        v.sub
      )
    );
  });
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        className="layoutStyle"
        collapsedWidth={45}
        width={250}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Menu
          theme="light"
          className="layoutStyle"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout className="site-layout">
        <Content
          style={{
            margin: "0 16px",
          }}
        ></Content>
      </Layout>
    </Layout>
  );
};
export default SideMenuComponent;
