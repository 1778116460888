import serviceUtil from "../..";

const config = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

const otpSentService = (payload) => {
  return serviceUtil
    .post("user/sendOtp/otp/request", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const otpVerifyService = (payload) => {
  return serviceUtil
    .post("user/sendOtp/otp/verify", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const userDetailsForHotelService = (payload) => {
  return serviceUtil
    .post("user/sendOtp/userDetails", payload)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const userLoginAuth = (payload, token) => {
  return serviceUtil
    .post("api/auth/login ", payload, {
      headers: { Authorization: "Bearer " + token },
    })
    .then((res) => {
      const data = res && res.data;
      return { data };
    });
};

const uploadProfileImage = (payload) => {
  return serviceUtil
    .post("api/auth/uploadProfileImage ", payload, config)
    .then((res) => {
      const data = res && res.data;
      return { data };
    });
};

const getProfileImage = async (id) => {
  return await serviceUtil.get(`api/auth/getProfileImage/${id}`).then((res) => {
    const data = res && res.data;
    return { data };
  });
};

const updateUserProfileById = (id , payload) => {
  return serviceUtil.put(`api/auth/updateProfileInfo/${id}`, payload).then((res) => {
    const data = res && res.data;
    return { data };
  });
}

export {
  otpSentService,
  otpVerifyService,
  userDetailsForHotelService,
  userLoginAuth,
  uploadProfileImage,
  getProfileImage,
  updateUserProfileById
};
