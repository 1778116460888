/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */


import React, { useState } from 'react';

const PaginationAlpha = ({ onChange }) => {
    const [activeAlpha, setactiveAlpha] = useState('A')
  const alphabet = ['A', 'B', 'C', 'D', 'E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y', 'Z'];

  const handleClick = letter => {
    onChange(letter);
    setactiveAlpha(letter)

  };

  return (
    <span className="paginationalpha">
      {alphabet.map(letter => (
        <p  className={`${activeAlpha===letter?"activeAlpha":"otherAlpha"}`} key={letter}  onClick={() => handleClick(letter)}>
          {letter}
        </p>
      ))}
      <div>

      </div>
    </span>
  );
};

export default PaginationAlpha;
