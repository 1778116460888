import React from "react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Col, Radio, Rate, Row, Tag } from "antd";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import BtnComp from "./../../../../components/atoms/buttonComponent/BtnComp";
import NewInputComp from "../../../../components/atoms/input/NewInputComp";
import "../../../../styles/global.scss";
const CheckboxGroup = Checkbox.Group;

function UserSideBar({
  onCancel,
  title = "Search",
  searchValue,
  searchOnChange = () => {},
  tag,
  tagFn = () => {},
  isTagChecked,
  star,
  rating,
  priceRange,
  priceRangeFun = () => {},
  category,
  categoryfun = () => {},
  rtingHighLow,
  type,
  typefun = () => {},
  other = [],
  // filterFunction,
}) {
  return (
    <>
      <Row className="userSideBar-div-fix">
        <Col span={24}>
          <p className="h4 text-center">Filters</p>

          <p
            className="h6 ext-justify pb-2"
            style={{
              textAlign: "left",
              borderBottom: "1px solid #eee",
            }}
          >
            {title && title}
          </p>

          <NewInputComp
            handleChange={searchOnChange}
            value={searchValue}
            name="search"
            label=""
            star="none"
            className="rounded-0 "
            placeholder={"Search.."}
          />
        </Col>
      </Row>
      <Row className="div-scroll pt-4 ">
        <Col span={24} className="nb-col1 filterColumn">
          <Col>
            {tag && (
              <div>
                <p
                  className="h6 ext-justify pb-2"
                  style={{
                    textAlign: "left",
                    borderBottom: "1px solid #eee",
                    marginTop: "30px",
                  }}
                >
                  {tag.tagName}
                </p>
                {tag.tagData.map((v, i) => {
                  return (
                    <Col style={{ marginTop: "10px" }} key={i}>
                      <Checkbox key={i} checked={isTagChecked} onChange={tagFn}>
                        {v}
                      </Checkbox>
                    </Col>
                  );
                })}
              </div>
            )}
            <p
              style={{ textAlign: "left", borderBottom: "1px solid #eee" }}
              className="h6 ext-justify pb-1 mb-2 mt-4 text-capitalize"
            >
              {star && star.starName}
            </p>
          </Col>
          {star &&
            star?.starData.map((v, i) => {
              return (
                <Col key={i} style={{ display: "flex" }}>
                  <Checkbox
                    onChange={(e) => star.handleChange(e, i + 1)}
                    style={{ marginTop: "6px", paddingRight: "8px" }}
                  />
                  <Rate
                    style={{ color: "#3eb64a", marginTop: "6px" }}
                    disabled
                    defaultValue={v}
                    allowHalf
                    count={v}
                  />
                </Col>
              );
            })}

          <Row />
          {rating && (
            <Row gutter={[0, 5]}>
              <Col span={24}>
                <p
                  className="h6 ext-justify pb-1 mt-2 mb-2"
                  style={{
                    textAlign: "left",
                    borderBottom: "1px solid #eee",
                  }}
                >
                  {rating.ratingName}
                </p>
              </Col>
              {/* <CheckboxGroup
                  className="priceRangeCheckbox"
                  style={{ display: 'flex', flexDirection: 'column' }}
                  options={rating.ratingData}
                  value={rating.ratingValue}
                  onChange={rating.ratingFun}
                /> */}
              {rating.ratingData.map((v, i) => {
                return (
                  <Col key={i} span={24} className="mb-2">
                    <Checkbox
                      name={v}
                      checked={rating.value == v ? true : false}
                      onChange={rating.ratingFun}
                    >
                      <Tag color="#3eb64a">{v}+</Tag>
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          )}

          {/* {priceRange && (
            <Col className="mb-2">
              <p
                className="h6 ext-justify pb-1 mt-3 "
                style={{ textAlign: 'center', borderBottom: '1px solid #eee' }}
              >
                {priceRange.priceRangeName}
              </p>
              {priceRange.priceRangeData.map((v, i) => {
                return (
                  <>
                    <div>
                      <Checkbox key={i} className="mt-2 mb-2" onChange={''}>
                        {v}
                      </Checkbox>
                    </div>
                  </>
                )
              })}
            </Col>
          )} */}
          {priceRange && (
            <Col className="mb-2">
              <p
                className="h6 ext-justify pb-1 mt-3 "
                style={{ textAlign: "left", borderBottom: "1px solid #eee" }}
              >
                {priceRange.priceRangeName}
              </p>
              {/* {priceRange.priceRangeData.map((v, i) => {
                return (
                  <> */}
              <div>
                <CheckboxGroup
                  className="priceRangeCheckbox"
                  style={{ display: "flex", flexDirection: "column" }}
                  options={priceRange.priceRangeData}
                  // value={priceRange.priceRangeValue}
                  onChange={priceRangeFun}
                />
                {/* <Checkbox key={i} className="mt-2 mb-2" onChange={(e)=>{priceRangeFun(e,i)}}>
                        {v} */}
                {/* </Checkbox> */}
              </div>
              {/* </>
                )
              })} */}
            </Col>
          )}
          {category && (
            <Col>
              <p
                className="h6 ext-justify pb-1 mt-3 mb-2"
                style={{ textAlign: "center", borderBottom: "1px solid #eee" }}
              >
                {category.title}
              </p>
              {category.categoryData.map((v, i) => {
                return (
                  <Col key={i}>
                    <Checkbox
                      className="mb-2"
                      value={v[category.categoryName]}
                      onChange={(e) => {
                        categoryfun(e, v, i);
                      }}
                    >
                      {v[category.categoryName]}
                    </Checkbox>
                  </Col>
                );
              })}
            </Col>
          )}
          {type && (
            <Col>
              <p
                className="h6 ext-justify pb-1 mt-3 mb-2"
                style={{ textAlign: "left", borderBottom: "1px solid #eee" }}
              >
                {type.title}
              </p>
              {type.typeData.map((v, i) => {
                return (
                  <Col key={i}>
                    <Checkbox
                      className="mb-2"
                      value={v[type.typeName]}
                      onChange={(e) => {
                        typefun(e, v, i);
                      }}
                    >
                      {v[type.typeName]}
                    </Checkbox>
                  </Col>
                );
              })}
            </Col>
          )}
          {other &&
            other.map((val, idx) => {
              return (
                <Col key={idx}>
                  <p
                    className="h6 ext-justify pb-1 mt-3 mb-2"
                    style={{
                      textAlign: "center",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    {val.title}
                  </p>
                  {val.data.map((v, i) => {
                    return (
                      <Col key={i}>
                        <Checkbox className="mb-2" onChange={""}>
                          {v}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Col>
              );
            })}

          {rtingHighLow && (
            <>
              <p
                className="h6 ext-justify pb-1 mt-3 mb-2"
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid #eee",
                }}
              >
                {rtingHighLow.title}
              </p>
              <Radio.Group
                onChange={rtingHighLow.onChange}
                value={rtingHighLow.value}
              >
                <div className="mb-2">
                  <Radio value={1}>LOW-HIGH</Radio>
                </div>
                <div className="mb-2">
                  <Radio value={-1}>HIGH-LOW</Radio>
                </div>
              </Radio.Group>
            </>
          )}

          <Col>
            <Col span={24}>
              <Row justify={"center"}>
                <Col span={18}>
                  <BtnComp
                    btnClickFun={onCancel}
                    pr={"10px"}
                    style={{
                      backgroundColor: "#3d75df",
                      color: "#fff",
                      width: "100%",
                      marginBottom: "50px",
                      marginTop: "10%",
                      borderRadius: "8px",
                    }}
                    // className={`${lg || md ? "rounded ":""}`}
                    btnName={`APPLY`}
                    endIcon={faFilter}
                  />
                </Col>
              </Row>
            </Col>
          </Col>
        </Col>
      </Row>
    </>
  );
}

export default UserSideBar;
