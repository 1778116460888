import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Rate,
  Button,
  Upload,
  Card,
  Space,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import HeaderForAll from "../../homePage/homePageHeader/HeaderForAll";
import { useNavigate } from "react-router-dom";
import { hotelCustomerReview } from "../../../services/utils/userUtils/hotelutils";
import { useSelector } from "react-redux";
import { getProfileImage } from "../../../services/utils/userUtils/hotelutils/otpverification";

import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
const { Paragraph } = Typography;
const CustomerReviewForm = () => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  // const [userDetails, setuserDetails] = useState();
  // const [userProfilePic, setUserProfilePic] = useState();

  const selector = useSelector((s) => s.userDetails.user);
  const USER_ID = selector?.id;
  // debugger;

  let { id, hotelId, userId } = useParams();
  const nav = useNavigate();
  const routeToHomePage = () => {
    setTimeout(() => {
      nav("/");
    }, 500);
  };
  useEffect(() => {
    const getUserProfileImage = () => {
      getProfileImage(USER_ID).then((res) => {
        console.log(res);
        // const { profileImage } = res.data.response;
        // setUserProfilePic(profileImage);
      });
    };

    if (selector?.name) {
      getUserProfileImage();
    }
    // setuserDetails(selector);
  }, []);

  const onFinish = async (values) => {
    const formdata = new FormData();
    setIsLoading(true);
    formdata.append("rating", values.rating);
    formdata.append("comment", values.comment);
    formdata.append("bookingId", id);
    formdata.append("hotelId", hotelId);
    formdata.append("userId", userId);
    // formdata.append("customerName", userDetails?.name);
    // formdata.append("profileImage", userProfilePic);
    formdata.append("image", file);
    await hotelCustomerReview(formdata)
      .then((res) => {
        if (res.message) {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: res.message,
            showConfirmButton: false,
            timer: 3000,
            customClass: {
              popup:
                window.innerWidth <= 600 ? "custom-popup-class-checkout" : "",
              title:
                window.innerWidth <= 600 ? "custom-title-class-checkout" : "",
            },
          });
        } else {
          alert("Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePhotoChange = (info) => {
    setFile(info.file);
  };

  return (
    <div>
      <HeaderForAll />
      <div
        style={{ padding: "50px 0", display: "flex", justifyContent: "center" }}
      >
        <Card style={{ width: "90%", maxWidth: "780px" }}>
          <Form
            form={form}
            name="customerReview"
            onFinish={onFinish}
            initialValues={{ rating: 3 }} // Default rating
            layout="vertical"
          >
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Card title="Rating">
                <Form.Item
                  name="rating"
                  rules={[{ required: true, message: "Please give a rating" }]}
                >
                  <Rate />
                </Form.Item>
              </Card>

              <Card title="Customer Comment Section">
                {/* <Form.Item
                  name="liked"
                  label="What did you like?"
                  rules={[
                    { required: true, message: "Please provide your comment" },
                  ]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                  name="disliked"
                  label="What didn't you like?"
                  rules={[
                    { required: true, message: "Please provide your comment" },
                  ]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item> */}

                <Form.Item
                  name="comment"
                  label="Write a short sentence to sum up your stay."
                  rules={[
                    { required: true, message: "Please provide your comment" },
                  ]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Card>

              <Card title="Add Photos to Your Review (Optional)">
                <Paragraph>
                  Share images of your vacation and experience to inspire others
                  to visit this place. Let your adventures tell a story and
                  create memories that last a lifetime!
                </Paragraph>
                <Form.Item>
                  <Upload
                    beforeUpload={() => false}
                    onChange={handlePhotoChange}
                    listType="picture"
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Add Photos</Button>
                  </Upload>
                </Form.Item>
              </Card>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isloading}
                  style={{ width: "100%", height: "50px" }}
                  onClick={routeToHomePage}
                >
                  Submit
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default CustomerReviewForm;
