import HomePageHeader from "../homePage/homePageHeader/HeaderForAll";
import { Grid } from "@mui/material";
import { Button } from "antd";
import errorbutton from "../../assets/error.png";

function BookingFail() {
  return (
    <>
      <HomePageHeader />
      <Grid container spacing={2} style={{ marginTop: "25px" }}>
        <Grid item lg={4} md={4} xs={4} />
        <Grid item lg={4} md={4} xs={4}>
          <div id="description" className="bix-box text-center">
            <img
              alt="errorimage"
              src={errorbutton}
              style={{ marginTop: "25px", width: "100px" }}
            />
            <h1 style={{ color: "red", marginTop: "25px" }}>Booking Failed</h1>
            <p className="address" style={{ marginTop: "25px" }}>
              Cancellation Reason
            </p>
            <Button
              type="primary"
              style={{ marginTop: "25px", backgroundColor: "red" }}
            >
              Try Again
            </Button>
          </div>
        </Grid>
        <Grid item lg={4} md={4} xs={4}></Grid>
      </Grid>
    </>
  );
}

export default BookingFail;
