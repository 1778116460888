import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";

function BtnComp({
  btnName = "Button",
  startIcon = "",
  endIcon = "",
  color,
  bg_color = "",
  // borderColor,
  style,
  pl,
  pr,
  type,
  btnClickFun = () => {},
  fontSize,
  className,
  disabled = false,
  height,
  width = "100px",
}) {
  return (
    <Button
      onClick={btnClickFun}
      disabled={disabled}
      type={type}
      style={{
        borderRadius: "0px",
        color: `${color}`,
        width: `${width}`,
        backgroundColor: `${bg_color}`,
        height: `${height}`,
        fontSize: `${fontSize}`,
        ...style,
      }}
      className={className}
    >
      {" "}
      {startIcon ? (
        <FontAwesomeIcon icon={startIcon ? startIcon : "null"} />
      ) : null}
      <p
        style={{
          paddingLeft: `${pl}`,
          paddingRight: `${pr}`,
          display: "inline",
          cursor: "pointer",
        }}
      >
        {btnName}
      </p>{" "}
      {endIcon ? <FontAwesomeIcon icon={endIcon ? endIcon : "null"} /> : null}
    </Button>
  );
}

export default BtnComp;
