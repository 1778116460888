import React from "react";
import { Layout, Row, Col } from "antd";
import { Content } from "antd/es/layout/layout";
import MainHeader from "./mainHeader/index";
import { UserRoute, UserRouteAll } from "../../../routes";
import { useLocation } from "react-router-dom";
import HomePage from "../../homePage";
import SearchBar from "../../../components/searchbar/SearchBar";
import { useSelector } from "react-redux";

// eslint-disable-next-line no-unused-vars
function NewUserLayout() {
  const { pathname } = useLocation();

  const searchCardFocus = useSelector(
    (state) => {
      return state?.searchCardData?.searchCardFocus;
    }
  );
  return (
    <>
      {pathname.includes("/checkout") || pathname.includes("/hotel/") ? (
        <UserRouteAll />
      ) : pathname === "/" ? (
        searchCardFocus ? <SearchBar /> : <HomePage />
      ) : (
        searchCardFocus ? <SearchBar /> :
          <Layout style={{ backgroundColor: "#fff" }}>
            <MainHeader />

            <Layout>
              <Content
                style={{
                  backgroundColor: "#fff",
                  margin: "0px auto",
                  width: "100%",
                }}
              >
                {/* <UserLayout/> */}

                <Row justify={"center"}>
                  <Col span={24}>
                    {/* __________________________________________________________________________________________________ */}

                    <UserRoute />

                    {/* __________________________________________________________________________________________________ */}
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
      )}
    </>
  );
}

export default NewUserLayout;
