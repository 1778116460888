import serviceUtil from './../../index';
const DestinationGetAll=()=>{
    return serviceUtil.get("master/locationSettings/getDestinationWithImage").then((res)=>{
        const data=res && res.data
        return {data};

    }).catch((err)=>{
        const errRes=err && err.response.data;
        return errRes;

    })
 }

 const getAllCoupons= () =>{
    return serviceUtil.get("/master/couponSettings/allCouponPage").then((res)=>{
        const data=res && res.data
        return {data}
    }).catch((err)=>{
        const errRes=err && err.response.data;
        return errRes;
    })
 }

 const getHomePageCoupons=()=>{
    return serviceUtil.get("/master/couponSettings/couponHomePage").then((res)=>{
        const data=res && res.data
        return {data}
    }).catch((err)=>{
        const errRes=err && err.response.data;
        return errRes;
    })
 }

 const footarLocationForAll=()=>{
    return serviceUtil.get("/registration/hotel/hotelLocation").then((res)=>{
        const data=res && res.data
        return data
    })
 }
 export {DestinationGetAll,getAllCoupons, footarLocationForAll, getHomePageCoupons}