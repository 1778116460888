import serviceUtil from "../../../index";
const config = {
 headers: {
  Accept: "application/json",
  "Content-Type": "application/json",
 },
 body: JSON.stringify(),
};

const hotelSettingRoomCategoryGetAll = () => {
 return serviceUtil
  .get("/master/hotelSettings/getRoomCategory")
  .then((res) => {
   const data = res && res.data;
   return { data };
  })
  .catch((err) => {
   const errRes = err && err.response.data;
   return { errRes };
  });
};

const hotelSettingRoomCategoryCreate = (payload) => {
 return serviceUtil
  .post("/master/hotelSettings/createRoomCategory", payload, config)
  .then((res) => {
   const data = res && res.data;
   return { data };
  })
  .catch((err) => {
   const errRes = err && err.response.data;
   return { errRes };
  });
};

const hotelSettingRoomCategoryUpdate = (roomCategoryId, payload) => {
 return serviceUtil
  .put(`/master/hotelSettings/updateRoomCategory/${roomCategoryId}`, payload, config)
  .then((res) => {
   const data = res && res.data;
   return { data };
  })
  .catch((err) => {
   const errRes = err && err.response.data;
   return { errRes };
  });
};

const hotelSettingRoomCategoryDelete = (roomCategoryId) => {
 return serviceUtil
  .deleteById(`/master/hotelSettings/deleteRoomCategory/${roomCategoryId}`)
  .then((res) => {
   const { data, message, err } = res && res.data;
   return { data, message, err };
  })
  .catch((err) => ({ err }));
};

export { hotelSettingRoomCategoryGetAll, hotelSettingRoomCategoryUpdate, hotelSettingRoomCategoryDelete, hotelSettingRoomCategoryCreate };
