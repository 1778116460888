import { filterKeyArr, sortNestedData, valueFun } from ".";

const OtherFunctionsObject = {
  fileterCategory: (filterData, categoryData) => {
    if (categoryData.length === 0) {
      return filterData;
    } else {
      return filterKeyArr(
        filterData,
        categoryData.map((v) => {
          return {
            category: v.roomCategoryName[v.roomCategoryName.length - 1],
          };
        }),
        ["category"],
        ["category"]
      );
    }
  },
  filterBySort: (data, order) =>
    sortNestedData(data, order, ["contractId", "discountRate"]),

  houseboatCategoryFilter: (alldata, selectedData) => {
    if (selectedData.length == 0) {
      return alldata;
    } else {
      const categoryFilterResult = alldata.filter((item1) =>
        item1?.contractId.contractRateDetails.some((item2) =>
          selectedData.some((slt) => slt._id == item2.roomCategory._id)
        )
      );
      return categoryFilterResult;
    }
  },

  houseboatRoomTypeFilter: (allData, selectedData) => {
    if (selectedData.length == 0) {
      return allData;
    } else {
      const roomTypeFilterResult = allData.filter((item1) =>
        item1?.contractId.contractRateDetails.some((item2) =>
          item2?.roomType.some((item3) =>
            selectedData.some((type) => type._id == item3._id)
          )
        )
      );
      return roomTypeFilterResult;
    }
  },
  filterByStar: (data, star, arr) => {
    if (star.length == 0) {
      return data;
    } else {
      return data.filter((item) => {
        return star.some((str) => str == Math.floor(valueFun(item, arr))); //arr=["reviewId","rating"]
      });
    }
  },
  filterByHotelType: (data, type, arr) => {
    if (type.length == 0) {
      return data;
    } else {
      return data.filter((item) => {
        return type.some((t) => t == valueFun(item, arr)); //arr=["reviewId","rating"]
      });
    }
  },

  filterByPrice: (data, priceRange, arr) => {
    if (priceRange === undefined || Object.keys(priceRange).length == 0) {
      return data;
    } else {
      // return data.filter(item=>{
      //   return (valueFun(item,arr) >= priceRange.min && valueFun(item,arr) < priceRange.max)  // arr=["packageBasicRate"]
      // })
      const filterData = data.filter((item) => {
        return (
          valueFun(item, arr) >= priceRange.min &&
          valueFun(item, arr) < priceRange.max
        );
      });
      return filterData;
    }
  },
  packageCategoryFilter: (alldata, selectedData) => {
    if (selectedData.length == 0) {
      return alldata;
    } else {
      const categoryFilterResult = alldata.filter((item1) =>
        item1.packageCategory.some((item2) =>
          selectedData.some((slt) => slt._id == item2)
        )
      );
      return categoryFilterResult;
    }
  },
  filterByCustomerRating: (data, star, arr) => {
    if (!star) {
      return data;
    } else {
      return data.filter((item) => {
        return star <= valueFun(item, arr); //arr=["reviewId","rating"]
      });
    }
  },
  filterByHotelTags: (data, isHotelTagChecked) => {
    if (isHotelTagChecked === false) {
      const tData = data.filter((item) => {
        return item?.basicDetails?.coupleFriendlyHotel === isHotelTagChecked;
      });
      return tData;
    } else if (isHotelTagChecked) {
      const tData = data.filter((item) => {
        return item?.basicDetails?.coupleFriendlyHotel === isHotelTagChecked;
      });
      return tData;
    } else {
      return data;
    }
  },
};

export default OtherFunctionsObject;
