/* eslint-disable no-debugger */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Col, Drawer, Input, Row } from "antd";
import logo from "../../../assets/biglogo.jpg";
import adminicon from "../../../assets/adm-icon.png";
import hotel from "../../../assets/hotel.png";
import { useNavigate } from "react-router-dom/dist";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleAuthLogin } from "../../../services/utils/userUtils/loginAuthByGoogle/GoogleAuth";
// import { InputOTP } from "antd-input-otp";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

import { moblienumberOtp } from "../../../services/utils/userUtils/hotelutils";
import { InputOTP } from "antd-input-otp";
import { userLoginAuth } from "../../../services/utils/userUtils/hotelutils/otpverification";
import { UserLoader } from "../../../components/atoms/userLoader";
import HotelBookingProceedModel from "../../../components/molecules/modalComponent/HotelBookingProceedModel";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../../reduxData/sliceData/userDetailsReducer";
import { useLocation } from "react-router";

// import { InputOTP } from "antd-input-otp";
// import axios from "axios";

function MoblieLoginModel({ isModalOpen, onCancel, style }) {
  const nav = useNavigate();
  const [phoneNumber, setphoneNumber] = useState("");
  const [isLoading, setisLoading] = useState(false);
  // const [isLogin, setisLogin] = useState(false)
  const [otpmodel, setotpmodel] = useState(false);
  const [otpValue, setotpValue] = useState("");
  const [numberErrors, setnumberErrors] = useState("");
  const [isRegister, setIsRegister] = useState(false);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const [proceedPaymentFailedModel, setproceedPaymentFailedModel] =
    useState(false);
  const [userInputData, setuserInputData] = useState({
    name: "",
    email: "",
  });
  const { pathname } = useLocation();

  const [
    ,
    // userData
    setuserData,
  ] = useState({});

  const handleChange = (e) => {
    setuserInputData((o) => ({ ...o, [e.target.name]: e.target.value }));
  };
  useEffect(() => {
    const elements = document.getElementsByClassName("ant-drawer-body");
    if (elements.length > 0) {
      const element = elements[0];
      if (pathname.match("/bookings")) {
        element.scroll(0, 0);
      } else {
        element.scroll(0, 10);
      } // Assuming you want to scroll the first element
    }
  }, [isModalOpen]);

  useEffect(() => {
    // Check for the loginSuccess flag in localStorage
    const loginSuccess = localStorage.getItem("loginSuccess");
    if (loginSuccess === "true") {
      // Display the SweetAlert
      Swal.fire({
        icon: "success",
        title: "Congratulations! Your account has been successfully created",
        showConfirmButton: false,
        timer: 4000,
        customClass: {
          popup: window.innerWidth <= 900 ? "custom-popup-class" : "",
          title: window.innerWidth <= 900 ? "custom-title-class" : "",
        },
      });
      // Clear the flag after displaying the alert
      localStorage.removeItem("loginSuccess");
    }
  }, []);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => loginFunction(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const loginFunction = async (coderes) => {
    // setUser(coderes)

    try {
      const res = await GoogleAuthLogin({ idToken: coderes.access_token });
      if (res?.data.success) {
        dispatch(
          setUserDetails({
            token: res.data.token,
            id: res.data.id,
            name: res.data.name,
            email: res.data.email,
          })
        );
        localStorage.setItem("loginSuccess", "true");

        setTimeout(function () {
          window.location.reload();
        }, 100);
      } else {
        console.log("login failed...");
      }
      onCancel();
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    setnumberErrors("");
    // setisLogin(false)
    setotpmodel(false);
    setIsRegister(false);
    setuserInputData({
      name: "",
      email: "",
    });
    setphoneNumber("");
    setotpValue("");
    sessionStorage.setItem("is_signup_popup_rendered", "yes");
    setAlowSignupPopup(false);
    onCancel();
    nav("/");
    // setIsModalOpen(false)
  };
  const navigate = useNavigate();

  const otpSendFunction = async () => {
    if (phoneNumber.length > 10 || phoneNumber.length < 10) {
      setnumberErrors("Please Enter valid Mobile number");
      // debugger;
    } else {
      // setotpmodel(true)
      setnumberErrors("");
      setisLoading(true);

      const res = await moblienumberOtp({
        phone: `+91${phoneNumber}`,
      });
      setToken(res?.data?.token);
      setotpValue(res?.data);
      setotpmodel(true);
      // debugger;
      if (res.data?.userData) {
        // setisLogin(true)

        setuserData(res.data?.userData);
      } else {
        setIsRegister(true);
        // alert('user not found')
      }
      setisLoading(false);
    }
  };

  useEffect(() => {
    if (phoneNumber.length > 10) {
      setnumberErrors("Please Enter valid Mobile number");
    } else {
      setnumberErrors("");
    }
  }, [phoneNumber]);

  const userDetailSend = async () => {
    if (otpValue && otpValue?.length && otpValue?.join("")?.length == 6) {
      //alert("6i");

      // debugger;
      const otpstr = Number(otpValue?.join(""));
      setisLoading(true);

      try {
        // debugger;
        const res = await userLoginAuth(
          {
            phone: `+91${phoneNumber}`,
            inputOTP: otpstr,
            firstName: userInputData.name,
            email: userInputData.email,
          },
          token
        );

        if (res?.data?.user_Name) {
          const { email, firstName, whatsAppNo, googleId, _id } =
            res.data.user_Name;

          dispatch(
            setUserDetails({
              id: _id,
              googleId: googleId,
              name: firstName,
              email: email,
              whatsAppNo: whatsAppNo,
            })
          );
          // debugger;
          localStorage.setItem("loginSuccess", "true");

          if (window.location.pathname !== "/bookings") {
            setTimeout(function () {
              window.location.reload()
            }, 100);
          } else {
            setTimeout(function () {
              window.location.href = "/bookings";
            }, 100);
          }

        }
      } catch (err) {
        // debugger;
        // handleCancel()
        setproceedPaymentFailedModel(true);
        console.log(err);
      }
      setisLoading(false);
    }
  };

  useEffect(() => {
    userDetailSend();
  }, [otpValue]);
  const [allowSignupPopup, setAlowSignupPopup] = useState(false);
  useEffect(() => {
    const isDesktop = window.innerWidth > 900 ? true : false;

    if (!isDesktop) {
      const isSignupPopupRendered =
        sessionStorage.getItem("is_signup_popup_rendered") || "";
      if (!isSignupPopupRendered) {
        setAlowSignupPopup(true);
      } else {
        setAlowSignupPopup(false);
      }
    }
  }, []);

  return (
    <div>
      <Drawer
        placement={"bottom"}
        width={500}
        height={"auto"}
        onClose={handleCancel}
        open={
          (pathname.length === 1 || pathname.includes("bookings")) &&
          (isModalOpen || allowSignupPopup)
        }
        headerStyle={{
          position: "fixed",
          zIndex: "2",
          background: "white",
          width: "100%",
          borderRadius: "22px 22px 0px 0px",
        }}
        className="mobile-popup-modal"
        closable={true}
        style={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          boxShadow: "none",
          padding: "0px",
          ...style,
        }}
        zIndex={12}
      >
        {isLoading ? <UserLoader /> : ""}
        <div className={`loginmodelcontent ${isLoading ? "lodercontent" : ""}`}>
          <div className="login-model-body py-2">
            <img className="login-popup-logo" src={logo} alt="" />
          </div>

          {!otpmodel ? (
            <div disabled={isLoading} style={{ width: "100%" }}>
              <p style={{ padding: "5%", fontWeight: "700" }}>
                Please enter your phone number to continue
              </p>
              <Input
                type={"number"}
                onChange={(e) => {
                  setphoneNumber(e.target.value);
                }}
                value={phoneNumber}
                // style={{ width: '60%' }}
                addonBefore="+91"
              />
              {numberErrors ? (
                <p className="re-err fs-10">{numberErrors}</p>
              ) : null}
              <Button onClick={otpSendFunction} className="login-model-otp-txt">
                Send OTP
              </Button>
            </div>
          ) : (
            <>
              <Row style={{ paddingX: "10%" }} justify={"center"}>
                {!isRegister ? <h4>Sign In</h4> : <h4>Sign up</h4>}
                {isRegister ? (
                  <Row justify={"center"} gutter={[30, 20]}>
                    <Col className="just-center" spna={6}>
                      Name
                    </Col>

                    <Col span={18}>
                      <Input
                        name="name"
                        value={userInputData.name}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col className="just-center" spna={6}>
                      Email
                    </Col>

                    <Col span={18}>
                      <Input
                        name="email"
                        value={userInputData.email}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                <Row
                  justify={"center"}
                  gutter={[30, 20]}
                  style={{ marginTop: "3%" }}
                >
                  <Col className="just-center" spna={6}>
                    OTP
                  </Col>

                  <Col span={20} style={{ marginLeft: "10px" }}>
                    <InputOTP
                      inputType="numeric"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      length={6}
                      style={{ height: "30px", width: "40px" }}
                      value={otpValue}
                      onChange={(e) => setotpValue(e)}
                    />
                  </Col>
                </Row>
              </Row>
            </>
          )}
          <hr className="hr-text" data-content="OR" />
          <div className="loginModel-div" onClick={login}>
            <Row>
              <Col span={4}>
                <img
                  className="loginMode-googlelogo"
                  src="https://img.icons8.com/color/48/null/google-logo.png"
                  alt=""
                />
              </Col>
              <Col className="signin" span={20} style={{ marginLeft: "-5%" }}>
                Sign in
              </Col>
            </Row>
          </div>
          <div style={{ marginTop: "3%" }}>
            By proceeding, you agree to{" "}
            <b
              style={{ color: "#337ab7", fontWeight: "300" }}
              onClick={() => {
                nav("/termsandconditionslink");
              }}
            >
              Terms & Condititons
            </b>
          </div>

          <Row style={{ marginTop: "5%" }}>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                className="loginbtn"
                style={{
                  border: "2px solid #1675df",
                  marginRight: "10%",
                  paddingBottom: "10%",
                }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                <img
                  src={adminicon}
                  className="loginModel-loginbtn-logo"
                  alt=""
                />{" "}
                Login as Admin
              </Button>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                className="loginbtn"
                style={{ border: "2px solid #1675df", paddingBottom: "10%" }}
              >
                <img src={hotel} className="loginModel-loginbtn-logo" alt="" />{" "}
                Login as Partner
              </Button>
            </Col>
          </Row>
          <Button className="login-model-closebtn mb-3" onClick={handleCancel}>
            Close
          </Button>
        </div>
        <HotelBookingProceedModel
          open={proceedPaymentFailedModel}
          setOpen={setproceedPaymentFailedModel}
          modelViewType={"isNotFormFilled"}
          message={"OTP verification failed.."}
        />
      </Drawer>
    </div>
  );
}

export default MoblieLoginModel;
