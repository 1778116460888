import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper } from "@mui/material";
import { Row, Col, Skeleton } from "antd";
import { useRef, useEffect, useState } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// message.config({
//   top: 300,
//   duration: 2,
//   maxCount: 3,
//   rtl: true,
//   prefixCls: 'my-message',
// });

export default function OfferCards({ data, copiedCode, setcopiedCode }) {

  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  //const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
  }, [copiedCode]);

  const copyCode = async (code) => {
    await navigator.clipboard.writeText(code);
    setcopiedCode(code);

    clearCodeDisplay();

    let myInterval = setInterval(function () {
      setcopiedCode("");
      clearCodeDisplay();
    }, 3000);

    localStorage.setItem("copyCodeIntervalId", myInterval);

  };

  const clearCodeDisplay = () => {
    var intervalId = localStorage.getItem("copyCodeIntervalId");

    if (intervalId && parseInt(intervalId) >= 0){
      intervalId = parseInt(intervalId);
      clearInterval(intervalId);
    }
  };

  return (
    <>
      {!data ? (
        <Skeleton avatar paragraph={{ rows: 3 }} />
      ) : (
        <Paper elevation={3}>
          <Row>
            <Row>
              <Col span={8} style={{ flex: "0 0 0" }}>
                <div ref={ref}>
                  {isVisible && (
                    <img
                      // src={data && data?.couponImage[0]}
                      src={
                        data &&
                        `${process.env.REACT_APP_S3_BUCKET_URL}/images/coupon/${data?.couponImage[0]}`
                      }
                      alt="coupon"
                      className="couponCardstyle"
                    ></img>
                  )}
                </div>
                {/* {!loadBottomImages && ( */}
                {/* <LazyLoadImage
                  src={data && data?.couponImage[0]}
                  alt="coupon"
                  effect="blur" // effect used when the image is loading (optional)
                  className="couponCardstyle"
                  // height="auto" // height of the image (optional)
                  // width="100%" // width of the image (optional)
                /> */}
                {/* )} */}
              </Col>
              <Col span={16} style={{ display: "block" }}>
                <div
                  className="cardHeading"
                  style={{
                    fontSize: "1.2rem",
                    padding: "10px 0px 0px 12px",
                    fontWeight: "500",
                  }}
                >
                  Get flat {data && data?.discountValue}% Off
                </div>
                <div
                  className="cardText"
                  style={{
                    fontSize: "0.9rem",
                    padding: "0px 12px 0px 12px",
                    opacity: "0.7",
                    height: "50px",
                  }}
                >
                  {data && data?.couponDescription}
                </div>
              </Col>
            </Row>

            <Row style={{ width: "100%" }}>
              <Col
                span={24}
                style={{
                  fontSize: "1.3vw !important",
                  padding: "0px 0px 0px 5px",
                  fontWeight: 500,
                }}
                className="cardCodeText"
              >
                <span style={{ fontSize: "1.3vw !important" }}>
                  Copy Code :
                </span>
                <span
                  className="cardCode"
                  style={{
                    color: "#1674DE",
                    fontSize: "1.5vw !important",
                    fontWeight: "600",
                    paddingLeft: "1%",
                  }}
                >
                  {/* GOS1210  */}
                  {data && data?.couponCode}
                  &nbsp;{" "}
                  <FontAwesomeIcon
                    onClick={() => copyCode(data?.couponCode)}
                    className="dis-icon-copy copyButton"
                    icon={faCopy}
                  />
                </span>

                {data?.couponCode === copiedCode ? <span className="ms-2 text-success">Copied</span> : <></>}

              </Col>
            </Row>
          </Row>
        </Paper>
      )}
    </>
  );
}
