import React, { useEffect, useState } from "react";
import { Tabs, Tag } from "antd";
import { Paper, Typography, Card, CardContent, CardMedia } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import moment from "moment";
import couple from "./../../../../assets/couple.png";
import { GetHotelBookingById } from "../../../../services/utils/admin/myBooking/hotelBookingList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
const { TabPane } = Tabs;

const HotelBookingDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const nav = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  // const hotelDetails = {
  //   name: "Sample Hotel Name",
  //   image: "https://via.placeholder.com/500",
  //   overview: "This is a sample hotel overview.",
  //   room: "This is a sample room description.",
  //   billing: "Billing details will be shown here.",
  // };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getHotelBookingDataById = async () => {
      try {
        const {
          data: { response },
        } = await GetHotelBookingById(id);
        setData(response[0]);
      } catch (err) {
        console.log(err.message);
      }
    };
    getHotelBookingDataById();
  }, []);

  return (
    <div>
      {isMobile && (
        <div className="mobile2ndHead justify-content-start">
          <IconButton onClick={() => nav("/bookings")}>
            <ArrowBackIcon sx={{ color: "white" }} />
          </IconButton>
          <p>&nbsp;&nbsp;Hotel Booking Details</p>
        </div>
      )}
      <Paper elevation={0} square sx={{ p: 2, mt: 2 }}>
        <Card>
          <CardMedia
            component="img"
            alt="Hotel Image"
            height="350"
            image={data?.hotelId?.profilePic[4]}
          />
          <CardContent>
            <Typography variant="h5" component="div">
              {data?.hotelId?.basicDetails?.hotelName}
            </Typography>
          </CardContent>
        </Card>
      </Paper>

      <Tabs
        defaultActiveKey="overview"
        size="large"
        animated
        tabBarGutter={40}
        tabBarStyle={{ paddingLeft: "20px" }}
        className="vh-100"
      >
        <TabPane tab="Overview" key="overview">
          <Grid container spacing={3}>
            {/* First Column */}
            <Grid item xs={12} md={4} spacing={2}>
              <Grid container direction="column">
                <Typography
                  className="text-capitalize"
                  sx={{ pl: 2 }}
                  variant="h6"
                  gutterBottom
                >
                  {data?.hotelId?.basicDetails?.hotelName}
                </Typography>
                <Typography
                  className="text-capitalize"
                  sx={{ pl: 2, color: "grey" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  {data?.hotelId?.hotelLocation?.hotelAddress}
                </Typography>
              </Grid>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} md={4} sx={{ pt: 1, pl: 4 }}>
              <Grid container direction="row">
                <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                  Guest:
                </Typography>
                <Typography
                  variant="h6"
                  className="text-capitalize"
                  gutterBottom
                >
                  {data?.prefix}.&nbsp;{data?.firstName}&nbsp;{data?.lastName}
                </Typography>
              </Grid>
              <Grid container direction="row">
                <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                  Booking Date:
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {moment(data?.bookingDate).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} sx={{ pt: 1, pl: 4 }}>
              <Grid container direction="row">
                <Typography sx={{ pr: 1, pl: 2 }} variant="h6" gutterBottom>
                  Tags:
                </Typography>
              </Grid>

              <Grid direction={"row"} container sx={{ pl: 3 }}>
                <Tag color="#1AB64F">
                  <FontAwesomeIcon icon={faStar} />
                </Tag>
                <Tag className="rounded-4 bg-transparent">
                  {" "}
                  {data?.hotelId?.basicDetails?.coupleFriendlyHotel ? (
                    <div>
                      {" "}
                      <img
                        style={{ height: "18px", width: "18px" }}
                        src={couple}
                        alt=""
                      />{" "}
                      Couple Friendly
                    </div>
                  ) : (
                    ""
                  )}
                </Tag>
                <Tag className="rounded-4 bg-transparent">
                  {" "}
                  {data?.hotelId?.basicDetails?.sanitizedStay ? (
                    <div>
                      {" "}
                      <img
                        style={{ height: "18px", width: "18px" }}
                        src="https://img.icons8.com/ios-filled/50/000000/soap-dispenser.png"
                        alt=""
                      />{" "}
                      Sanitized-Stay
                    </div>
                  ) : (
                    " "
                  )}
                </Tag>
              </Grid>
            </Grid>
          </Grid>
        </TabPane>
        <TabPane tab="Room" key="room">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} sx={{ pt: 1, pl: 4 }}>
              <Grid container direction="row">
                <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                  Room Category:
                </Typography>
                <Typography
                  variant="h6"
                  className="text-capitalize"
                  gutterBottom
                >
                  {data?.roomCategory}
                </Typography>
              </Grid>
              <Grid container direction="row">
                <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                  Room Type:
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {data?.roomType}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </TabPane>
        <TabPane tab="Billing" key="billing">
          <Typography sx={{ p: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} sx={{ pt: 1, pl: 4 }}>
                <Grid container direction="row">
                  <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                    Room Price:
                  </Typography>
                  <Typography
                    variant="h6"
                    className="text-capitalize"
                    gutterBottom
                  >
                    Rs {data?.roomPrice}
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                    Discount:
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Rs {data?.discount}
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                    GST:
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Rs {data?.gst}
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Typography variant="h6" sx={{ pr: 1, pl: 2 }}>
                    Payable Amount:
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Rs {data?.payableAmount}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Typography>
        </TabPane>
        <TabPane tab="Policies" key="policies">
          <Typography sx={{ p: 2 }}>
            {data?.hotelId?.termsAndConditions.map((term, index) => (
              <ul key={index}>
                <li>{term}</li>
              </ul>
            ))}
          </Typography>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default HotelBookingDetails;
