const MINIMUM_LENGTH_HEADERS = [
  {
    dataIndex: "col1",
    title: "S.N",
    nummeric: false,
    disablePadding: true,
    label: "S.N",
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 30,
  },
  {
    dataIndex: "col2",
    title: "Market",
    nummeric: false,
    disablePadding: true,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 120,
  },
  {
    dataIndex: "col3",
    title: "Minimum Length Stay",
    nummeric: false,
    disablePadding: true,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 120,
  },
  {
    dataIndex: "col4",
    title: "Status",
    nummeric: false,
    disablePadding: true,
    sorter: {
      compare: (a, b) => a.math - b.math,
      multiple: 2,
    },
    width: 120,
  },
  {
    dataIndex: "col5",
    title: "Actions",
    nummeric: false,
    disablePadding: true,
    label: "Category",
    filter: "select",
    width: 30,
  },
];

export default MINIMUM_LENGTH_HEADERS;
