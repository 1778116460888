const STOP_SALE = [
    {
      dataIndex: "col1",
      title: "S.N",
      nummeric: false,
      disablePadding: true,
      label: "S.N",
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 40,
    },
    {
      dataIndex: "col2",
      title: "Market",
      nummeric: false,
      disablePadding: true,
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 80,
    },
    {
      dataIndex: "col3",
      title: "Room Category",
      nummeric: false,
      disablePadding: true,
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 100,
    },
    {
      dataIndex: "col4",
      title: "Stop Sale",
      nummeric: false,
      disablePadding: true,
      sorter: {
        compare: (a, b) => a.math - b.math,
        multiple: 2,
      },
      width: 100,
    },
    {
      dataIndex: "col5",
      title: "Status",
      nummeric: false,
      disablePadding: true,
      label: "Category",
      filter: "select",
      width: 50,
    },
    {
        dataIndex: "col6",
        title: "Actions",
        nummeric: false,
        disablePadding: true,
        label: "Category",
        filter: "select",
        width: 50,
      },
  ];
  
  export default STOP_SALE;
  
  