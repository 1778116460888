/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-prototype-builtins */
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import BottomFooter from "../../newUserLayout/userFooter/BottomFooter";
import UserSideBarModel from "./../../newUserLayout/userSidebarModel/index";
import { Col, Row, Grid } from "antd";
import UserSideBar from "../../newUserLayout/userSideBar";
import ShikaraCard from "./../../../../components/atoms/userAtoms/shikaraCard/index";
import { UserShikaraGetAll } from "../../../../services/utils/userUtils/shikara";
// import UserModel from '../../../../components/molecules/modalComponent/UserModel'
import { useNavigate } from "react-router-dom";
import {
  // checkboxFilter,
  filterSearchfun,
} from "../../../../services/utils/userUtils/dynamicFun";
import { Helmet } from "react-helmet";
// import OtherFunctionsObject from "../../../../services/utils/userUtils/dynamicFun/functionlist";
import { userShikaraBookNowModelState } from "../../../../constants/states";
import { UserLoader } from "../../../../components/atoms/userLoader";
import ShikaraConfermModal from "../../../../components/molecules/modalComponent/ShikaraConfermModal";
import ShikaraSeo from "../../../../constants/seo/shikaraSeo";
const { useBreakpoint } = Grid;
const objectStructure = [
  ["shikaraName"],
  ["state", "stateName"],
  ["place", "destinationName"],
];

function UserShikaraOtherPage() {
  const nav = useNavigate();
  const [getData, setGetData] = useState([]);
  const [filterData, setfilterData] = useState([]);
  const [modelOpen, setmodelOpen] = useState(false);
  const [searchData, setsearchData] = useState("");
  const [ratingsequence, setratingsequence] = useState(0);
  const [categoryData, setcategoryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState({ status: false, data: {} });
  const { lg } = useBreakpoint();
  const [loading, setLoading] = useState(false);

  const [modelData, setModelData] = useState(userShikaraBookNowModelState);
  // const { fileterCategory, filterBySort } = OtherFunctionsObject;
  const getAllApi = async () => {
    setLoading(true);
    const res = await UserShikaraGetAll();
    setGetData(res.data);
    setfilterData(res.data);
    setLoading(false);
  };
  const filterFun = () => {
    // const afterCategory = fileterCategory(getData, categoryData);
    // const afterSorting = filterBySort(afterCategory, ratingsequence);
    // setfilterData(afterSorting);
    setmodelOpen(false);
  };
  const [guest, setGuest] = useState({
    // rooms: '1',
    adults: "1",
    children: "0",
  });

  //search on Change funtion for filter

  const searchOnChange = ({ target: { value: sval } }) => {
    setsearchData(sval);
    const filldata = filterSearchfun(sval, getData, objectStructure);
    setfilterData(filldata);
  };

  const categoryfun = ({ target: { checked } }, v) => {
    if (checked) {
      setcategoryData((prevState) => {
        return [...prevState, v];
      });
    } else if (!checked) {
      setcategoryData((prevState) => {
        let tempData = [...prevState];
        let newData = tempData.filter(
          (data) => data?.roomCategoryName !== v?.roomCategoryName
        );
        
        return [...newData];
      });
    }
  };
  const handleOk = () => {
    if (isModalOpen?.data) {
      setModelData({
        ...modelData,
        shikaraID: isModalOpen.data?._id,
        goingTo: isModalOpen.data.place?.destinationName,
      });
      setIsModalOpen({ ...isModalOpen, status: false });
      nav("/shikara/usershikarabooknow", {
        state: {
          data: isModalOpen.data,
          guest: { guest: guest },
          travel: { travelDate: modelData.checkIn },
        },
      });
      window.scrollTo(0, 0);
    }
  };
  const handleCancel = () => {
    setIsModalOpen({ ...isModalOpen, status: false });
  };
  const handleCheckIn = (e, s) => {
    setModelData({ ...modelData, ckeckIn: s });
  };
  const sideBar = (
    <UserSideBar
      title={"Shikara"}
      filterFunction={filterFun}
      searchValue={searchData}
      searchOnChange={searchOnChange}
      category={{
        title: "Category's",
        categoryName: "roomCategoryName",
        categoryData: [
          { roomCategoryName: "Category 1" },
          { roomCategoryName: "Category 2" },
          { roomCategoryName: "Category 3" },
        ],
      }}
      categoryfun={(e, v) => {
        categoryfun(e, v);
      }}
      rtingHighLow={{
        title: "Select Shikara Rate",
        value: ratingsequence,
        onChange: (e) => {
          setratingsequence(e.target.value);
        },
      }}
    />
  );

  useEffect(() => {
    getAllApi();
  }, []);

  useEffect(() => {
    filterShikaraList();
  }, [ratingsequence, categoryData]);

  const filterShikaraList = () => {
    let filteredData = getData;
    const categories = categoryData.map(
      (category) => category.roomCategoryName
    );

    if (ratingsequence) {
      if (ratingsequence === 1) {
        filteredData = filteredData.slice().sort((a, b) => {
          return parseInt(a?.contractId?.rate) - parseInt(b?.contractId?.rate);
        });
      } else if (ratingsequence === -1) {
        filteredData = filteredData.slice().sort((a, b) => {
          return parseInt(b?.contractId?.rate) - parseInt(a?.contractId?.rate);
        });
      }
    }

    if (categories.length) {
      filteredData = filteredData.filter((item) =>
        categories.includes(`Category ${item?.category}`)
      );
    }

    setfilterData(filteredData);
  };

  return (
    <>
      {loading ? <UserLoader /> : ""}
      <Row justify={"center"} style={{ minHeight: "100vh" }}>
        {lg ? (
          <Col xs={22} sm={22} md={22} lg={5} xl={5}>
            {sideBar}
          </Col>
        ) : (
          ""
        )}

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={17}
          xl={17}
          style={{ paddingBottom: "60px" }}
        >
          <Helmet>
            <title>{ShikaraSeo[0].title}</title>
            <meta name="description" content={ShikaraSeo[0].description} />
          </Helmet>

          <Row justify={"center"}>
            <Col span={24}>
              {filterData.map((item, i) => {
                return (
                  <ShikaraCard
                    key={i}
                    data={item}
                    setIsModalOpen={setIsModalOpen}
                  />
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* _____________________footer_______________________ */}
      <BottomFooter>

        <div onClick={() => setmodelOpen(modelOpen ? false : true)}>
          <FontAwesomeIcon
            className="footericon"
            color="#000"
            style={{ fontSize: "1rem" }}
            icon={faFilter}
          />
          <p>Filters</p>
        </div>
      </BottomFooter>
      <UserSideBarModel
        isModalOpen={modelOpen}
        onCancel={() => {
          setmodelOpen(false);
        }}
      >
        {sideBar}
      </UserSideBarModel>
      <ShikaraConfermModal
        open={isModalOpen.status}
        handleok={handleOk}
        handlecancel={handleCancel}
        locationValue={
          isModalOpen.data && isModalOpen.data.place?.destinationName
        }
        checkIn={modelData.ckeckIn}
        handleCheckIn={handleCheckIn}
        modelData={modelData}
        setModelData={setModelData}
        guest={guest}
        setGuest={setGuest}
        datepicker={true}
      />
      {/* ____________________________________________ */}
    </>
  );
}

export default UserShikaraOtherPage;
