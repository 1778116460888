import FullCalendar from "@fullcalendar/react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import React from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Col, Row } from "antd";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
function CalendarComponent() {
  return (
    <>
    <Row className="page-back">
    <p onClick={"backBtnFun"} style={{ cursor: "pointer" }}>
      <KeyboardDoubleArrowLeftIcon style={{ fontSize: "1.2rem" }} />
      <span style={{ paddingTop: "2px", cursor: "pointer" }}>Back</span>
    </p>
  </Row>
      <Row>
        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            height={700}
            contentHeight={700}
          />
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Row style={{ paddingLeft: "5%" }}>
            <div style={{ width: "90%" }}>
              <p style={{ color: "#4383b4", fontSize: "1.4rem" }}>
                Todays events
              </p>
              <hr style={{ color: "#4383b4" }} />
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CalendarComponent;




