import serviceUtil from "../..";

const GoogleAuthLogin = (payload) => {
    return serviceUtil
        .post("authLogin/googleLogin",payload)
        .then((res) => {
            const data = res && res.data;
            return { data };
        })
        .catch((err) => {
            const errRes = err && err.response.data;
            return { errRes };
        });
};
export {GoogleAuthLogin}