import AddIcon from "@mui/icons-material/Add";
import BtnComp from "../../../components/atoms/buttonComponent/BtnComp";
import CONSTANTS from "../../../constants";
import HotelOccupancyModal from "../../../components/forms/parnter/occupancyModals/HotelOccupancyModal";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import MinimumLengthModal from "../../../components/forms/parnter/occupancyModals/MinimumLengthModal";
import React, { useEffect, useState } from "react";
import TableComponent from "../../../components/molecules/tableComponent";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material/styles";
import { Col, Input, Row, Select } from "antd";
import { NotificationBox } from "../../../components/atoms/notificationBox/NotificationBox";
import { DeleteModel } from "../../../components/molecules/modalComponent/DeleteModel";

import {
  basicSettingsDesignationDelete,
  basicSettingsDesignationGetAll,
} from "../../../services/utils/admin/manageMaster/basicsettings/Designation";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function OccupancyComponent() {
  const [modalOpen, setModalOpen] = useState(false);
  const [contextHolder, openNotificationSuccess] = NotificationBox();
  const [deleteModal, setDeleteModal] = useState(false);
  const [defaultFormData, setDefaultformData] = useState({
    designation: "",
  });

  const [modalValue, setModalValue] = useState("add");
  const [rows, setRows] = useState([]);

  const [tableId, setTableId] = useState();
  const [value, setValue] = useState("");
  const [tableHeader, setTableHeader] = useState("Hotel Occupancy");

  useEffect(() => {
    getTableData();
  }, []);
  const CreateBtnFun = () => {
    setModalValue("add");
    setModalOpen(true);
  };

  const pageOption = [
    {
      value: "5",
      label: "5",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "25",
      label: "25",
    },
    {
      value: "50",
      label: "50",
    },
    {
      value: "100",
      label: "100",
    },
  ];

  const backBtnFun = () => {
    setModalOpen(false);
  };

  const getTableData = async () => {
    const { data } = await basicSettingsDesignationGetAll();
    if (data) {
      let arrayOfRows = [];
      data &&
        data.response.map((item, index) => {
          arrayOfRows.push({
            col1: index + 1,
            col2: item.designation,
            col3: (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <BootstrapTooltip
                  className="tooltipSize"
                  title="Edit"
                  placement="top"
                >
                  <FontAwesomeIcon
                    icon={faPencil}
                    color="#69aa46"
                    fontSize="20px"
                    style={{ marginRight: "6%" }}
                    onClick={handleEditClick}
                  />
                </BootstrapTooltip>
                <BootstrapTooltip title="Delete" placement="top">
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    color="#dd5a43"
                    fontSize="20px"
                    onClick={() => {
                      setDeleteModal(true);
                      setTableId(item._id);
                    }}
                  />
                </BootstrapTooltip>
              </div>
            ),
          });
        });
      setRows(arrayOfRows);
    }
  };

  const filterData = () => {
    if (!value) return rows;
    return rows.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLocaleLowerCase().includes(value.toLocaleLowerCase())
      )
    );
  };
  const filteredRows = filterData();

  useEffect(() => {
    filterData();
  }, [value]);

  const handleEditClick = (data) => {
    setModalValue("edit");
    setModalOpen(true);
    setDefaultformData(data);
  };

  const onCloseDelModal = () => {
    setDeleteModal(false);
  };

  const deleteTableData = async () => {
    await basicSettingsDesignationDelete(tableId);
    setDeleteModal(false);
    getTableData();
    openNotificationSuccess("Designation Deleted Successfully");
  };

  return (
    <>
      {contextHolder}
      <Row className="page-back">
        <p onClick={backBtnFun} style={{ cursor: "pointer" }}>
          <KeyboardDoubleArrowLeftIcon style={{ fontSize: "1.2rem" }} />
          <span style={{ paddingTop: "2px", cursor: "pointer" }}>Back</span>
        </p>
      </Row>
      <Row style={{ backgroundColor: "#f0f0f0" }}>
        <p
          style={{
            color: "#2679b5",
            margin: "0 8px",
            fontSize: "30px",
            fontWeight: 300,
            paddingTop: "7px",
            paddingBottom: "16px",
          }}
        >
          Occupancy
        </p>
      </Row>
      <BtnComp
        btnName="Hotel Occupancy"
        width="150px"
        color="#999"
        style={{ marginBottom: "15px" }}
        btnClickFun={() => {
          setTableHeader("Hotel Occupancy");
        }}
      />
      <BtnComp
        btnName="Minimum Length"
        width="150px"
        color="#999"
        btnClickFun={() => {
          setTableHeader("Minimum Length");
        }}
      />
      {tableHeader === "Hotel Occupancy" ? (
        <>
          <Row className="table-header">
            <Col flex="auto"> List of Occupancies</Col>
            <Col flex="40px">
              <div onClick={CreateBtnFun} className="table-create-btn">
                Create <AddIcon style={{ fontSize: "18px" }} />
              </div>
            </Col>
          </Row>
          <Row className="dataTableHead">
            <Col span={12}>
              <Row style={{ padding: "0px 12px" }}>
                <Col className="header-select-child"> Display</Col>
                <Col className="header-select-child">
                  <Select
                    defaultValue="10"
                    className="header-select"
                    style={{ marginTop: "-5px", backgroundColor: "#fff" }}
                    bordered={false}
                    options={pageOption}
                  />
                </Col>
                <Col className="header-select-child"> records</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className="header-serch" style={{ float: "right" }}>
                <Col style={{ padding: "5px" }}>Search:</Col>
                <Col>
                  {" "}
                  <Input
                    onChange={(e) => setValue(e.target.value)}
                    placeholder=""
                    value={value}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <TableComponent
            data={filteredRows}
            columns={CONSTANTS.HOTEL_OCCUPANCY_HEADERS}
          />
          {modalOpen ? (
            <HotelOccupancyModal
              setModalOpen={setModalOpen}
              modalValue={modalValue}
              defaultFormData={defaultFormData}
              getTableData={getTableData}
            />
          ) : (
            ""
          )}
          {deleteModal ? (
            <div>
              <DeleteModel
                deleteModal={deleteModal}
                getTableData={getTableData}
                onCloseDelModal={onCloseDelModal}
                deleteTableData={deleteTableData}
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          <Row className="table-header">
            <Col flex="auto"> List of Minimum Length Stay</Col>
            <Col flex="40px">
              <div onClick={CreateBtnFun} className="table-create-btn">
                Create <AddIcon style={{ fontSize: "18px" }} />
              </div>
            </Col>
          </Row>
          <Row className="dataTableHead">
            <Col span={12}>
              <Row style={{ padding: "0px 12px" }}>
                <Col className="header-select-child"> Display</Col>
                <Col className="header-select-child">
                  <Select
                    defaultValue="10"
                    className="header-select"
                    style={{ marginTop: "-5px", backgroundColor: "#fff" }}
                    bordered={false}
                    options={pageOption}
                  />
                </Col>
                <Col className="header-select-child"> records</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row className="header-serch" style={{ float: "right" }}>
                <Col style={{ padding: "5px" }}>Search:</Col>
                <Col>
                  {" "}
                  <Input
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Search"
                    value={value}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <TableComponent
            data={filteredRows}
            columns={CONSTANTS.MINIMUM_LENGTH_HEADERS}
          />
          {modalOpen ? (
            <MinimumLengthModal
              setModalOpen={setModalOpen}
              modalValue={modalValue}
              defaultFormData={defaultFormData}
              getTableData={getTableData}
            />
          ) : (
            ""
          )}
          {deleteModal ? (
            <div>
              <DeleteModel
                deleteModal={deleteModal}
                getTableData={getTableData}
                onCloseDelModal={onCloseDelModal}
                deleteTableData={deleteTableData}
              />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

export default OccupancyComponent;
