/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    Col,
    Row,
    Collapse
  } from "antd";
  import "slick-carousel/slick/slick.css";
  import "slick-carousel/slick/slick-theme.css";
  import React from "react";

function ViewAll({ data, viewAllType }) {
  
    const Panel = Collapse.Panel;

    return (
        <>
        {viewAllType === "all" && (
            <>
              <Row justify={"center"} gutter={[20, 20]}>
                {data[0]?.profilePic?.length > 0 &&
                  data[0]?.profilePic?.map((val, id) => {
                    return (
                      <Col span={21} key={id}>
                        <img className="imgboxi" src={val} alt="" />
                      </Col>
                    );
                  })}
              </Row>
              <Row
                justify={"center"}
                gutter={[20, 20]}
                style={{ marginTop: "20px" }}
              >
                {data[0]?.hotelRoomCategory?.roomImages?.length > 0 &&
                  data[0]?.hotelRoomCategory?.map((val, id) => {
                    return (
                      <>
                        <Col span={21} key={id}>
                          <img
                            className="imgboxi"
                            src={val?.roomImages}
                            alt=""
                          />
                        </Col>
                      </>
                    );
                  })}
              </Row>
            </>
          )}

          {viewAllType === "rooms" && (
            <>
              <Collapse defaultActiveKey={["0"]}>
                {data[0]?.hotelRoomCategory?.map((val, id) => {
                  return (
                    <Panel
                      header={val?.roomCategory?.roomCategory}
                      style={{ fontWeight: "bold" }}
                      key={id}
                    >
                      {val?.roomImages?.length > 0 ? (
                        val?.roomImages?.map((url, id) => {
                          return (
                            <Col
                              key={id}
                              style={{ marginTop: "10px", width: "90%" }}
                            >
                              <img className="imgboxi" src={url} alt="" />
                            </Col>
                          );
                        })
                      ) : (
                        <Col style={{ marginTop: "10px", width: "90%" }}>
                          <p>Image not available.</p>
                        </Col>
                      )}
                    </Panel>
                  );
                })}
              </Collapse>
            </>
          )}

          {viewAllType === "nearbyattraction" && (
            <Row justify={"center"} gutter={[20, 20]}>
              {data[0]?.nearByAttractions?.map((val, id) => {
                return (
                  <Col span={21} key={id}>
                    <img className="imgboxi" src={val} alt="" />
                  </Col>
                );
              })}
            </Row>
          )}
          </>
    );
}

export default ViewAll;
