const HouseboatSeo = [
  {
    title: "Top 5 Alleppey Boat House Alappuzha - Godsownstay.com",
    description:
      "Top 5 Alleppey Boat House Alappuzha Starting From @ 3499.We offers complimentery Breakfast,Welcome Drink,Lunch ,Tea and Snack and Dinner .",
  },
  {
    title: "Alleppey Houseboat Charges @ 3399",
    description:
      "Find out the best  Alleppey Houseboat Charges t from  Punnamada Alleppey Boat House alappuzha kerala .We offers multiple type off hoseboat ,we also providing Custom booking for customer requirement",
  },
  {
    title: "Best Houseboat at Alappuzha - Godsownstay.com",
    description:
      "Choose Houseboat at Alappuzha House From Godsownstay.com and Book and Get  Coupon code : WEEKLYSPECIAL26 and get 20% discount on Any Houseboat .",
  },
  {
    title: "Top 10 Houseboat OF Kerala - Complimentary Foods",
    description:
      "Book one of the Best Houseboat of  kerala,We offers WIFI, complimentary Breakfast,Welcome Drinks.Lunch and Tean and Snack & Dinner",
  },
  {
    title: "Top 5 Huse Boating In Kerala Starting From @ 4500",
    description:
      "Choose  from 35 Luxury Houseboats in Kumarakom with prices starting as low as Rs.2999 and get Upto 45% OFF  on all Kumarakom Houseboats .we provide WIFI, AC ,Complimentary BF,LUNCH, DINNER ,WELCOME DRINK",
  },
  {
    title: "Top 10 Kerala Alleppey Houseboat Rates @ 6999",
    description:
      "Find out  Best Kerala Alleppey Boat House Rates From Godsownstay.com and Book and Get  Coupon code : WEEKLYSPECIAL26 and get 20% discount on Any Houseboat .",
  },
  {
    title: "Kerala Alleppey Houseboat Booking - Upto 75% OFF on Houseboat",
    description:
      " Choose Kerala Alleppey Houseboat Booking Rates  starting @ 3999.Find out Best Houseboat From Godsownstay.com and get   Upto 45% OFF on Alleppey Houseboat . Free Wifi Accomadation and Food.",
  },
  {
    title: "Houseboat In Kumarakon -Book Now",
    description:
      "Our Luxuries Houseboat In Kumarakom starting From Budeget Friendly Rates, We offers One of the best Kumarakom Houseboat From Kumarakom Boat Jetty.Starting Range of @ 4999",
  },
  {
    title: "Kerala premium Houseboat 21 Houseboats",
    description:
      "Find Out Kerala Premium Houseboat 21 Houseboats.We  offering luxury Houseboat accommodation in Alleppey and Kumarakom backwaters.",
  },
  {
    title: " Best Kumarakom House Boat Booking | Book Now @ 2499",
    description:
      "Choose  Kumarakom House Boat Booking From Godsownstay.com.Offers wide range of Houseboat Starting from Rs 1999 . We offers Complimentory Breakfast - Lunch -wecome Drink and Tea & Snanks",
  },
];

export default HouseboatSeo;
