import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { messageService } from "../../../services/rxjsServices/index";

const TableComponent = ({ data = [], columns = [], numberOfItem }) => {
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(data);

  useEffect(() => {
    requestSearch(searchText);
  }, [searchText]);

  useEffect(() => {
    const subscription = messageService.getMessage().subscribe((val) => {
      setSearchText(val);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);
  const requestSearch = (searchVal) => {
    if (searchVal) {
      const filterData = data.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchVal.toLowerCase());
      });
      setRows(filterData);
    } else {
      setRows(data);
    }
  };
  return (
    <Table
      rows={rows}
      id="table-to-xls"
      columns={columns}
      showSorterTooltip="false"
      dataSource={data}
      pagination={{
        pageSize: numberOfItem,
      }}
      scroll={{
        y: 240,
        x: 900,
      }}
      locale={{
        cancelSort: "cancel sort text",
      }}
    />
  );
};

export default TableComponent;
