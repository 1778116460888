import React, { useRef, useEffect } from "react";
import { Row, Col } from "antd";
import Slider from "react-slick";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper } from "@mui/material";

const settings = {
  // dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1200,
  arrows: false,
  responsive: [
    {
      breakpoint: 840, // Mobile breakpoint
      settings: {
        slidesToShow: 2, // Show one slide on mobile
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500, // Mobile breakpoint
      settings: {
        slidesToShow: 1, // Show one slide on mobile
        slidesToScroll: 1,
      },
    },
  ],
};

const config = {
  rootMargin: "0px 0px 0px 0px",
  thresold: 0,
};

const OfferCardsHomePage = ({ offerData, copiedCode, setcopiedCode }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    let observer = new window.IntersectionObserver(function (entries, self) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImages(entry.target);
          self.unobserve(entry.target);
        }
      });
    }, config);

    const imgs = document.querySelectorAll("[data-src]");
    imgs.forEach((img) => {
      observer.observe(img);
    });
    return () => {
      imgs.forEach((img) => {
        observer.observe(img);
      });
    };
  }, []);

  function loadImages(image) {
    image.src = image.dataset.src;
  }

  const copyCode = async (code) => {
    await navigator.clipboard.writeText(code);
    setcopiedCode(code);

    clearCodeDisplay();

    let myInterval = setInterval(function () {
      setcopiedCode("");
      clearCodeDisplay();
    }, 3000);

    localStorage.setItem("copyCodeIntervalId", myInterval);

  };

  const clearCodeDisplay = () => {
    var intervalId = localStorage.getItem("copyCodeIntervalId");

    if (intervalId && parseInt(intervalId) >= 0){
      intervalId = parseInt(intervalId);
      clearInterval(intervalId);
    }
  };

  return (
    <>
      <Row gutter={20}>
        <div
          style={{ paddingTop: "20px", paddingBottom: "4%" }}
          className="divScrollRow"
        >
          <Slider
            {...settings}
            ref={sliderRef}
            style={{ width: "100%", position: "relative" }}
          >
            {offerData?.map((v, i) => {
              return (
                <Col key={i} span={24} className="my-2">
                  <Paper elevation={3}>
                    <Row style={{ width: "100%" }}>
                      <Row style={{ display: "flex" }}>
                        <Col span={7}>
                          <img
                            // src={v?.couponImage[0]}
                            src={`${process.env.REACT_APP_S3_BUCKET_URL}/images/coupon/${v?.couponImage[0]}`}
                            // data-src={v?.couponImage[0]}
                            alt="coupon"
                            className="couponCardstyle"
                            loading="lazy"
                          ></img>
                        </Col>
                        <Col span={17} style={{ display: "block" }}>
                          <div
                            className="cardHeading"
                            style={{
                              fontSize: "1.2rem",
                              padding: "10px 0px 0px 12px",
                              fontWeight: "500",
                            }}
                          >
                            Get flat {v && v?.discountValue}% Off
                          </div>
                          <div
                            className="cardText"
                            style={{
                              fontSize: "0.9rem",
                              padding: "0px 12px 0px 12px",
                              opacity: "0.5",
                              height: "50px",
                              overflow: "auto",
                            }}
                          >
                            {v && v?.couponDescription}
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ width: "100%" }}>
                        <Col
                          span={24}
                          style={{
                            fontSize: "1.3vw !important",
                            padding: "0px 0px 0px 5px",
                            fontWeight: 500,
                          }}
                          className="cardCodeText"
                        >
                          <span style={{ fontSize: "1.3vw !important" }}>
                            Copy Code :
                          </span>
                          <span
                            className="cardCode"
                            style={{
                              color: "#1674DE",
                              fontSize: "1.5vw !important",
                              fontWeight: "600",
                              paddingLeft: "1%",
                            }}
                          >
                            {/* GOS1210  */}
                            {v && v?.couponCode}
                            &nbsp;{" "}
                            <FontAwesomeIcon
                              onClick={() => copyCode(v?.couponCode)}
                              className="dis-icon-copy copyButton"
                              icon={faCopy}
                            />
                          </span>
                          {v?.couponCode === copiedCode ? <span className="ms-2 text-success">Copied</span> : <></>}
                        </Col>
                      </Row>
                    </Row>
                  </Paper>
                </Col>
              );
            })}
          </Slider>
        </div>
      </Row>
    </>
  );
};

export default OfferCardsHomePage;
