import { CaretDownOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Select } from "antd";
import { useDispatch } from "react-redux";
import { setSearchCardFocus } from "../../../reduxData/sliceData/searchCardReducer";

function SelectComponent({
  label = "select",
  value = "",
  star = "block",
  name,
  dropdownRender,
  onSearch,
  filterOption,
  handleChange,
  notFoundContent,
  errors,
  touched,
  faIcon,
  iconFun,
  mode,
  placeholder,
  labelfontweight = "600",
  errorfontweight = "400",
  allowClear,
  className = "slect-smyroom",
  suffixIcon = <CaretDownOutlined />,
  options = [
    {
      value: "",
      label: "SELECT",
    },
  ],
  style,  
  allowSearchCard,
  // defaultValue = "SELECT",
  // optionRender
}) {
  const dispatch = useDispatch();

  const isMobile = window.innerWidth <= 900;

  const handleFocus = () => {
    isMobile && allowSearchCard && dispatch(setSearchCardFocus(true));
  };

  return (
    <div>
      <div className="re-inp-box">
        <Row style={{ fontWeight: labelfontweight }}>
          {name == "prefix" ? (
            ""
          ) : (
            <p style={{ display: `${star}` }} className="re-input-p">
              *
            </p>
          )}

          {label}
          {faIcon && (
            <FontAwesomeIcon
              style={{ color: "#1c72b9", height: "8pt" }}
              className="px-2 pt-1"
              onClick={iconFun}
              icon={faIcon}
            />
          )}
        </Row>
        <Row>
          <Select
            onFocus={handleFocus}
            placeholder={placeholder}
            allowClear={allowClear}
            tokenSeparators={[","]}
            className={className}
            filterOption={filterOption}
            dropdownRender={dropdownRender}
            notFoundContent={notFoundContent}
            onSearch={onSearch}
            style={{
              border: `${errors
                  ? errors[name] && touched[name]
                    ? "2px solid #d16e6c"
                    : ""
                  : null
                }`,
              color: "#858585",
              width: "100%",
              ...style,
            }}
            dropdownStyle={{ borderRadius: "1px" }}
            // defaultValue={defaultValue}
            onChange={handleChange}
            options={options}
            // optionRender={optionRender}
            suffixIcon={suffixIcon}
            showSearch={true}
            dropdownMatchSelectWidth={true}
            // placeholder={placeholder}
            value={value === "" ? undefined : value}
            mode={mode}

          //  dropdownAlign={{ offset: [0, 0] }}
          />
          {errors ? (
            errors[name] && touched[name] ? (
              <p
                className="re-err fs-10"
                style={{ fontWeight: errorfontweight }}
              >
                {errors[name]}
              </p>
            ) : null
          ) : null}
        </Row>
      </div>
    </div>
  );
}

export default SelectComponent;
