import { Tabs } from 'antd';
import TabOne from './TabOne';
import TabThree from './TabThree';
import TabTwo from './TabTwo';
const MainTabs = () => {
    return (
        <div className="">
            <Tabs
                defaultActiveKey="1"
                type="card"
               items={[
                {
                    label:"Hotel Availability",
                    key: 1,
                    children: <TabOne/>,
                },
                {
                    label: "Block CheckIn Checkouts",
                    key: 2,
                    children: <TabTwo/>,
                },
                {
                    label: "Stop Sale",
                    key: 3,
                    children: <TabThree/>,
                },
               ]}
                
            />
        </div>
    );
};
export default MainTabs;