import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Flex, Button, Row } from "antd";
import couple from "../../../../assets/couple.png";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import moment from "moment";
import Grid from "@mui/material/Grid";
// import { useNavigate } from "react-router-dom";

const styles = {
  card: {
    display: "flex",
    // maxWidth: 1000,
    width: "100%",
    margin: "auto",
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  content: {
    padding: 16,
  },
};

const HotelBookingCard = ({ data, mobile }) => {
  const navigateToDetailPage = () => {
    window.location.href = `${process.env.REACT_APP_FRONTEND_BASE_URL}hotel/booking/details/${data._id}`;
  };
  return (
    <>
      <Card
        style={
          mobile
            ? { ...styles.card }
            : {
                ...styles.card,
                height: "275px",
                position: "relative",
              }
        }
      >
        <CardActionArea>
          <Grid container>
            <Grid item xs={12} md={4}>
              <CardMedia
                component="img"
                alt="hotel"
                image={data?.Hoteldetail[0]?.profilePic[3]}
                style={
                  mobile
                    ? { ...styles.media, height: "212px" }
                    : { ...styles.media, height: "275px" }
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <CardContent style={{ ...styles.content }}>
                <Typography
                  variant="h4"
                  component="div"
                  style={
                    mobile ? { fontSize: "1.5rem" } : { fontSize: "1.5rem" }
                  }
                >
                  {data?.Hoteldetail[0]?.basicDetails?.hotelName}
                  <span style={{ fontSize: "1.1rem" }}>
                    ({data?.destinations?.destinationName})
                  </span>
                </Typography>
                {mobile ? (
                  <Typography style={{ display: "flex", gap: "10px" }}>
                    {data?.Hoteldetail[0]?.basicDetails?.coupleFriendlyHotel ? (
                      <div
                        style={{
                          display: "flex",
                          border: "1px solid lightgray",
                          paddingLeft: "9px",
                          paddingTop: "3px",
                          fontSize: "14px",
                          borderRadius: "16px",
                          width: "119px",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            height: "19px",
                            width: "17px",
                            marginTop: "-3px",
                          }}
                          src={couple}
                          alt=""
                        />
                        &nbsp;{" "}
                        <span className="address" style={{ fontSize: "10px" }}>
                          Couple Friendly
                        </span>
                      </div>
                    ) : (
                      " "
                    )}

                    {data?.Hoteldetail[0]?.basicDetails?.coupleFriendlyHotel ? (
                      <div
                        style={{
                          display: "flex",
                          border: "1px solid lightgray",
                          paddingLeft: "9px",
                          paddingTop: "3px",
                          fontSize: "14px",
                          borderRadius: "16px",
                          width: "128px",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            height: "15px",
                            width: "17px",
                          }}
                          src="https://static.thenounproject.com/png/1716003-200.png"
                          alt=""
                        />
                        &nbsp;{" "}
                        <span className="address" style={{ fontSize: "10px" }}>
                          Gos Stay Assured
                        </span>
                      </div>
                    ) : (
                      " "
                    )}
                  </Typography>
                ) : (
                  <Typography style={{ display: "flex", gap: "10px" }}>
                    {data?.Hoteldetail[0]?.basicDetails?.coupleFriendlyHotel ? (
                      <div
                        style={{
                          display: "flex",
                          border: "1px solid lightgray",
                          paddingLeft: "9px",
                          paddingTop: "3px",
                          fontSize: "14px",
                          borderRadius: "16px",
                          width: "142px",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            height: "22px",
                            width: "20px",
                            marginTop: "-3px",
                          }}
                          src={couple}
                          alt=""
                        />
                        &nbsp; <span className="address">Couple Friendly</span>
                      </div>
                    ) : (
                      " "
                    )}

                    {data?.Hoteldetail[0]?.basicDetails?.coupleFriendlyHotel ? (
                      <div
                        style={{
                          display: "flex",
                          border: "1px solid lightgray",
                          paddingLeft: "9px",
                          paddingTop: "3px",
                          fontSize: "14px",
                          borderRadius: "16px",
                          width: "158px",
                        }}
                      >
                        {" "}
                        <img
                          style={{
                            height: "20px",
                            width: "20px",
                            marginTop: "-1px",
                          }}
                          src="https://static.thenounproject.com/png/1716003-200.png"
                          alt=""
                        />
                        &nbsp; <span className="address">Gos Stay Assured</span>
                      </div>
                    ) : (
                      " "
                    )}
                  </Typography>
                )}

                {/* <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{ width: "100%" }}
                >
                  {data?.Hoteldetail[0]?.hotelLocation?.hotelAddress}
                </Typography> */}
                {mobile ? (
                  <>
                    <Flex
                      gap={"large"}
                      className="pt-4"
                      style={mobile ? {} : {}}
                    >
                      <Row>
                        <label
                          htmlFor="bookingDate"
                          className="form-label"
                          style={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          Booking Date:
                          <p>
                            {moment(data?.bookingDate).format("DD/MM/YYYY")}
                          </p>
                        </label>
                      </Row>
                      <Row>
                        <label
                          htmlFor="bookingDate"
                          className="form-label"
                          style={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          CheckIn Date:
                          <p>{moment(data?.checkIn).format("DD/MM/YYYY")}</p>
                        </label>
                      </Row>
                      <Row>
                        <label
                          htmlFor="bookingDate"
                          className="form-label"
                          style={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          CheckOut Date:
                          <p>{moment(data?.checkOut).format("DD/MM/YYYY")}</p>
                        </label>
                      </Row>
                    </Flex>
                    <Flex
                      gap={"large"}
                      className="pt-4"
                      style={mobile ? {} : {}}
                    >
                      <Row>
                        <label
                          htmlFor="amoundPaid"
                          className="form-label"
                          style={{ fontWeight: "600", fontSize: "14px" }}
                        >
                          Amount Paid:
                          <p>
                            <CurrencyRupeeIcon fontSize="small" />
                            {
                              data?.payableAmount // Convert into Indian Rupee Format.
                              // ? Number(data?.payableAmount).toLocaleString('en-IN')
                              // : data?.payableAmount.toLocaleString('en-IN')
                            }
                          </p>
                        </label>
                      </Row>
                      {mobile ? (
                        <Row>
                          <Button
                            type="link"
                            size="large"
                            // onClick={() => navigate(`/bookings/hotel/${data?._id}`)}
                            onClick={navigateToDetailPage}
                            style={{
                              margin: "4%",
                              color: "rgb(255, 255, 255)",
                              backgroundColor: "rgb(225, 45, 112)",
                            }}
                          >
                            View Details
                          </Button>
                        </Row>
                      ) : (
                        ""
                      )}
                    </Flex>
                  </>
                ) : (
                  <Flex gap={"large"} className="pt-4" style={mobile ? {} : {}}>
                    <Row>
                      <label
                        htmlFor="bookingDate"
                        className="form-label"
                        style={
                          mobile
                            ? { fontWeight: "400", fontSize: "10px" }
                            : { fontWeight: "600", fontSize: "15px" }
                        }
                      >
                        Booking Date:
                        <p>{moment(data?.bookingDate).format("DD/MM/YYYY")}</p>
                      </label>
                    </Row>
                    <Row>
                      <label
                        htmlFor="bookingDate"
                        className="form-label"
                        style={
                          mobile
                            ? { fontWeight: "400", fontSize: "10px" }
                            : { fontWeight: "600", fontSize: "15px" }
                        }
                      >
                        CheckIn Date:
                        <p>{moment(data?.checkIn).format("DD/MM/YYYY")}</p>
                      </label>
                    </Row>
                    <Row>
                      <label
                        htmlFor="bookingDate"
                        className="form-label"
                        style={
                          mobile
                            ? { fontWeight: "400", fontSize: "10px" }
                            : { fontWeight: "600", fontSize: "15px" }
                        }
                      >
                        CheckOut Date:
                        <p>{moment(data?.checkOut).format("DD/MM/YYYY")}</p>
                      </label>
                    </Row>
                    <Row>
                      <label
                        htmlFor="amoundPaid"
                        className="form-label"
                        style={
                          mobile
                            ? { fontWeight: "400", fontSize: "10px" }
                            : { fontWeight: "600", fontSize: "15px" }
                        }
                      >
                        Amount Paid:
                        <p>
                          <CurrencyRupeeIcon fontSize="small" />
                          {
                            data?.payableAmount // Convert into Indian Rupee Format.
                            // ? Number(data?.payableAmount).toLocaleString('en-IN')
                            // : data?.payableAmount.toLocaleString('en-IN')
                          }
                        </p>
                      </label>
                    </Row>
                  </Flex>
                )}

                <Flex
                  className="pt-4"
                  justify="flex-end"
                  align="flex-end"
                  style={
                    mobile
                      ? ""
                      : { position: "absolute", bottom: "10px", right: "10px" }
                  }
                >
                  {mobile ? (
                    ""
                  ) : (
                    <Button
                      type="link"
                      size="large"
                      // onClick={() => navigate(`/bookings/hotel/${data?._id}`)}
                      onClick={navigateToDetailPage}
                      style={{
                        margin: "4%",
                        color: "rgb(255, 255, 255)",
                        backgroundColor: "rgb(225, 45, 112)",
                      }}
                    >
                      View Details
                    </Button>
                  )}
                </Flex>
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </>
  );
};

export default HotelBookingCard;
