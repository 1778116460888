// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Drawer, Row} from 'antd'
// import { faClose,

//   } from '@fortawesome/free-solid-svg-icons'



function UserSideBarModel({ children,isModalOpen,onCancel, style, }) {
  

  return (
    <div>
      <Drawer
        placement={'bottom'}
        width={500}
        onClose={onCancel}
        open={isModalOpen}
        height={550}
        closable={true}
        // headerStyle={{ display: 'none' }}
        style={{
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          boxShadow: 'none',
          padding: '0px',
          // height: '100vh',
          overflowY:"scroll",
          ...style,
        }}
        zIndex={1}
      >
        <Row>
          <Col span={24} >
          {/* <div className="mobile_cross">

            <FontAwesomeIcon
              icon={faClose}
              onClick={() => setmodelOpen(false)}
            />
          </div> */}
        {children}
        
          </Col>
        </Row>
        
      </Drawer>
    </div>
  )
}

export default UserSideBarModel
