import BtnComp from "../../../components/atoms/buttonComponent/BtnComp";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import NewTextArea from "../../../components/atoms/textAreaComponent/NewTextArea";
import React from "react";
import SelectComponent from "../../../components/atoms/SelectComponent/SelectComponent";
import { faCalendarDays, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, DatePicker, Row } from "antd";
import NewInputComp from "../../../components/atoms/input/NewInputComp";
import { useNavigate } from "react-router-dom";

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
const SavePolicyDetails = () => {
  const navigate = useNavigate();
  const backBtnFun = () => {
    navigate(-1);
  };
  return (
    <>
      <Row className="page-back">
        <p onClick={backBtnFun} style={{ cursor: "pointer" }}>
          <KeyboardDoubleArrowLeftIcon style={{ fontSize: "1.2rem" }} />
          <span style={{ paddingTop: "2px", cursor: "pointer" }}>Back</span>
        </p>
      </Row>
      <Row>
        <Col span={6}>
          <NewInputComp label="Policy Code" />
        </Col>
        <Col span={6} offset={2}>
          <SelectComponent label="Market Type" star="none" />
        </Col>
        <Col span={6} offset={2}>
          <NewTextArea label="Remarks" />
        </Col>
      </Row>
      <fieldset className="my-5" style={{ border: "1px solid #9d9e9d" }}>
        <legend
          className="mx-5 w-auto"
          style={{
            borderRadius: "4px",
            border: "1px solid #9d9e9d",
            lineHeight: 2,
            backgroundColor: "#fff",
            cursor: "pointer",
            fontSize: "12px",
            color: "#9d9e9d",
            paddingRight: "25px",
            paddingLeft: "15px",
            position: "relative",
            top: "-16px",
          }}
        >
          Validity List
        </legend>
        <Row style={{ marginLeft: "4%", marginRight: "4%" }}>
          <Col span={10}>
            <p>From Date</p>
            <DatePicker
              onChange={""}
              placeholder={""}
              suffixIcon={""}
              style={{ width: "80%" }}
            />
            <span
              style={{
                border: "1px solid #ccc",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "6px",
                paddingBottom: "6px",
                backgroundColor: "#eee",
              }}
            >
              <FontAwesomeIcon icon={faCalendarDays} />
            </span>
          </Col>
          <Col span={10} offset={2} className="mb-5">
            <p>To Date</p>
            <DatePicker
              onChange={""}
              placeholder={""}
              suffixIcon={""}
              style={{ width: "80%" }}
            />
            <span
              style={{
                border: "1px solid #ccc",
                paddingLeft: "10px",
                paddingRight: "10px",
                paddingTop: "6px",
                paddingBottom: "6px",
                backgroundColor: "#eee",
              }}
            >
              <FontAwesomeIcon icon={faCalendarDays} />
            </span>
          </Col>
          <Col span={2}>
            <BtnComp
              width="60px"
              btnName=""
              className="mt-2 px-2"
              startIcon={faPlus}
              pl="5px"
              bg_color="#6fb3e0"
              color="#FFF"
            />
          </Col>
        </Row>
      </fieldset>
      <fieldset className="my-5" style={{ border: "1px solid #9d9e9d" }}>
        <legend
          className="mx-5 w-auto"
          style={{
            borderRadius: "4px",
            border: "1px solid #9d9e9d",
            lineHeight: 2,
            backgroundColor: "#fff",
            cursor: "pointer",
            fontSize: "12px",
            color: "#9d9e9d",
            paddingRight: "25px",
            paddingLeft: "15px",
            position: "relative",
            top: "-16px",
          }}
        >
          Cancellation Policy
        </legend>
        <Row style={{ marginLeft: "4%", marginRight: "4%" }}>
          <Col span={3} className="mt-1 ">
            <p>Cancellation fee of</p>
          </Col>
          <Col span={3} className="mb-5">
            <NewInputComp label="" star="none" />
          </Col>
          <Col span={1} offset={1} className="mt-1">
            <SelectComponent label="" star="none" />
          </Col>
          <Col span={5} offset={1} className="mt-1">
            <p>of the total booking if cancelled less than</p>
          </Col>
          <Col span={3} offset={1}>
            <NewInputComp label="" star="none" />
          </Col>
          <Col span={3} offset={1} className="mt-1">
            <p>hours prior to arrival</p>
          </Col>

          <Col span={1}>
            <BtnComp
              width="60px"
              btnName=""
              className="mt-2 px-2"
              startIcon={faPlus}
              pl="5px"
              bg_color="#6fb3e0"
              color="#FFF"
            />
          </Col>
        </Row>
      </fieldset>
      <fieldset className="my-5" style={{ border: "1px solid #9d9e9d" }}>
        <legend
          className="mx-5 w-auto"
          style={{
            borderRadius: "4px",
            border: "1px solid #9d9e9d",
            lineHeight: 2,
            backgroundColor: "#fff",
            cursor: "pointer",
            fontSize: "12px",
            color: "#9d9e9d",
            paddingRight: "25px",
            paddingLeft: "15px",
            position: "relative",
            top: "-16px",
          }}
        >
          Amendment Policy
        </legend>
        <Row style={{ marginLeft: "4%", marginRight: "4%" }}>
          <Col span={3} className="mt-1 ">
            <p>Amendment fee of</p>
          </Col>
          <Col span={3} className="mb-5">
            <NewInputComp label="" star="none" />
          </Col>
          <Col span={1} offset={1} className="mt-1">
            <SelectComponent label="" star="none" />
          </Col>
          <Col span={5} offset={1} className="mt-1">
            <p>of the total booking if cancelled less than</p>
          </Col>
          <Col span={3} offset={1}>
            <NewInputComp label="" star="none" />
          </Col>
          <Col span={3} offset={1} className="mt-1">
            <p>hours prior to arrival</p>
          </Col>

          <Col span={1}>
            <BtnComp
              width="60px"
              btnName=""
              className="mt-2 px-2"
              startIcon={faPlus}
              pl="5px"
              bg_color="#6fb3e0"
              color="#FFF"
            />
          </Col>
        </Row>
      </fieldset>
      <fieldset className="my-5" style={{ border: "1px solid #9d9e9d" }}>
        <legend
          className="mx-5 w-auto"
          style={{
            borderRadius: "4px",
            border: "1px solid #9d9e9d",
            lineHeight: 2,
            backgroundColor: "#fff",
            cursor: "pointer",
            fontSize: "12px",
            color: "#9d9e9d",
            paddingRight: "25px",
            paddingLeft: "15px",
            position: "relative",
            top: "-16px",
          }}
        >
          Child Policy
        </legend>
        <Row style={{ marginLeft: "4%", marginRight: "4%" }}>
          <Col span={20} className="mb-4">
            <NewInputComp label="" star="none" />
          </Col>
          <Col span={2} offset={2} className="mt-3">
            <BtnComp
              width="60px"
              btnName=""
              className="mt-2 px-2"
              startIcon={faPlus}
              pl="5px"
              bg_color="#6fb3e0"
              color="#FFF"
            />
          </Col>
        </Row>
      </fieldset>
      <fieldset className="my-5" style={{ border: "1px solid #9d9e9d" }}>
        <legend
          className="mx-5 w-auto"
          style={{
            borderRadius: "4px",
            border: "1px solid #9d9e9d",
            lineHeight: 2,
            backgroundColor: "#fff",
            cursor: "pointer",
            fontSize: "12px",
            color: "#9d9e9d",
            paddingRight: "25px",
            paddingLeft: "15px",
            position: "relative",
            top: "-16px",
          }}
        >
          Additional Policies
        </legend>
        <Row style={{ marginLeft: "4%", marginRight: "4%" }}>
          <Col span={24}>
            <Row>
              <Col span={3} className="mt-1 ">
                <p>No-Show: (All seasons) </p>
              </Col>
              <Col span={3} className="mb-5">
                <NewInputComp label="" star="none" />
              </Col>
              <Col span={1} offset={1} className="mt-1">
                <SelectComponent label="" star="none" />
              </Col>
              <Col span={5} offset={1} className="mt-1">
                <p> charge on total booking without exception.</p>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="mt-1 ">
                <p>Early Departure: (All Seasons) :</p>
              </Col>
              <Col span={3} className="mb-5">
                <NewInputComp label="" star="none" />
              </Col>
              <Col span={1} offset={1} className="mt-1">
                <SelectComponent label="" star="none" />
              </Col>
              <Col span={15} offset={1} className="mt-1">
                <p>
                  of the booked period will be charged should a guest depart
                  ahead of the booked date of departure. In case of advance
                  payment the amount will be non-refundable.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={3} className="mt-1 ">
                <p>Non Refundable: (All Seasons): </p>
              </Col>
              <Col span={3} className="mb-5">
                <NewInputComp label="" star="none" />
              </Col>
              <Col span={1} offset={1} className="mt-1">
                <SelectComponent label="" star="none" />
              </Col>
              <Col span={5} offset={1} className="mt-1">
                <p>charge of total booking without exception.</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </fieldset>
    </>
  );
};

export default SavePolicyDetails;
