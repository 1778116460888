import serviceUtil from "../..";

const HouseBoatGetAll = () => {
  return serviceUtil
    .get("user/houseboat/getAllHouseBoatRoomDetails")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const GetHouseboatRoomCategory = () => {
  return serviceUtil
    .get("/master/houseboatSettings/getHouseboatRoomCategory")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const GetHouseboatType = () => {
  return serviceUtil
    .get("master/houseboatSettings/getHouseboatRoomType")
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const UserHouseBoatGetfilter = (data) => {
  return serviceUtil
    .post("user/filterSearch/housebaotFilter", data)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};
const createHouseBoatGuestDetails = (data) => {
  return serviceUtil
    .post("user/houseboat/createHouseboatGuestDetails", data)
    .then((res) => {
      const data = res && res.data;
      return { data };
    })
    .catch((err) => {
      const errRes = err && err.response.data;
      return { errRes };
    });
};

const houseboatBookingEmail = (payload) => {
  return serviceUtil
    .post("user/houseboat/houseboatBookingEmail", payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      const errRes = err;
      return { errRes };
    });
};
export {
  HouseBoatGetAll,
  GetHouseboatRoomCategory,
  GetHouseboatType,
  UserHouseBoatGetfilter,
  createHouseBoatGuestDetails,
  houseboatBookingEmail,
};
