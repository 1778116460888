import HotelBookNow from "../pages/usersPage/userHotels/hotelInnerPages/hotelBookNow";
import HotelsAllPage from "../pages/usersPage/userHotels/hotelsAllPage";
import PackageAllPage from "../pages/usersPage/userPackage/packageAllPage";
import UserShikaraAllPage from "../pages/usersPage/userShikara/userShikaraAllPage";
import { HotelBookNowSecondPage } from "./../pages/usersPage/userHotels/hotelBookNow/index";

const userAllRoutPathesObj = [
  {
    element: (
      <>
        <HotelsAllPage />
      </>
    ),
    path: "/hotel/all",
    index: true,
  },
  {
    element: (
      <>
        <PackageAllPage />
      </>
    ),
    path: "/package/all",
    index: true,
  },
  {
    element: (
      <>
        <UserShikaraAllPage />
      </>
    ),
    path: "/shikara/all",
    index: true,
  },

  {
    element: (
      <>
        <HotelBookNow />
      </>
    ),
    path: "/hotel/details",
    index: true,
  },
  {
    element: (
      <>
        <HotelBookNow />
      </>
    ),
    path: "/hotel/:id",
    index: true,
  },
  {
    element: (
      <>
        <HotelBookNowSecondPage />
      </>
    ),
    path: "/hotel/checkout",
    index: true,
  },
  {
    element: (
      <>
        <HotelBookNowSecondPage />
      </>
    ),
    path: "/hotel/checkout/*",
    index: true,
  },
];

export default userAllRoutPathesObj;
