export const partnerRoutesPath = {
    1: "/partner/partnerDashboard", //1
    2:"/partner/calendar",
    3:"/partner/gallery",
    4:"/partner/occupancy",
    5:"/partner/contractRate",
    6:"/partner/policy",
    7:"/partner/hotelProfile",
    8:"/partner/availability",
    9:"/partner/hotelAvailability",
    10:"/partner/policy/savePolicyDetails"
    
   
   };