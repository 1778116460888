import DropDownComp from "../../atoms/dropdownComponent/DropDownComp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { faKey, faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom/dist";

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// import LOGO from "../../assets/logo/Flinko Logo.svg";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";

const HeaderComponent = () => {
  const nav = useNavigate();
  const [openMode, setopenMode] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState();

  const handleMobileMenuOpen = (e) => {
    setMobileMoreAnchorEl(e.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const isMobileOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = "primary-search-account-menu-mobile";

  const dropdownOption = {
    profile: (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <p onClick={""}>
        <FontAwesomeIcon style={{ color: "#164888" }} icon={faUser} /> Profile
      </p>
    ),
    changePassword: (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <p onClick={""}>
        <FontAwesomeIcon style={{ color: "#164888" }} icon={faKey} /> Change
        Password
      </p>
    ),
    hr: (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <hr style={{ margin: "0px", padding: "0px" }} />
    ),
    logout: (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <p
        onClick={() => {
          localStorage.clear();
          setTimeout(function () {
            nav("/");
          }, 10);
        }}
      >
        <FontAwesomeIcon style={{ color: "red" }} icon={faPowerOff} /> Logout
      </p>
    ),
  };
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={handleMobileMenuClose}
      open={isMobileOpen}
    >
      <MenuItem>
        <IconButton sx={{ mr: 2 }}>
          <SettingsOutlinedIcon fontSize="small" />
        </IconButton>
        <p className="mb-0">Settings</p>
      </MenuItem>
      <MenuItem>
        <IconButton sx={{ mr: 2 }}>
          <NotificationsNoneOutlinedIcon fontSize="small" />
        </IconButton>
        <p className="mb-0">Notification</p>
      </MenuItem>
      <MenuItem>
        <IconButton sx={{ mr: 2 }}>
          <Avatar
            alt="Remy Sharp"
            src="/static/images/avatar/2.jpg"
            sx={{ width: 25, height: 25 }}
          />
        </IconButton>
        <p className="mb-0">Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, height: "50px" }}>
      <AppBar
        sx={{
          backgroundColor: "#164888",
          height: "50px",
        }}
      >
        <Container maxWidth="m" sx={{ pr: 0 }} className="pr-0">
          <Toolbar
            style={{ minHeight: "50px", paddingRight: "0px" }}
            sx={{ pr: 0 }}
            className="pr-0"
          >
            <img
              className="px-3 "
              width="70"
              src="https://www.godsownstay.com/resources/images/sublogo1.png"
              alt="GODSOWNSTAY"
            />
            <small className="fs-4">Godsownstay.com</small>
            <Box sx={{ flexGrow: 1 }}>
              <div className="mx-4 px-3">
                <div className="headerLine"></div>
              </div>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Box
                sx={{ display: { xs: "none", md: "flex" } }}
                onClick={() => {
                  setopenMode(openMode ? false : true);
                }}
              >
                <IconButton>
                  <Avatar
                    src="/broken-image.jpg"
                    sx={{ width: 25, height: 25 }}
                  />
                  <span>
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "0.8rem",
                        fontWeight: "300",
                      }}
                    >
                      welcome.
                    </p>
                    <p style={{ color: "#fff", fontSize: "0.9rem" }}>DEMO</p>
                  </span>
                  <DropDownComp
                    placement={"bottomRight"}
                    arrow={true}
                    icon={true}
                    open={openMode}
                    overlayStyle={{ marginTop: "50px", marginLeft: "45px" }}
                    DropOptions={[
                      dropdownOption.profile,
                      dropdownOption.changePassword,
                      dropdownOption.hr,
                      dropdownOption.logout,
                    ]}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <IconButton
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
};

export default HeaderComponent;
