import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd";
import { useFormik } from "formik";
import React from "react";
import { forgotPassword } from "../../../services/utils/loginForm";
import BtnComp from "../../atoms/buttonComponent/BtnComp";
import NewInputComp from "../../atoms/input/NewInputComp";
import { NotificationBox } from "../../atoms/notificationBox/NotificationBox";
import ModalComponent from "../../molecules/modalComponent/ModelComponent";
import { ForgetPasswordSchema } from "./ForgetPasswordSchema";

function ForGetPasswordModel({ setModalOpen }) {
  const [contextHolder, openNotificationSucess, openNotificationError] =
    NotificationBox();

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: { emailId: "", userName: "" },
    validationSchema: ForgetPasswordSchema,

    onSubmit: (values, action) => {
      addDataFunction();
      action.resetForm();
      setModalOpen(false);
    },
  });
  const handleClose = () => {
    setModalOpen(false);
  };

  const addDataFunction = async () => {
    if (values.emailId.trim() != "arunkumarsneeli2@gmail.com") {
      openNotificationError("Please enter correct emaid id");
    } else {
      const res = await forgotPassword(values);
      localStorage.setItem("resetToken", res?.data?.token);
      try {
        res.data.error
          ? openNotificationError(res.data.message)
          : openNotificationSucess(res.data.message);
      } catch (error) {
        res ? openNotificationError("Something Went Wrong..!") : "";
      }
    }
  };

  return (
    <div>
      {contextHolder}
      <ModalComponent
        showFooter={true}
        closable={handleClose}
        modalTitle="Forgot Password?"
        onCloseIconClick={handleClose}
      >
        <Row className="p-2 mx-2" justify="space-around" align="middle">
          <Col xs={12} sm={24} md={12} lg={4}>
            <span className="re-input-p">*</span>
            MAIL ID :
          </Col>
          <Col xs={12} sm={24} md={12} lg={18}>
            <NewInputComp
              name="emailId"
              value={values.emailId ? values.emailId : ""}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              placeholder="email"
              label=""
              star="none"
              suffix={<FontAwesomeIcon icon={faEnvelope} />}
            />
          </Col>
        </Row>
        <Row className="p-2 mx-2" justify="space-around" align="middle">
          <Col xs={12} sm={24} md={12} lg={4}>
            <span className="re-input-p">*</span>
            USER NAME :
          </Col>
          <Col xs={12} sm={24} md={12} lg={18}>
            <NewInputComp
              name="userName"
              value={values.userName ? values.userName : ""}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              placeholder="User Name"
              label=""
              star="none"
              suffix={<FontAwesomeIcon icon={faUser} />}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={1}></Col>
          <Col xs={24} sm={24} md={24} lg={22}>
            <BtnComp
              color={"white"}
              bg_color="#ff4d63"
              btnName="Submit"
              width="100%"
              // btnClickFun={handleSubmit}
              btnClickFun={handleSubmit}
            />
          </Col>
        </Row>
      </ModalComponent>
    </div>
  );
}

export default ForGetPasswordModel;
