import { createSlice } from '@reduxjs/toolkit'

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    footerActiveTab:0
  },
  reducers: {
    setActiveFooterTab:(state,action)=>{
      state.footerActiveTab=action.payload
    },  
  },
});


export const {setActiveFooterTab} = homeSlice.actions
export default homeSlice.reducer
