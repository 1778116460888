import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  advanceSearchFilterApi,
  userHotelDetailsBook,
} from "../../services/utils/userUtils/hotelutils";
// import { filterSearchfun } from '../../services/utils/userUtils/dynamicFun';
// import { hotelObjStructure } from '../../constants/states';

const fetchHotelData = createAsyncThunk("data/hotel", async () => {
  const response = await userHotelDetailsBook();
  return response.data;
});

const searchApi = createAsyncThunk(
  "data/searchApi",
  async (searchValue, { rejectWithValue }) => {
    try {
      const response = await advanceSearchFilterApi(searchValue);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const hotelSlice = createSlice({
  name: "hotelAllData",
  initialState: {
    data: {},
    filterData: {},
    selectedData: [],
    selectedRoom: {
      actualRoomRate: 0,
      serviceAndGst: 0,
      gosOffers: [],
      roomRate: 0,
    },
    roomPrice: {},
    error: null,
  },
  reducers: {
    setHotelAllData: (state, action) => {
      state.data = action.payload;
    },
    selectHotel: (state, action) => {
      state.selectedData = action.payload;
    },
    removeSelectHotel: (state) => {
      state.selectedData = {};
    },
    setSelectedRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    setRoomPrice: (state, action) => {
      state.roomPrice = action.payload;
    },
    removeRoomPrice: (state) => {
      state.roomPrice = {};
    },
    removeSelectedRoom: (state) => {
      state.selectedRoom = {};
    },
    getHotelData: (state) => {
      state.filterData = state.data;
    },
    addFilteredData: (state, action) => {
      state.filterData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHotelData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.filterData = action.payload;
        state.error = null;
      })
      .addCase(fetchHotelData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchApi.fulfilled, (state, action) => {
        state.loading = false;
        state.filterData = action.payload.data;
      })
      .addCase(searchApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export { fetchHotelData, searchApi };
export const {
  getHotelData,
  filterHotelDataBySearch,
  selectHotel,
  removeSelectHotel,
  setSelectedRoom,
  setRoomPrice,
  removeRoomPrice,
  setHotelAllData,
  removeSelectedRoom,
  addFilteredData,
} = hotelSlice.actions;
export const allHotelData = (state) => state.hotelAllData.filterData;
export default hotelSlice.reducer;
